import React, { useMemo, useState } from "react";
import Head from "../../../../../layout/head/Head";
import Content from "../../../../../layout/content/Content";
import {
  Block,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  PreviewAltCard,
} from "../../../../../components/Component";
import { Step, Steps } from "react-step-builder";
import { TenantPaymentReview } from "./TenantPaymentReview";
import { TenantPaymentConfirmation } from "./TenantPaymentConfirmation";
import { TenantPaymentDetails } from "./TenantPaymentDetails";
import moment from "moment/moment";
import { useLocation } from "react-router";
import { DATE_FORMAT } from "../../../../../utils";
export const TenantPaymentWizardContext = React.createContext();

const Header = (props) => {
  return (
    <div className="steps clearfix">
      <ul>
        <li
          className={
            (props.current >= 0 ? "first done" : "first") + " wizard-margin"
          }
        >
          <a href="#wizard-01-h-0" onClick={(ev) => ev.preventDefault()}>
            <span className="number">Details</span>
          </a>
        </li>
        <li
          className={
            (props.current >= 2 ? "first done" : "first") + " wizard-margin"
          }
        >
          <Icon name="arrow-long-right"></Icon>
        </li>
        <li className={(props.current >= 2 ? "done" : "") + " wizard-margin"}>
          <a href="#wizard-01-h-1" onClick={(ev) => ev.preventDefault()}>
            <span className="number">Review</span>
          </a>
        </li>
        <li className={(props.current >= 3 ? "done" : "") + " wizard-margin"}>
          <Icon name="arrow-long-right"></Icon>
        </li>
        <li className={(props.current >= 3 ? "done" : "") + " wizard-margin"}>
          <a href="#wizard-01-h-2" onClick={(ev) => ev.preventDefault()}>
            <span className="number">Confirmation</span>
          </a>
        </li>
        <li></li>
      </ul>
    </div>
  );
};

const config = {
  before: Header,
};

const TenantRentPayment = () => {
  const [data, setData] = useState();
  const location = useLocation();

  const paymentWizardContext = useMemo(
    () => ({
      data: data,
      updateData: (key, value) => {
        setData((prev) => ({
          ...prev,
          [key]: value,
        }));
      },
    }),
    [data]
  );

  return (
    <React.Fragment>
      <Head title="Payment"></Head>

      <Content>
        <BlockHead size="sm">
          <BlockHeadContent>
            <BlockTitle page>Payment</BlockTitle>
          </BlockHeadContent>
          <BlockDes className="text-soft">
            {location.state?.history ? "" : `${moment().format(DATE_FORMAT)}`}
          </BlockDes>
        </BlockHead>

        <Block>
          <PreviewAltCard>
            <div className="nk-wizard nk-wizard-simple is-alter wizard clearfix">
              <TenantPaymentWizardContext.Provider value={paymentWizardContext}>
                <Steps config={location.state?.history ? null : config}>
                  <Step component={TenantPaymentDetails} />
                  <Step component={TenantPaymentReview} />
                  <Step component={TenantPaymentConfirmation} />
                </Steps>
              </TenantPaymentWizardContext.Provider>
            </div>
          </PreviewAltCard>
        </Block>
      </Content>
    </React.Fragment>
  );
};

export default TenantRentPayment;
