import React, { useEffect, useState } from "react";
import {
  Block,
  BlockContent,
  BlockDes,
  BlockHead,
  BlockTitle,
  Button,
  Icon,
  PreviewCard,
} from "../../components/Component";
import Logo from "../../images/logo.svg";
import LogoDark from "../../images/logo-dark.svg";
import PageContainer from "../../layout/page-container/PageContainer";
import Head from "../../layout/head/Head";
import AuthFooter from "./AuthFooter";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Alert, Spinner } from "reactstrap";
import { OTPVerificationType } from "../../utils/Utils";
import { useHistory, useLocation } from "react-router-dom";
import { changeEmail, verifyEmailChangeOTP } from "./AuthData";
import { showSuccessToast } from "../../utils";

const OTPVerification = () => {
  const { errors, register, handleSubmit } = useForm();
  const [loading, setLoading] = useState(false);
  const [loadingResend, setLoadingResend] = useState(false);

  const history = useHistory();
  const location = useLocation();
  const [errorValue, setErrorValue] = useState(null);
  const [isShowTimer, setIsShowTimer] = useState(false);
  const [timerCount, setTimer] = useState(60);

  useEffect(() => {
    if (
      location.state.accountType === undefined &&
      location.state.otpType === OTPVerificationType.signup
    ) {
      // checkStateAndMove();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFormSubmit = (formData) => {
    var data = formData;
    data["email"] = location.state.email;
    setLoading(true);
    verifyEmailChangeOTP(data, (data, error) => {
      if (error === null) {
        showSuccessToast("Account email has been changed successfully!");
          history.push({
            pathname: `${process.env.PUBLIC_URL}/user-profile-setting`,
          });
      } else {
        setLoading(false);
        setErrorValue(error.message)
      }
    });
  };

  function resend() {
    var data = {
      token: location.state.token,
      email: location.state.email,
    };
    changeEmail(data, (data, error) => {
      setLoading(false)
      if (error === null) {
        
      } else {
        setLoading(false);
        setErrorValue(error.message)
      }
    });
  }

  const showTimer = () => {
    setIsShowTimer(true);
    let interval = setInterval(() => {
      setTimer((lastTimerCount) => {
        lastTimerCount <= 1 && clearInterval(interval)
        lastTimerCount - 1 === 0 && setIsShowTimer(false);
        lastTimerCount - 1 === 0 && setTimer(60);
        return lastTimerCount - 1;
      });
    }, 1000); //each count lasts for a second
    return () => {
      clearInterval(interval);
      setTimer(60);
      setIsShowTimer(false);
    };
  };

  return (
    <React.Fragment>
      <Head title="Verification" />
      <PageContainer>
        <Block className="nk-block-middle nk-auth-body  wide-xs">
          <div className="brand-logo pb-4 text-left mx-4">
            <Link to={process.env.PUBLIC_URL + "/"} className="logo-link">
              <img
                className="logo-light logo-img logo-img-lg"
                src={Logo}
                alt="logo"
              />
              <img
                className="logo-dark logo-img logo-img-lg"
                src={LogoDark}
                alt="logo-dark"
              />
            </Link>
          </div>
          <PreviewCard className="card-bordered" bodyClass="card-inner-lg">
            <BlockHead>
              <BlockContent>
                <BlockTitle tag="h5">
                  {location.state.otpType === OTPVerificationType.signup
                    ? "Email verification"
                    : "Reset password verification"}
                </BlockTitle>
                <BlockDes>
                  <p>
                    Enter the 6-digit code that you received in your email to
                    verify your email address.
                  </p>
                </BlockDes>
              </BlockContent>
            </BlockHead>
            {errorValue && (
              <div className="mb-3">
                <Alert color="danger" className="alert-icon">
                  {" "}
                  <Icon name="alert-circle" /> {errorValue}{" "}
                </Alert>
              </div>
            )}
            <form
              className="is-alter"
              onSubmit={handleSubmit(handleFormSubmit)}
            >
              <div className="form-group">
                <div className="form-label-group">
                  <label className="form-label" htmlFor="default-01">
                    6-digit code
                  </label>
                </div>
                <div className="form-control-wrap">
                  <input
                    type="text"
                    className="form-control form-control-lg"
                    id="default-01"
                    name="otp"
                    placeholder=""
                    minLength={6}
                    maxLength={6}
                    ref={register({
                      required: true,
                      message: "Please Check your OTP, try again",
                    })}
                  />
                  {errors.otp && (
                    <span className="invalid">This field is required</span>
                  )}
                </div>
              </div>
              <div className="form-group">
                <Button
                  type="submit"
                  color="primary"
                  size="lg"
                  className="btn-block"
                >
                  {loading ? <Spinner size="sm" color="light" /> : "Verify"}
                </Button>
              </div>
            </form>
            <div className="form-note-s2 text-center pt-4">
              {loadingResend ? (
                <Spinner size="sm" color="light" />
              ) :isShowTimer ? (
                <p className="text-danger">Wait {timerCount} seconds to resend</p>
              ) : (
                <Button
                  size="lg"
                  className="text text-primary"
                  onClick={resend}
                >
                  Resend
                </Button>
              )}
            </div>
            {/* <div className="form-note-s2 text-center pt-4">
              <Link to={`${process.env.PUBLIC_URL}/auth-login`}>
                <strong>Back</strong>
              </Link>
            </div> */}
          </PreviewCard>
        </Block>
        <div className="gap-100px" />
        <AuthFooter />
      </PageContainer>
    </React.Fragment>
  );
};
export default OTPVerification;
