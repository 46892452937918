import React, { useEffect, useState } from "react";
import { RSelect } from "../Component";
import { MaintenanceRequestStatusTypes } from "../../utils";

const MaintenanceStatusDropdown = ({
  value,
  isMulti = false,
  onSelect,
  isShowAll = false,
  disabled = false,
  ...props
}) => {
  
  const [list, setList] = useState();
  useEffect(() => {
    setList(MaintenanceRequestStatusTypes);
  }, []);

  return (
    <RSelect
      options={list ?? []}
      isMulti={isMulti}
      isDisabled={disabled}
      placeholder={"Status"}
      className={"w-min-300px"}
      value={value ?? (isShowAll ? (list ? list[0] : null) : null)}
      onChange={(e) => {
        if (isShowAll === false) {
          onSelect(e);
        } else {
          e.id === 0 ? onSelect(undefined) : onSelect(e);
        }
      }}
      {...props}
    />
  );
};

export default MaintenanceStatusDropdown;
