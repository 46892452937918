import React, { useRef, useState } from "react";
import {
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  BlueText,
  Button,
  ConfirmationAlert,
  DataTableHead,
  DataTableItem,
  DataTableRow,
  Icon,
  PaginationComponent,
  PreviewAltCard,
  PropertyDropdown,
  UserAvatar,
} from "../../../components/Component";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import { useEffect } from "react";
import {
  Badge,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import { fetchTenantAssociateWithPM, inviteTenant, removeTenantByPM } from "./TanantData";
import {
  AppText,
  COUNTRY_CODE,
  CURRENCY_CODE,
  DATE_FORMAT,
  formatPhoneNumber,
  numberFormatter,
  showErrorToast,
  showSuccessToast,
} from "../../../utils";
import LoadingComponent from "../../../layout/spinner/LoadingSpinner";
import AddTenants from "../properties/AddRemoveTenants";
import { findUpper } from "../../../utils/Utils";
import { useHistory } from "react-router";
import AddInvoice from "./AddInvoice";
import { ChatFloatingUI } from "../chat/ChatFloatingUI";
import { ChatType } from "../../../services/chat/chatservice";
import { Storage } from "../../../services/storage/storage";
import moment from "moment/moment";

const TenantList = () => {
  const history = useHistory();
  const [currentPage, setCurrentPage] = useState(1);
  const [property, setProperty] = useState();
  const [itemPerPage, setItemPerPage] = useState(20);
  const [totalItems, setTotalItems] = useState(0);
  const [isInitScreen, setIsInitScreen] = useState(true);
  const [tenants, setTenants] = useState([]);
  const [onSearchText, setSearchText] = useState("");
  const [onSearch, setOnSearch] = useState(true);
  const [tenantsModal, setTenantsModal] = useState(false);
  const [invoiceModal, setInvoiceModal] = useState(false);
  const [selectedTenant, setSelectedTenant] = useState();
  const [chatId, setChatId] = useState();
  const [user] = useState(Storage.getLoggedInUser()?.user);

  const dropdownPages = [5, 10, 15, 20];
  const [sort, setSortState] = useState("asc");
  const toggle = () => setOnSearch(!onSearch);
  const searchInput = useRef(null);

  useEffect(() => {
    loadTenantList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isInitScreen) return;
    if (currentPage === 1) {
      loadTenantList();
    } else {
      setCurrentPage(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemPerPage, onSearchText, sort, property]);

  useEffect(() => {
    if (searchInput.current && !onSearch) {
      searchInput.current.focus();
    }
  }, [onSearch]);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // onChange function for searching name
  const onFilterChange = (e) => {
    setSearchText(e.target.value);
    setCurrentPage(1);
  };

  const loadTenantList = () => {
    let sortParams = { field: "firstName", order: sort };
    // let serviceIds = selectedServices.map((item) => item.id);
    let params = {
      pageInfo: { pageIndex: currentPage, itemsPerPage: itemPerPage },
      sorting: sortParams,
    };

    if (onSearchText.length > 0) {
      params["search"] = onSearchText;
    }

    if (property) {
      params["propertyId"] = property.id;
    }

    fetchTenantAssociateWithPM(params, (data, error) => {
      if (error === null) {
        setTenants(data.list);
        if (currentPage === 1) {
          setTotalItems(data.pageInfo?.totalItems ?? 0);
        }
      }
      setIsInitScreen(false);
    });
  };

  function removeTenant(item) {
    let id = item?.unitTenant?.id;
    id &&
      ConfirmationAlert(
        "Removing Tenant!",
        `Do you want to remove your tenant ${item.name}?`,
        () => {
          removeTenantByPM({ id: id }, (data, error) => {
            if (error === null) {
              loadTenantList();
            } else {
              showErrorToast(error.message);
            }
          });
        }
      );
  }

  function resentInvitation(item) {
    
    let params = {
      unitId: item?.unit?.id,
      rentAmount: parseFloat(item?.unitTenant?.rent ?? 0),
      startDate: item?.unitTenant?.startDate
        ? moment(item?.unitTenant?.startDate).format("YYYY-MM-DD")
        : null,
      email: item?.email,
    };

    if (item?.unitTenant?.endDate) {
      params.endDate = moment(item?.unitTenant?.endDate).format("YYYY-MM-DD");
    }

    inviteTenant(params, (data, error) => {
      if (error === null) {
        showSuccessToast(AppText.invitationSendSuccessfully);
      } else {
        showErrorToast(error.message);
      }
    });
  }
  
  function addInvoice(item) {
    setSelectedTenant(item);
    setInvoiceModal(true);
  }

  return (
    <React.Fragment>
      <Head title="Add invoice"></Head>
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle page>{AppText.tenants}</BlockTitle>
            </BlockHeadContent>
            <BlockHeadContent>
              <Button
                className="me-2"
                color="primary"
                onClick={() => setInvoiceModal(true)}
              >
                <span>Add invoice</span>
              </Button>
              <Button color="primary" onClick={() => setTenantsModal(true)}>
                <span>Invite tenant</span>
              </Button>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        <Block>
          <div className="card-inner bg-white position-relative card-tools-toggle border-bottom">
            <div className="card-title-group">
              <div className="card-tools">
                <div className="form-inline flex-nowrap gx-3">
                  <div className="form-wrap">
                    <PropertyDropdown
                      value={property}
                      isShowAll={true}
                      onSelect={(e) => setProperty(e)}
                      className="w-250px"
                    />
                  </div>
                </div>
              </div>
              <div className="card-tools me-n1">
                <ul className="btn-toolbar gx-1">
                  <li>
                    <a
                      href="#search"
                      onClick={(ev) => {
                        ev.preventDefault();
                        toggle();
                      }}
                      className="btn btn-icon search-toggle toggle-search"
                    >
                      <Icon name="search"></Icon>
                    </a>
                  </li>
                  <li className="btn-toolbar-sep"></li>
                  <li>
                    <ul className="btn-toolbar gx-1">
                      <li>
                        <UncontrolledDropdown>
                          <DropdownToggle
                            color="tranparent"
                            className="btn btn-trigger btn-icon dropdown-toggle"
                          >
                            <Icon name="setting"></Icon>
                          </DropdownToggle>
                          <DropdownMenu end className="dropdown-menu-xs">
                            <ul className="link-check">
                              <li>
                                <span>Show</span>
                              </li>
                              {dropdownPages.map((item, index) => {
                                return (
                                  <React.Fragment key={index}>
                                    <li
                                      className={
                                        itemPerPage === item ? "active" : ""
                                      }
                                    >
                                      <DropdownItem
                                        tag="a"
                                        href="#dropdownitem"
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                          setItemPerPage(item);
                                        }}
                                      >
                                        {item}
                                      </DropdownItem>
                                    </li>
                                  </React.Fragment>
                                );
                              })}
                            </ul>
                            <ul className="link-check">
                              <li>
                                <span>Order</span>
                              </li>
                              <li className={sort === "desc" ? "active" : ""}>
                                <DropdownItem
                                  tag="a"
                                  href="#dropdownitem"
                                  onClick={(ev) => {
                                    ev.preventDefault();
                                    setSortState("desc");
                                    // sortFunc("dsc");
                                  }}
                                >
                                  DESC
                                </DropdownItem>
                              </li>
                              <li className={sort === "asc" ? "active" : ""}>
                                <DropdownItem
                                  tag="a"
                                  href="#dropdownitem"
                                  onClick={(ev) => {
                                    ev.preventDefault();
                                    setSortState("asc");
                                    // sortFunc("asc");
                                  }}
                                >
                                  ASC
                                </DropdownItem>
                              </li>
                            </ul>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
            <div className={`card-search search-wrap ${!onSearch && "active"}`}>
              <div className="card-body">
                <div className="search-content">
                  <Button
                    className="search-back btn-icon toggle-search active"
                    onClick={() => {
                      setSearchText("");
                      toggle();
                    }}
                  >
                    <Icon name="arrow-left"></Icon>
                  </Button>
                  <input
                    type="text"
                    className="border-transparent form-focus-none form-control"
                    placeholder="Search by company name"
                    value={onSearchText}
                    onChange={(e) => onFilterChange(e)}
                    ref={searchInput}
                  />
                  <Button className="search-submit btn-icon">
                    <Icon name="search"></Icon>
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div className="nk-tb-list nk-tb-ulist bg-white">
            <DataTableHead className="nk-tb-item nk-tb-head">
              <DataTableRow>
                <span className="sub-text text-secondary">Name</span>
              </DataTableRow>
              <DataTableRow>
                <span className="sub-text text-secondary">Email</span>
              </DataTableRow>
              <DataTableRow size="lg">
                <span className="sub-text text-secondary">Phone</span>
              </DataTableRow>
              <DataTableRow size="lg">
                <span className="sub-text text-secondary">Property</span>
              </DataTableRow>
              <DataTableRow size="lg">
                <span className="sub-text text-secondary">Unit</span>
              </DataTableRow>
              <DataTableRow size="lg">
                <span className="sub-text text-secondary text-end">Rent</span>
              </DataTableRow>
              <DataTableRow size="lg">
                <span className="sub-text text-secondary text-end">
                  Balance
                </span>
              </DataTableRow>
              <DataTableRow size="lg">
                <span className="sub-text text-secondary">Lease starts on</span>
              </DataTableRow>
              <DataTableRow size="lg">
                <span className="sub-text text-secondary">Lease ends on</span>
              </DataTableRow>
              <DataTableRow>
                <span className="sub-text text-secondary">Status</span>
              </DataTableRow>
              <DataTableRow className="nk-tb-col-tools text-end"></DataTableRow>
            </DataTableHead>
            {tenants?.map((item, index) => {
              let isInvited =
                item.unitTenant?.status === "PENDING" ? true : false;
              return (
                <DataTableItem
                  key={index}
                  onClick={(ev) => {
                    ev.preventDefault();
                  }}
                >
                  <DataTableRow>
                    {item?.name?.length > 0 ? (
                      <BlueText
                        isShowImage={true}
                        image={item?.image?.thumbUrl}
                        title={item.name}
                        onClick={(ev) => {
                          ev.preventDefault();
                          const name = item?.name
                            .trim()
                            .replace(/\s+/g, "-")
                            .toLowerCase();
                          history.push({
                            pathname: `${process.env.PUBLIC_URL}/tenant-detail/${item.id}-${name}`,
                          });
                        }}
                      />
                    ) : (
                      <div className="project-info">
                        <h6 className="title fSize14">{"-"}</h6>
                      </div>
                    )}
                  </DataTableRow>
                  <DataTableRow className="text-secondary">
                    <span>{item.email}</span>
                  </DataTableRow>
                  <DataTableRow size="lg" className="text-secondary">
                    <span>
                      {item.phone && !isInvited
                        ? formatPhoneNumber(COUNTRY_CODE + " ", item.phone)
                        : "-"}
                    </span>
                  </DataTableRow>
                  <DataTableRow size="lg" className="text-secondary">
                    <span>{item.property?.name}</span>
                  </DataTableRow>
                  <DataTableRow size="lg" className="text-secondary">
                    <span>{item.unit?.name}</span>
                  </DataTableRow>
                  <DataTableRow size="lg" className="text-secondary text-end">
                    <span>
                      {numberFormatter(
                        CURRENCY_CODE,
                        "" + (item.unitTenant?.rent ?? 0)
                      )}
                    </span>
                  </DataTableRow>
                  <DataTableRow size="lg" className="text-secondary text-end">
                    <span>
                      {numberFormatter(
                        CURRENCY_CODE,
                        "" + (item.unitTenant?.dueAmount ?? 0)
                      )}
                    </span>
                  </DataTableRow>
                  <DataTableRow size="lg" className="text-secondary">
                    <span>
                      {item?.unitTenant?.startDate
                        ? moment(item?.unitTenant?.startDate).format(
                            DATE_FORMAT
                          )
                        : "-"}
                    </span>
                  </DataTableRow>
                  <DataTableRow size="lg" className="text-secondary">
                    <span>
                      {item?.unitTenant?.endDate
                        ? moment(item?.unitTenant?.endDate).format(DATE_FORMAT)
                        : "-"}
                    </span>
                  </DataTableRow>
                  <DataTableRow className="text-secondary">
                    <span>
                      <Badge
                        className={`badge-dim fSize14 p-1 ${
                          isInvited
                            ? "bg-orange-dim text-orange"
                            : "bg-success-dim text-success"
                        } border-0`}
                        color={isInvited ? "orange" : "success"}
                      >
                        <span>{isInvited ? "Invited" : "Approved"}</span>
                      </Badge>
                    </span>
                  </DataTableRow>
                  <DataTableRow className="nk-tb-col-tools text-end">
                    <ul className="nk-tb-actions gx-1">
                      <li>
                        <UncontrolledDropdown>
                          <DropdownToggle
                            tag="a"
                            className="text-soft dropdown-toggle btn btn-icon btn-trigger"
                          >
                            <Icon name="more-h"></Icon>
                          </DropdownToggle>
                          <DropdownMenu end>
                            <ul className="link-list-opt no-bdr">
                              <li>
                                <DropdownItem
                                  tag="a"
                                  href="#markasdone"
                                  onClick={(ev) => {
                                    ev.preventDefault();
                                    addInvoice(item);
                                  }}
                                >
                                  <Icon name="sign-mxn"></Icon>
                                  <span>Add invoice</span>
                                </DropdownItem>
                              </li>
                              <li>
                                <DropdownItem
                                  tag="a"
                                  href="#chat"
                                  onClick={(ev) => {
                                    ev.preventDefault();
                                    setChatId(item.chatId);
                                  }}
                                >
                                  <Icon name="chat-circle"></Icon>
                                  <span>Message</span>
                                </DropdownItem>
                              </li>
                              <li>
                                <DropdownItem
                                  tag="a"
                                  href="#markasdone"
                                  onClick={(ev) => {
                                    ev.preventDefault();
                                    removeTenant(item);
                                  }}
                                >
                                  <Icon name="trash"></Icon>
                                  <span>Remove</span>
                                </DropdownItem>
                              </li>
                              {item.unitTenant?.status === "PENDING" && (
                                <li>
                                <DropdownItem
                                  tag="a"
                                  href="#markasdone"
                                  onClick={(ev) => {
                                    ev.preventDefault();
                                    resentInvitation(item);
                                  }}
                                >
                                  <Icon name="send-alt"></Icon>
                                  <span>Resend invitation</span>
                                </DropdownItem>
                              </li>
                              )}
                            </ul>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </li>
                    </ul>
                  </DataTableRow>
                </DataTableItem>
              );
            })}
          </div>
          <PreviewAltCard className={"border-top"}>
            {totalItems > 0 ? (
              <PaginationComponent
                itemPerPage={itemPerPage}
                totalItems={totalItems}
                paginate={paginate}
                currentPage={currentPage}
              />
            ) : (
              <div className="text-center">
                <span className="text-silent">No tenant added yet.</span>
              </div>
            )}
          </PreviewAltCard>
        </Block>
      </Content>
      <AddTenants
        isOpen={tenantsModal}
        onCancel={() => {
          setTenantsModal(false);
        }}
        onSave={() => {
          loadTenantList();
        }}
      />
      <AddInvoice
        isOpen={invoiceModal}
        tenant={selectedTenant}
        onCancel={() => {
          setInvoiceModal(false);
        }}
        onSave={() => {}}
      />
      <LoadingComponent isLoading={isInitScreen} />
      {chatId && (
        <ChatFloatingUI
          loggedInId={user.chatId}
          otherChatId={chatId}
          onClosed={() => setChatId()}
          chatType={ChatType.tenant_pm}
        />
      )}
    </React.Fragment>
  );
};
export default TenantList;
