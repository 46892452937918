import React, { Fragment, useEffect, useState } from "react";
import Head from "../../layout/head/Head";
import PageContainer from "../../layout/page-container/PageContainer";
import {
  Col,
  Button,
  Block,
  BlockContent,
  BlockDes,
  BlockHead,
  BlockTitle,
  PreviewCard,
  RSelect,
  Icon,
} from "../../components/Component";
import { Step, Steps } from "react-step-builder";
import { AppText, SuggestionType, businessSizes, propertiesTypes } from "../../utils";
import { useForm } from "react-hook-form";
import { Alert, Form, Spinner } from "reactstrap";
import {
  serviceOfferingsData,
  associationsData,
  socialResponsibilityData,
} from "./VendorSetupData";
import Dropzone from "react-dropzone";
import Swal from "sweetalert2";
import { components } from "react-select";
import Logo from "../../images/long-logo.png";
import {
  fetchAssociationList,
  fetchUserInfo,
  getServices,
  vendorSetup,
} from "./AuthData";
import { CanNotFindServiceModel } from "./CanNotFindService";
import { Storage } from "../../services/storage/storage";
import { ThanksLettingUsKnow } from "./ThanksLettingUsKnow";
import { SuggestService } from "./SuggestService";
import { ImageCropper } from "../../components/cropper/cropper";

const Header = (props) => {
  return (
    <div className="steps clearfix">
      <ul>
        <li className={props.current >= 1 ? "first done" : "first"}>
          <a href="#wizard-01-h-0" onClick={(ev) => ev.preventDefault()}>
            <h5 className="text-capitalize">Business</h5>
          </a>
        </li>
        <li className={props.current >= 2 ? "done" : ""}>
          <a href="#wizard-01-h-1" onClick={(ev) => ev.preventDefault()}>
            <h5 className="text-capitalize">Details</h5>
          </a>
        </li>
        <li className={props.current >= 3 ? "done" : ""}>
          <a href="#wizard-01-h-2" onClick={(ev) => ev.preventDefault()}>
            <h5 className="text-capitalize">Brand</h5>
            <h4> </h4>
          </a>
        </li>
      </ul>
    </div>
  );
};

const config = {
  before: Header,
};

const Business = (props) => {
  const [isBusinessSizeError, setIsBusinessSizeError] = useState(false);
  const [isServicesError, setIsServicesError] = useState(false);
  const [loading, setLoading] = useState();
  const [errorVal, setError] = useState("");
  const [serviceList, setServiceList] = useState([]);
  const [isShowCanNotFindService, setIsShowCanNotFindService] = useState(false);
  const [isShowThanksLettingKnow, setIsShowThanksLettingKnow] = useState(false);
  const [isShowSuggestService, setIsShowSuggestService] = useState(false);

  // Change Page
  const { handleSubmit } = useForm();

  useEffect(() => {
    getServiceList();
  }, []);

  const onInputChange = (e) => {
    props.setState(e.target.name, e.target.value);
  };

  const onClosedThanks = () => {
    setIsShowThanksLettingKnow(false);
    props.setState("services", [{ label: "No service", id: 0 }]);
  };

  // submit function to add a new item
  const onFormSubmit = (data) => {
    // const { title, subtitle, description, tasks, totalTask } = data;
    if (props.state.size === null || props.state.size === undefined) {
      setIsBusinessSizeError(true);
    } else {
      setIsBusinessSizeError(false);
    }
    if ((props.state.services?.length ?? 0) === 0) {
      setIsServicesError(true);
    } else {
      setIsServicesError(false);
    }
    if (
      (props.state.services?.length ?? 0) !== 0 &&
      props.state.size !== null &&
      props.state.size !== undefined
    ) {
      let isSeriveSkipped = props.state.services?.some((item) => item.id === 0);
      let params = {
        website: props.state.website,
        businessSize: props.state.size.value,
        servicesIds: isSeriveSkipped
          ? []
          : props.state.services?.map((item) => item.id),
        stepNo: 1,
        isServiceSkipped: isSeriveSkipped,
      };
      setLoading(true);
      vendorSetup(null, params, (data, error) => {
        setLoading(false);
        if (error === null) {
          props.next();
        } else {
          setError(error.message);
        }
      });
    }
  };

  function getServiceList() {
    getServices({}, (list, error) => {
      if (error === null) {
        setServiceList(list);
      }
    });
  }

  const Menu = (props) => {
    return (
      <Fragment>
        <components.Menu {...props}>
          <div>
            <div>{props.children}</div>
            <div className="mx-2 my-1">
              <span className="text">
                Can't find a service? Click{" "}
                <a
                  href="#here"
                  className="text-azure"
                  onClick={(ev) => {
                    setIsShowCanNotFindService(true);
                  }}
                >
                  here
                </a>
              </span>
            </div>
          </div>
        </components.Menu>
      </Fragment>
    );
  };
  const Option = (props) => {
    return (
      <Fragment>
        <components.Option {...props}>{props.children}</components.Option>
      </Fragment>
    );
  };

  return (
    <>
      <br />
      <BlockHead>
        <BlockContent>
          <BlockTitle tag="h4">Tell us about your business</BlockTitle>
          <BlockDes>
            <p>
              This information will be viewed in your listing on the{" "}
              {process.env.REACT_APP_APP_NAME} marketplace.
            </p>
          </BlockDes>
        </BlockContent>
      </BlockHead>
      {errorVal && (
        <div className="mb-3">
          <Alert color="danger" className="alert-icon">
            {" "}
            <Icon name="alert-circle" /> {errorVal}{" "}
          </Alert>
        </div>
      )}
      <Block>
        <Form
          className="is-alter form-validate row gy-4"
          onSubmit={handleSubmit(onFormSubmit)}
        >
          <Col md="8">
            <div className="form-group">
              <label className="form-label">{`${
                AppText.website
              } (${AppText.optional.toLowerCase()})`}</label>
              <input
                type="text"
                name="website"
                defaultValue={props.state.website}
                placeholder="Enter your website url"
                onChange={(e) => onInputChange(e)}
                className="form-control fSize14"
              />
            </div>
          </Col>
          <Col md="8">
            <div className="form-group">
              <label className="form-label">{AppText.businessSize}</label>
              <div className="form-control-wrap">
                <RSelect
                  options={businessSizes}
                  defaultValue={props.state.size}
                  onChange={(e) => props.setState("size", e)}
                  name="size"
                />
                {isBusinessSizeError && (
                  <p className="text-danger fs-11px ff-italic">
                    This field is required
                  </p>
                )}
              </div>
            </div>
          </Col>
          <Col md="8">
            <div className="form-group">
              <label className="form-label">
                {AppText.primaryBusinessServices}
              </label>
              <div className="form-control-wrap">
                <RSelect
                  isMulti
                  components={{ Menu, Option }}
                  options={serviceList}
                  value={props.state.services}
                  onChange={(e) => {
                    // var list = e.filter(item => item.id !== 0);
                    // if (list.some(item => item.id === 1)) {
                    //   list = list.filter(item => item.id === 1);
                    // }
                    props.setState("services", e);
                  }}
                  name="services"
                />
                {/* Ref: https://codesandbox.io/s/zx45kql9q4?file=/src/MySelect.js 
                         https://julietonyekaoha.medium.com/customizing-reusable-react-select-menu-options-components-7642190caa73
                */}
                {isServicesError && (
                  <p className="text-danger fs-11px ff-italic">
                    This field is required
                  </p>
                )}
              </div>
            </div>
          </Col>
          <Col size="12">
            <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
              <li>
                <Button
                  color="primary"
                  size="md"
                  type="submit"
                  disabled={loading}
                  className="fSize14"
                >
                  {loading ? <Spinner size="sm" color="light" /> : "Continue"}
                </Button>
              </li>
            </ul>
          </Col>
        </Form>
      </Block>
      <CanNotFindServiceModel
        modal={isShowCanNotFindService}
        closeHandler={() => setIsShowCanNotFindService(false)}
        onSelectHandler={(index) => {
          setIsShowCanNotFindService(false);
          if (index === 0) {
            setIsShowThanksLettingKnow(true);
          } else {
            setIsShowSuggestService(true);
          }
        }}
      />
      <ThanksLettingUsKnow
        modal={isShowThanksLettingKnow}
        closeHandler={() => onClosedThanks()}
        onSelectHandler={() => {
          onClosedThanks();
        }}
      />
      <SuggestService
        modal={isShowSuggestService}
        closeHandler={() => setIsShowSuggestService(false)}
        onSelectHandler={() => {
          setIsShowSuggestService(false);
          setIsShowThanksLettingKnow(true);
        }}
      />
    </>
  );
};

const Details = (props) => {
  const { errors, register, handleSubmit } = useForm();
  const [loading, setLoading] = useState();
  const [errorVal, setError] = useState("");
  const [associationList, setAssociationList] = useState([]);
  const [isShowCanNotFindAssociation, setIsShowCanNotFindAssociation] =
    useState(false);
  const [isShowThanksLettingKnow, setIsShowThanksLettingKnow] = useState(false);
  const [isShowSuggestAssociation, setIsShowSuggestAssociation] =
    useState(false);

  useEffect(() => {
    getAssociations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onInputChange = (e) => {
    props.setState(e.target.name, e.target.value);
  };

  const handledServiceOfferings = (e) => {
    var serviceOfferings = props.state.serviceOfferings;
    if (serviceOfferings === undefined || serviceOfferings === null) {
      serviceOfferings = {};
      serviceOfferingsData.map((item) => {
        return (serviceOfferings[item.name] = false);
      });
    }
    serviceOfferings[e.target.name] = e.currentTarget.checked;
    props.setState("serviceOfferings", serviceOfferings);
  };

  const handledSocialResponsibility = (e) => {
    var socialResponsibility = props.state.socialResponsibility;
    if (socialResponsibility === undefined || socialResponsibility === null) {
      socialResponsibility = {};
      socialResponsibilityData.map((item) => {
        return (socialResponsibility[item.name] = false);
      });
    }
    socialResponsibility[e.target.name] = e.currentTarget.checked;
    props.setState("socialResponsibility", socialResponsibility);
  };

  const onFormSubmit = (data) => {
    let params = {
      serviceRadius: props.state.radius,
      propertyTypes: props.state.propertyTypes?.map((item) => item.value) ?? [],
      associationIds: props.state.associations?.map((item) => item.id) ?? [],
      isServiceIndividualUnits: props.state.serviceOfferings?.units ?? false,
      isServiceSuppliesGoods: props.state.serviceOfferings?.goods ?? false,
      isServiceUnionizedBusiness:
        props.state.serviceOfferings?.business ?? false,
      isSocialDiversityInclusionPolicy:
        props.state.socialResponsibility?.diversity ?? false,
      isSocialEnvironSustainPolicy:
        props.state.socialResponsibility?.environmental ?? false,
      isSocialStaffCovidVaccinated:
        props.state.socialResponsibility?.covid19 ?? false,
      stepNo: 2,
    };
    setLoading(true);
    vendorSetup(null, params, (data, error) => {
      setLoading(false);
      if (error === null) {
        props.next();
      } else {
        setError(error.message);
      }
    });
  };

  const getAssociations = () => {
    fetchAssociationList({}, (data, error) => {
      setLoading(false);
      if (error === null) {
        setAssociationList(data);
      }
    });
  };

  const Menu = (props) => {
    return (
      <Fragment>
        <components.Menu {...props}>
          <div>
            <div>{props.children}</div>
            <div className="mx-2 my-1">
              <span className="text">
                Can't find a association? Click{" "}
                <a
                  href="#here"
                  className="text-azure"
                  onClick={(ev) => {
                    setIsShowCanNotFindAssociation(true);
                  }}
                >
                  here
                </a>
              </span>
            </div>
          </div>
        </components.Menu>
      </Fragment>
    );
  };
  const Option = (props) => {
    return (
      <Fragment>
        <components.Option {...props}>{props.children}</components.Option>
      </Fragment>
    );
  };

  return (
    <>
      <br />
      <BlockHead>
        <BlockContent>
          <BlockTitle tag="h4">
            Please share additional details about your business
          </BlockTitle>
          <BlockDes>
            <p>
              We will use these details to match you with potential customers.
              You can always update this information later.
            </p>
          </BlockDes>
        </BlockContent>
      </BlockHead>
      {errorVal && (
        <div className="mb-3">
          <Alert color="danger" className="alert-icon">
            {" "}
            <Icon name="alert-circle" /> {errorVal}{" "}
          </Alert>
        </div>
      )}
      <Block>
        <Form
          className="form-validate row gy-4"
          onSubmit={handleSubmit(onFormSubmit)}
        >
          <Col md="4" className={"me-1"}>
            <div className="form-group">
              <label className="form-label">{AppText.serviceRadius}</label>
              <div className="form-control-wrap">
                <div className="input-group">
                  <input
                    type="number"
                    name="radius"
                    defaultValue={props.state?.radius ?? ""}
                    placeholder="How far do you service"
                    onChange={(e) => onInputChange(e)}
                    className="form-control fSize14"
                    ref={register({ required: true })}
                  />
                  <div className="input-group-append">
                    <span className="input-group-text">miles</span>
                  </div>
                </div>
                {errors.radius && (
                  <p className="invalid">This field is required</p>
                )}
              </div>
            </div>
          </Col>
          <Col md="8">
            <div className="form-group">
              <label className="form-label">
                {AppText.propertyTypesServiced}
              </label>
              <div className="form-control-wrap">
                <RSelect
                  isMulti
                  defaultValue={props.state?.propertyTypes}
                  options={propertiesTypes}
                  className={"fSize14"}
                  onChange={(e) => props.setState("propertyTypes", e)}
                />
              </div>
            </div>
          </Col>
          <Col md="8">
            <div className="preview-block">
              <div className="form-group">
                <label className="form-label">{AppText.associations}</label>
                <div className="form-control-wrap">
                  <RSelect
                  isMulti
                  components={{ Menu, Option }}
                  className={"fSize14"}
                  options={associationList}
                  value={props.state?.associations}
                  onChange={(e) => {
                    props.setState("associations", e);
                  }}
                  name="associations"
                />
                </div>
              </div>
            </div>
          </Col>
          <Col md="8">
            <div className="preview-block">
              <div className="form-group">
                <label className="form-label">{AppText.serviceOfferings}</label>
                <div className="g-3 align-center flex-wrap">
                  {serviceOfferingsData.map((item) => {
                    return (
                      <div className="g" key={item.id}>
                        <div className="custom-control custom-control-sm custom-checkbox">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id={item.id}
                            name={item.name}
                            defaultChecked={
                              props.state?.serviceOfferings?.[item.name] ??
                              false
                            }
                            onChange={(e) => handledServiceOfferings(e)}
                          />
                          <label
                            className="custom-control-label fSize14"
                            htmlFor={item.id}
                          >
                            {" "}
                            {item.text}
                          </label>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </Col>
          <Col>
            <div className="preview-block">
              <div className="form-group">
                <label className="form-label">
                  {AppText.corporateSocialResponsibility}
                </label>
                <div className="g-3 align-center flex-wrap">
                  {socialResponsibilityData.map((item) => {
                    return (
                      <div className="g" key={item.id}>
                        <div className="custom-control custom-control-sm custom-checkbox">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id={item.id}
                            name={item.name}
                            defaultChecked={
                              props.state?.socialResponsibility?.[item.name] ??
                              false
                            }
                            onChange={(e) => handledSocialResponsibility(e)}
                          />
                          <label
                            className="custom-control-label fSize14"
                            htmlFor={item.id}
                          >
                            {" "}
                            {item.text}
                          </label>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </Col>
          <Col size="12">
            <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
              <li>
                <Button
                  color="light"
                  size="md"
                  type="button"
                  onClick={() => props.prev()}
                  className="fSize14"
                >
                  Back
                </Button>
              </li>
              <li>
                <Button
                  color="primary"
                  size="md"
                  type="submit"
                  disabled={loading}
                  className="fSize14"
                >
                  {loading ? <Spinner size="sm" color="light" /> : "Continue"}
                </Button>
              </li>
            </ul>
          </Col>
        </Form>
      </Block>
      <CanNotFindServiceModel
          modal={isShowCanNotFindAssociation}
          type={SuggestionType.association}
          closeHandler={() => setIsShowCanNotFindAssociation(false)}
          onSelectHandler={(index) => {
            setIsShowCanNotFindAssociation(false);
            if (index === 0) {
              setIsShowThanksLettingKnow(true);
            } else {
              setIsShowSuggestAssociation(true);
            }
          }}
        />
        <ThanksLettingUsKnow
          modal={isShowThanksLettingKnow}
          type={SuggestionType.association}
          closeHandler={() => setIsShowThanksLettingKnow(false)}
          onSelectHandler={() => {
            setIsShowThanksLettingKnow(false);
          }}
        />
        <SuggestService //Same UI is used for add associations
          modal={isShowSuggestAssociation}
          type={SuggestionType.association}
          closeHandler={() => setIsShowSuggestAssociation(false)}
          onSelectHandler={() => {
            setIsShowSuggestAssociation(false);
            setIsShowThanksLettingKnow(true);
          }}
        />
    </>
  );
};

const Brand = (props) => {
  const { errors, register, handleSubmit } = useForm();
  const [isLogoError, setIsLogoError] = useState(false);
  const [loading, setLoading] = useState();
  const [errorVal, setError] = useState("");
  const [isImageCropperVisible, setIsImageCropperVisible] = useState(false);
  const [file, setFile] = useState();

  const onInputChange = (e) => {
    props.setState(e.target.name, e.target.value);
  };

  const handleImageDrop = (acceptedFiles) => {
    if((acceptedFiles?.length ?? 0) > 0) {
      setFile(acceptedFiles[0])
      setIsImageCropperVisible(true)
      setIsLogoError(false);
    }    
  };

  const onFormSubmit = () => {
    setIsLogoError((props.state.logo?.length ?? 0) === 0);
    if ((props.state.logo?.length ?? 0) !== 0) {
      let params = {
        description: props.state.description,
        stepNo: 3,
      };
      let logo = props.state.logo[0].file;
      setLoading(true);
      vendorSetup(logo, params, (data, error) => {
        setLoading(false);
        if (error === null) {
          Swal.fire({
            icon: "success",
            title: "Good job!",
            text: "You are all set and ready to gain more customers and business.",
            confirmButtonText: "Ok",
          }).then((result) => {
            fetchUserInfo((data, error) => {
              Storage.setLastSignedInTime(Date.now());
              Storage.setIsStaySignedIn(false);
              window.history.pushState(
                `${process.env.PUBLIC_URL ? process.env.PUBLIC_URL : "/"}`,
                "auth-login",
                `${process.env.PUBLIC_URL ? process.env.PUBLIC_URL : "/"}`
              );
              window.location.reload();
            });
          });
        } else {
          setError(error.message);
        }
      });
    }
  };

  return (
    <>
      <br />
      <BlockHead>
        <BlockContent>
          <BlockTitle tag="h4">
            Get more exposure by building your brand
          </BlockTitle>
          <BlockDes>
            <p>
              Having a logo and a description helps potential customers find and
              learn about you in {process.env.REACT_APP_APP_NAME} marketplace.
            </p>
          </BlockDes>
        </BlockContent>
      </BlockHead>
      {errorVal && (
        <div className="mb-3">
          <Alert color="danger" className="alert-icon">
            {" "}
            <Icon name="alert-circle" /> {errorVal}{" "}
          </Alert>
        </div>
      )}
      <Block>
        <Form
          className="form-validate row gy-4"
          onSubmit={handleSubmit(onFormSubmit)}
        >
          <Col size="12">
            <div className="form-group">
              <label className="form-label">
                {AppText.businessDescription}
              </label>
              <div className="form-control-wrap">
                <textarea
                  className="form-control no-resize fSize14"
                  name="description"
                  defaultValue={props.state?.description ?? ""}
                  onChange={(e) => onInputChange(e)}
                  ref={register({ required: true })}
                />
                {errors.description && (
                  <p className="invalid">This field is required</p>
                )}
              </div>
            </div>
          </Col>
          <Col sm="12">
            <label className="form-label">{AppText.logo}</label>
            <div className="form-control-wrap">
              <Dropzone
                onDrop={(acceptedFiles) => handleImageDrop(acceptedFiles)}
                accept={[".jpg", ".png", ".jpeg", ".svg"]}
                name="logo"
              >
                {({ getRootProps, getInputProps }) => (
                  <section>
                    <div
                      {...getRootProps()}
                      className="dropzone upload-zone dz-clickable"
                    >
                      <input {...getInputProps()} />
                      {(props.state.logo?.length ?? 0) === 0 && (
                        <div className="dz-message">
                          <span className="dz-message-text">
                            Drag and drop file
                          </span>
                          <span className="dz-message-or">or</span>
                          <strong className="text-primary">SELECT</strong>
                        </div>
                      )}
                      {props.state.logo?.map((file) => (
                        <div
                          key={file.file.name}
                          className="dz-preview dz-processing dz-image-preview dz-error dz-complete"
                        >
                          <div className="dz-image">
                            <img src={file.preview} alt="preview" />
                          </div>
                        </div>
                      ))}
                    </div>
                  </section>
                )}
              </Dropzone>
              {isLogoError && (
                <p className="text-danger fs-11px ff-italic">
                  This field is required
                </p>
              )}
            </div>
          </Col>
          <Col size="12">
            <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
              <li>
                <Button
                  color="light"
                  size="md"
                  type="button"
                  className="fSize14"
                  onClick={() => props.prev()}
                >
                  Back
                </Button>
              </li>
              <li>
                <Button
                  color="primary"
                  size="md"
                  type="submit"
                  className="fSize14"
                  disabled={loading}
                >
                  {loading ? <Spinner size="sm" color="light" /> : "Submit"}
                </Button>
              </li>
            </ul>
          </Col>
        </Form>
      </Block>
      {isImageCropperVisible && (
         <ImageCropper
         visible={isImageCropperVisible}
         file={file}
         onCancel={() => {
           setFile();
           setIsImageCropperVisible(false);
         }}
         onCrop={(file) => {
           if (file !== null && file !== undefined) {
             let data = {
               preview: URL.createObjectURL(file),
               file: file,
             };
             props.setState("logo", [data]);
           }
         }}
       />
      )}
    </>
  );
};

const VendorSetup = () => {
  const onLogoClick = () => {
    Swal.fire({
      title: "Are you sure that you want to close this screen?",
      text: `All entered info will be missed`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Continue",
    }).then((result) => {
      if (result.isConfirmed) {
        Storage.clearAll();
        window.history.pushState(
          `${process.env.PUBLIC_URL ? process.env.PUBLIC_URL : "/"}`,
          "auth-login",
          `${process.env.PUBLIC_URL ? process.env.PUBLIC_URL : "/"}`
        );
        window.location.reload();
      }
    });
  };

  return (
    <React.Fragment>
      <Head title="Vendor"></Head>
      <PageContainer>
        <div className="nk-split nk-split-page nk-split-lg">
          <div className="nk-split-content nk-split-stretch bg-blue-dim d-flex toggle-break-lg toggle-slide toggle-slide-left toggle-screen-lg">
            <div className="brand-logo pb-4 text-left mx-4">
              {/* <Link to={process.env.PUBLIC_URL + "/"} className="logo-link"> */}
              <img
                className="logo-img logo-img-lg mt-4 ms-2 long-logo"
                src={Logo}
                alt="logo"
                style={{ cursor: "pointer" }}
                onClick={() => onLogoClick()}
              />
              {/* </Link> */}
            </div>
          </div>
          <div className="nk-split-content nk-block-area nk-block-area-column nk-auth-container bg-white">
            <PreviewCard className="h-100">
              {/* is-vertical */}
              <div className="nk-wizard nk-wizard-simple wizard clearfix">
                <Steps config={config}>
                  <Step component={Business} />
                  <Step component={Details} />
                  <Step component={Brand} />
                </Steps>
              </div>
            </PreviewCard>
          </div>
        </div>
      </PageContainer>
    </React.Fragment>
  );
};

export default VendorSetup;
