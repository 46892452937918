import React, { useEffect, useRef, useState } from "react";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import {
  ActionButton,
  BackButton,
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Button,
  Icon,
  Toolbar,
} from "../../../components/Component";
import { useHistory, useLocation } from "react-router-dom";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import classNames from "classnames";
import { propertiesTypes, showErrorToast } from "../../../utils";
import LoadingComponent from "../../../layout/spinner/LoadingSpinner";
import ReactPaymentHistory from "./History/ReactPaymentHistory";
import Charges from "./charges/Charges";
import { tenantDetails } from "./TanantData";
import { ChatFloatingUI } from "../chat/ChatFloatingUI";
import { Storage } from "../../../services/storage/storage";
import { ChatType } from "../../../services/chat/chatservice";

const TenantDetail = ({ match }) => {
  const history = useHistory();
  const location = useLocation();
  const [itemPerPage, setItemPerPage] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  const [tenant, setTenant] = useState();
  const [onSearchText, setSearchText] = useState("");
  const [onSearch, setonSearch] = useState(true);

  const [sort, setSortState] = useState("desc");
  // const [selectedLocation, setSelectedLocation] = useState([]);
  // const [selectedPropertyType, setSelectedPropertyType] = useState([]);
  // const [locationList, setLocation] = useState([]);
  // const [selectedFromDate, setSelectedFromdate] = useState();
  // const [selectedToDate, setSelectedTodate] = useState();
  const [selectedFilterData, setSelectedFilterData] = useState({});
  const [isFilterApply, setIsFilterApply] = useState(false);
  const [activeTab, setActivetab] = useState();
  const [isInitScreen, setIsInitScreen] = useState(true);
  const [invoiceModal, setInvoiceModal] = useState(false);
  const [path, setPath] = useState();

  const dropdownPages = [5, 10, 15, 20];
  const searchInput = useRef(null);
  const dropDownRef = useRef();
  const paymentFilterData = selectedFilterData;

  const [isStartChat, setIsStartChat] = useState(false);
  const [user] = useState(Storage.getLoggedInUser()?.user);

  const toggle = (tabid) => {
    setActivetab(tabid);
  };
  const textSearch = () => setonSearch(!onSearch);

  useEffect(() => {
    if (searchInput.current && !onSearch) {
      searchInput.current.focus();
    }
  }, [onSearch]);

  useEffect(() => {
    const idStr = match.params.id;
    if (idStr !== undefined || null || "") {
      let list = idStr.split("-");
      const id = list.length > 0 ? parseInt(list[0]) : "";
      loadTenantDetail(id);
    } else {
      history.goBack();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match.params.id]);

  useEffect(() => {
    setPath(location.state?.path);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    toggle(location.hash === "#charges" ? "2" : "1");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.hash]);

  function resetFilter() {
    setSelectedFilterData();
  }

  const loadTenantDetail = (id) => {
    tenantDetails({ id: id }, (data, error) => {
      if (error === null) {
        setTenant(data);
      } else {
        showErrorToast(error.message);
        history.goBack();
      }
      setIsInitScreen(false);
    });
  };

  return (
    <React.Fragment>
      <Head title="Property Detail"></Head>
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle page>{tenant?.name ?? ""}</BlockTitle>
            </BlockHeadContent>

            <BlockHeadContent>
              <ActionButton
                title={"Message"}
                className="me-2"
                onClick={() => setIsStartChat(true)}
              />
              <ActionButton
                title={"Add invoice"}
                className="me-2"
                onClick={() => setInvoiceModal(true)}
              />
              <BackButton
                onPress={() => {
                  if (path) {
                    history.push(`${process.env.PUBLIC_URL}${path}`);
                  } else {
                    history.push(`${process.env.PUBLIC_URL}/tenants`);
                  }
                }}
              />
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        {activeTab && tenant && (
          <Block className="bg-white">
            <div className="card-inner bg-white position-relative card-tools-toggle">
              <Nav tabs className="">
                <NavItem>
                  <NavLink
                    tag="a"
                    href="#payment-and-charges"
                    className={classNames({ active: activeTab === "1" })}
                  >
                    Payments and charges
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    tag="a"
                    href="#charges"
                    className={classNames({ active: activeTab === "2" })}
                  >
                    Invoice management
                  </NavLink>
                </NavItem>

                <div className="ms-auto mt-1">
                  <Toolbar
                    tenantId={tenant?.id}
                    textSearch={textSearch}
                    dropDownRef={dropDownRef}
                    isFilterApply={isFilterApply}
                    selectedFilterData={selectedFilterData}
                    onFilterApply={(data) => {
                      setSelectedFilterData(data);
                      if (isFilterApply === true) {
                        setCurrentPage(0);
                        setTimeout(() => {
                          setCurrentPage(1);
                        }, 200);
                      }
                      setIsFilterApply(true);
                    }}
                    onResetFilter={(e) => {
                      resetFilter();
                      setIsFilterApply(false);
                    }}
                    dropdownPages={dropdownPages}
                    setItemPerPage={setItemPerPage}
                    sort={sort}
                    itemPerPage={itemPerPage}
                    setSortState={setSortState}
                  />
                </div>
                <div
                  className={`card-search search-wrap ${!onSearch && "active"}`}
                >
                  <div className="card-body">
                    <div className="search-content">
                      <Button
                        className="search-back btn-icon toggle-search active"
                        onClick={() => {
                          setSearchText("");
                          textSearch();
                        }}
                      >
                        <Icon name="arrow-left"></Icon>
                      </Button>
                      <input
                        type="text"
                        className="border-transparent form-focus-none form-control"
                        placeholder="Search by user or email"
                        value={onSearchText}
                        ref={searchInput}
                        onChange={(e) => setSearchText(e.target.value)}
                      />
                      <Button className="search-submit btn-icon">
                        <Icon name="search"></Icon>
                      </Button>
                    </div>
                  </div>
                </div>
              </Nav>
            </div>
            <TabContent className="bg-white px-4" activeTab={activeTab}>
              <TabPane tabId="1">
                <div className="card-aside-wrap">
                  <div className="card-content">
                    <div className="card-inner p-0 mt-1 mb-4 max-w-856">
                      <ReactPaymentHistory
                        isNeedtoShow
                        isNeedBorder
                        tenant={tenant}
                        itemPerPage={itemPerPage}
                        selectedFilterData={selectedFilterData}
                      />
                    </div>
                  </div>
                </div>
              </TabPane>
              <TabPane tabId="2">
                <div className="card-aside-wrap">
                  <div className="card-content">
                    <div className="card-inner p-0 mt-1 mb-4 max-w-856">
                      <Charges
                        isVisible
                        tenant={tenant}
                        invoiceModal={invoiceModal}
                        onInvoiceModelClose={() => setInvoiceModal(false)}
                      />
                    </div>
                  </div>
                </div>
              </TabPane>
            </TabContent>
          </Block>
        )}
      </Content>
      <LoadingComponent isLoading={isInitScreen} />
      {isStartChat && (
        <ChatFloatingUI
          loggedInId={user.chatId}
          otherChatId={tenant.chatId}
          onClosed={() => setIsStartChat(false)}
          chatType={ChatType.tenant_pm}
        />
      )}
    </React.Fragment>
  );
};

export default TenantDetail;
