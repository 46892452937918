/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Row, Col } from "../../components/Component";
import { Link } from "react-router-dom";
import { AppText, HELP, PRIVACY_POLICY, TERMS_AND_CONDITIONS, openLinkInNewTab } from "../../utils";

const AuthFooter = () => {
  return (
    <div className="nk-footer nk-auth-footer-full">
      <div className="container wide-lg">
        <Row className="g-3">
          <Col lg={6} className="order-lg-last">
            <ul className="nav nav-sm justify-content-center justify-content-lg-end">
              <li className="nav-item">
                <a
                  href=""
                  className={"nav-link"}
                  onClick={(e) => openLinkInNewTab(TERMS_AND_CONDITIONS, e)}
                >
                  <p className="fSize14">Terms & conditions</p>
                </a>
              </li>
              <li className="nav-item">
                <a
                  href=""
                  className={"nav-link"}
                  onClick={(e) => openLinkInNewTab(PRIVACY_POLICY, e)}
                >
                  <p className="fSize14">Privacy policy</p>
                </a>
              </li>
              <li className="nav-item">
                <a
                  href=""
                  className={"nav-link"}
                  onClick={(e) => openLinkInNewTab(HELP, e)}
                >
                  <p className="fSize14">Help</p>
                </a>
              </li>
            </ul>
          </Col>
          <Col lg="6">
            <div className="nk-block-content text-center text-lg-start">
              <p className="text-soft">{AppText.copyright}</p>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};
export default AuthFooter;
