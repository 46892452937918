import { AppText, HELP } from "../../utils";

export const menuManager = [
  {
    icon: "package-fill",
    text: "Requests",
    subMenu: [
      {
        text: "Vendor requests",
        link: "/",
      },
      {
        text: "Tenant requests",
        link: "/tenant-requests",
      },
    ],
  },
  {
    icon: "list-thumb-fill",
    text: "Vendors",
    link: "/vendors",
  },
  {
    icon: "calendar-booking-fill",
    text: "Calendar",
    link: "/calendar",
  },
  {
    icon: "chat-fill",
    text: "Chat",
    link: "/chat",
  },
  {
    icon: "building-fill",
    text: "Properties",
    link: "/properties",
  },
  {
    icon: "user-alt-fill",
    text: AppText.tenants,
    link: "/tenants",
  },
  {
    icon: "help-fill",
    text: "FAQ",
    link: HELP,
    external: true,
  },
  {
    icon: "telegram",
    text: "Feedback and help",
    link: process.env.REACT_APP_FeedBack_Help,
    external: true,
  },
];

export const menuVendor = [
  {
    icon: "package-fill",
    text: "Requests",
    link: "/",
  },
  {
    icon: "calendar-booking-fill",
    text: "Calendar",
    link: "/calendar",
  },
  {
    icon: "chat-fill",
    text: "Chat",
    link: "/chat",
  },
  {
    icon: "help-fill",
    text: "FAQ",
    link: HELP,
    external: true,
  },
  {
    icon: "telegram",
    text: "Feedback and help",
    link: "https://t.me/+zrKqOekfOrhiZTk5",
    external: true,
  },
];

export const menuTenant = [
  {
    icon: "home-fill",
    text: "Dashboard",
    link: "/",
  },
  {
    icon: "package-fill",
    text: "Requests",
    link: "/requests",
  },
  {
    icon: "calendar-booking-fill",
    text: "Calendar",
    link: "/calendar",
  },
  {
    icon: "chat-fill",
    text: "Chat",
    link: "/chat",
  },
  {
    icon: "help-fill",
    text: "FAQ",
    link: HELP,
    external: true,
  },
  {
    icon: "telegram",
    text: "Feedback and help",
    link: process.env.REACT_APP_FeedBack_Help,
    external: true,
  },
];
