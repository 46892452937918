import React, { useRef } from "react";
import { Block, Button } from "../../../../../components/Component";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom";
import moment from "moment/moment";
import { TenantPaymentWizardContext } from "./TenantRentPayment";
import { CardText } from "reactstrap";
import { CURRENCY_CODE, DATE_TIME_FORMAT, numberFormatterWithDecimal } from "../../../../../utils";
import { Storage } from "../../../../../services/storage/storage";
import { useReactToPrint } from "react-to-print";

export const TenantPaymentConfirmation = (props) => {
  const { data, updateData } = React.useContext(TenantPaymentWizardContext);
  const history = useHistory();
  const location = useLocation();

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    pageStyle:
      "@media print { @body { background-color: #ffffff !important; display: table; table-layout: fixed; padding-top: 2.5cm; padding-bottom: 2.5cm; height: auto; } }",
  });
  return (
    <Block>
      <div className="invoice-bills">
        <div ref={componentRef} style={{ backgroundColor: "white" }}>
          <div className="mt-2 mb-5">
            <p>
              <strong>Your payment is being processed!</strong>
              <br />
              <br />A confirmation email will be sent to{" "}
              <strong>
                {Storage.getLoggedInUser()?.user?.email ?? "your email"}
              </strong>{" "}
              when processing is complete. <br />
              <br />
              {(data?.paymentRemain?.status !== "succeeded" ||
                data?.paymentRemain?.status === undefined) && (
                <span className="fw-semibold text-warning">
                  Payment processing can take up to 7 business days.
                </span>
              )}
            </p>
          </div>
          <div className="table-responsive ">
            <table className="table table-striped border-top border-1">
              <tbody>
                <tr>
                  <td className="">Reference number</td>
                  <td className="">#{data?.paymentInvocieId ?? ""}</td>
                </tr>
                <tr>
                  <td className="">Payment date and time</td>
                  <td className="">
                    {data?.paymentInvocieDate
                      ? moment(data?.paymentInvocieDate).format(
                        DATE_TIME_FORMAT
                        )
                      : moment().format(DATE_TIME_FORMAT)}
                  </td>
                </tr>
                <tr>
                  <td className="">Payment account</td>
                  <td className="">
                    {data?.paymentRemain?.bank && (
                      <CardText className="text ">
                        <strong>{data?.paymentRemain?.bank?.name}</strong>{" "}
                        {"**** **** **** "} {data?.paymentRemain?.bank?.last4}
                      </CardText>
                    )}
                  </td>
                </tr>
                <tr>
                  <td className="">Payment amount</td>
                  <td className="">
                    {numberFormatterWithDecimal(
                      CURRENCY_CODE,
                      "" + data?.totalAmountToPay
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="">
          {/* <p className="fw-bold"> Are you want you pay $15000 ?</p> */}
          <div className="nk-notes ff-italic fs-12px text-soft mt-3">
            <div className="form-group">
              <Button
                type="submit"
                color="gray"
                size="md"
                className="btn-dim"
                onClick={() => {
                  location.state?.history ? history.goBack() : history.push(`${process.env.PUBLIC_URL}/`);
                }}
              >
                Close
              </Button>
              <Button
                type="button"
                color="primary"
                size="md"
                className="btn-dim mx-4"
                onClick={handlePrint}
              >
                Print
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Block>
  );
};
