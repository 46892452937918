import React, { useEffect, useState } from "react";
import { DropdownMenu } from "reactstrap";
import { Col, RSelect, Row } from "../../../components/Component";

export const VendorListFilter = ({
  isVisible,
  serviceList,
  propertiesTypes,
  filteredServices,
  filteredPropertyTypes,
  onFilterApply,
  onResetFilter,
}) => {
  const [selectedServices, setSelectedServices] = useState([]);
  const [selectedPropertyTypes, setSelectedPropertyTypes] = useState([]);

  useEffect(() => {
    if(isVisible) {
        setSelectedServices(filteredServices);
        setSelectedPropertyTypes(filteredPropertyTypes);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible]);

  return (
    <DropdownMenu
      end
      className="filter-wg dropdown-menu-xl"
      style={{ overflow: "visible" }}
    >
      <div className="dropdown-head">
        <span className="sub-title dropdown-title">Filter Vendors</span>
      </div>
      <div className="dropdown-body dropdown-body-rg">
        <Row className="gx-6 gy-3">
          <Col size="12">
            <div className="form-group">
              <label className="overline-title overline-title-alt">
                Primary business service
              </label>
              <RSelect
                options={serviceList}
                isMulti
                value={selectedServices}
                placeholder="Select services"
                onChange={(e) => setSelectedServices(e)}
              />
            </div>
          </Col>
          <Col size="12">
            <div className="form-group">
              <label className="overline-title overline-title-alt">
                Property types serviced
              </label>
              <RSelect
                options={propertiesTypes}
                isMulti
                value={selectedPropertyTypes}
                placeholder="Select property type"
                onChange={(e) => setSelectedPropertyTypes(e)}
              />
            </div>
          </Col>
          <Col size="12">
            <div className="form-group">
              <button
                type="button"
                className="btn btn-primary"
                onClick={(e) => {
                  e.preventDefault();
                  if (selectedServices.length === 0 && selectedPropertyTypes.length === 0) {
                    onResetFilter();
                  } else {
                    let data = {
                        services: selectedServices, 
                        propertyTypies: selectedPropertyTypes, 
                      };
                    onFilterApply(data);
                  }
                }}
              >
                Filter
              </button>
            </div>
          </Col>
        </Row>
      </div>
      <div className="dropdown-foot between">
        <a
          href="#reset"
          onClick={(ev) => {
            ev.preventDefault();
            onResetFilter();
          }}
          className="clickable"
        >
          Reset Filter
        </a>
      </div>
    </DropdownMenu>
  );
};
