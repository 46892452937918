import React, { createRef, useEffect, useRef } from "react";
import { useState } from "react";
import { Badge } from "reactstrap";

import ReactPlayer from "react-player";
import { openLinkInNewTab } from "../../../utils";
import { Icon } from "../../../components/Component";

export const useIsMobile = () => {
  const screenSize = useRef();

  useEffect(() => {
    window.addEventListener("resize", () => {
      screenSize.current = window.innerWidth;
    });
    return () => {
      window.removeEventListener("resize", () => {
        screenSize.current = window.innerWidth;
      });
    };
  }, []);

  return screenSize.current <= 1304;
};

const VideoContainer = ({
  className,
  vedioUrl,
  thumbUrl,
  item,
  onDelete,
  onClick,
  autoPlay,
  controls,
  isPlaying = false,
}) => {

  return (
    <div
      className={` mb-1 me-1 bg-black rounded   ${className}`}
      style={{ position: "relative" }}
      onClick={(ev) => onClick()}
    >
      <ReactPlayer
        width={"100%"}
        height={"100%"}
        url={vedioUrl}
        playing={autoPlay}
        controls={controls}
        light={thumbUrl}
      />
      {isPlaying === false && <Icon name="play-fill" className={"text-white play-icon"}/>}
    </div>
  );
};

export default VideoContainer;
