import { Button, Icon } from "../Component";

const IconButton = ({ onClick, icon, className, size, color,disabled }) => {
  return (
    <Button className={`btn-round btn-icon ${className}`} disabled={disabled} type="button" color={color} size={size} onClick={onClick}>
      <Icon name={icon} />
    </Button>
  );
};

export default IconButton;
