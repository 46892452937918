import React, { useEffect, useState } from "react";

import { RSelect } from "../Component";
import {
  dropDownConvertor,
  fetchCategoryList,
} from "../../pages/panel/maintenance-request/MaintenanceRequestData";

const CategoryDropdown = ({
  value,
  isMulti = false,
  onSelect,
  isShowAll = false,
  disabled = false,
  ...props
}) => {
  const [list, setList] = useState();

  useEffect(() => {
    !disabled && callCategoryList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return () => {
      setList();
    };
  }, []);

  const callCategoryList = () => {
    let params = {
      type: "MR",
    };
    fetchCategoryList(params, (data, error) => {
      if (error === null) {
        if (isShowAll === false) {
          let cData = dropDownConvertor(data.list);
          setList(cData);
        } else {
          let all = {
            id: 0,
            label: "All",
            value: "All",
          };
          setList([all, ...data]);
        }
      }
    });
  };
  return (
    <RSelect
      options={list}
      isMulti={isMulti}
      isDisabled={disabled}
      placeholder={""}
      value={value ?? (isShowAll ? (list ? list[0] : null) : null)}
      onChange={(e) => {
        if (isShowAll === false) {
          onSelect(e);
        } else {
          e.id === 0 ? onSelect(undefined) : onSelect(e);
        }
      }}
      {...props}
    />
  );
};

export default CategoryDropdown;
