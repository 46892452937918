import React from "react";

const TableTitle = ({ title, align, className }) => {
  return (
    <ul>
      <li>
        <div
          className={`d-flex container sub-text p-0 text-secondary fw-normal ${
            align ? `px-1 justify-content-${align}` : ""
          } ${className ? className : ""}`}
        >
          {title}
        </div>
      </li>
    </ul>
  );
};

export default TableTitle;
