import React from "react";
import { useRef } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Alert, Modal, ModalBody, Spinner } from "reactstrap";
import {
  Button,
  Col,
  Icon,
  Row,
  RSelect,
} from "../../../../components/Component";
import {
  getServices,
  updateUser,
  vendorSetup,
} from "../../../../pages/auth/AuthData";
import {
  serviceOfferingsData,
  socialResponsibilityData,
} from "../../../../pages/auth/VendorSetupData";
import {
  getServiceOfferFlagsAsText,
  getSocialFlagsAsText,
} from "../../../../pages/panel/customer/VendorData";
import { loadScript } from "../../../../services/google/autocomplete";
import { handleScriptLoad } from "../../../../services/google/autocomplete";
import { Storage } from "../../../../services/storage/storage";
import {
  AppText,
  businessSizes,
  COUNTRY_CODE,
  covertToNumberOnly,
  formatPhoneNumber,
  propertiesTypes,
} from "../../../../utils";

export const VendorUpdateProfileModal = ({
  modal,
  modalTab = "1",
  associations,
  setModalTab,
  user,
  services,
  onSaved,
  onClosed,
}) => {
  const [formData, setFormData] = useState({});
  const autoCompleteRef = useRef(null);
  const [phoneNo, setPhoneNo] = useState(formData.phone);
  const [serviceList, setServiceList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errorVal, setError] = useState();

  useEffect(() => {
    if (modal) {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY}&libraries=places`,
        () => handleScriptLoad(onUpdateAddress, autoCompleteRef)
      );
      setData();
    } else {
      setError();
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modal]);

  useEffect(() => {
    getServiceList();
  }, []);

  const setData = () => {
    setFormData({
      firstName: user?.firstName,
      lastName: user?.lastName,
      companyName: user?.companyInfo?.companyName,
      officeAddress: user?.companyInfo?.address?.fullAddress,
      latitude: user?.companyInfo?.address?.latitude,
      longitude: user?.companyInfo?.address?.longitude,
      phone: user?.phone,
      website: user?.companyInfo?.website,
      businessSize: user?.companyInfo?.businessSize,
      associations: user?.companyInfo?.associations,
      services: services,
      serviceRadius: user?.companyInfo?.serviceRadius,
      propertyTypesServiced: user?.companyInfo?.propertyTypes,
      isServiceIndividualUnits: user?.companyInfo?.isServiceIndividualUnits,
      isServiceSuppliesGoods: user?.companyInfo?.isServiceSuppliesGoods,
      isServiceUnionizedBusiness: user?.companyInfo?.isServiceUnionizedBusiness,
      isSocialDiversityInclusionPolicy:
        user?.companyInfo?.isSocialDiversityInclusionPolicy,
      isSocialEnvironSustainPolicy:
        user?.companyInfo?.isSocialEnvironSustainPolicy,
      isSocialStaffCovidVaccinated:
        user?.companyInfo?.isSocialStaffCovidVaccinated,
      description: user?.companyInfo?.description,
      logo: user?.companyInfo?.image,
    });
  };

  const onUpdateAddress = (data) => {
    setFormData({
      ...formData,
      latitude: data?.lat,
      longitude: data?.lng,
      fullAddress: data?.fullAddress,
      city: data?.city,
      state: data?.state,
      country: data?.country,
      zipCode: data?.zipCode,
    });
  };

  const handledServiceOfferings = (e) => {
    if (e.target.name === "units") {
      setFormData({
        ...formData,
        isServiceIndividualUnits: e.currentTarget.checked,
      });
    } else if (e.target.name === "goods") {
      setFormData({
        ...formData,
        isServiceSuppliesGoods: e.currentTarget.checked,
      });
    } else if (e.target.name === "business") {
      setFormData({
        ...formData,
        isServiceUnionizedBusiness: e.currentTarget.checked,
      });
    }
  };

  const handledSocialResponsibility = (e) => {
    if (e.target.name === "diversity") {
      setFormData({
        ...formData,
        isSocialDiversityInclusionPolicy: e.currentTarget.checked,
      });
    } else if (e.target.name === "environmental") {
      setFormData({
        ...formData,
        isSocialEnvironSustainPolicy: e.currentTarget.checked,
      });
    } else if (e.target.name === "covid19") {
      setFormData({
        ...formData,
        isSocialStaffCovidVaccinated: e.currentTarget.checked,
      });
    }
  };

  const onInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  function getServiceList() {
    getServices({}, (list, error) => {
      if (error === null) {
        setServiceList(list);
      }
    });
  }

  function submitForm() {
    setLoading(true);
    if (modalTab === "1") {
      var data = formData;
      updateUser(data, (data, error) => {
        setLoading(false);
        let userInfo = Storage.getLoggedInUser();
        userInfo.user.firstName = formData?.firstName;
        userInfo.user.lastName = formData?.lastName;
        Storage.setLoggedInUser(userInfo);
        if (error === null) {
          onSaved(true);
        } else {
          setError(error.message);
        }
      });
    } else {
      var params = {
        companyName: formData?.companyName,
        latitude: formData?.latitude,
        longitude: formData?.longitude,
        fullAddress: formData?.fullAddress,
        city: formData?.city,
        state: formData?.state,
        country: formData?.country,
        zipCode: formData?.zipCode,
        phone: covertToNumberOnly(formData?.phone),
        website: formData?.website,
        businessSize: formData?.businessSize,
        serviceRadius: formData?.serviceRadius,
        isServiceIndividualUnits: formData?.isServiceIndividualUnits ?? false,
        isServiceSuppliesGoods: formData?.isServiceSuppliesGoods ?? false,
        isServiceUnionizedBusiness:
          formData?.isServiceUnionizedBusiness ?? false,
        isSocialDiversityInclusionPolicy:
          formData?.isSocialDiversityInclusionPolicy ?? false,
        isSocialEnvironSustainPolicy:
          formData?.isSocialEnvironSustainPolicy ?? false,
        isSocialStaffCovidVaccinated:
          formData?.isSocialStaffCovidVaccinated ?? false,
        description: formData?.description,
        servicesIds: formData?.services.map((item) => item.id),
        associationIds: formData?.associations.map((item) => item.id),
        propertyTypes: formData?.propertyTypesServiced,
      };

      if (params.latitude === undefined || params.longitude === undefined) {
        setError(AppText.addressError);
        setLoading(false);
        return;
      }

      vendorSetup(null, params, (data, error) => {
        setLoading(false);
        if (error === null) {
          onSaved(false);
        } else {
          setError(error.message);
        }
      });
    }
  }

  return (
    <Modal
      isOpen={modal}
      className="modal-dialog-centered"
      size="lg"
      toggle={() => onClosed()}
      autoComplete="off"
    >
      <ModalBody>
        <a
          href="#dropdownitem"
          onClick={(ev) => {
            ev.preventDefault();
            onClosed();
          }}
          className="close"
        >
          <Icon name="cross-sm"></Icon>
        </a>
        <div className="p-2">
          <h5 className="title">Update profile</h5>
          {errorVal && (
            <div className="my-2">
              <Alert color="danger" className="alert-icon">
                {" "}
                <Icon name="alert-circle" /> {errorVal}{" "}
              </Alert>
            </div>
          )}
          <ul className="nk-nav nav nav-tabs">
            <li className="nav-item">
              <a
                className={`nav-link ${modalTab === "1" && "active"}`}
                onClick={(ev) => {
                  ev.preventDefault();
                  setModalTab("1");
                }}
                href="#personal"
              >
                User
              </a>
            </li>
            <li className="nav-item">
              <a
                className={`nav-link ${modalTab === "2" && "active"}`}
                onClick={(ev) => {
                  ev.preventDefault();
                  setModalTab("2");
                }}
                href="#address"
              >
                Company
              </a>
            </li>
          </ul>
          <div className="tab-content">
            <div
              className={`tab-pane ${modalTab === "1" ? "active" : ""}`}
              id="personal"
            >
              <Row className="gy-4">
                <Col md="6">
                  <div className="form-group">
                    <label className="form-label" htmlFor="full-name">
                      First name
                    </label>
                    <input
                      type="text"
                      id="first-name"
                      className="form-control"
                      name="firstName"
                      onChange={(e) => onInputChange(e)}
                      defaultValue={formData?.firstName ?? ""}
                      placeholder="Enter first name"
                    />
                  </div>
                </Col>

                <Col md="6">
                  <div className="form-group">
                    <label className="form-label" htmlFor="display-name">
                      Last name
                    </label>
                    <input
                      type="text"
                      id="last-name"
                      className="form-control"
                      name="lastName"
                      onChange={(e) => onInputChange(e)}
                      defaultValue={formData?.lastName ?? ""}
                      placeholder="Enter last name"
                    />
                  </div>
                </Col>

                <Col size="12">
                  <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                    <li>
                      <Button
                        color="primary"
                        size="lg"
                        onClick={() => submitForm()}
                      >
                        {loading ? <Spinner size="sm" color="light" /> : "Save"}
                      </Button>
                    </li>
                    <li>
                      <Button
                        onClick={(ev) => {
                          ev.preventDefault();
                          onClosed();
                        }}
                        className="link link-light"
                      >
                        Cancel
                      </Button>
                    </li>
                  </ul>
                </Col>
              </Row>
            </div>
            <div
              className={`tab-pane ${modalTab === "2" ? "active" : ""}`}
              id="company"
            >
              <Row className="gy-4">
                <Col md="6">
                  <div className="form-group">
                    <label className="form-label" htmlFor="companyName">
                      Company name
                    </label>
                    <input
                      type="text"
                      id="companyName"
                      name="companyName"
                      onChange={(e) => onInputChange(e)}
                      defaultValue={formData?.companyName ?? ""}
                      className="form-control"
                    />
                  </div>
                </Col>

                <Col md="6">
                  <div className="form-group">
                    <label className="form-label" htmlFor="office-address">
                      Office address
                    </label>
                    <input
                      type="text"
                      id="office-address"
                      name="fullAddress"
                      defaultValue={formData?.officeAddress ?? ""}
                      className="form-control"
                      onChange={(e) => onUpdateAddress(null)}
                      autoComplete="off"
                      ref={(event) => {
                        autoCompleteRef.current = event;
                      }}
                    />
                  </div>
                </Col>

                <Col md="6">
                  <div className="form-group">
                    <label className="form-label" htmlFor="phone">
                      Phone
                    </label>
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <span className="input-group-text">{COUNTRY_CODE}</span>
                      </div>
                      <input
                        type="text"
                        id="phone"
                        name="phone"
                        onChange={(e) => {
                          setPhoneNo(e.target.value);
                          onInputChange(e);
                        }}
                        defaultValue={formatPhoneNumber("", formData.phone)}
                        className="form-control"
                        maxLength={14}
                        minLength={14}
                        value={formatPhoneNumber("", phoneNo)}
                      />
                    </div>
                  </div>
                </Col>

                <Col md="6">
                  <div className="form-group">
                    <label className="form-label" htmlFor="website">
                      Website
                    </label>
                    <input
                      type="text"
                      id="website"
                      name="website"
                      onChange={(e) => onInputChange(e)}
                      defaultValue={
                        formData?.website?.length === 0
                          ? "N/A"
                          : formData?.website
                      }
                      className="form-control"
                    />
                  </div>
                </Col>

                <Col md="6">
                  <div className="form-group">
                    <label className="form-label" htmlFor="businessSize">
                      Business size
                    </label>
                    <RSelect
                      options={businessSizes}
                      defaultValue={{
                        value: formData.businessSize,
                        label: formData.businessSize,
                      }}
                      onChange={(e) =>
                        setFormData({ ...formData, businessSize: e.value })
                      }
                      name="size"
                    />
                  </div>
                </Col>

                <Col md="6">
                  <div className="form-group">
                    <label className="form-label" htmlFor="businessSize">
                      Primary business service(s)
                    </label>
                    <RSelect
                      isMulti
                      options={serviceList}
                      defaultValue={formData?.services}
                      onChange={(e) => {
                        setFormData({ ...formData, services: e });
                      }}
                      name="services"
                    />
                  </div>
                </Col>

                <Col md="6">
                  <div className="form-group">
                    <label className="form-label" htmlFor="serviceRadius">
                      Service radius
                    </label>
                    <input
                      type="numeric"
                      max={150}
                      id="serviceRadius"
                      name="serviceRadius"
                      onChange={(e) => onInputChange(e)}
                      defaultValue={formData?.serviceRadius ?? ""}
                      className="form-control"
                    />
                  </div>
                </Col>

                <Col md="6">
                  <div className="form-group">
                    <label className="form-label" htmlFor="businessSize">
                      Property types serviced
                    </label>
                    <RSelect
                      isMulti
                      defaultValue={propertiesTypes.filter((item) =>
                        formData?.propertyTypesServiced?.includes(item.value)
                      )}
                      options={propertiesTypes}
                      className={"fSize14"}
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          propertyTypesServiced: e.map((item) => item.value),
                        });
                      }}
                    />
                  </div>
                </Col>

                <Col md="12">
                  <div className="form-group">
                    <label className="form-label" htmlFor="businessSize">
                      Associations
                    </label>
                    <RSelect
                      isMulti
                      value={formData?.associations}
                      options={associations}
                      className={"fSize14"}
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          associations: e,
                        });
                      }}
                    />
                  </div>
                </Col>

                <Col md="12">
                  <div className="form-group">
                    <label className="form-label" htmlFor="businessSize">
                      Service offerings
                    </label>
                    <div className="g-3 align-center flex-wrap">
                      {serviceOfferingsData.map((item) => {
                        return (
                          <div className="g" key={item.id}>
                            <div className="custom-control custom-control-sm custom-checkbox">
                              <input
                                type="checkbox"
                                className="custom-control-input"
                                id={item.id}
                                name={item.name}
                                defaultChecked={getServiceOfferFlagsAsText(
                                  user?.companyInfo
                                ).includes(item.text)}
                                onChange={(e) => handledServiceOfferings(e)}
                              />
                              <label
                                className="custom-control-label fSize14"
                                htmlFor={item.id}
                              >
                                {" "}
                                {item.text}
                              </label>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </Col>

                <Col md="12">
                  <div className="form-group">
                    <label className="form-label" htmlFor="businessSize">
                      Corporate social responsibility
                    </label>
                    <div className="g-3 align-center flex-wrap">
                      {socialResponsibilityData.map((item) => {
                        return (
                          <div className="g" key={item.id}>
                            <div className="custom-control custom-control-sm custom-checkbox">
                              <input
                                type="checkbox"
                                className="custom-control-input"
                                id={item.id}
                                name={item.name}
                                defaultChecked={getSocialFlagsAsText(
                                  user?.companyInfo
                                ).includes(item.text)}
                                onChange={(e) => handledSocialResponsibility(e)}
                              />
                              <label
                                className="custom-control-label fSize14"
                                htmlFor={item.id}
                              >
                                {" "}
                                {item.text}
                              </label>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </Col>

                <Col md="12">
                  <div className="form-group">
                    <label className="form-label" htmlFor="businessSize">
                      Business description
                    </label>
                    <div className="form-control-wrap">
                      <textarea
                        className="form-control no-resize fSize14"
                        name="description"
                        defaultValue={formData?.description ?? ""}
                        onChange={(e) => onInputChange(e)}
                      />
                    </div>
                  </div>
                </Col>

                <Col size="12">
                  <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                    <li>
                      <Button
                        color="primary"
                        size="lg"
                        onClick={() => submitForm()}
                      >
                        {loading ? <Spinner size="sm" color="light" /> : "Save"}
                      </Button>
                    </li>
                    <li>
                      <a
                        href="#dropdownitem"
                        onClick={(ev) => {
                          ev.preventDefault();
                          onClosed();
                        }}
                        className="link link-light"
                      >
                        Cancel
                      </a>
                    </li>
                  </ul>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};
