import Swal from "sweetalert2";

export const ConfirmationAlert = (title, message, onConfirmation) => {
  Swal.fire({
    title: title ?? "",
    text: message,
    icon: "warning",
    showCancelButton: true,
    confirmButtonText: "Yes",
  }).then((result) => {
    if (result.isConfirmed) {
      onConfirmation();
    }
  });
};

export const SuccessAlert = (title, message, onConfirmation) => {
  Swal.fire({
    title: title ?? "",
    html: message,
    icon: "success",
    confirmButtonText: "Close",
  }).then((result) => {
      onConfirmation();
  });
};

