import React, { useContext } from "react";
import {
  DropdownToggle,
  DropdownMenu,
  UncontrolledDropdown,
  DropdownItem,
} from "reactstrap";
import { Link } from "react-router-dom";
import { Icon, UserAvatar } from "../../../components/Component";
import { findUpper } from "../../../utils/Utils";
import { ChatContext } from "./ChatContext";
import { dateTimeForChat } from "../../../utils";

export const MeChat = ({ item, chat, onRemoveMessage }) => {
  return (
    <div className="chat is-me">
      <div className="chat-content">
        <div className="chat-bubbles">
          {item.chat.map((msg, idx) => {
            return (
              <div className="chat-bubble" key={idx}>
                {msg === "deleted" ? (
                  <div className="chat-msg border bg-white text-muted">
                    Message has been deleted
                  </div>
                ) : (
                  <React.Fragment>
                    <div className={`chat-msg bg-primary line-break-anywhere`}>
                      {msg}
                    </div>
                    {/* <ul className="chat-msg-more">
                      <li className="d-none d-sm-block">
                        <a
                          href="#delete"
                          onClick={(ev) => {
                            ev.preventDefault();
                            onRemoveMessage(item);
                          }}
                          className="btn btn-icon btn-sm btn-trigger"
                        >
                          <Icon name="trash-fill"></Icon>
                        </a>
                      </li>
                    </ul> */}
                  </React.Fragment>
                )}
              </div>
            );
          })}
        </div>
        <ul className="chat-meta">
          <li>{dateTimeForChat(item.date)}</li>
        </ul>
      </div>
    </div>
  );
};

export const YouChat = ({ item, chat }) => {
  return (
    <div className="chat is-you">
      <div className="chat-avatar">
        {/* <UserAvatar image={item.user.image} theme={"white"} text={findUpper(item.user.name)} /> */}
      </div>
      <div className="chat-content">
        <div className="chat-bubbles">
          {item.chat.map((msg, idx) => {
            return (
              <div className="chat-bubble" key={idx}>
                <div className="chat-msg">{msg}</div>
              </div>
            );
          })}
        </div>
        <ul className="chat-meta">
          <li>{dateTimeForChat(item.date)}</li>
        </ul>
      </div>
    </div>
  );
};

export const MetaChat = ({ item }) => {
  return (
    <div className="chat-sap">
      <div className="chat-sap-meta">
        <span>{item}</span>
      </div>
    </div>
  );
};

export const ChatItemHeader = ({ item }) => {
  return (
    <Link to={`${process.env.PUBLIC_URL}/app-chat`} className="chat-link">
      {item.group === true ? (
        <div className="chat-media user-avatar user-avatar-multiple">
          {item.user.slice(0, 2).map((user, idx) => {
            return (
              <UserAvatar
                key={idx}
                theme={user.theme}
                text={findUpper(user.name)}
                image={user.image}
                className="chat-media"
              ></UserAvatar>
            );
          })}
          <span className={"status dot dot-lg dot-success"}></span>
        </div>
      ) : (
        <UserAvatar
          theme={item.theme}
          text={findUpper(item.name)}
          image={item.image}
          className="chat-media"
        >
          <span
            className={`status dot dot-lg dot-${
              item.active === true ? "success" : "gray"
            }`}
          ></span>
        </UserAvatar>
      )}
      <div className="chat-info">
        <div className="chat-from">
          <div className="name">
            {item.nickname ? item.nickname : item.name}
          </div>
          <span className="time">{item.date}</span>
        </div>
        <div className="chat-context">
          <div className="text">
            <p>
              {item.convo.length !== 0 &&
                item.convo[item.convo.length - 1].chat[0]}
            </p>
          </div>
          <div className="status delivered">
            <Icon
              name={`${
                item.delivered === true
                  ? "check-circle-fill"
                  : item.delivered === "sent"
                  ? "check-circle"
                  : ""
              }`}
            ></Icon>
          </div>
        </div>
      </div>
    </Link>
  );
};

export const ChatItem = ({
  item,
  chatItemClick,
  setSelectedChannel,
  selectedChannel,
}) => {
  const { deleteConvo, deleteChannel, hideChannel, currentUserChatIdState } =
    useContext(ChatContext);
  const [currentUserChatId, setCurrentUserChatId] = currentUserChatIdState;
  const checkBeforeDelete = (item) => {
    deleteChannel(item);
    if (selectedChannel.url === item.url) {
      setSelectedChannel(null);
    }
  };

  const checkBeforeHide = (item) => {
    hideChannel(item);
    if (selectedChannel.url === item.url) {
      setSelectedChannel(null);
    }
  };

  function getOtherUser() {
    var otherChatUser;
    let list = item.members.filter((user) => user.userId !== currentUserChatId);
    if (list?.length === 1) {
      otherChatUser = list[0];
    }
    return otherChatUser;
  }

  let otherUser = getOtherUser();
  return (
    <li
      className={`chat-item ${
        (item.unreadMessageCount ?? 0) > 0 ? "is-unread" : ""
      }`}
    >
      <a
        className="chat-link"
        href="#chat-link"
        onClick={(ev) => {
          ev.preventDefault();
          chatItemClick(item);
        }}
      >
        {item.group === true ? (
          <div className="chat-media user-avatar user-avatar-multiple">
            {item.user.slice(0, 2).map((user, idx) => {
              return (
                <UserAvatar
                  key={idx}
                  theme={"white"}
                  text={findUpper(otherUser?.nickname ?? "")}
                  image={otherUser?.plainProfileUrl}
                  className="chat-media"
                ></UserAvatar>
              );
            })}
            <span className={"status dot dot-lg dot-success"}></span>
          </div>
        ) : (
          <UserAvatar
            theme={"white"}
            text={findUpper(otherUser?.nickname ?? "")}
            image={otherUser?.plainProfileUrl}
            className="chat-media"
          >
            <span
              className={`status dot dot-lg dot-${
                otherUser?.connectionStatus === "online" ? "success" : "gray"
              }`}
            ></span>
          </UserAvatar>
        )}
        <div className="chat-info">
          <div className="chat-from">
            <div className="name">{otherUser?.nickname}</div>
            <span className="time">
              {dateTimeForChat(item?.lastMessage?.createdAt)}
            </span>
          </div>
          <div className="chat-context">
          <div className="text text-secondary">
              <p>
                {otherUser?.metaData?.subName ?? ""}
              </p>
            </div>
          </div>
          <div className="chat-context">
            <div className="text text-gray">
              <p>
                {item?.lastMessage?.message ??
                  "Become first to start communication"}
              </p>
            </div>
            <div className="status delivered">
              <Icon
                name={`${
                  // item.delivered === true ? "check-circle-fill" : item.delivered === "sent" ? "check-circle" : ""
                  (item.unreadMessageCount ?? 0) > 0
                    ? "check-circle-fill"
                    : "check-circle"
                }`}
              ></Icon>
            </div>
          </div>
        </div>
      </a>
      <div className="chat-actions">
        <UncontrolledDropdown>
          <DropdownToggle
            tag="a"
            className="btn btn-icon btn-sm btn-trigger dropdown-toggle"
          >
            <Icon name="more-h"></Icon>
          </DropdownToggle>
          <DropdownMenu end>
            <ul className="link-list-opt no-bdr">
              <li onClick={() => checkBeforeDelete(item)}>
                <DropdownItem
                  tag="a"
                  href="#delete"
                  onClick={(ev) => {
                    ev.preventDefault();
                  }}
                >
                  Delete
                </DropdownItem>
              </li>
              <li onClick={() => deleteConvo(item)}>
                <DropdownItem
                  tag="a"
                  href="#unread"
                  className={item.unread ? "disabled" : ""}
                  onClick={(ev) => {
                    ev.preventDefault();
                  }}
                >
                  Remove all messages
                </DropdownItem>
              </li>
              <li onClick={() => checkBeforeHide(item)}>
                <DropdownItem
                  tag="a"
                  href="#archive"
                  className={item.archive ? "disabled" : ""}
                  onClick={(ev) => {
                    ev.preventDefault();
                  }}
                >
                  Archive
                </DropdownItem>
              </li>
            </ul>
          </DropdownMenu>
        </UncontrolledDropdown>
      </div>
    </li>
  );
};
