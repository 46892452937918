/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { AppText, HELP, PRIVACY_POLICY, TERMS_AND_CONDITIONS, openLinkInNewTab } from "../../utils";

const Footer = () => {
  return (
    <div className="nk-footer">
      <div className="container-fluid">
        <div className="nk-footer-wrap">
          <div className="nk-footer-copyright">
            {AppText.copyright}
          </div>
          <div className="nk-footer-links">
            <ul className="nav nav-sm">
              <li className="nav-item">
                <a
                  href=""
                  className={"nav-link"}
                  onClick={(e) => openLinkInNewTab(TERMS_AND_CONDITIONS, e)}
               >
                  <p className="fSize14">Terms & conditions</p>
                </a>
              </li>
              <li className="nav-item">
                <a
                  href=""
                  className={"nav-link"}
                  onClick={(e) => openLinkInNewTab(PRIVACY_POLICY, e)}
                >
                  <p className="fSize14">Privacy policy</p>
                </a>
              </li>
              <li className="nav-item">
                <a
                  href=""
                  className={"nav-link"}
                  onClick={(e) => openLinkInNewTab(HELP, e)}
                >
                  <p className="fSize14">Help</p>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Footer;
