import React, { useEffect, useState } from "react";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import { searchInvoice, searchInvoiceList, tenantList } from "./DropdownData";
import { RSelect } from "../Component";

export const InvoiceSearchDropdown = ({
  value,
  isMulti = false,
  isShowAll = false,
  onSelect,
  selectedId,
  onSearch,
  placeholder,
  inputSearchString,
  ...props
}) => {
  const [list, setList] = useState([]);
  const [searchString, setSearchString] = useState();
  const [isSelected, setIsSelected] = useState(true);

  useEffect(() => {
    // if(searchString === "" || !searchString) {
    //   setList([]);
    // } else {
    //   fetchSearchInvoice();
    // }
    fetchSearchInvoice();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchString]);

  const fetchSearchInvoice = () => {
    searchInvoiceList(searchString, (data, error) => {
      if (error === null) {
        if (isShowAll === false) {
          setList(data);
        }
      }
    });
  };

  useEffect(() => {
    if (selectedId && !value) {
      const selectedOption = list?.find((option) => option.id === selectedId);
      if (selectedOption) {
        onSelect(selectedOption);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list, selectedId]);

  useEffect(() => {
    if (value) {
      const selectedOption = list?.find((option) => option.id === value.id);
      if (selectedOption) {
        setSearchString(selectedOption.name);
      }
    }
  }, [value]);

  const handleSearch = (text) => {
    if(isSelected === false) {
      setSearchString(text);
      onSearch(text)
    }
  };

  return (
    // <ReactSearchAutocomplete
    //   placeholder={placeholder}
    //   items={list}
    //   styling={{
    //     height: "36px",
    //     border: "1px solid #dbdfea",
    //     borderRadius: "4px",
    //     backgroundColor: "white",
    //     boxShadow: "white 0px 1px 6px 0px",
    //     hoverBackgroundColor: "white",
    //     color: "rgb(60, 77, 98)",
    //     fontSize: "13px",
    //     fontFamily: "Poppins, sans-serif",
    //     iconColor: "grey",
    //     lineColor: "rgb(232, 234, 237)",
    //     placeholderColor: "grey",
    //     clearIconMargin: "3px 14px 0 0",
    //     searchIconMargin: "0 0 0 16px",
    //     zIndex: 100,
    //   }}
    //   onSearch={handleSearch}
    //   onSelect={handleSelection}
    //   inputSearchString={searchString ?? value?.name}
    //   autoFocus
    //   {...props}
    // />

    <RSelect
        options={list ?? []}
        isMulti={isMulti}
        placeholder={""}
        value={value ?? (isShowAll ? (list ? list[0] : null) : null)}
        onInputChange={handleSearch}
        onMenuClose={() => !isSelected && onSearch(searchString)}
        onMenuOpen={() => setIsSelected(false)}
        onChange={(e) => {
          setIsSelected(true)
          if (isShowAll === false) {
            onSelect(e);
          } else {
            e.id === 0 ? onSelect(undefined) : onSelect(e);
          }
        }}
        {...props}
      />
  );
};
