// Texts
export const AppText = {
  copyright: `© 2023 ${process.env.REACT_APP_APP_NAME}. All rights reserved.`,
  email: "Email",
  password: "Password",
  forgotPassword: "Forgot your password?",
  enterYourEmail: "Enter your email",
  enterYourPassword: "Enter your password",
  proprtyName: "Property name",
  address: "Address",
  proprtyType: "Property type",
  annualBudget: "Annual budget",
  yearConstructed: "Year constructed",
  sizeSqFt: "Size",
  bank: "Payment bank",
  stories: "Stories",
  units: "Number of units",
  Attachments: "attachments",
  website: "Website",
  optional: "Optional",
  businessSize: "Business size",
  services: "Services",
  serviceRadius: "Service radius (150 miles maximum)",
  propertyTypesServiced: "Property types serviced (select all that apply)",
  associations: "Associations",
  serviceOfferings: "Service offerings",
  corporateSocialResponsibility: "Corporate social responsibility",
  businessDescription: "Business description",
  logo: "Logo",
  invalidEmail: "Invalid email address",
  invalidMobile: "Invalid mobile number",
  resetYourPassword: "Reset your password",
  newpassword: "New password",
  confirmpassword: "Confirm password",
  primaryBusinessServices: "Primary business service(s)",
  connectError: "Please check your network connect and try again",
  maxFileSize: "(Max file size 100MB)",
  maxFileSizeError: "You can upload max 100 MB file",
  addressError: "Please check your selected address",
  invitationSendSuccessfully: "Your invitation has been sent successfully.",
  loginToProcess: "Please login or signup to process your action",
  notRelatedToCurrentUser: "Sorry, this action is not related to your current account.",
  tenants: "Tenants",
  fieldRequires: "This field is required",
};
