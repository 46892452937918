import React, { useEffect } from "react";
import {
  ActionButton,
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Button,
  Col,
  Icon,
  Row,
  UrgencyBadge,
} from "../../../components/Component";
import {
  Card,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Spinner,
  UncontrolledDropdown,
} from "reactstrap";
import Content from "../../../layout/content/Content";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import {
  maintenanceRequestCommentApi,
  maintenanceRequestCommentListApi,
  maintenanceRequestDetailApi,
  maintenanceStatusUpdateApi,
} from "./MaintenanceRequestData";
import { useState } from "react";
import {
  RequestActivtyType,
  COUNTRY_CODE,
  DATE_FORMAT,
  DATE_TIME_FORMAT,
  MaintenanceRequestStatusType,
  TIME_FORMAT,
  formatPhoneNumber,
  openLinkInNewTab,
  showErrorToast,
  toCapitalize,
} from "../../../utils";
import moment from "moment/moment";
import StatusBadge from "../../../components/Badge/StatusBadge";
import VariantCard from "./VariantCard";
import Head from "../../../layout/head/Head";

import { fileUploader } from "../request/RequestData";
import { useSelector } from "react-redux";
import { ChatFloatingUI } from "../chat/ChatFloatingUI";
import { ChatType } from "../../../services/chat/chatservice";
import LoadingComponent from "../../../layout/spinner/LoadingSpinner";
import { UserAccountType } from "../../../utils/Utils";

function MaintentenanceDetails({ match }) {
  const history = useHistory();
  const [maintenanceDetails, setMaintenanceDetails] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [commentList, setCommentList] = useState([]);
  const [comment, setComment] = useState("");
  const [isInitScreen, setIsInitScreen] = useState(true);

  const user = useSelector((state) => state.user.currentUser);
  const [chatId, setChatId] = useState();
  const [commentfiles, setCommentFiles] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageInfo, setPageInfo] = useState({});
  const [isSendingComment, setIsSendingComment] = useState(false);

  useEffect(() => {
    const idStr = match.params.id;
    if (idStr !== undefined || null || "") {
      let list = idStr.split("-");
      const id = list.length > 0 ? list[0] : "";
      fetchMaintenanceRequestDetail(parseInt(id));
    } else {
      history.goBack();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match.params.id]);

  useEffect(() => {
    maintenanceDetails &&
      !isInitScreen &&
      fetchMaintenanceRequestCommentList(maintenanceDetails.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, maintenanceDetails?.status]);

  useEffect(() => {
    const { clientHeight } = document.getElementById("style-3");
    const scrollableDiv = document.getElementById("style-3");
    scrollableDiv.scroll({
      top: clientHeight,
      left: 0,
      // behavior: "smooth",
    });
    scrollableDiv.addEventListener("scroll", handleScroll, true);
    return () => {
      scrollableDiv.removeEventListener("scroll", handleScroll, true);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleScroll = (e) => {
    const { scrollTop } = document.getElementById("style-3");
    if (scrollTop === 0) {
      if (currentPage !== pageInfo.totalPages) {
        setCurrentPage(currentPage + 1);
      }
    }
  };

  const onTextSubmit = (comment) => {
    if (comment.length !== 0 || commentfiles.length > 0) {
      let params = {
        id: null,
        maintenanceRequestId: maintenanceDetails?.id
          ? maintenanceDetails?.id
          : null,
        description: comment,
        fileIds:
          commentfiles.length > 0
            ? commentfiles.map((item) => item.uploadedFileId)
            : [],
      };
      setIsSendingComment(true);
      maintenanceRequestCommentApi(params, (data, error) => {
        if (error === null) {
          fetchMaintenanceRequestDetail(maintenanceDetails.id);
          setComment("");
          setCommentFiles([]);
        }
        setIsSendingComment(false);
      });
    }
  };

  function upload(file, index) {
    let files = [...commentfiles];
    file.uploadedFileId = null;
    file.isUploading = true;
    files.push(file);
    setCommentFiles(files);
    fileUploader(file, "MAINTENANCE_REQ_COMMENT", (data, error) => {
      let files = [...commentfiles];
      if (error === null) {
        file.uploadedFileId = data.id;
        file.isUploading = false;
        files.splice(index, 0, file);
        setCommentFiles(files);
      } else {
        // files.splice(index, 1);
        showErrorToast(error.message);
      }
    });
  }

  const onRemoveFile = (fid) => {
    let updatedFiles = [...commentfiles];
    let files = updatedFiles.filter((item) => item?.uploadedFileId !== fid);
    setCommentFiles(files);
  };

  // API------------------------------------>

  const fetchMaintenanceRequestDetail = (id) => {
    let params = {
      id: id,
    };
    maintenanceRequestDetailApi(params, (data, error) => {
      if (error === null) {
        setMaintenanceDetails(data);
        if (currentPage === 1) {
          fetchMaintenanceRequestCommentList(id);
        } else {
          setCurrentPage(1);
        }
      }
      setIsInitScreen(false);
    });
  };

  const fetchMaintenanceStatusUpdate = (status) => {
    setIsLoading(true);
    let params = { id: maintenanceDetails.id, status: status };
    maintenanceStatusUpdateApi(params, (data, error) => {
      if (error === null) {
        setMaintenanceDetails((prev) => ({
          ...prev,
          status: status,
        }));
      } else {
        showErrorToast(error.message);
      }
      setIsLoading(false);
    });
  };

  const fetchMaintenanceRequestCommentList = (id) => {
    let params = {
      pageInfo: {
        pageIndex: currentPage,
        itemsPerPage: 20,
      },
      mrId: id,
    };
    maintenanceRequestCommentListApi(params, (data, error) => {
      if (error === null) {
        let list =
          currentPage === 1
            ? [...(data?.list ?? [])]
            : [...(data?.list ?? []), ...commentList];
        setCommentList(list);

        if (currentPage === 1) {
          setPageInfo(data.pageInfo);
          const { scrollHeight } = document.getElementById("style-3");
          const scrollableDiv = document.getElementById("style-3");
          scrollableDiv.scroll({
            top: scrollHeight,
            left: 0,
          });
        }
      }
    });
  };

  return (
    <>
      <Head title={"maintenance request"}></Head>
      <Content>
        <BlockHead size="sm">
          <BlockBetween className="g-3">
            <BlockHeadContent>
              <BlockTitle page>Maintenance request</BlockTitle>
              <BlockDes className="text-soft">
                <ul className="list-inline">
                  <li>
                    <span className="text-base">
                      Request ID #{maintenanceDetails?.id}
                    </span>
                  </li>
                  <li>
                    <span className="text-base">
                      {` Created on  
                      ${
                        maintenanceDetails?.createdAt
                          ? moment(maintenanceDetails?.createdAt).format(
                              DATE_TIME_FORMAT
                            )
                          : ""
                      }`}
                    </span>
                  </li>
                </ul>
              </BlockDes>
            </BlockHeadContent>
            <BlockHeadContent>
              <ul className="nk-block-tools g-3">
                <li>
                  <Button
                    color="light"
                    outline
                    className="bg-white d-none d-sm-inline-flex"
                    onClick={() => history.goBack()}
                  >
                    <Icon name="arrow-left"></Icon>
                    <span>Back</span>
                  </Button>
                </li>
                {user.accountType === UserAccountType.manager && (
                  <li>
                    <UncontrolledDropdown>
                      <div className="btn-group">
                        <Button color="primary" type="submit">
                          {isLoading ? (
                            <Spinner size="sm" color="light" />
                          ) : (
                            toCapitalize(maintenanceDetails?.status ?? "")
                              .split("_")
                              .join(" ")
                          )}
                        </Button>
                        <DropdownToggle
                          className="dropdown-toggle-split"
                          color="primary"
                        >
                          <Icon name="chevron-down"></Icon>
                        </DropdownToggle>
                      </div>
                      {maintenanceDetails?.status ===
                      MaintenanceRequestStatusType.open ? (
                        <DropdownMenu>
                          <ul className="link-list-opt">
                            <li>
                              <DropdownItem
                                tag="a"
                                href="#links"
                                onClick={(ev) => {
                                  ev.preventDefault();
                                  fetchMaintenanceStatusUpdate(
                                    MaintenanceRequestStatusType.inprogress
                                  );
                                }}
                              >
                                <span>In progress</span>
                              </DropdownItem>
                            </li>
                            <li>
                              <DropdownItem
                                tag="a"
                                href="#links"
                                onClick={(ev) => {
                                  ev.preventDefault();
                                  fetchMaintenanceStatusUpdate(
                                    MaintenanceRequestStatusType.closed
                                  );
                                }}
                              >
                                <span>Closed</span>
                              </DropdownItem>
                            </li>
                          </ul>
                        </DropdownMenu>
                      ) : maintenanceDetails?.status ===
                        MaintenanceRequestStatusType.inprogress ? (
                        <DropdownMenu>
                          <ul className="link-list-opt">
                            <li>
                              <DropdownItem
                                tag="a"
                                href="#links"
                                onClick={(ev) => {
                                  ev.preventDefault();
                                  fetchMaintenanceStatusUpdate(
                                    MaintenanceRequestStatusType.open
                                  );
                                }}
                              >
                                <span>Open</span>
                              </DropdownItem>
                            </li>
                            <li>
                              <DropdownItem
                                tag="a"
                                href="#links"
                                onClick={(ev) => {
                                  ev.preventDefault();
                                  fetchMaintenanceStatusUpdate(
                                    MaintenanceRequestStatusType.closed
                                  );
                                }}
                              >
                                <span>Closed</span>
                              </DropdownItem>
                            </li>
                          </ul>
                        </DropdownMenu>
                      ) : maintenanceDetails?.status ===
                        MaintenanceRequestStatusType.closed ? (
                        <DropdownMenu>
                          <ul className="link-list-opt">
                            <li>
                              <DropdownItem
                                tag="a"
                                href="#links"
                                onClick={(ev) => {
                                  ev.preventDefault();
                                  fetchMaintenanceStatusUpdate(
                                    MaintenanceRequestStatusType.open
                                  );
                                }}
                              >
                                <span>Open</span>
                              </DropdownItem>
                            </li>
                            <li>
                              <DropdownItem
                                tag="a"
                                href="#links"
                                onClick={(ev) => {
                                  ev.preventDefault();
                                  fetchMaintenanceStatusUpdate(
                                    MaintenanceRequestStatusType.inprogress
                                  );
                                }}
                              >
                                <span>In progress</span>
                              </DropdownItem>
                            </li>
                          </ul>
                        </DropdownMenu>
                      ) : null}
                    </UncontrolledDropdown>
                  </li>
                )}
                <li>
                  <ActionButton
                    title={"Chat"}
                    onClick={() => {
                      let id =
                        user.userType === "PM"
                          ? maintenanceDetails?.tenantChatId
                          : maintenanceDetails?.pmChatId;
                      setChatId(id);
                    }}
                  />
                </li>
                <li>
                  <ActionButton
                    title={"Edit"}
                    // loading={loading}
                    // disabled={disabled}
                    onClick={() => {
                      history.push({
                        pathname: `${process.env.PUBLIC_URL}/maintenance-request`,
                        state: { data: maintenanceDetails },
                      });
                    }}
                  />
                </li>
              </ul>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>

        <Block>
          <Row className="gy-5">
            <Col lg="5">
              <BlockHead>
                <BlockHeadContent>
                  <BlockTitle tag="h5">Details</BlockTitle>
                  {/* <p>Submission date, approve date, status etc.</p> */}
                </BlockHeadContent>
              </BlockHead>
              <Card className="card-bordered">
                <ul className="data-list is-compact">
                  <li className="data-item">
                    <div className="data-col">
                      <div className="data-label" style={{ flex: 1 }}>
                        Description
                      </div>
                      <div className="data-value" style={{ flex: 2 }}>
                        {maintenanceDetails?.description &&
                          toCapitalize(maintenanceDetails?.description ?? "")}
                      </div>
                    </div>
                  </li>
                  <li className="data-item">
                    <div className="data-col">
                      <div className="data-label" style={{ flex: 1 }}>
                        Status
                      </div>
                      <div className="data-value" style={{ flex: 2 }}>
                        <StatusBadge text={maintenanceDetails?.status ?? ""} />
                      </div>
                    </div>
                  </li>
                  <li className="data-item">
                    <div className="data-col">
                      <div className="data-label" style={{ flex: 1 }}>
                        Urgency
                      </div>
                      <div className="data-value" style={{ flex: 2 }}>
                        <UrgencyBadge
                          text={maintenanceDetails?.urgency ?? ""}
                        />
                      </div>
                    </div>
                  </li>
                  <li className="data-item">
                    <div className="data-col">
                      <div className="data-label" style={{ flex: 1 }}>
                        Category
                      </div>
                      <div className="data-value" style={{ flex: 2 }}>
                        <span>{maintenanceDetails?.category?.value ?? ""}</span>
                      </div>
                    </div>
                  </li>
                  <li className="data-item">
                    <div className="data-col">
                      <div className="data-label" style={{ flex: 1 }}>
                        Property
                      </div>
                      <div className="data-value" style={{ flex: 2 }}>
                        <span>{maintenanceDetails?.property?.value ?? ""}</span>
                      </div>
                    </div>
                  </li>
                  <li className="data-item">
                    <div className="data-col">
                      <div className="data-label" style={{ flex: 1 }}>
                        Unit
                      </div>
                      <div className="data-value" style={{ flex: 2 }}>
                        <span>{maintenanceDetails?.unit?.value ?? ""}</span>
                      </div>
                    </div>
                  </li>
                  <li className="data-item">
                    <div className="data-col">
                      <div className="data-label" style={{ flex: 1 }}>
                        Phone number
                      </div>
                      <div className="data-value" style={{ flex: 2 }}>
                        <ul>
                          {maintenanceDetails?.contactPhones
                            ?.split(",")
                            ?.map((item, index) => {
                              return (
                                <li key={index}>
                                  {formatPhoneNumber(COUNTRY_CODE + " ", item)}
                                  <br />
                                </li>
                              );
                            })}
                        </ul>
                      </div>
                    </div>
                  </li>
                  <li className="data-item">
                    <div className="data-col">
                      <div className="data-label" style={{ flex: 1 }}>
                        Email
                      </div>
                      <div className="data-value" style={{ flex: 2 }}>
                        <ul>
                          {maintenanceDetails?.contactEmails
                            ?.split(",")
                            ?.map((item, index) => {
                              return (
                                <li key={index}>
                                  {item}
                                  <br />{" "}
                                </li>
                              );
                            })}
                        </ul>
                      </div>
                    </div>
                  </li>
                  <li className="data-item">
                    <div className="data-col">
                      <div className="data-label" style={{ flex: 1 }}>
                        Scheduled date
                      </div>
                      <div className="data-value" style={{ flex: 2 }}>
                        <span>
                          {maintenanceDetails?.scheduleFor
                            ? moment(maintenanceDetails?.scheduleFor).format(
                                DATE_FORMAT
                              )
                            : "-"}
                        </span>
                      </div>
                    </div>
                  </li>
                  <li className="data-item">
                    <div className="data-col">
                      <div className="data-label" style={{ flex: 1 }}>
                        Scheduled time
                      </div>
                      <div className="data-value" style={{ flex: 2 }}>
                        <span>
                          {maintenanceDetails?.scheduleFor
                            ? moment(maintenanceDetails?.scheduleFor).format(
                                TIME_FORMAT
                              )
                            : "-"}
                        </span>
                        {maintenanceDetails?.scheduleEnd ? (
                          <span>
                            {" - "}
                            {maintenanceDetails?.scheduleFor
                              ? moment(maintenanceDetails?.scheduleEnd).format(
                                  TIME_FORMAT
                                )
                              : "-"}
                          </span>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </li>
                </ul>
              </Card>
              <BlockHead className="">
                <BlockBetween>
                  <BlockHeadContent>
                    <BlockTitle tag="h5">Files</BlockTitle>
                  </BlockHeadContent>
                </BlockBetween>
              </BlockHead>
              <Card className="card-bordered overflow-hidden">
                <ul className="data-list is-compact">
                  {maintenanceDetails?.files?.length > 0 ? (
                    maintenanceDetails?.files?.map((fItem, fIndex) => (
                      <React.Fragment key={fIndex}>
                        <li className="data-item">
                          <div className="data-col">
                            <div className="data-value">
                              <a
                                href="#files"
                                className="clickable"
                                onClick={(ev) => {
                                  ev.preventDefault();
                                  openLinkInNewTab(fItem.fileUrl, ev);
                                }}
                              >
                                {fItem.name ?? fItem.fileKey}
                              </a>
                            </div>
                          </div>
                        </li>
                      </React.Fragment>
                    ))
                  ) : (
                    <p className="text-center my-2">No files attached</p>
                  )}
                </ul>
              </Card>
            </Col>
            <Col lg="7">
              <BlockHead className="">
                <BlockBetween>
                  <BlockHeadContent>
                    <BlockTitle tag="h5">
                      <span>&nbsp;</span>
                    </BlockTitle>
                  </BlockHeadContent>
                </BlockBetween>
              </BlockHead>
              <Card
                id="style-3"
                className="card-bordered px-3 py-2 comment-section-height overflow-auto"
              >
                <Block>
                  <div className="nk-history">
                    {commentList.map((hitem, hindex) => {
                      return (
                        <React.Fragment key={hindex}>
                          <div key={hitem.id} className="nk-history-item ps-4">
                            <div className="nk-history-symbol">
                              <div
                                className={`nk-history-symbol-dot border-primary`}
                              ></div>
                            </div>
                            <div className="nk-history-content">
                              <VariantCard item={hitem} />
                            </div>
                          </div>
                        </React.Fragment>
                      );
                    })}
                    {commentList?.length === 0 && <>No activity yet</>}
                  </div>
                </Block>
              </Card>
              <div className="nk-chat-editor p-0">
                {commentfiles.length > 0 && (
                  <div className="p-1">
                    {commentfiles.map((fItem, fIndex) => (
                      <div
                        className="btn-group pb-1 pe-1"
                        key={"file" + fIndex}
                      >
                        <Button color="primary" type="submit">
                          {fItem.name}
                        </Button>
                        <Button
                          className="dropdown-toggle-split"
                          color="primary"
                          onClick={() => onRemoveFile(fItem?.uploadedFileId)}
                        >
                          {fItem?.isUploading ? (
                            <Spinner size="sm" color="light" />
                          ) : (
                            <Icon name="cross-sm"></Icon>
                          )}
                        </Button>
                      </div>
                    ))}
                  </div>
                )}
              </div>

              <div className="nk-chat-editor align-items-end">
                <DropdownToggle
                  color="tranparent"
                  className="btn btn-trigger btn-icon dropdown-toggle min-w-36"
                >
                  {/* <Icon name="setting"></Icon> */}
                  <label className="mx-auto text-primary" htmlFor="files">
                    <Icon name="clip" className={"mb-1 "} />
                  </label>
                  <input
                    type="file"
                    name="files"
                    accept="image/*,.pdf,.doc,.docx,.xml,video/*"
                    className="form-control"
                    id="files"
                    style={{ display: "none" }}
                    onChange={(e) => {
                      let temp = Array.prototype.slice.call(e.target.files);
                      if (temp.length > 0) {
                        upload(temp[0]);
                      }
                    }}
                  />
                </DropdownToggle>
                <div className="nk-chat-editor-form">
                  <div className="form-control-wrap">
                    <textarea
                      className="form-control form-control-simple no-resize"
                      rows="1"
                      id="default-textarea"
                      onChange={(e) => setComment(e?.target?.value)}
                      value={comment}
                      placeholder="Type your message..."
                      onKeyDown={(e) => {
                        // e.code === "Enter" && onTextSubmit(e);
                      }}
                    ></textarea>
                  </div>
                </div>
                <ul className="nk-chat-editor-tools g-2">
                  <li>
                    <Button
                      color="primary"
                      onClick={(e) => {
                        e.preventDefault();
                        onTextSubmit(comment);
                      }}
                      className="btn-round btn-icon comment-send-button"
                    >
                      {isSendingComment ? (
                        <Spinner size="sm" color="light" />
                      ) : (
                        <Icon name="send-alt"></Icon>
                      )}
                    </Button>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </Block>
      </Content>
      {chatId && (
        <ChatFloatingUI
          loggedInId={user.chatId}
          otherChatId={chatId}
          onClosed={() => setChatId()}
          chatType={ChatType.tenant_pm}
        />
      )}
      <LoadingComponent isLoading={isInitScreen} />
    </>
  );
}

export default MaintentenanceDetails;
