import React from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { Button } from "../../components/Component";
import { SuggestionType } from "../../utils";

export const ThanksLettingUsKnow = ({
  modal,
  type = SuggestionType.service,
  closeHandler,
  onSelectHandler,
}) => {
  return (
    <Modal isOpen={modal} toggle={closeHandler}>
      <ModalHeader>Thanks for letting us know</ModalHeader>
      <ModalBody>
        {type === SuggestionType.service ? (
          <>
            <p>
              It sounds like the services listed on {process.env.REACT_APP_APP_NAME} don't match your
              busniess. Our team will reach out to you soon to resolve it.
            </p>
            <b>
              You can still receive quote requests for any services on your
              listing.
            </b>
          </>
        ) : type === SuggestionType.association ? (
          <>
            <p>
              It sounds like the associations listed on {process.env.REACT_APP_APP_NAME} don't match
              your busniess. Our team will reach out to you soon to resolve it.
            </p>
          </>
        ) : null}
        <div>
          <Button
            color={"primary"}
            onClick={onSelectHandler}
            className="mt-4 mb-2"
          >
            Continue
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
};
