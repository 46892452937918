import React from "react";
import { findUpper } from "../../utils/Utils";
import { UserAvatar } from "../Component";

export const BlueText = ({ title, image, imageClass, isShowImage, className, onClick = () => {} }) => {
  return (
    <React.Fragment>
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a
        href={undefined}
        onClick={onClick}
        className={`project-title text-primary custom-underline cursor my-1 ${
          className ? className : ""
        }`}
      >
        <ul className="align-center flex-wrap flex-sm-nowrap">
          {isShowImage && (
            <li>
              <UserAvatar
                className={"sm " + imageClass}
                theme={"white"}
                text={findUpper(title ?? "")}
                image={image}
              />
            </li>
          )}
          <li>
            <div className="project-info">
              <span className={`title fSize14`}>{title}</span>
            </div>
          </li>
        </ul>
      </a>
    </React.Fragment>
  );
};
