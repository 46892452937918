import { APIDataConverter } from "./api-data-converter";
import { APIError, APIErrorCode, APIServiceBase } from "./api-service-base";
import { Storage } from "../storage/storage";
import { APIServiceMethod } from "./api-path";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";
/**
 * 
https://api.altitude.thestaging.live/rest-docs/
Basic Auth: venusapps / alTiTude@123
api key : altitude@2023
 * 
 */

export class APIServiceImpl extends APIServiceBase {
  constructor() {
    super();
    this.dataConverter = new APIDataConverter();
  }

  async handleAPIResopnse(response) {
    const json = await response.json();
    if ((response.status === 200 || response.status === 201) && json) {
      return json;
    } else {
      let error = new APIError(
        json?.errorCode ?? APIErrorCode.UnkonwnError,
        json?.message ?? null,
        json?.title ?? null,
        json?.isForceLogout ?? false
      );
      if(json?.errorCode === 504) {
        <Redirect to={`${process.env.PUBLIC_URL}/errors/504-modern`} />
      } else if(json?.errorCode === 502) {
        <Redirect to={`${process.env.PUBLIC_URL}/errors/504-modern`} />
      } else if (json?.isForceLogout === true) {
        Storage.clearAll();
        window.location.reload();
      }
      throw error;
    }
  }

  setUserToken(accessToken) {
    this.accessToken = accessToken;
  }

  handleAPIException(exception) {
    if (exception instanceof APIError) {
      return exception;
    } else {
      if (exception.code === 20 || exception.message === "Aborted") {
        // Abort Api call
        return new APIError(APIErrorCode.Aborted);
      } else {
        return new APIError(APIErrorCode.UnkonwnError);
      }
    }
  }

  handleAuthResponse(authResponse) {
    if (!authResponse.accessToken) {
      throw new APIError(APIErrorCode.UnexpectedAuthResponse);
    }
    this.accessToken = authResponse.accessToken;
  }

  // ************************Auth  API's*******************************//

  async signIn(req) {
    const authResponse = await this.publicAPICall("auth/sign-in", "POST", req);
    this.handleAuthResponse(authResponse);
    return this.dataConverter.toAuthorization(authResponse);
  }

  async signUp(req) {
    const authResponse = await this.publicAPICall(
      "auth/create-account",
      "POST",
      req
    );
    this.handleAuthResponse(authResponse);
    return this.dataConverter.toAuthorization(authResponse);
  }

  async verifyEmailToken(req) {
    const authResponse = await this.publicAPICall(
      "auth/verify-email-token",
      "POST",
      req
    );
    this.handleAuthResponse(authResponse);
    return this.dataConverter.toAuthorization(authResponse);
  }

  async verifyResetPasswordToken(req) {
    return await this.publicAPICall(
      "auth/verify-reset-password-token",
      "POST",
      req
    );
  }

  async resendVerifyEmail(req) {
    return await this.publicAPICall("auth/resend-email-link", "POST", req);
  }

  async generateSession(req) {
    const authResponse = await this.publicAPICall(
      "auth/login-with-token",
      "POST",
      req
    );
    this.handleAuthResponse(authResponse);
    return this.dataConverter.toAuthorization(authResponse);
  }

  async resetPassword(req) {
    return this.publicAPICall("auth/reset-password", "POST", req);
  }

  async createPassword(req) {
    const result = await this.sessionAPICall(
      "user/create-password",
      "POST",
      req
    );
    return this.dataConverter.toSessionUser(result);
  }

  async setAccountType(req) {
    const result = await this.sessionAPICall("user/select-role", "POST", req);
    return result;
  }

  async fetchLegalDocs() {
    const result = await this.publicAPICall("content/legal-docs", "GET", {});
    return result;
  }

  async acceptTermPolicy(req) {
    const result = await this.sessionAPICall(
      "user/accept-terms-policy",
      "POST",
      req
    );
    return result;
  }

  async recoverPassword(req) {
    return this.publicAPICall("auth/recover-password", "POST", req);
  }

  async checkPassword(req) {
    const result = await this.sessionAPICall(
      "user/check-password",
      "POST",
      req
    );
    return result;
  }

  async changePassword(req) {
    const result = await this.sessionAPICall(
      "user/change-password",
      "POST",
      req
    );
    return result;
  }

  async uploadImage(avatar) {
    // then create FormData
    let body = new FormData();
    body.append("avatar", avatar);

    const result = await this.uploadImageApiCall(
      "user/set-user-avatar",
      "POST",
      body
    );
    return result;
  }

  async changeEmail(req) {
    return this.sessionAPICall("user/email-change", "POST", req);
  }

  async verifyEmailChangeOTP(req) {
    return this.sessionAPICall("user/verify-email-change-otp", "POST", req);
  }

  async logout() {
    return await this.sessionAPICall("user/sign-out", "POST", {});
  }

  // ************************User API's*******************************//
  async fetchCurrentUser() {
    const result = await this.sessionAPICall("user/current-user", "GET");
    let user = this.dataConverter.toSessionUser(result);
    return user;
  }

  async updateUser(req) {
    const result = await this.sessionAPICall(
      "user/update-account",
      "POST",
      req
    );
    return result;
  }

  async updateUserTenant(req) {
    const result = await this.sessionAPICall(
      "tenant/update-profile",
      "POST",
      req
    );
    return result;
  }

  async updatePMCompanyInfo(req) {
    const result = await this.sessionAPICall(
      "pm/update-information",
      "POST",
      req
    );
    return result;
  }

  async preferredVendor(req) {
    const result = await this.sessionAPICall(
      "pm/preferred-vendor",
      "POST",
      req
    );
    return result;
  }

  async setNotificationFlags(req) {
    const result = await this.sessionAPICall(
      "user/change-settings",
      "POST",
      req
    );
    return result;
  }

  async vendorSetup(image, req) {
    let body = new FormData();
    if (image) {
      body.append("logo", image);
    }

    Object.keys(req).forEach((key) => {
      if (req[key] !== undefined && req[key] !== null && req[key] !== "") {
        body.append(key, req[key]);
      }
    });
    const result = await this.uploadImageApiCall(
      "vendor/update-profile",
      "POST",
      body
    );
    return result;
  }

  async fetchServices(req) {
    const result = await this.sessionAPICall(
      "vendor/service-list",
      "POST",
      req
    );
    return this.dataConverter.toServiceList(result);
  }

  async addSuggestService(req) {
    return this.sessionAPICall("vendor/suggest-services", "POST", req);
  }

  async fetchAssociations(req) {
    const result = await this.sessionAPICall(
      "vendor/associations-list",
      "POST",
      req
    );
    return this.dataConverter.toAssociationList(result);
  }

  async addSuggestAssociation(req) {
    return this.sessionAPICall("vendor/suggest-associations", "POST", req);
  }

  async getVendorList(req) {
    const result = await this.sessionAPICall("vendor/list", "POST", req);
    return result;
  }

  async getVendorDetail(req) {
    const result = await this.sessionAPICall(
      "vendor/vendor-detail",
      "POST",
      req
    );
    return result;
  }

  // ************************Payment API's*******************************//
  async saveCard(req) {
    const result = await this.sessionAPICall("card/save", "POST", req);
    return this.dataConverter.toCard(result.card);
  }

  async getCards(req) {
    const result = await this.sessionAPICall("card/list", "POST", req);
    return this.dataConverter.toCards(result.cards);
  }

  // ************************Quote Request API's*******************************//
  async quoteRequest(req) {
    const result = await this.sessionAPICall("quotation/request", "POST", req);
    return result;
  }

  async updateQuoteRequest(req) {
    const result = await this.sessionAPICall(
      "quotation/request-update",
      "POST",
      req
    );
    return result;
  }

  async fetchRequestDetail(req) {
    const result = await this.sessionAPICall(
      "quotation/quotation-request-detail",
      "POST",
      req
    );
    return result.data;
  }

  async getRequestList(req) {
    const result = await this.sessionAPICall(
      "quotation/request-list",
      "POST",
      req
    );
    return result;
  }

  async getVendorRequestList(req) {
    const result = await this.sessionAPICall(
      "quotation/request-list-for-vendor",
      "POST",
      req
    );
    return result;
  }

  async removeQuotation(req) {
    const result = await this.sessionAPICall(
      "quotation/quotation-remove",
      "POST",
      req
    );
    return result;
  }

  async removeRequestbyVendor(req) {
    const result = await this.sessionAPICall(
      "quotation/remove-by-vendor",
      "POST",
      req
    );
    return result;
  }

  async getBidList(req) {
    const result = await this.sessionAPICall(
      "quotation/quotation-bid-list",
      "POST",
      req
    );
    return this.dataConverter.toBidList(result);
  }

  async getInvitedVendor(req) {
    const result = await this.sessionAPICall(
      "quotation/invited-vendor",
      "POST",
      req
    );
    return this.dataConverter.toInvitedVendorList(result);
  }

  async sendBid(req) {
    const result = await this.sessionAPICall(
      "quotation/quotation-request-bid",
      "POST",
      req
    );
    return result;
  }

  async updateSentBid(req) {
    const result = await this.sessionAPICall(
      "quotation/quotation-request-edit-bid",
      "POST",
      req
    );
    return result;
  }

  async actionOnBid(req) {
    const result = await this.sessionAPICall(
      "quotation/bid-moderate",
      "POST",
      req
    );
    return this.dataConverter.toBid(result.data);
  }

  async getTimeline(req) {
    const result = await this.sessionAPICall("quotation/timeline", "POST", req);
    return this.dataConverter.toTimeLineList(result);
  }

  async updateRequestExtraFileId(req) {
    const result = await this.sessionAPICall(
      "quotation/file-upload",
      "POST",
      req
    );
    return result;
  }

  async markRequestAsCompletedByVendor(req) {
    const result = await this.sessionAPICall(
      "quotation/mark-as-completed",
      "POST",
      req
    );
    return result;
  }

  async moderateRequestByPM(req) {
    const result = await this.sessionAPICall(
      "quotation/request-moderate",
      "POST",
      req
    );
    return result;
  }

  // ************************Property API's*******************************//
  async propertyList(req) {
    const result = await this.sessionAPICall("property/list", "POST", req);
    return this.dataConverter.toPropertyList(result);
  }

  async createProperty(image, req) {
    // then create FormData
    let body = new FormData();
    if (image) {
      body.append("propertyImage", image.file);
    }

    Object.keys(req).forEach((key) => {
      if (req[key] !== undefined && req[key] !== null && req[key] !== "") {
        body.append(key, req[key]);
      }
    });

    const result = await this.uploadImageApiCall(
      req.id ? "property/update" : "property/create",
      "POST",
      body
    );
    return result;
  }

  async removeProperty(req) {
    const result = await this.sessionAPICall("property/remove", "POST", req);
    return result;
  }

  async saveUnit(req) {
    const result = await this.sessionAPICall("unit/save", "POST", req);
    return result;
  }

  async propertyDetails(req) {
    const result = await this.sessionAPICall("property/detail", "POST", req);
    return result;
  }

  async savePropertyContact(req) {
    const result = await this.sessionAPICall(
      "property/save-property-contact",
      "POST",
      req
    );
    return result;
  }
  async removeContact(req) {
    const result = await this.sessionAPICall(
      "property/remove-property-contact",
      "POST",
      req
    );
    return result;
  }
  async savePropertyOwner(req) {
    const result = await this.sessionAPICall(
      "property/save-property-owner",
      "POST",
      req
    );
    return result;
  }
  async removeOwner(req) {
    const result = await this.sessionAPICall(
      "property/remove-property-owner",
      "POST",
      req
    );
    return result;
  }

  // ************************ Tenants API's *******************************//
  async tenantList(req) {
    const result = await this.sessionAPICall("tenant/list", "POST", req);
    return result;
  }

  async tenantAssociateWithPM(req) {
    const result = await this.sessionAPICall(
      "tenant/list-associated-with-pm",
      "POST",
      req
    );
    return result;
  }
  async unitHistoryList(req) {
    const result = await this.sessionAPICall(
      "tenant/unit-history-list",
      "POST",
      req
    );
    return result;
  }

  // ************************File API's*******************************//
  async fileUploader(file, type) {
    if (file === undefined || file === null) {
      return null;
    }

    let body = new FormData();
    body.append("file", file);
    body.append("type", type);
    body.append("name", file.name);
    const result = await this.uploadImageApiCall(
      "common/file-data",
      "POST",
      body
    );
    return result.data;
  }

  async fetchConfig() {
    const result = await this.sessionAPICall("common/config-data", "POST", {});
    return result;
  }

  // ************************Common API's*******************************//
  async postCall(path, req, timeout) {
    return await this.sessionAPICall(
      path,
      APIServiceMethod.post,
      req,
      null,
      timeout
    );
  }

  async getCall(path, req) {
    return await this.sessionAPICall(path, APIServiceMethod.get, req);
  }
}
