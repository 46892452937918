import React, { useEffect, useState } from "react";
import { DropdownToggle, DropdownMenu, Dropdown } from "reactstrap";
import { Icon } from "../../../../components/Component";
import { LinkList, LinkItem } from "../../../../components/links/Links";
import UserAvatar from "../../../../components/user/UserAvatar";
import { apiService } from "../../../../services/api";
import { Storage } from "../../../../services/storage/storage";
import { UserAccountType, findUpper } from "../../../../utils/Utils";

const User = () => {
  const [open, setOpen] = useState(false);
  const [user, setUser] = useState(null);

  useEffect(() => {
    updateUser();
  }, []);

  const toggle = () => {
    updateUser();
    setOpen((prevState) => !prevState);
  };

  const updateUser = () => {
    let user = Storage.getLoggedInUser().user;
    setUser(user);
  };

  const handleSignout = () => {
    apiService
      .logout()
      .then(() => {})
      .catch((error) => console.log(error))
      .finally(() => {
        Storage.clearAll();
        checkAndLoad();
      });
  };

  function checkAndLoad() {
    window.history.pushState(
      `${process.env.PUBLIC_URL ? process.env.PUBLIC_URL : "/"}`,
      "auth-login",
      `${process.env.PUBLIC_URL ? process.env.PUBLIC_URL : "/"}`
    );
    window.location.reload();
  }

  return (
    <Dropdown isOpen={open} className="user-dropdown" toggle={toggle}>
      <DropdownToggle
        tag="a"
        href="#toggle"
        className="dropdown-toggle"
        onClick={(ev) => {
          ev.preventDefault();
        }}
      >
        <div className="user-toggle">
          <UserAvatar
            text={findUpper(
              (user?.firstName ?? "") + " " + (user?.lastName ?? "")
            )}
            theme="white"
            className="sm"
            image={user?.avatar?.thumbUrl}
          />
          <div className="user-info d-none d-md-block">
            {/* <div className="user-status">{user?.accountType}</div> */}
            {user?.accountType === UserAccountType.tenant ? (
              <div className="user-status">
                {user?.property
                  ? (user?.property?.name ?? "") +
                    ", Apt. " +
                    (user?.property?.unitName ?? "")
                  : "Your property name"}
              </div>
            ) : (
              <div className="user-status">
                {user?.companyInfo?.companyName ?? ""}
              </div>
            )}
            <div className="user-name dropdown-indicator">
              {(user?.firstName ?? "") + " " + (user?.lastName ?? "")}
            </div>
          </div>
        </div>
      </DropdownToggle>
      <DropdownMenu end className="dropdown-menu-md dropdown-menu-s1">
        <div className="dropdown-inner user-card-wrap bg-lighter d-none d-md-block">
          <div className="user-card sm">
            <div className="user-info">
              <span className="lead-text">
                {(user?.firstName ?? "") + " " + (user?.lastName ?? "")}
              </span>
              <span className="sub-text">{user?.email}</span>
              <span className="sub-text">{user?.accountType}</span>
            </div>
          </div>
        </div>
        <div className="dropdown-inner">
          <LinkList>
            <LinkItem link="/user-profile" icon="user-alt" onClick={toggle}>
              View profile
            </LinkItem>
            <LinkItem
              link="/user-profile-setting"
              icon="setting-alt"
              onClick={toggle}
            >
              Account setting
            </LinkItem>
          </LinkList>
        </div>
        <div className="dropdown-inner">
          <LinkList>
            <a
              // href={`${process.env.PUBLIC_URL}/auth-login`}
              href="#logout"
              onClick={handleSignout}
              className="clickable"
            >
              <Icon name="signout"></Icon>
              <span>Sign out</span>
            </a>
          </LinkList>
        </div>
      </DropdownMenu>
    </Dropdown>
  );
};

export default User;
