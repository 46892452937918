import React, { useEffect, useState } from "react";
import { Button } from "reactstrap";
import {
  BankDropdown,
  Block,
  Col,
  DatePicker,
  FormInput,
  FormInputType,
  PayonDropdown,
  Row,
} from "../../../../../components/Component";
import {
  CURRENCY_CODE,
  DATE_FORMAT,
  numberFormatterWithDecimal,
  removeTimeFromServerDateString,
} from "../../../../../utils";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom";
import moment from "moment";
import { TenantAutoPaymentWizardContext } from "./AutoRentPayment";
import { Storage } from "../../../../../services/storage/storage";
import { fetchInvoiceList } from "../InvoiceData";
import { UserAccountType } from "../../../../../utils/Utils";

export const TenantAutoPaymentDetails = (props) => {
  const { data, updateData, updateWholeData } = React.useContext(
    TenantAutoPaymentWizardContext
  );
  const history = useHistory();
  const location = useLocation();
  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    let user = Storage.getLoggedInUser()?.user;
    if (data?.list) {
    } else {
      getInvoiceList(user?.property?.tenantId);
      let detail = location.state?.detail;
      if (location.state?.detail) {
        updateWholeData(detail);
      }
    }
  }, []);

  useEffect(() => {
    if (
      !data?.bank ||
      !data?.startDate ||
      !data?.endDate ||
      !data?.selectedDate ||
      !data?.amount ||
      data?.amount === ""
    ) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [data]);

  function getInvoiceList(id) {
    let params = {
      tenantId: id,
      invoiceType: "RECURRING",
      status: "ACTIVE",
    };
    fetchInvoiceList(params, (data, error) => {
      if (error === null) {
        updateData("list", data.list);
      }
    });
  }

  return (
    <Block>
      <div className="invoice-bills">
        <div className="table-responsive">
          <table className="table table-striped">
            <thead>
              <tr>
                <th className="w-min-250px">Active on</th>
                <th className="w-60">Recurring charges</th>
                <th></th>
                <th></th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody>
              {data?.list?.map((item, index) => {
                let date = item.effectiveDate
                  ? moment(removeTimeFromServerDateString(item.effectiveDate))
                  : undefined;
                let endDate = item.endDate
                  ? moment(removeTimeFromServerDateString(item.endDate))
                  : undefined;
                return (
                  <tr key={index}>
                    <td>
                      {date ? date.format(DATE_FORMAT) : ""} -{" "}
                      {endDate ? endDate.format(DATE_FORMAT) : ""}
                    </td>
                    <td>
                      {item.items?.map((item) => item.title)?.join(", ") ?? ""}
                    </td>
                    <td></td>
                    <td></td>
                    <td>
                      {numberFormatterWithDecimal(
                        CURRENCY_CODE,
                        "" + (item?.totalAmount ?? 0)
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
            <tfoot className="">
              <tr className=" h-40">
                <td colSpan="2"></td>
                <td className="fw-bold text-end" colSpan="2">
                  Total balance
                </td>
                <td className="fw-bold">
                  {numberFormatterWithDecimal(
                    CURRENCY_CODE,
                    "" +
                      data?.list?.reduce((a, c) => {
                        return a + c.totalAmount;
                      }, 0)
                  )}
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
        <Row className={"mt-3"}>
          <Col md="3">
            <label htmlFor="bankDropdown" className="form-label">
              Auto pay account
            </label>
            <BankDropdown
              accountType={UserAccountType.tenant}
              selectedId={data?.bankId}
              value={data?.bank} // Pass the selected bank value to the BankDropdown component
              onSelect={(bank) => updateData("bank", bank)} // Handle the bank selection
              placeholder="Select bank account" // Add a placeholder for the dropdown
            />
          </Col>
          <Col md="2">
            <div className="form-group">
              <label className="form-label">Start date</label>
              <DatePicker
                value={data?.startDate ?? null}
                onChange={(date) => {
                  updateData("startDate", date);
                }}
              />
            </div>
          </Col>
          <Col md="2">
            <div className="form-group">
              <label className="form-label">End date</label>
              <DatePicker
                value={data?.endDate ?? null}
                onChange={(date) => {
                  updateData("endDate", date);
                }}
              />
            </div>
          </Col>
          <Col md="2">
            <label htmlFor="bankDropdown" className="form-label">
              Pay on
            </label>
            <PayonDropdown
              value={data?.selectedDate}
              start={location.state?.pmDetail?.rentalInvoiceGenerateDay ?? 0}
              end={location.state?.pmDetail?.dueDays ?? 0}
              onSelect={(date) => updateData("selectedDate", date)}
            />
          </Col>
          <Col md="2">
            <div className="form-group">
              <FormInput
                label={"Payment amount"}
                type={FormInputType.amount}
                value={data?.amount ?? ""}
                onTextChange={(text) => {
                  updateData("amount", text);
                }}
              />
            </div>
          </Col>
        </Row>

        {(data?.paymentRemain?.amountNeedToPay ?? 0) -
          parseFloat(data?.paymentAmount ?? 0) <
          0 && (
          <span className="invalid">
            Don’t enter more than account balance value
          </span>
        )}
        <div className="nk-notes ff-italic fs-12px text-soft mt-5">
          <div className="form-group d-flex align-items-center">
            <Button
              type="button"
              color="gray"
              size="md"
              className="btn-dim me-4"
              onClick={() => {
                history.push(`${process.env.PUBLIC_URL}/`);
              }}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              color="primary"
              disabled={disabled}
              size="md"
              // defaultValue={data?.paymentAmount}
              className="me-2"
              onClick={() => props.next()}
            >
              Continue
            </Button>
          </div>
        </div>
      </div>
    </Block>
  );
};
