import { apiService } from "../../../../services/api";
import { APIServicePath } from "../../../../services/api/api-path";

export function addCustomInvoice(params, callback) {
  apiService
    .postCall(APIServicePath.addInvoice, params)
    .then((auth) => {
      callback(auth, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function fetchInvoiceList(params, callback) {
  apiService
    .postCall(APIServicePath.listInvoice, params)
    .then((auth) => {
      callback(auth, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function revokeCustomInvoice(params, callback) {
  apiService
    .postCall(APIServicePath.cancelInvoice, params)
    .then((auth) => {
      callback(auth, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function getInvoiceStatus(item) {
  let status = "";
  if (item.invoiceType === "SINGLE" && item.status === "NEW") {
    status = "Assigned";
  } else if (item.invoiceType === "SINGLE" && item.status === "ACTIVE") {
    status = "Charged";
  } else if (item.invoiceType === "RECURRING" && item.status === "ACTIVE") {
    status = "Active";
  } else if (item.invoiceType === "RECURRING" && item.status === "DISABLED") {
    status = "Inactive";
  } else if (item.invoiceType === "SINGLE" && item.status === "DISABLED") {
    status = "Revoked";
  }
  return status;
}
