import React from "react";
import { useState } from "react";
import { Card } from "reactstrap";
import { Button, Icon, UserAvatar } from "../../../components/Component";
import CopyToClipboard from "react-copy-to-clipboard";
import ImageContainer from "./ImageContainer";
import VideoContainer from "./VideoContainer";
import PreviewModal from "./PreviewModal";
import { UserAccountType, findUpper } from "../../../utils/Utils";
import { RequestActivtyType } from "../../../utils";

const VariantCard = ({ item }) => {
  const [modal, setModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState();

  let files = item?.files ?? null;
  let deletedFiles = item.eventValue ?? null;

  return (
    <Card className="border shadow-none ">
      <div className="card-body gy-2">
        <div className="d-flex align-items-center mb-2">
          <div className={`user-avatar xs text-primary bg-primary-dim`}>
            <UserAvatar
              className="sm"
              // theme={"white"}
              text={findUpper(
                item?.userInfo?.firstName + " " + item?.userInfo?.lastName ?? ""
              )}
              image={item?.userInfo?.avatar?.thumbUrl}
            />
            {/* <Icon name={"user-alt-fill"}></Icon> */}
          </div>
          <h5 className="fs-14px fw-medium ms-2">
            {item?.userInfo?.firstName + " " + item?.userInfo?.lastName}
          </h5>
        </div>

        {(files?.length ?? 0) > 0 && (
          <label className="lead fSize14 fw-light text-gray">
            {`File ${files?.map((item) => item.name)?.join(", ")} was added`}
            <br />
          </label>
        )}

        {(deletedFiles?.length ?? 0) > 0 && (
          <label className="lead fSize14 fw-light text-gray mb-2">{`File ${deletedFiles
            ?.map((item) => item.name)
            ?.join(", ")} was deleted`}</label>
        )}

        {(files?.length ?? 0) > 0 && (
          <div className="d-flex flex-wrap">
            {files?.map((fItem, fIndex) => {
              if (fItem.status === "DELETED") {
                return <React.Fragment key={fIndex}></React.Fragment>;
              }
              if ((fItem?.mimeType ?? "").includes("image/")) {
                return (
                  <div className="w-150" key={"file" + fIndex}>
                    <ImageContainer
                      img={fItem?.fileUrl}
                      onClick={() => {
                        setModal(true);
                        setSelectedItem(fItem);
                      }}
                    />
                  </div>
                );
              } else {
                return (
                  <div key={"file" + fIndex}>
                    <VideoContainer
                      className="w-200 h-120"
                      vedioUrl={fItem?.fileUrl}
                      thumbUrl={fItem?.thumbUrl}
                      onClick={() => {
                        setModal(true);
                        setSelectedItem(fItem);
                      }}
                    />
                  </div>
                );
              }
            })}
          </div>
        )}

        {item.statusUpdate ? (
          <p className="">{`Status changed from ${item.statusUpdate.old.label} to ${item.statusUpdate.new.label}`}</p>
        ) : (item?.comment?.description.length ?? 0) > 0 ? (
          <p className="lead  fSize14">{item?.comment?.description ?? ""}</p>
        ) : null}

        <ul className="nk-history-meta ">
          <li className="fs-12">{item?.time}</li>
          <li className="fs-12">{item?.date}</li>
        </ul>
      </div>
      {selectedItem && (
        <PreviewModal
          isOpen={modal}
          isEdit={"listingInformationModal.edit"}
          item={selectedItem}
          data={(data) => {}}
          onCancel={() => {
            // setListingInformationModal({ edit: false }, { add: false });
            setModal(false);
            setSelectedItem();
          }}
          onSave={() => {
            // setListingInformationModal({ edit: false }, { add: false });
            setSelectedItem();
          }}
        />
      )}
    </Card>
  );
};

export default VariantCard;
