import moment from "moment";
import React from "react";
import { toast } from "react-toastify";
import {
  AppText,
  EMAIL_REGX,
  PASSWORD_REGX,
  PHONE_REGX,
  propertiesTypes,
  quoteRequestRecurringType,
  StatusType,
} from ".";
import { Icon } from "../components/Component";

export function formatPhoneNumber(prefix, value) {
  // if input value is falsy eg if the user deletes the input, then just return
  if (!value) {
    return value;
  }

  if (value === prefix) {
    return "";
  }
  var phoneNumber = value.replace(prefix, "");
  // clean the input for any non-digit values.
  phoneNumber = phoneNumber.replace(/[^\d]/g, "");
  // phoneNumberLength is used to know when to apply our formatting for the phone number
  const phoneNumberLength = phoneNumber.length;

  // we need to return the value with no formatting if its less then four digits
  // this is to avoid weird behavior that occurs if you  format the area code to early

  if (phoneNumberLength < 4) {
    return prefix + phoneNumber.slice(0);
  }

  // if phoneNumberLength is greater than 4 and less the 7 we start to return
  // the formatted number
  if (phoneNumberLength < 7) {
    return `${prefix}(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
  }
  // finally, if the phoneNumberLength is greater then seven, we add the last
  // bit of formatting and return it.
  return `${prefix}(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
    3,
    6
  )} ${phoneNumber.slice(6, 10)}`;
}

export const numberFormatter = (prefix, value) => {
  if (value === undefined || value === null || value === "") {
    return "";
  }
  var amount = value.replace(/[^\d.]/g, "");

  amount = parseFloat(amount);
  let isDecimal = amount % 1 !== 0;
  amount =
    prefix +
    amount.toFixed(isDecimal ? 2 : 0).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  return amount;
};

export const numberFormatterWithDecimal = (prefix, value) => {
  if (value === undefined || value === null || value === "") {
    return "";
  }
  var amount = value.replace(/[^\d.]/g, "");
  amount = parseFloat(amount);
  amount = prefix + amount.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  return amount;
};

export const isNumberOnly = (value) => {
  return !isNaN(+value);
};

export const covertToNumberOnly = (value) => {
  let numberText = typeof value === "number" ? "" + value : value
  return numberText?.replaceAll(/[^\d.]/g, "") ?? 0;
};

export const toCapitalize = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

export const isValidPassword = (value) => {
  if (value === null || value === undefined) {
    return false;
  }
  return PASSWORD_REGX.test(value);
};

export const isValidPhone = (value) => {
  if (value === null || value === undefined) {
    return false;
  }
  return PHONE_REGX.test(value);
};

export const isValidEmail = (value) => {
  if (value === null || value === undefined) {
    return false;
  }
  return EMAIL_REGX.test(value);
};

export const successToast = (message) => {
  toast.success(message, {
    position: "top-center",
    autoClose: true,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: false,
    // closeButton: <CloseButton />,
  });
};

export const propertyTypeToObject = (type) => {
  let list = propertiesTypes.filter((item) => item.value === type);
  return list.length === 1 ? list[0] : {};
};

export const propertyTypesToString = (data) => {
  let list = data.filter((item) => item !== "");
  var items = [];
  for (let index = 0; index < list?.length ?? 0; index++) {
    const value = propertyTypeToObject(list[index])?.label;
    if (value) {
      items.push(value);
    }
  }
  return listAsNewLineString(items);
};

export const propertyTypesToStringWithComma = (data) => {
  let list = data.filter((item) => item !== "");
  var items = [];
  for (let index = 0; index < list?.length ?? 0; index++) {
    const value = propertyTypeToObject(list[index])?.label;
    if (value) {
      items.push(value);
    }
  }
  return items.join(", ");
};

export const listAsNewLineString = (list) => {
  if ((list?.length ?? 0) === 0) {
    return "Not specified";
  }
  return list.map((line, index) => (
    <React.Fragment key={index}>
      {line}
      <br />
    </React.Fragment>
  ));
};

export const removeTimeFromServerDateString = (dateString) => {
  let date = dateString?.replace("T00:00:00.000Z", "") ?? "";
  return date;
};

export const getDateTimeFromString = (dateString) => {
  if (dateString === null || undefined || "") {
    return null;
  }
  let date = moment(dateString).toDate();
  let time = moment(dateString).toDate();
  return { date, time };
};

export function newlineText(text) {
  const newText =
    text?.split("\n")?.map((str, index) => <p key={index}>{str}</p>) ?? "";
  return newText;
}

export function statusColor(text) {
  var color = "";
  switch (text) {
    case StatusType.draft:
      color = "gray";
      break;
    case StatusType.published:
      color = "warning";
      break;
    case StatusType.quoted:
      color = "orange";
      break;
    case StatusType.confirmed:
      color = "purple";
      break;
    case StatusType.complete:
      color = "azure";
      break;
    case StatusType.closed:
      color = "teal";
      break;
    case StatusType.reopened:
      color = "danger";
      break;
    case StatusType.deleted:
      color = "dark";
      break;
    case StatusType.rejected:
      color = "danger";
      break;
    default:
      color = "gray";
      break;
  }
  return color;
}

export const openLinkInNewTab = (url, event) => {
  if (event?.metaKey) {
    event.preventDefault();
    window.open(url, "_blank");
  } else {
    window.open(url, "_blank");
  }
};

export function quoteRequestRecurringTypeText(value) {
  let list = quoteRequestRecurringType.filter((item) => item.value === value);
  return list.length === 1 ? list[0].label : "-";
}

export const showErrorToast = (message) => {
  let text = message.length > 0 ? message : AppText.connectError;
  toast.error(text, {
    position: "top-right",
    autoClose: true,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: false,
    bodyClassName: "me-5",
    closeButton: <CloseButton />,
  });
};

export const showSuccessToast = (message) => {
  toast.success(message, {
    position: "top-right",
    autoClose: true,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: false,
    bodyClassName: "me-5",
    closeButton: <CloseButton />,
  });
};

export const showInfoToast = (message) => {
  toast.info(message, {
    position: "top-right",
    autoClose: false,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: false,
    bodyClassName: "me-5",
    closeButton: <CloseButton />,
  });
};
const CloseButton = () => {
  return (
    <span className="btn-trigger toast-close-button" role="button">
      <Icon name="cross"></Icon>
    </span>
  );
};

export function dateTimeForChat(date) {
  if (date === null || date === undefined) {
    return "";
  }
  const currentDateMoment = moment(new Date());
  let seconds = currentDateMoment.diff(date, "seconds");
  let mins = currentDateMoment.diff(date, "minutes");
  let hour = currentDateMoment.diff(date, "hours");
  let days = currentDateMoment.diff(date, "days");
  let weeks = currentDateMoment.diff(date, "weeks");
  let months = currentDateMoment.diff(date, "months");
  if (seconds < 5) {
    return "Now";
  } else if (seconds < 60) {
    return seconds + " s ago";
  } else if (mins < 60) {
    return mins + " m ago";
  } else if (hour < 24) {
    return hour + " h ago";
  } else if (days < 7) {
    return days + " d ago";
  } else if (weeks < 4) {
    return weeks + " w ago";
  } else if (months < 12) {
    return months + " mon ago";
  } else {
    return currentDateMoment.format("MMM DD, YYYY");
  }
}

export function openWebsite(website, ev) {
  if (website) {
    if (website.includes("http")) {
      openLinkInNewTab(website, ev);
    } else {
      openLinkInNewTab("https://" + website);
    }
  }
}

export function getPayoutAccountData(element) {
  let item = null;
  if ((element?.payoutBanks?.length ?? 0) > 0) {
    let name =
      element?.payoutBanks[0].bankName +
      " (****" +
      element?.payoutBanks[0].last4 +
      ")";

    item = {
      id: element.id,
      label: name,
      value: name,
    };
  } else if ((element?.payoutCards?.length ?? 0) > 0) {
    let name =
      element.payoutCards[0].brand +
      " (****" +
      element.payoutCards[0].last4 +
      ")";

    item = {
      id: element.id,
      label: name,
      value: name,
    };
  }
  return item;
}

export const convertToDropdownItem = (item) => {
  let value = {
    id: item?.id,
    value: toCapitalize(item?.name ?? item?.title ?? ""),
    label: item?.name ?? item?.title ?? "",
  };
  return value;
};

export const convertToDropdownList = (items) => {
  let list = [];
  items.map((item, index) => {
    return list.push(convertToDropdownItem(item));
  });
  return list;
};

export const getNumberAsText = (number) => {
  return  number === 1 ? "1st" : number === 2 ? "2nd" :  number === 3 ? "3rd" : number + "th";
}
