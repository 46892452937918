import React from "react";
import Head from "../../../head/Head";
import { Card } from "reactstrap";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Button,
} from "../../../../components/Component";
import { useHistory } from "react-router";
import { VerifyPasswordType } from "../../../../pages/auth/VerifyPassword";

const UserProfileSettingPage = ({ sm, updateSm }) => {
  const history = useHistory();

  return (
    <React.Fragment>
      <Head title="Settings"></Head>
      <BlockHead size="lg">
        <BlockBetween>
          <BlockHeadContent>
            <BlockTitle tag="h4">Security settings</BlockTitle>
            <BlockDes>
              <p>These settings will help you to keep your account secure.</p>
            </BlockDes>
          </BlockHeadContent>
          <BlockHeadContent className="align-self-start d-lg-none">
            <Button
              className={`toggle btn btn-icon btn-trigger mt-n1 ${
                sm ? "active" : ""
              }`}
              onClick={() => updateSm(!sm)}
            >
              <Icon name="menu-alt-r"></Icon>
            </Button>
          </BlockHeadContent>
        </BlockBetween>
      </BlockHead>

      <Block>
        <Card className="card-bordered shadow-none">
          <div className="card-inner-group">
            <div className="card-inner">
              <div className="between-center flex-wrap g-3">
                <div className="nk-block-text">
                  <h6>Change password</h6>
                  <p>Set a unique password to protect your account.</p>
                </div>
                <div className="nk-block-actions flex-shrink-sm-0">
                  <ul className="align-center flex-wrap flex-sm-nowrap gx-3 gy-2">
                    <li className="order-md-last">
                      <Button
                        onClick={() => {
                          history.push({
                            pathname: `${process.env.PUBLIC_URL}/verify-create-password`,
                            state: {
                              type: VerifyPasswordType.changePassword,
                            },
                          });
                        }}
                        color="primary"
                      >
                        Change password
                      </Button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="card-inner">
              <div className="between-center flex-wrap g-3">
                <div className="nk-block-text">
                  <h6>Change email</h6>
                </div>
                <div className="nk-block-actions flex-shrink-sm-0">
                  <ul className="align-center flex-wrap flex-sm-nowrap gx-3 gy-2">
                    <li className="order-md-last">
                      <Button
                        onClick={() => {
                          history.push({
                            pathname: `${process.env.PUBLIC_URL}/verify-create-password`,
                            state: {
                              type: VerifyPasswordType.changeEmail,
                            },
                          });
                        }}
                        color="primary"
                      >
                        Change email
                      </Button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </Card>
      </Block>
    </React.Fragment>
  );
};
export default UserProfileSettingPage;
