import React, { useState, createContext } from "react";
import ChatService from "../../../services/chat/chatservice";

export const ChatContext = createContext();

export const ChatContextProvider = (props) => {
  const [chat, setChat] = useState([]);
  const [currentUserChatId, setCurrentUserChatId] = useState();

  // Function for deleting a conversation
  const deleteConvo = (channel) => {
   ChatService.instance
   .deleteAllMessage(channel)
   .then((data) => {
     channel.lastMessage = null
     updateChannel(channel);
   })
   .catch((error) => {
    console.log("Error in messages deletion", error);
   })
  };

  // Universal function for any props
  const deleteChannel = (channel) => {
    ChatService.instance.deleteChannel(channel)
    let list = chat.filter(item => item.url !== channel.url)
    setChat(list);
  };

  const hideChannel = (channel) => {
    let params = {
      hidePreviousMessages: true,
      allowAutoUnhide: true,
    }
    ChatService.instance.hideChannel(channel, params, (data) => {
      let list = chat.filter(item => item.url !== channel.url)
      setChat(list);
    })
   
  };

  // Function to change nickame
  const changeNickname = (id, name) => {
    const data = chat;
    const index = data.findIndex((item) => item.id === id);
    data[index].nickname = name;
    setChat([...data]);
  };

  // Function to change theme
  const changeTheme = (id, name) => {
    const data = chat;
    const index = data.findIndex((item) => item.id === id);
    data[index].chatTheme = name;
    setChat([...data]);
  };

  // Removes user from group
  const deleteUser = (id, userId) => {
    let data = chat;
    const grouped = data.findIndex((item) => item.id === id);
    const group = data.find((item) => item.id === id);
    const user = group.user.filter((user) => user.id !== userId);
    group.user = user;
    data[grouped] = group;
    setChat([...data]);
  };

  // Makes a user admin
  const makeAdmin = (id, userId) => {
    let data = chat;
    const grouped = data.findIndex((item) => item.id === id);
    const group = data.find((item) => item.id === id);
    group.user.forEach((element) => {
      element.role = "User";
    });
    const userIndex = group.user.findIndex((item) => item.id === userId);
    group.user[userIndex].role = "Admin";
    data[grouped] = group;
    setChat([...data]);
  };

  // Add a user to a chat
  const addUserToChat = (id, object) => {
    let data = chat;
    const group = data.find((item) => item.id === id);
    let newUserGroup = [...group.user, object];
    group.user = newUserGroup;
    setChat(data);
    // let newUserData = userData;
    // let user = newUserData.filter((el) => el.id !== object.id);
    // setUserData(user);
  };

  const updateChannel = (channel) => {
   let list = [...chat];
   if(chat?.some(item => item.url === channel.url)) {
     let index = chat.findIndex(item => item.url === channel.url)
     list[index] = channel;
   } else {
     // Add channel to list.
     list.push(channel);
   }
   let result = list.sort((a, b) => a?.lastMessage?.createdAt > b?.lastMessage?.createdAt)
   setChat(result);
  };

  return (
    <ChatContext.Provider
      value={{
        chatState: [chat, setChat],
        chatData: chat,
        // userData: userData,
        currentUserChatIdState: [currentUserChatId, setCurrentUserChatId],
        deleteConvo: deleteConvo,
        deleteChannel: deleteChannel,
        hideChannel: hideChannel,
        changeNickname: changeNickname,
        changeTheme: changeTheme,
        deleteUser: deleteUser,
        makeAdmin: makeAdmin,
        addUserToChat: addUserToChat,
        updateChannel: updateChannel,
      }}
    >
      {props.children}
    </ChatContext.Provider>
  );
};
