import React, { useEffect, useState } from "react";
import Head from "../../../layout/head/Head";
import Content from "../../../layout/content/Content";
import {
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  DropdownItem,
} from "reactstrap";

import {
  Block,
  BlockHead,
  BlockBetween,
  BlockHeadContent,
  BlockTitle,
  BlockDes,
  Icon,
  Button,
  UserAvatar,
  PaginationComponent,
  PreviewAltCard,
  DataTableHead,
  DataTableRow,
  DataTableItem,
  BlueText,
} from "../../../components/Component";

import { propertyList, removeProperty } from "./PropertyData";
import { findUpper } from "../../../utils/Utils";
import {
  AppText,
  CURRENCY_CODE,
  numberFormatter,
  propertyTypeToObject,
} from "../../../utils";
import PropertyAddModel from "./PropertyAdd";
import Swal from "sweetalert2";
import LoadingComponent from "../../../layout/spinner/LoadingSpinner";
import { useHistory } from "react-router";

export const PropertyListPage = () => {
  const [modal, setModal] = useState(false);

  const [formData, setFormData] = useState({
    id: 0,
    name: "",
    address: {},
    type: "",
    budget: "",
    year: "",
    size: "",
    stories: "",
    units: "",
    color: "",
    attachment: {},
  });
  const history = useHistory();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemPerPage] = useState(20);
  const [totalItems, setTotalItems] = useState(0);
  const [currentItems, setCurrentItems] = useState([]);
  const [isInitScreen, setIsInitScreen] = useState(true);
  const [selectedProperty, setSelectedProperty] = useState();

  useEffect(() => {
    if (currentPage !== 0) {
      loadList();
    }
  }, [currentPage]);

  useEffect(() => {
    setTotalItems(currentItems.length);
  }, [currentItems]);

  // Change Page
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  function loadList() {
    propertyList(currentPage, itemPerPage, (data, error) => {
      if (error === null) {
        setCurrentItems(data.list);
        if (currentPage === 1) {
          setTotalItems(data.pageInfo?.totalItems ?? 0);
        }
      }
      setIsInitScreen(false);
    });
  }
  // function to reset the form
  const resetForm = () => {
    setFormData({
      id: 0,
      name: "",
      address: {},
      type: "",
      budget: "",
      year: "",
      size: "",
      stories: "",
      units: "",
      color: "",
      attachment: {},
    });
  };

  const onRemoveClick = (item) => {
    Swal.fire({
      title: "Are you sure?",
      text: `You want to remove ${item.name} property?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, remove it!",
    }).then((result) => {
      if (result.isConfirmed) {
        removeProperty(item.id, (data, error) => {
          if (error === null) {
            setCurrentItems(
              currentItems.filter((value) => value.id !== item.id)
            );
          }
        });
      }
    });
  };

  function modelClose() {
    setModal(false);
    setSelectedProperty();
    resetForm();
  }

  return (
    <React.Fragment>
      <Head title="Properties"></Head>
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle page>Properties</BlockTitle>
              <BlockDes className="text-soft">
                {totalItems === 0
                  ? `You have no property added yet.`
                  : totalItems === 1
                  ? `You have one property.`
                  : `You have ${totalItems} properties.`}
              </BlockDes>
            </BlockHeadContent>
            <BlockHeadContent>
              <Button color="primary" onClick={() => setModal(true)}>
                <span>Add property</span>
              </Button>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>

        <Block>
          <div className="nk-tb-list nk-tb-ulist bg-white">
            <DataTableHead className="nk-tb-item nk-tb-head">
              <DataTableRow>
                <span className="sub-text"></span>
              </DataTableRow>
              <DataTableRow>
                <span className="sub-text text-secondary">
                  {AppText.proprtyName}
                </span>
              </DataTableRow>
              <DataTableRow>
                <span className="sub-text text-secondary">
                  {AppText.address}
                </span>
              </DataTableRow>
              <DataTableRow size="xxl">
                <span className="sub-text text-secondary">
                  {AppText.proprtyType}
                </span>
              </DataTableRow>
              <DataTableRow size="xxl">
                <span className="sub-text text-secondary text-end">
                  {AppText.annualBudget}
                </span>
              </DataTableRow>
              <DataTableRow size="sm">
                <span className="sub-text text-secondary text-end">
                  {AppText.yearConstructed}
                </span>
              </DataTableRow>
              <DataTableRow size="sm">
                <span className="sub-text text-secondary text-end">
                  {AppText.sizeSqFt}
                </span>
              </DataTableRow>
              <DataTableRow size="sm">
                <span className="sub-text text-secondary text-end">
                  {AppText.stories}
                </span>
              </DataTableRow>
              <DataTableRow size="sm">
                <span className="sub-text text-secondary text-end">
                  {AppText.units}
                </span>
              </DataTableRow>
              <DataTableRow className="nk-tb-col-tools text-end"></DataTableRow>
            </DataTableHead>
            {currentItems.length > 0
              ? currentItems.map((item) => {
                  return (
                    <DataTableItem
                      onClick={(ev) => {
                        ev.preventDefault();
                      }}
                      key={item.id}
                    >
                      <DataTableRow>
                        <UserAvatar
                          className="sq"
                          theme={"white"}
                          text={findUpper(item.name)}
                          image={item?.image?.imageUrl}
                        />
                      </DataTableRow>
                      <DataTableRow>
                        <BlueText
                          title={item.name}
                          onClick={(ev) => {
                            ev.preventDefault();
                            const name = item?.name
                              .trim()
                              .replace(/\s+/g, "-")
                              .toLowerCase();
                            history.push({
                              pathname: `${process.env.PUBLIC_URL}/property-detail/${item.id}-${name}`,
                            });
                          }}
                        />
                      </DataTableRow>
                      <DataTableRow className="text-secondary">
                        <span>{item.address?.fullAddress ?? ""}</span>
                      </DataTableRow>
                      <DataTableRow size="xxl" className="text-secondary">
                        <span>{propertyTypeToObject(item.type).label}</span>
                      </DataTableRow>
                      <DataTableRow
                        size="xxl"
                        className="text-secondary text-end"
                      >
                        <span>
                          {item.annualBudget === 0 || item.annualBudget === null
                            ? "N/A"
                            : numberFormatter(
                                CURRENCY_CODE,
                                "" + item.annualBudget
                              )}
                        </span>
                      </DataTableRow>
                      <DataTableRow
                        size="sm"
                        className="text-secondary text-end"
                      >
                        <span>
                          {item.yearConstructed === 0 ||
                          item.yearConstructed === null
                            ? "N/A"
                            : item.yearConstructed}
                        </span>
                      </DataTableRow>
                      <DataTableRow
                        size="sm"
                        className="text-secondary text-end"
                      >
                        {item.sizeSqFt === 0 || item.sizeSqFt === null
                          ? "N/A"
                          : numberFormatter("", "" + item.sizeSqFt)}

                        {item.sizeSqFt === 0 || item.sizeSqFt === null ? (
                          ""
                        ) : (
                          <span className="ms-1">
                            ft<sup> 2</sup>
                          </span>
                        )}
                      </DataTableRow>
                      <DataTableRow
                        size="sm"
                        className="text-secondary text-end"
                      >
                        <span>
                          {item.stories === 0 ||
                          item.stories === null ||
                          item.stories === ""
                            ? "N/A"
                            : item.stories}
                        </span>
                      </DataTableRow>
                      <DataTableRow
                        size="sm"
                        className="text-secondary text-end"
                      >
                        <span>
                          {item.units === 0 ||
                          item.units === null ||
                          item.units === ""
                            ? "N/A"
                            : item.units}
                        </span>
                      </DataTableRow>
                      <DataTableRow className="nk-tb-col-tools text-end">
                        <ul className="nk-tb-actions gx-1">
                          <li>
                            <UncontrolledDropdown>
                              <DropdownToggle
                                tag="a"
                                className="text-soft dropdown-toggle btn btn-icon btn-trigger"
                              >
                                <Icon name="more-h"></Icon>
                              </DropdownToggle>
                              <DropdownMenu end>
                                <ul className="link-list-opt no-bdr">
                                  <li
                                    onClick={() => {
                                      setSelectedProperty(item);
                                      setModal(true);
                                    }}
                                  >
                                    <DropdownItem
                                      tag="a"
                                      href="#markasdone"
                                      onClick={(ev) => {
                                        ev.preventDefault();
                                      }}
                                    >
                                      <Icon name="edit"></Icon>
                                      <span>Edit</span>
                                    </DropdownItem>
                                  </li>
                                  <li onClick={() => onRemoveClick(item)}>
                                    <DropdownItem
                                      tag="a"
                                      href="#markasdone"
                                      onClick={(ev) => {
                                        ev.preventDefault();
                                      }}
                                    >
                                      <Icon name="trash"></Icon>
                                      <span>Remove</span>
                                    </DropdownItem>
                                  </li>
                                </ul>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </li>
                        </ul>
                      </DataTableRow>
                    </DataTableItem>
                  );
                })
              : null}
          </div>
          <PreviewAltCard className={"border-top"}>
            {totalItems > 0 ? (
              <PaginationComponent
                itemPerPage={itemPerPage}
                totalItems={totalItems}
                paginate={paginate}
                currentPage={currentPage}
              />
            ) : (
              <div className="text-center">
                <span className="text-silent">
                  No property added yet. Let's add your first one!
                </span>
              </div>
            )}
          </PreviewAltCard>
        </Block>
        <PropertyAddModel
          isOpen={modal}
          property={selectedProperty}
          onCancel={() => {
            modelClose();
          }}
          onSave={() => {
            modelClose();
            setCurrentPage(0);
            setCurrentPage(1);
          }}
        />
      </Content>
      <LoadingComponent isLoading={isInitScreen} />
    </React.Fragment>
  );
};

export default PropertyListPage;
