import React, { useEffect, useRef, useState } from "react";
import {
  Block,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Button,
  Col,
  ConfirmationAlert,
  Icon,
  Row,
  SuccessAlert,
  Toolbar,
} from "../../../../components/Component";
import Content from "../../../../layout/content/Content";
import Head from "../../../../layout/head/Head";
import LoadingComponent from "../../../../layout/spinner/LoadingSpinner";
import { Storage } from "../../../../services/storage/storage";
import {
  Alert,
  Card,
  CardBody,
  CardText,
  Spinner,
  UncontrolledAlert,
} from "reactstrap";
import {
  CURRENCY_CODE,
  DATE_FORMAT,
  numberFormatterWithDecimal,
  propertiesTypes,
  showErrorToast,
  showSuccessToast,
} from "../../../../utils";
import { fetchUserInfo } from "../../../auth/AuthData";
import { useHistory } from "react-router";
import moment from "moment/moment";
import ReactPaymentHistory from "../History/ReactPaymentHistory";
import {
  cancelAutoPaymentSettings,
  fetchInvitation,
  getContractDetail,
  invitationModerate,
  processTenantInvitationByToken,
} from "./TenantDashboardData";
import ChatService, {
  connectWithChat,
} from "../../../../services/chat/chatservice";

const HomeTenant = () => {
  const [isInitScreen, setIsInitScreen] = useState(true);
  const [pendingInvitations, setPendingInvitations] = useState([]);
  const [pendingPayments, setPendingPayments] = useState();

  const [itemPerPage, setItemPerPage] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  const [onSearchText, setSearchText] = useState("");
  const [onSearch, setonSearch] = useState(true);

  const [sort, setSortState] = useState("desc");
  const [isFilterApply, setIsFilterApply] = useState(false);
  const [selectedFilterData, setSelectedFilterData] = useState({});
  const dropdownPages = [5, 10, 15, 20];
  const searchInput = useRef(null);
  const textSearch = () => setonSearch(!onSearch);

  const [autoPayment, setAutoPayment] = useState();
  const [tenant, setTenant] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    checkAnyInLocalInvitation();
  }, []);

  function checkAnyInLocalInvitation() {
    let object = Storage.getEmailActions();
    if (object?.action === "unit_tenant_invite") {
      acceptInvitationByToken(object);
    } else {
      loadInvitation();
    }
  }

  function resetFilter() {
    setSelectedFilterData({});
  }

  function loadInvitation() {
    fetchInvitation((data, error) => {
      if (error === null) {
        setPendingInvitations(data.list);
      }
      loadTenantPropertyDue();
      loadContractDetail();
    });
  }

  function acceptInvitationByToken(object) {
    processTenantInvitationByToken(object, (data, error) => {
      if (error === null) {
        Storage.removeEmailActions();
        showSuccessToast(
          "Congratulations, you are connected with your property manager."
        );
        loadProfileToUpdateProperty();
      }
    });
  }

  function connectToChat(user) {
    if (ChatService.instance.isConnected() === false) {
      connectWithChat((status, error) => {
        if (status) {
          buildChat(user);
        } else {
          window.location.reload();
          setIsInitScreen(false);
        }
      });
    } else {
      buildChat(user);
    }
  }

  function buildChat(user) {
    let selfId = user?.chatId;
    let managerId = user?.property?.pmChatId;
    if (selfId && managerId) {
      let ids = [selfId, managerId];
      ChatService.instance
        .createPersonalChat(ids, ids)
        .then((groupChannel) => {})
        .catch((error) => {
          console.log("error : ", error);
        })
        .finally(() => {
          window.location.reload();
          setIsInitScreen(false);
        });
    } else {
      window.location.reload();
      setIsInitScreen(false);
    }
  }

  function loadProfileToUpdateProperty() {
    setIsInitScreen(true);
    fetchUserInfo((user, error) => {
      connectToChat(user);
    });
  }

  function loadTenantPropertyDue() {
    fetchUserInfo((data, error) => {
      if (error === null) {
        setPendingPayments(data.property);
      }
      setIsInitScreen(false);
    });
  }

  function loadContractDetail() {
    setLoading(true);
    getContractDetail((data, error) => {
      if (error === null) {
        setAutoPayment(data.detail);
        setTenant(data?.tenant);
      }
      setLoading(false);
    });
  }

  function showWelcomeAlert(item) {
    SuccessAlert(
      `Welcome to ${item?.property?.name ?? ""}, Apt. ${
        item?.unit?.unitName ?? ""
      }!`,
      "If you accepted invitation accidentally, reach out to us via info@heyaltitude.com.",
      () => {
        loadProfileToUpdateProperty();
      }
    );
  }

  let today = moment(moment().format(DATE_FORMAT), DATE_FORMAT);
  let dueDays = tenant?.dueDate
    ? moment(tenant.dueDate).diff(today, "days")
    : null;
  return (
    <React.Fragment>
      <Head title="Dashboard"></Head>
      <Content>
        <BlockHead size="sm">
          <BlockHeadContent>
            <BlockTitle page>Dashboard</BlockTitle>
          </BlockHeadContent>
        </BlockHead>

        <Block>
          <div className="mb-4">
            {pendingInvitations.map((item, index) => {
              return (
                <React.Fragment key={index}>
                  <PendingInvitationView
                    item={item}
                    onConnected={(status) => {
                      if (status) {
                        showWelcomeAlert(item);
                      } else {
                        loadProfileToUpdateProperty();
                      }
                    }}
                  />
                </React.Fragment>
              );
            })}
          </div>
          <div>
            {dueDays !== null && dueDays >= 0 && dueDays <= 5 ? (
              <UncontrolledAlert
                className="alert-icon mb-4"
                color="danger"
                fade={false}
              >
                <Icon name="alert-circle" />
                <strong>
                  {dueDays === 0
                    ? `Your payment is due today`
                    : dueDays === 1
                    ? `Your payment is due tomorrow.`
                    : `Your payment is due in ${dueDays} days.`}
                </strong>
              </UncontrolledAlert>
            ) : null}
          </div>
          <Row className="g-gs">
            <Col xxl={"4"} md={"6"} className={pendingPayments ? "" : "me-5"}>
              <PendingPaymentView item={pendingPayments} tenant={tenant} />
            </Col>
            {pendingPayments && (
              <Col xxl="4" md="6">
                <AutoPaymentView
                  autoPayment={autoPayment}
                  tenant={tenant}
                  loading={loading}
                  onCancel={() => {
                    setAutoPayment();
                  }}
                />
              </Col>
            )}
            <Col xxl="8">
              <Card>
                <div className="card-inner">
                  <div className="card-title-group">
                    <div className="card-title">
                      <h6 className="title">Payments and charges</h6>
                    </div>
                    <div className="ms-auto ">
                      <Toolbar
                        tenantId={tenant?.id}
                        textSearch={textSearch}
                        isFilterApply={isFilterApply}
                        selectedFilterData={selectedFilterData}
                        onFilterApply={(data) => {
                          setSelectedFilterData(data);
                          if (isFilterApply === true) {
                            setCurrentPage(0);
                            setTimeout(() => {
                              setCurrentPage(1);
                            }, 200);
                          }
                          setIsFilterApply(true);
                        }}
                        onResetFilter={(e) => {
                          resetFilter();
                          setIsFilterApply(false);
                        }}
                        dropdownPages={dropdownPages}
                        setItemPerPage={setItemPerPage}
                        itemPerPage={itemPerPage}
                        currentPage={currentPage}
                        sort={sort}
                        setSortState={setSortState}
                      />
                    </div>
                    <div
                      className={`card-search search-wrap ${
                        !onSearch && "active"
                      }`}
                    >
                      <div className="card-body">
                        <div className="search-content">
                          <Button
                            className="search-back btn-icon toggle-search active"
                            onClick={() => {
                              setSearchText("");
                              textSearch();
                            }}
                          >
                            <Icon name="arrow-left"></Icon>
                          </Button>
                          <input
                            type="text"
                            className="border-transparent form-focus-none form-control"
                            placeholder="Search by user or email"
                            value={onSearchText}
                            ref={searchInput}
                            onChange={(e) => setSearchText(e.target.value)}
                          />
                          <Button className="search-submit btn-icon">
                            <Icon name="search"></Icon>
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <ReactPaymentHistory
                  itemPerPage={itemPerPage}
                  selectedFilterData={selectedFilterData}
                />
              </Card>
            </Col>
          </Row>
        </Block>
      </Content>
      <LoadingComponent isLoading={isInitScreen} />
    </React.Fragment>
  );
};
export default HomeTenant;

const PendingInvitationView = ({ item, onConnected }) => {
  const [loading, setIsLoading] = useState(false);

  function inviteAction(isAccept) {
    setIsLoading(true);
    let params = {
      id: item.id,
      status: isAccept ? "APPROVED" : "REJECTED",
    };
    invitationModerate(params, (data, error) => {
      if (error === null && data.ok) {
        onConnected(isAccept);
      } else {
        showErrorToast(error.message);
      }
      setIsLoading(false);
    });
  }

  return (
    <Card className="card-bordered mb-2">
      <CardBody className="card-inner d-flex justify-between">
        <CardText className="text m-0">
          {item?.pm?.companyName ?? ""} invited you to{" "}
          {item?.property?.name ?? ""}, Apt. {item?.unit?.unitName ?? ""}
        </CardText>
        {loading ? (
          <Spinner size="sm" color="dark" />
        ) : (
          <ul className="align-center flex-wrap flex-sm-nowrap gx-3 gy-2">
            <>
              <li>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a
                  href={undefined}
                  onClick={(ev) => {
                    ev.preventDefault();
                    inviteAction(true);
                  }}
                  className="link cursor"
                >
                  Accept
                </a>
              </li>
              <li>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a
                  href={undefined}
                  onClick={(ev) => {
                    ev.preventDefault();
                    inviteAction(false);
                  }}
                  className="link cursor text-danger"
                  color="dangar"
                >
                  Decline
                </a>
              </li>
            </>
          </ul>
        )}
      </CardBody>
    </Card>
  );
};

const PendingPaymentView = ({ item, tenant }) => {
  let history = useHistory();
  function showRentPayment() {
    history.push({
      pathname: `${process.env.PUBLIC_URL}/rent-payment`,
      state: { duePayment: item },
    });
  }

  let totalRemain = (item?.totalDue ?? 0) - (item?.underProcessingAmount ?? 0);
  let dueAmount = item
    ? numberFormatterWithDecimal(CURRENCY_CODE, "" + totalRemain)
    : "$0.00";

  return (
    <Card className="is-dark h-100">
      <div className="nk-ecwg nk-ecwg1">
        <div className="card-inner">
          <div className="card-title-group">
            <div className="card-title">
              <h6 className="title">Current balance</h6>
            </div>
            {totalRemain > 0 && (
              <div className="card-tools">
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a
                  href={undefined}
                  onClick={(ev) => {
                    ev.preventDefault();
                    showRentPayment();
                  }}
                  className="link cursor white-text-button border my-n1 p-1 round"
                >
                  Pay now
                </a>
              </div>
            )}
          </div>
          <div className={`card-title-group ${tenant?.dueDate ? "mb-n4" : ""}`}>
            <div className="data">
              <div className="amount">{dueAmount}</div>
              <div className="info">
                <strong>As of</strong> {`${moment().format(DATE_FORMAT)}`}
              </div>
              {item?.underProcessingAmount > 0 && (
                <div className="info">
                  <strong>{`${numberFormatterWithDecimal(
                    CURRENCY_CODE,
                    "" + (item?.underProcessingAmount ?? 0)
                  )}`}</strong>{" "}
                  is under processing
                </div>
              )}
            </div>
            {tenant?.dueDate && (
              <div className="mt-n5 text-end text-white">
                <strong>{"Due date: "}</strong>
                <label>{moment(tenant.dueDate).format(DATE_FORMAT)}</label>
              </div>
            )}
          </div>
        </div>
      </div>
    </Card>
  );
};

const AutoPaymentView = ({ autoPayment, tenant, loading, onCancel }) => {
  let history = useHistory();

  function autoRentPayment() {
    history.push({
      pathname: `${process.env.PUBLIC_URL}/auto-rent-payment`,
      state: { detail: autoPayment, pmDetail: tenant?.pmDetail },
    });
  }

  function cancelAutoPayment() {
    ConfirmationAlert(
      "Cancel auto payment!",
      "Do you want to cancel your auto payment?",
      () => {
        cancelAutoPaymentSettings((data, error) => {
          if (error === null) {
            onCancel();
            showSuccessToast(
              "Your auto payment has been cancelled successfully."
            );
          } else {
            showErrorToast(error.message);
          }
        });
      }
    );
  }

  return (
    <Card className="bg-white h-100">
      <div className="nk-ecwg nk-ecwg1">
        <div className="card-inner">
          {loading ? (
            <div className="justify-center align-item-center">
              <Spinner size="sm" color="dark" />
            </div>
          ) : autoPayment ? (
            <>
              <div className="card-title-group">
                <div className="card-title">
                  <h6 className="title">Auto payment</h6>
                </div>
                <div className="card-tools">
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <a
                    href={undefined}
                    onClick={(ev) => {
                      ev.preventDefault();
                      cancelAutoPayment();
                    }}
                    className="link cursor text-gray"
                  >
                    Cancel
                  </a>
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <a
                    href={undefined}
                    onClick={(ev) => {
                      ev.preventDefault();
                      autoRentPayment();
                    }}
                    className="link cursor ms-2"
                  >
                    Edit
                  </a>
                </div>
              </div>
              <div className="data">
                <div className="amount">
                  {numberFormatterWithDecimal(
                    CURRENCY_CODE,
                    autoPayment.amount
                  )}
                </div>
                <div className="info">
                  {`${moment(autoPayment.startDate).format(
                    DATE_FORMAT
                  )} - ${moment(autoPayment.endDate).format(
                    DATE_FORMAT
                  )} • Pay on ${autoPayment.selectedDate.label}`}
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="card-title-group">
                <div className="card-title">
                  <h6 className="title">Missing a payment can be expensive</h6>
                </div>
              </div>
              <div className="data"></div>
              <Button
                outline
                color="dark"
                className="btn-dim"
                onClick={() => autoRentPayment()}
              >
                Set up auto-pay
                <Icon name="chevron-right" />
              </Button>
            </>
          )}
        </div>
      </div>
    </Card>
  );
};
