import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { Redirect } from "react-router-dom";
import { Storage } from "../../services/storage/storage";
import { AppText, showErrorToast, showInfoToast } from "../../utils";
import AccountType from "./AccountType";
import { UserAccountType } from "../../utils/Utils";

const EmailRequestProcess = (props) => {
  const [loading, setLoading] = useState();
  const history = useHistory();
  const [errorVal, setError] = useState("");
  const [isShowSuccessMessage, setIsShowSuccessMessage] = useState(false);

  useEffect(() => {
    let list = props.location.search.split("?");
    if (list.length === 2) {
      let values = list[1].split("&");
      let object = {};
      for (let index = 0; index < values.length ?? 0; index++) {
        let items = values[index].split("=");
        if (items.length === 2) {
          switch (items[0]) {
            case "email":
              object.email = items[1];
              break;
            case "action":
              object.action = items[1];
              break;
            case "tkn":
              object.token = items[1];
              break;
            default:
              break;
          }
        }
      }
      performOperations(object);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function performOperations(object) {
    if (object.action) {
      // Save Informationation
      let auth = Storage.getUserToken();
      if (auth) {
        let userInfo = Storage.getLoggedInUser();
        if (userInfo?.user?.email === object.email) {
          switch (object.action) {
            case "unit_tenant_invite":
              acceptTenantInvitation();
              break;
            default:
              break;
          }
        } else {
          history.push(`${process.env.PUBLIC_URL}/`);
        }
      } else {
        Storage.setEmailActions(object);
        history.push({
          pathname: `${process.env.PUBLIC_URL}/auth-register`,
          state: { type: UserAccountType.tenant },
        });
      }
    } else {
      <Redirect to={`${process.env.PUBLIC_URL}/`}></Redirect>;
    }
  }

  function acceptTenantInvitation() {
    history.push(`${process.env.PUBLIC_URL}/`);
  }

  return <></>;
};

export default EmailRequestProcess;
