import React from "react";
import { Route, Redirect } from "react-router-dom";
import { apiService } from "../services/api";
import { connectWithChat } from "../services/chat/chatservice";

import { Storage } from "../services/storage/storage";
import { UserAccountType } from "../utils/Utils";
const auth = Storage.getUserToken();
const authInfo = Storage.getLoggedInUser();
const isSignedIn = Storage.isSignedIn();

apiService.setUserToken(auth);
connectWithChat(() => {})
const PrivateRoute = ({ exact, component: Component, ...rest }) => (
  <Route
    exact={exact ? true : false}
    rest
    render={(props) =>
      isSignedIn && auth && authInfo.user?.isEmailVerified === true ? authInfo.user?.accountType === UserAccountType.vendor && authInfo.user?.isVendorProfileSetup === false ? (
        <Redirect to={`${process.env.PUBLIC_URL}/auth-login`}></Redirect>
      ) : (
        <Component {...props} {...rest}></Component>
      ) : (
        <Redirect to={`${process.env.PUBLIC_URL}/auth-login`}></Redirect>
      )
    }
  ></Route>
);

export default PrivateRoute;
