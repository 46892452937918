import React, { useState } from "react";
import {
  Block,
  BlockContent,
  BlockHead,
  BlockTitle,
  Button,
  Icon,
  PreviewCard,
} from "../../components/Component";
import Logo from "../../images/logo.svg";
import LogoDark from "../../images/logo-dark.svg";
import { Form, Spinner, Alert } from "reactstrap";
import PageContainer from "../../layout/page-container/PageContainer";
import Head from "../../layout/head/Head";
import AuthFooter from "./AuthFooter";
import { useForm } from "react-hook-form";
import { Link, useHistory } from "react-router-dom";
import { AppText } from "../../utils";
import { signin } from "./AuthData";
import { UserAccountType } from "../../utils/Utils";
import { Storage } from "../../services/storage/storage";
import { ConfirmationType } from "./Confirmation";

const Login = () => {
  const [loading, setLoading] = useState(false);
  const [passState, setPassState] = useState(false);
  const [errorVal, setError] = useState("");
  const [isStaySignedIn, setIsStaySignedIn] = useState(false);
  const history = useHistory();

  const onFormSubmit = (formData) => {

    setLoading(true);
    signin(formData, (data, error) => {
      setLoading(false);
      if (error === null) {
        if (data.user.isEmailVerified === false) {
          history.push({
            pathname: `${process.env.PUBLIC_URL}/confirmation`,
            state: {
              type: ConfirmationType.email,
              email: formData.email,
              accountType: data.type,
            },
          });
        } else if (
          data.user.isVendorProfileSetup === false &&
          data.user.accountType === UserAccountType.vendor
        ) {
          history.push(`${process.env.PUBLIC_URL}/vendor-setup`);
        } else {
          Storage.setLastSignedInTime(Date.now());
          Storage.setIsStaySignedIn(isStaySignedIn);
          checkAndLoad();
        }
      } else {
        setError(error.message);
      }
    });
  };

  function checkAndLoad() {
    window.history.pushState(
      `${process.env.PUBLIC_URL ? process.env.PUBLIC_URL : "/"}`,
      "auth-login",
      `${process.env.PUBLIC_URL ? process.env.PUBLIC_URL : "/"}`
    );
    window.location.reload();
  }

  const { errors, register, handleSubmit } = useForm();

  return (
    <React.Fragment>
      <Head title="Sign in" />
      <PageContainer>
        <Block className="nk-block-middle nk-auth-body wide-xs">
          <div className="brand-logo pb-4 text-left mx-4">
            <Link to={process.env.PUBLIC_URL + "/"} className="logo-link">
              <img
                className="logo-light logo-img logo-img-lg"
                src={Logo}
                alt="logo"
              />
              <img
                className="logo-dark logo-img logo-img-lg"
                src={LogoDark}
                alt="logo-dark"
              />
            </Link>
          </div>

          <PreviewCard className="card-bordered" bodyClass="card-inner-lg">
            <BlockHead>
              <BlockContent>
                <BlockTitle tag="h4">Sign in to your account</BlockTitle>
                {/* <BlockDes>
                  <p>Access Dashlite using your email and passcode.</p>
                </BlockDes> */}
              </BlockContent>
            </BlockHead>
            {errorVal && (
              <div className="mb-3">
                <Alert color="danger" className="alert-icon">
                  {" "}
                  <Icon name="alert-circle" /> {errorVal}{" "}
                </Alert>
              </div>
            )}
            <Form className="is-alter" onSubmit={handleSubmit(onFormSubmit)}>
              <div className="form-group">
                <div className="form-label-group">
                  <label className="form-label" htmlFor="default-01">
                    {AppText.email}
                  </label>
                </div>
                <div className="form-control-wrap">
                  <input
                    type="text"
                    id="default-01"
                    name="email"
                    ref={register({ required: "This field is required" })}
                    className="form-control-lg form-control"
                  />
                  {errors.email && (
                    <span className="invalid">{errors.email.message}</span>
                  )}
                </div>
              </div>
              <div className="form-group">
                <div className="form-label-group">
                  <label className="form-label" htmlFor="password">
                    {AppText.password}
                  </label>
                  <Link
                    className="form-note-s2"
                    to={`${process.env.PUBLIC_URL}/auth-reset`}
                  >
                    {AppText.forgotPassword}
                  </Link>
                </div>
                <div className="form-control-wrap">
                  <a
                    href="#password"
                    onClick={(ev) => {
                      ev.preventDefault();
                      setPassState(!passState);
                    }}
                    className={`form-icon lg form-icon-right passcode-switch ${
                      passState ? "is-hidden" : "is-shown"
                    }`}
                  >
                    <Icon name="eye" className="passcode-icon icon-hide"></Icon>
                    <Icon
                      name="eye-off"
                      className="passcode-icon icon-show"
                    ></Icon>
                  </a>
                  <input
                    type={passState ? "text" : "password"}
                    id="password"
                    name="password"
                    ref={register({ required: "This field is required" })}
                    className={`form-control-lg form-control ${
                      passState ? "is-hidden" : "is-shown"
                    }`}
                  />
                  {errors.password && (
                    <span className="invalid">{errors.password.message}</span>
                  )}
                </div>
              </div>
              <div className="form-group">
                <div className="g-3 align-center flex-wrap">
                <div className="g">
                <div className="custom-control custom-control-sm custom-checkbox">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id={"stay-signed-in"}
                    name={"stay-signed-in"}
                    defaultChecked={isStaySignedIn}
                    onChange={(e) => setIsStaySignedIn(!isStaySignedIn)}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor={"stay-signed-in"}
                  >
                    <p className="fs-14px">{" Stay signed in for a week"}</p>
                  </label>
                  </div>
                  </div>
                </div>
              </div>
              <div className="form-group">
                <Button
                  size="lg"
                  className="btn-block"
                  type="submit"
                  color="primary"
                  disabled={loading}
                >
                  {loading ? <Spinner size="sm" color="light" /> : "Continue"}
                </Button>
              </div>
            </Form>
            <div className="form-note-s2 text-center pt-4">
              {" "}
              Don't have an account?{" "}
              <Link to={`${process.env.PUBLIC_URL}/account-type`}>Sign up</Link>
            </div>
            {/* <div className="text-center pt-4 pb-3">
              <h6 className="overline-title overline-title-sap">
                <span>OR</span>
              </h6>
            </div>
            <ul className="nav justify-center gx-4">
              <li className="nav-item">
                <a
                  className="nav-link"
                  href="#socials"
                  onClick={(ev) => {
                    ev.preventDefault();
                  }}
                >
                  Facebook
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  href="#socials"
                  onClick={(ev) => {
                    ev.preventDefault();
                  }}
                >
                  Google
                </a>
              </li>
            </ul> */}
          </PreviewCard>
        </Block>
        <div className="gap-100px" />
        <AuthFooter />
      </PageContainer>
    </React.Fragment>
  );
};
export default Login;
