import React, { useState, useEffect } from "react";
import Content from "../../../content/Content";
import UserProfileRegularPage from "./UserProfileRegular";
import UserProfileSettingPage from "./UserProfileSetting";
import UserProfileNotificationPage from "./UserProfileNotification";
import { Route, Switch, Link, useHistory } from "react-router-dom";
import { Icon, UserAvatar } from "../../../../components/Component";
import { findUpper, UserAccountType } from "../../../../utils/Utils";
import {
  Card,
  DropdownItem,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
  Spinner,
} from "reactstrap";
import { fetchUserInfo, setImage } from "../../../../pages/auth/AuthData";
import UserProfilePaymentPage from "./UserProfilePayment";
import { ImageCropper } from "../../../../components/cropper/cropper";
import UserInvoiceSettings from "./UserInvoiceSettings";

const UserProfileLayout = () => {
  const [sm, updateSm] = useState(false);
  const [mobileView, setMobileView] = useState(false);
  const [profileName, setProfileName] = useState("Abu Bin Ishtiak");
  const [user, setUser] = useState();
  const [file, setFile] = useState(null);
  const [isImageCropperVisible, setIsImageCropperVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [modal, setModal] = useState(false);
  const history = useHistory();

  function fetchUser(callback) {
    fetchUserInfo((data, error) => {
      if (data) {
        setUser(data);
        callback();
      } else {
        console.log("Error : ", error);
      }
    });
  }

  function uploadImage(file) {
    setIsLoading(true);
    setImage(file, (data, error) => {
      if (data) {
        fetchUser(() => {
          window.location.reload();
        });
      } else {
        console.log("Error : ", error);
      }
      setIsLoading(false);
    });
  }

  // function to change the design view under 990 px
  const viewChange = () => {
    if (window.innerWidth < 990) {
      setMobileView(true);
    } else {
      setMobileView(false);
      updateSm(false);
    }
  };

  useEffect(() => {
    fetchUser(() => {});
  }, []);

  useEffect(() => {
    viewChange();
    window.addEventListener("load", viewChange);
    window.addEventListener("resize", viewChange);
    document
      .getElementsByClassName("nk-header")[0]
      .addEventListener("click", function () {
        updateSm(false);
      });
    return () => {
      window.removeEventListener("resize", viewChange);
      window.removeEventListener("load", viewChange);
    };
  }, []);

  return (
    <React.Fragment>
      <Content>
        <Card className="card-bordered">
          <div className="card-aside-wrap">
            <div
              className={`card-aside card-aside-left user-aside toggle-slide toggle-slide-left toggle-break-lg ${
                sm ? "content-active" : ""
              }`}
            >
              <div className="card-inner-group">
                <div className="card-inner">
                  <div className="user-card">
                    <UserAvatar
                      text={findUpper(
                        (user?.firstName ?? "") + " " + (user?.lastName ?? "")
                      )}
                      theme="white"
                      image={user?.avatar?.thumbUrl}
                    />
                    <div className="user-info">
                      <span className="lead-text">
                        {(user?.firstName ?? "") + " " + (user?.lastName ?? "")}
                      </span>
                      <span className="sub-text">{user?.email}</span>
                    </div>
                    <div className="user-action">
                      {isLoading ? (
                        <Spinner size="sm" color="dark" />
                      ) : (
                        <UncontrolledDropdown>
                          <DropdownToggle
                            tag="a"
                            className="btn btn-icon btn-trigger me-n2"
                          >
                            <Icon name="more-v"></Icon>
                          </DropdownToggle>
                          <DropdownMenu end>
                            <ul className="link-list-opt no-bdr">
                              <li>
                                <DropdownItem
                                  tag="a"
                                  href="#dropdownitem"
                                  onClick={(ev) => {
                                    ev.preventDefault();
                                    const input =
                                      document.createElement("input");
                                    input.type = "file";
                                    input.accept = "image/*";
                                    input.onchange = (event) => {
                                      const file = event.target.files[0];
                                      setFile(file);
                                      setIsImageCropperVisible(true);
                                    };
                                    input.click();
                                  }}
                                >
                                  <Icon name="camera-fill"></Icon>
                                  <span>Change photo</span>
                                </DropdownItem>
                              </li>
                              <li>
                                <DropdownItem
                                  tag="a"
                                  href="#dropdownitem"
                                  onClick={(ev) => {
                                    ev.preventDefault();
                                    if (
                                      window.location.pathname !==
                                      `${process.env.PUBLIC_URL}/user-profile`
                                    ) {
                                      history.push(
                                        `${process.env.PUBLIC_URL}/user-profile`
                                      );
                                    }
                                    setTimeout(() => {
                                      setModal(true);
                                    }, 1000);
                                  }}
                                >
                                  <Icon name="edit-fill"></Icon>
                                  <span>Update profile</span>
                                </DropdownItem>
                              </li>
                            </ul>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      )}
                    </div>
                  </div>
                </div>
                <div className="card-inner p-0">
                  <ul className="link-list-menu">
                    <li onClick={() => updateSm(false)}>
                      <Link
                        to={`${process.env.PUBLIC_URL}/user-profile`}
                        className={
                          window.location.pathname ===
                          `${process.env.PUBLIC_URL}/user-profile`
                            ? "active"
                            : ""
                        }
                      >
                        <Icon name="user-fill-c"></Icon>
                        <span>Account information</span>
                      </Link>
                    </li>
                    <li onClick={() => updateSm(false)}>
                      <Link
                        to={`${process.env.PUBLIC_URL}/user-profile-payment`}
                        className={
                          window.location.pathname ===
                          `${process.env.PUBLIC_URL}/user-profile-payment`
                            ? "active"
                            : ""
                        }
                      >
                        <Icon name="wallet-fill"></Icon>
                        <span>Payment</span>
                      </Link>
                    </li>
                    <li onClick={() => updateSm(false)}>
                      <Link
                        to={`${process.env.PUBLIC_URL}/user-profile-notification`}
                        className={
                          window.location.pathname ===
                          `${process.env.PUBLIC_URL}/user-profile-notification`
                            ? "active"
                            : ""
                        }
                      >
                        <Icon name="bell-fill"></Icon>
                        <span>Notifications</span>
                      </Link>
                    </li>
                    <li onClick={() => updateSm(false)}>
                      <Link
                        to={`${process.env.PUBLIC_URL}/user-profile-setting`}
                        className={
                          window.location.pathname ===
                          `${process.env.PUBLIC_URL}/user-profile-setting`
                            ? "active"
                            : ""
                        }
                      >
                        <Icon name="lock-alt-fill"></Icon>
                        <span>Security settings</span>
                      </Link>
                    </li>
                    {user?.accountType === UserAccountType.manager && (
                      <li onClick={() => updateSm(false)}>
                        <Link
                          to={`${process.env.PUBLIC_URL}/user-invoice-setting`}
                          className={
                            window.location.pathname ===
                            `${process.env.PUBLIC_URL}/user-invoice-setting`
                              ? "active"
                              : ""
                          }
                        >
                          <Icon name="bag-fill"></Icon>
                          <span>General invoice settings</span>
                        </Link>
                      </li>
                    )}
                  </ul>
                </div>
              </div>
            </div>
            <div className="card-inner card-inner-lg">
              {sm && mobileView && (
                <div
                  className="toggle-overlay"
                  onClick={() => updateSm(!sm)}
                ></div>
              )}
              <Switch>
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/user-profile`}
                  render={() => (
                    <UserProfileRegularPage
                      updateSm={updateSm}
                      sm={sm}
                      setProfileName={setProfileName}
                      modal={modal}
                      setModal={setModal}
                    />
                  )}
                ></Route>
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/user-profile-notification`}
                  render={() => (
                    <UserProfileNotificationPage updateSm={updateSm} sm={sm} />
                  )}
                ></Route>
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/user-profile-payment`}
                  render={() => (
                    <UserProfilePaymentPage updateSm={updateSm} sm={sm} />
                  )}
                ></Route>
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/user-profile-setting`}
                  render={() => (
                    <UserProfileSettingPage updateSm={updateSm} sm={sm} />
                  )}
                ></Route>
                {user?.accountType === UserAccountType.manager && (
                  <Route
                    exact
                    path={`${process.env.PUBLIC_URL}/user-invoice-setting`}
                    render={() => (
                      <UserInvoiceSettings updateSm={updateSm} sm={sm} />
                    )}
                  ></Route>
                )}
              </Switch>
            </div>
          </div>
        </Card>
      </Content>
      <ImageCropper
        visible={isImageCropperVisible}
        file={file}
        onCancel={() => {
          setFile();
          setIsImageCropperVisible(false);
        }}
        onCrop={(file) => {
          if (file !== null && file !== undefined) {
            uploadImage(file);
          }
        }}
      />
    </React.Fragment>
  );
};

export default UserProfileLayout;
