"use client";

import React, { Suspense, lazy } from "react";
import ReactDOM from "react-dom";
import "./assets/scss/dashlite.scss";
import "./assets/scss/style-email.scss";
import { BrowserRouter as Router, Route } from "react-router-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ToastContainer } from "react-toastify";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { ErrorBoundary } from "react-error-boundary";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
import store from "./services/store/store";

const promise = loadStripe(process.env.REACT_APP_STRIPE_KEY);
const Error404Modern = lazy(() => import("./pages/error/404-modern"));
const Error504Modern = lazy(() => import("./pages/error/504-modern"));
const persistor = persistStore(store);

ReactDOM.render(
  <React.Fragment>
    <Provider store={store}>
    <ErrorBoundary fallback={<Error504Modern />}>
      <Suspense fallback={<div />}>
        <Router basename={`/`}>
          <Route
            render={({ location }) =>
              location.state && location.state.is404 ? (
                <Error404Modern />
              ) : (
                <PersistGate persistor={persistor}>
                  <Elements stripe={promise}>
                    <App />
                  </Elements>
                  <ToastContainer />
                </PersistGate>
              )
            }
          />
        </Router>
      </Suspense>
    </ErrorBoundary>
    </Provider>
  </React.Fragment>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
