import React from "react";
import { Icon } from "../../../components/Component";
const Timeline = ({data}) => {
  let year = data?.length > 0 ? data[0].year : ""
  return (
    <React.Fragment>
      <div className="card-inner">
        <div className="timeline">
          <h6 className="timeline-head">{year}</h6>
          <ul className="timeline-list">
            {data.map((item, index) => {
              return (
                <React.Fragment key={index}>
                <li className="timeline-item">
                  <div className={`timeline-status ${item.fill} ${item.outline ? "is-outline" : " "}`}></div>
                  <div className="timeline-date">
                    {item.date} <Icon name="alarm-alt"></Icon>
                  </div>
                  <div className="timeline-data">
                    <h6 className="timeline-title">{item.text}</h6>
                    <div className="timeline-des">
                      <p>{item.subtitle}</p>
                      <span className="time">{item.time}</span>
                    </div>
                  </div>
                </li>
                </React.Fragment>
              );
            })}
          </ul>
        </div>
      </div>
    </React.Fragment>
  );
};
export default Timeline;