import React, { useState, useEffect } from "react";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";

import {
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Button,
  Col,
  Row,
  Block,
} from "../../../components/Component";
import { Badge, Card } from "reactstrap";
import { useHistory } from "react-router-dom";
import {
  getServiceOfferFlagsAsText,
  getSocialFlagsAsText,
  getVendorDetail,
} from "./VendorData";
import {
  COUNTRY_CODE,
  DATE_FORMAT,
  formatPhoneNumber,
  newlineText,
  openWebsite,
  propertyTypesToStringWithComma,
  showErrorToast,
} from "../../../utils";
import { getServices, preferredVendor } from "../../auth/AuthData";
import moment from "moment";
import { ChatFloatingUI } from "../chat/ChatFloatingUI";
import { Storage } from "../../../services/storage/storage";
import LoadingComponent from "../../../layout/spinner/LoadingSpinner";
import { ChatType } from "../../../services/chat/chatservice";

const VendorDetails = ({ match }) => {
  const history = useHistory();
  const [sliderData, setSliderData] = useState([]);
  const [vendor, setVendor] = useState();
  const [services, setServices] = useState([]);
  const [selectedServices, setSelectedServices] = useState([]);
  const [chatId, setChatId] = useState();
  const [user] = useState(Storage.getLoggedInUser()?.user);
  const [isInitScreen, setIsInitScreen] = useState(true);

  // grabs the id of the url and loads the corresponding data
  useEffect(() => {
    const idStr = match.params.id;
    if (idStr !== undefined || null || "") {
      let list = idStr.split("-");
      const id = list.length > 0 ? list[0] : ""
      fetchVendor(id);
    } else {
      history.goBack();
    }
  }, [match.params.id]); // eslint-disable-line react-hooks/exhaustive-deps

  function addRequest() {
    let vendorList = [{ id: vendor.id, name: vendor.companyName }];
    history.push({
      pathname: `${process.env.PUBLIC_URL}/new-request`,
      state: { services: selectedServices, vendors: vendorList },
    });
  }

  function updateVendorPreference() {
    preferredVendor({ vendorId: parseInt(vendor?.id) }, (data, error) => {
      if (error === null) {
        setVendor(prev => ({
          ...prev,
          isPreferred: data.action === "added",
        }))
      } else {
        showErrorToast(error.message);
      }
    });
  }

  function fetchVendor(id) {
    getVendorDetail({ id: parseInt(id) }, (object, error) => {
      if (error === null) {
        setVendor(object.data);
        setSliderData(object.data);
        fetchServices(id);
      } else {
        setIsInitScreen(false)
        history.goBack();
      }
    });
  }

  function fetchServices(id) {
    getServices({ vendorId: parseInt(id) }, (object, error) => {
      if (error === null) {
        setServices(object);
      }
      setIsInitScreen(false)
    });
  }

  return (
    <React.Fragment>
      <Head title={vendor?.companyName ?? "Loading"}></Head>
      {sliderData && vendor && (
        <Content>
          <BlockHead size="sm">
            <BlockBetween className="g-3">
              <BlockHeadContent>
                <BlockTitle>Vendor details</BlockTitle>
                <BlockDes>On {process.env.REACT_APP_APP_NAME} since {moment(vendor.createdAt).format(DATE_FORMAT)}.</BlockDes>
              </BlockHeadContent>
            </BlockBetween>
          </BlockHead>

          <Block>
            <Card>
              <div className="card-inner">
                <Row className="pb-5">
                  <Col lg={6}>
                    <div className="product-gallery p-4 me-xl-1  me-xxl-5">
                      <div
                        className="slider-item rounded d-flex align-items-center justify-content-center"
                        key={vendor.id}
                      >
                        <img
                          src={vendor?.image?.imageUrl}
                          className=""
                          alt=""
                        />
                      </div>
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div className="product-info mt-5 me-xxl-5">
                      <div className="mb-1">
                        {vendor?.isPreferred &&
                        <Badge className="badge-dim me-2" color="primary">
                          Preferred vendor
                        </Badge>}
                        {/* <Badge className="badge-dim" color="success">
                          Verified
                        </Badge> */}
                      </div>
                      <h2 className="product-title">{vendor.companyName}</h2>
                      <div className="product-excrept text-soft">
                        <p className="lead">
                          {newlineText(vendor.description)}
                        </p>
                      </div>
                      <div className="product-meta">
                        <ul className="d-flex g-3 gx-5">
                          <li>
                            <div className=" fs-14px text-muted">
                              Business size
                            </div>
                            <div className="fs-14px fw-bold text-secondary">
                              {vendor.businessSize ?? "N/A"}
                            </div>
                          </li>
                          <li>
                            <div className="fs-14px text-muted">Phone</div>
                            <div className="fs-14px fw-bold text-secondary">
                              {formatPhoneNumber(COUNTRY_CODE + " ", vendor.phone) ?? "N/A"}
                            </div>
                          </li>
                          <li>
                            <div className="fs-14px text-muted">Website</div>
                            <div className="fs-14px fw-bold text-secondary">
                              {(vendor?.website?.length ?? 0) > 0 ? (
                                 <a href={undefined} className="text-blue cursor" onClick={(ev) => openWebsite(vendor.website, ev)}>{vendor.website}</a>
                              ) : (
                                <>{"N/A"}</>
                              )}
                            </div>
                          </li>
                        </ul>
                      </div>
                      <div className="product-meta">
                        <ul className="d-flex g-3 gx-5">
                          <li>
                            <div className="fs-14px text-muted">
                              Serving within miles
                            </div>
                            <div className="fs-14px fw-bold text-secondary">
                              {vendor.serviceRadius}
                            </div>
                          </li>
                          <li>
                            <div className="fs-14px text-muted">Address</div>
                            <div className="fs-14px fw-bold text-secondary">
                              {vendor?.address?.fullAddress ?? ""}
                            </div>
                          </li>
                        </ul>
                      </div>
                      <div className="product-meta">
                        <ul className="d-flex g-3 gx-5">
                          <li>
                            <div className=" fs-14px text-muted">
                              Property types serviced
                            </div>
                            <div className="fs-14px fw-bold text-secondary">
                              {propertyTypesToStringWithComma(
                                vendor.propertyTypes ?? []
                              )}
                            </div>
                          </li>
                        </ul>
                      </div>

                      <div className="product-meta">
                        <ul className="d-flex g-3 gx-5">
                          <li>
                            <div className=" fs-14px text-muted">
                              Associations
                            </div>
                            <div className="fs-14px fw-bold text-secondary">
                              {vendor.associations.length > 0 ? vendor.associations.map(item => item.name).join(", ") : "N/A"}
                            </div>
                          </li>
                        </ul>
                      </div>
                      <div className="product-meta">
                        <ul className="d-flex g-3 gx-5">
                          <li>
                            <div className="fs-14px text-muted">
                              Service offering
                            </div>
                            <div className="fs-14px fw-bold text-secondary">
                              {getServiceOfferFlagsAsText(vendor)}
                            </div>
                          </li>
                        </ul>
                      </div>
                      <div className="product-meta">
                        <ul className="d-flex g-3 gx-5">
                          <li>
                            <div className="fs-14px text-muted">
                              Corporate social responsibility
                            </div>
                            <div className="fs-14px fw-bold text-secondary">
                              {getSocialFlagsAsText(vendor)}
                            </div>
                          </li>
                        </ul>
                      </div>
                      <div className="product-meta">
                        <div className="text-muted mb-1">
                          Primary business service
                        </div>
                        <ul className="custom-control-group">
                          {services.map((item, index) => {
                            return (
                              <React.Fragment key={index}>
                                <li>
                                  <div className="custom-control custom-checkbox custom-control-pro no-control">
                                    <input
                                      type="checkbox"
                                      className="custom-control-input"
                                      id={"service_clicked" + index}
                                      name="sizeCheck"
                                      onChange={(e) => {
                                        if (
                                          selectedServices.some(
                                            (data) => data.id === item.id
                                          )
                                        ) {
                                          setSelectedServices(
                                            selectedServices.filter(
                                              (data) => data.id !== item.id
                                            )
                                          );
                                        } else {
                                          let list = [...selectedServices];
                                          list.push(item);
                                          setSelectedServices(list);
                                        }
                                      }}
                                      //checked={selectedServices.some(data => data.id === item.id)}
                                    />
                                    <label
                                      className="custom-control-label"
                                      htmlFor={"service_clicked" + index}
                                    >
                                      {item.label}
                                    </label>
                                  </div>
                                </li>
                              </React.Fragment>
                            );
                          })}
                        </ul>
                      </div>
                      <div className="product-meta">
                        <a
                          href="#preferred"
                          onClick={(ev) => {
                            ev.preventDefault();
                            updateVendorPreference();
                          }}
                          className="clickable text-azure"
                        >
                          <div className="text-primary mb-2">
                            {vendor?.isPreferred
                              ? <span className="text-danger">Remove from preferred vendors</span>
                              : "Add to preferred vendors"}
                          </div>
                        </a>
                        <ul className="d-flex flex-wrap ailgn-center g-2 pt-1">
                          <li>
                            <Button
                              color="primary"
                              onClick={() => addRequest()}
                            >
                              Send request
                            </Button>
                          </li>
                          <li>
                            <Button className="btn-dim" color="primary" onClick={() => setChatId(vendor.sbId)}>
                              Message
                            </Button>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </Card>
          </Block>
        </Content>
      )}
      {chatId && (
        <ChatFloatingUI
          loggedInId={user.chatId}
          otherChatId={chatId}
          onClosed={() => setChatId()}
          chatType={ChatType.vendor_pm}
        />
      )}
      <LoadingComponent isLoading={isInitScreen} />
    </React.Fragment>
  );
};

export default VendorDetails;
