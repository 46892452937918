import React from "react";
import { Icon } from "../../../components/Component";
import SimpleBar from "simplebar-react";
import { Input } from "reactstrap";
import { ChatItem } from "./ChatPartials";

export const ChatAsideBody = ({
  onInputChange,
  selectedChannel,
  setSelectedChannel,
  chatItemClick,
  filteredChatList,
}) => {
  return (
    <SimpleBar className="nk-chat-aside-body">
      {/* <div className="nk-chat-aside-search">
        <div className="form-group">
          <div className="form-control-wrap">
            <div className="form-icon form-icon-left">
              <Icon name="search"></Icon>
            </div>
            <Input
              type="text"
              className="form-round"
              id="default-03"
              placeholder="Search by name"
              onChange={(e) => onInputChange(e)}
            />
          </div>
        </div>
      </div> */}
      <div className="nk-chat-list">
        <h6 className="title overline-title-alt">Messages</h6>
        <ul className="chat-list">
          {filteredChatList.length !== 0 ? (
            filteredChatList.map((item, idx) => {
              return (
                <ChatItem
                  key={idx}
                  item={item}
                  selectedChannel={selectedChannel}
                  setSelectedChannel={setSelectedChannel}
                  chatItemClick={chatItemClick}
                ></ChatItem>
              );
            })
          ) : (
            <p className="m-3">No chats yet</p>
          )}
        </ul>
      </div>
    </SimpleBar>
  );
};
