import React, { useEffect, useState } from "react";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Button,
  Icon,
} from "../../../components/Component";
import { useHistory, useLocation } from "react-router-dom";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import classNames from "classnames";
import PropertyGeneral from "./PropertyGeneral";
import PropertyUnit from "./PropertyUnit";
import { propertiesTypes } from "../../../utils";
import { fetchPropertyDetails } from "./PropertyData";
import LoadingComponent from "../../../layout/spinner/LoadingSpinner";

const PropertyDetail = ({ match }) => {
  const history = useHistory();
  const location = useLocation();
  const [activeTab, setActivetab] = useState();
  const [isInitScreen, setIsInitScreen] = useState(true);
  const [property, setProperty] = useState();

  const toggle = (tabid) => {
    setActivetab(tabid);
  };

  let types = propertiesTypes.filter(
    (item) => item?.value === property?.type
  );

  useEffect(() => {
    const idStr = match.params.id;
    if (idStr !== undefined || null || "") {
      let list = idStr.split("-");
      const id = list.length > 0 ? parseInt(list[0]) : "";
      loadPropertyDetails(id);
    } else {
      history.goBack();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match.params.id]);


  useEffect(() => {
    toggle(location.hash === "#unit" ? "2" : "1");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.hash]);

  const loadPropertyDetails = (id) => {
    let params = { id: id };
    fetchPropertyDetails(params, (data, error) => {
      if (error === null) {
        setProperty(data.data);
      }
      setIsInitScreen(false);
    });
  };

  return (
    <React.Fragment>
      <Head title="Property Detail"></Head>
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle page>
                {property?.name ?? ""}
              </BlockTitle>
              <BlockDes className="text-soft">
                <ul className="list-inline">
                  <li>
                    <span className="text-base">
                      {types.length > 0 ? types[0].label : ""}
                    </span>
                  </li>
                </ul>
              </BlockDes>
            </BlockHeadContent>
            <BlockHeadContent>
              <Button
                color="light"
                outline
                className="bg-white d-none d-sm-inline-flex"
                onClick={() => history.goBack()}
              >
                <Icon name="arrow-left"></Icon>
                <span>Back</span>
              </Button>
              <a
                href="#back"
                onClick={(ev) => {
                  ev.preventDefault();
                  // navigate(-1);
                }}
                className="btn btn-icon btn-outline-light bg-white d-inline-flex d-sm-none"
              >
                <Icon name="arrow-left"></Icon>
              </a>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        {activeTab && property && (
          <Block className="bg-white px-4">
            <Nav tabs className="">
              <NavItem>
                <NavLink
                  tag="a"
                  href="#general"
                  className={classNames({ active: activeTab === "1" })}
                >
                  General
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  tag="a"
                  href="#unit"
                  className={classNames({ active: activeTab === "2" })}
                >
                  Units
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent className="bg-white" activeTab={activeTab}>
              <TabPane tabId="1">
                <PropertyGeneral
                  property={property}
                  onReloadPropertyRequest={() => {
                    loadPropertyDetails(property.id);
                  }}
                />
              </TabPane>
              <TabPane tabId="2">
                <PropertyUnit isVisible property={property} />
              </TabPane>
            </TabContent>
          </Block>
        )}
      </Content>
      <LoadingComponent isLoading={isInitScreen} />
    </React.Fragment>
  );
};

export default PropertyDetail;
