import React, { useEffect, useState } from "react";
import { Card } from "reactstrap";
import {
  DataTableHead,
  DataTableRow,
  DataTableItem,
  PaginationComponent,
  BlueText,
  TableText,
  PaymentStatusBadge,
} from "../../../../components/Component";
import { fetchTenantPaymentAndChanges } from "../Dashboard/TenantDashboardData";
import moment from "moment";
import {
  CURRENCY_CODE,
  DATE_FORMAT,
  numberFormatterWithDecimal,
} from "../../../../utils";
import { useHistory } from "react-router";

const ReactPaymentHistory = ({
  isNeedtoShow,
  selectedFilterData,
  // paymentFilterData,
  isNeedBorder,
  tenant,
  locationList,
  fromDate,
  toDate,
  searchText,
  itemPerPage,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [transactions, setTransactions] = useState([]);

  const history = useHistory();
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  useEffect(() => {
    getPaymentHistory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  useEffect(() => {
    if(currentPage === 1) {
      getPaymentHistory()
    } else {
      setCurrentPage(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemPerPage, selectedFilterData]);

  function showRentPaymentDetails(item) {
    history.push({
      pathname: `${process.env.PUBLIC_URL}/rent-payment`,
      state: { history: item },
    });
  }

  function getPaymentHistory() {
    let params = {
      pageInfo: {
        pageIndex: currentPage,
        itemsPerPage: itemPerPage,
      },
      search: searchText,
      tenantId: tenant?.id,
    };
    if (selectedFilterData) {
      if (selectedFilterData?.location?.length > 0) {
        params["unitId"] = selectedFilterData?.location?.map((item) => item.id);
      }
      if (selectedFilterData?.paymentCharge?.length > 0 && selectedFilterData?.paymentCharge?.some(item => item.id === 0)) {
        params["isPaymentOnly"] = true;
      } else {
        params["isPaymentOnly"] = false;
      }

      if (selectedFilterData?.paymentCharge?.length > 0) {
        let list = selectedFilterData?.paymentCharge?.filter(item => item.id !== 0)
        params["itemIds"] = list?.map(
          (item) => item.id
        );
      }
      if (selectedFilterData?.FromDate) {
        params["startDate"] = moment(selectedFilterData?.FromDate).format(
          "YYYY/MM/DD"
        );
      }
      if (selectedFilterData?.ToDate) {
        params["endDate"] = moment(selectedFilterData?.ToDate).format(
          "YYYY/MM/DD"
        );
      }
    }
    fetchTenantPaymentAndChanges(params, (data, error) => {
      if (error === null) {
        setTransactions(data.list);
        if (currentPage === 1) {
          setTotalItems(data.pageInfo?.totalItems ?? 0);
        }
      }
    });
  }

  return (
    <Card
      className={`card-full shadow-none ${
        isNeedBorder ? "card-stretch remove-shadow border pt-2" : ""
      }`}
    >
      <div className={`nk-tb-list payment-history-table`}>
        <DataTableHead>
          <DataTableRow>
            <span className="sub-text text-secondary">Date</span>
          </DataTableRow>
          <DataTableRow>
            <span className="sub-text text-secondary">Location</span>
          </DataTableRow>
          <DataTableRow>
            <span className="sub-text text-secondary">
              Payments and charges
            </span>
          </DataTableRow>
          <DataTableRow size="md">
            <span className="sub-text text-secondary">Status</span>
          </DataTableRow>
          <DataTableRow size="md" className={"text-end"}>
            <span className="sub-text text-secondary">Amount</span>
          </DataTableRow>
          <DataTableRow size="md" className={"text-end"}>
            <span className="sub-text text-secondary">Balance</span>
          </DataTableRow>
          {/* <DataTableRow>
            <span className="d-none d-sm-inline">Status</span>
          </DataTableRow> */}
        </DataTableHead>
        {transactions.length > 0
          ? transactions.map((item, idx) => (
              <DataTableItem key={idx}>
                <DataTableRow>
                  <span className="fSize14 text-secondary">
                    {moment(item.date).format(DATE_FORMAT)}
                  </span>
                </DataTableRow>
                <DataTableRow>
                  <div className="user-card">
                    <span className="fSize14 text-secondary">
                      {(item.unit?.propertyName ?? "") +
                        ", Apt. " +
                        (item.unit?.name ?? "")}
                    </span>
                  </div>
                </DataTableRow>
                <DataTableRow>
                  {item.type === "PAYMENT" ? (
                    <BlueText
                      title={"Payment"}
                      onClick={(ev) => {
                        ev.preventDefault();
                        showRentPaymentDetails(item);
                      }}
                    />
                  ) : (
                    <>
                      {item?.items?.map((item, index) => {
                        return (
                          <React.Fragment key={index}>
                            <BlueText
                              title={item.title}
                              onClick={(ev) => {
                                ev.preventDefault();
                              }}
                            />
                          </React.Fragment>
                        );
                      })}
                    </>
                  )}
                </DataTableRow>
                <DataTableRow>
                  <span className="fSize14 text-secondary">
                    <PaymentStatusBadge status={item?.status}/>
                  </span>
                </DataTableRow>
                <DataTableRow className={"text-end"}>
                  {item.type === "PAYMENT" ? (
                    <TableText
                      isBold={true}
                      title={
                        item.amount
                          ? numberFormatterWithDecimal(
                              "+ " + CURRENCY_CODE,
                              "" + item.amount
                            )
                          : "+ $0.00"
                      }
                    />
                  ) : (
                    <>
                      {item?.items?.map((item, index) => {
                        return (
                          <div key={index} className="my-1">
                            <TableText
                              title={
                                item.amount
                                  ? numberFormatterWithDecimal(
                                      CURRENCY_CODE,
                                      "" + item.amount
                                    )
                                  : "$0.00"
                              }
                            />
                            <br />
                          </div>
                        );
                      })}
                    </>
                  )}
                </DataTableRow>
                <DataTableRow className={"text-end"}>
                  <span className="fSize14 text-secondary">
                    {item.balance
                      ? numberFormatterWithDecimal(
                          CURRENCY_CODE,
                          "" + item.balance
                        )
                      : "$0.00"}
                  </span>
                </DataTableRow>
              </DataTableItem>
            ))
          : null}
      </div>
      <div className={"border-top"}>
        {totalItems > 0 ? (
          <div className="m-4">
            <PaginationComponent
              itemPerPage={itemPerPage}
              totalItems={totalItems}
              paginate={paginate}
              currentPage={currentPage}
            />
          </div>
        ) : (
          <div className="text-center m-4">
            <span className="text-silent">{"No payments yet."}</span>
          </div>
        )}
      </div>
    </Card>
  );
};
export default ReactPaymentHistory;
