import Moment from "moment";
import { apiService } from "../../../services/api";
import { AppText, covertToNumberOnly } from "../../../utils";
import { APIServicePath } from "../../../services/api/api-path";

export function propertyList(page, itemPerPage, callback) {
  let params = {
    pageInfo: {
      pageIndex: page,
      itemsPerPage: itemPerPage,
    },
  };
  apiService
    .propertyList(params)
    .then((data) => {
      callback(data, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function unitHistoryList(tenantId, callback) {
  let params = {
    tenantId: tenantId,
  };
  apiService
    .unitHistoryList(params)
    .then((data) => {
      callback(data, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function propertyListAsSelector(page, itemPerPage, callback) {
  propertyList(page, itemPerPage, (data, error) => {
    if (error === null) {
      var list = [];
      data.list.forEach((e) => {
        list.push({
          label: e.name,
          value: e.name,
          id: e.id,
        });
      });
      callback(list, null);
    } else {
      callback(null, error);
    }
  });
}

export function createProperty(data, callback) {
  let params = {
    id: data.id ?? null,
    name: data.propertyname,
    propertyType: data.type?.value,
    fullAddress: data?.address?.fullAddress,
    city: data?.address?.city ?? data?.address?.subcity,
    state: data?.address?.state,
    country: data?.address?.country,
    zipCode: data?.address?.zipCode,
    latitude: data?.address?.latitude,
    longitude: data?.address?.longitude,
    // annualBudget: parseFloat(covertToNumberOnly(data?.budget ?? 0)),
    annualBudget:
      data?.budget && data?.budget?.length > 0
        ? parseFloat(covertToNumberOnly(data?.budget ?? 0))
        : 0,
    yearConstructed: data.year ? Moment(data.year).year() : undefined,
    sizeSqFt: data?.size?.replace(/[^\d.]/g, "") ?? "",
    units: data?.units?.replace(/[^\d.]/g, "") ?? "",
    stories: data?.stories?.replace(/[^\d.]/g, "") ?? "",
    payoutAccountId: data?.bank?.id ?? null,
  };
  var imageObject = null;
  if (
    (data.attachment?.length ?? 0) > 0 &&
    data.attachment[0].isOld === false
  ) {
    imageObject = data.attachment[0];
  }

  if (params.latitude === undefined || params.longitude === undefined) {
    callback(null, { message: AppText.addressError });
    return;
  }

  apiService
    .createProperty(imageObject, params)
    .then((auth) => {
      callback(auth, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function removeProperty(id, callback) {
  let req = {
    id: id,
  };
  apiService
    .removeProperty(req)
    .then((auth) => {
      callback(auth, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function savePropertyUnit(params, callback) {
  apiService
    .postCall(APIServicePath.saveUnit, params)
    .then((data) => {
      callback(data, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function fetchPropertyDetails(params, callback) {
  apiService
    .postCall(APIServicePath.propertyDetails, params)
    .then((auth) => {
      callback(auth, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function fetchSavePropertyContact(params, callback) {
  apiService
    .postCall(APIServicePath.savePropertyContact, params)
    .then((auth) => {
      callback(auth, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function fetchRemoveContact(params, callback) {
  apiService
    .postCall(APIServicePath.removeContact, params)
    .then((auth) => {
      callback(auth, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function fetchSavePropertyOwner(params, callback) {
  apiService
    .postCall(APIServicePath.savePropertyOwner, params)
    .then((auth) => {
      callback(auth, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function fetchRemoveOwner(params, callback) {
  apiService
    .postCall(APIServicePath.removeOwner, params)
    .then((auth) => {
      callback(auth, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function fetchUnits(params, callback) {
  apiService
    .postCall(APIServicePath.unitList, params)
    .then((auth) => {
      callback(auth, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function unitRemove(params, callback) {
  apiService
    .postCall(APIServicePath.removeUnit, params)
    .then((auth) => {
      callback(auth, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function fetchAddListingInformation(params, callback) {
  apiService
    .postCall(APIServicePath.addListingInformation, params)
    .then((auth) => {
      callback(auth, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function fetchAddAppliance(params, callback) {
  apiService
    .postCall(APIServicePath.addAppliance, params)
    .then((auth) => {
      callback(auth, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}
export function fetchRemoveAppliance(params, callback) {
  apiService
    .postCall(APIServicePath.removeAppliance, params)
    .then((auth) => {
      callback(auth, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function fetchSavePropertyMedia(params, callback) {
  apiService
    .postCall(APIServicePath.savePropertyMedia, params)
    .then((auth) => {
      callback(auth, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function fetchRemovePropertyMedia(params, callback) {
  apiService
    .postCall(APIServicePath.removePropertyMedia, params)
    .then((auth) => {
      callback(auth, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}
