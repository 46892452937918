import React, { useState } from "react";
import Content from "../../../layout/content/Content";
import { ModalBody, Modal, Form, Alert, Spinner } from "reactstrap";

import {
  Icon,
  Button,
  Col,
  RSelect,
  BankDropdown,
  FormInput,
  FormInputType,
} from "../../../components/Component";

import { createProperty } from "./PropertyData";
import { useForm } from "react-hook-form";
import {
  AppText,
  getPayoutAccountData,
  numberFormatter,
  propertiesTypes,
} from "../../../utils";
import Dropzone from "react-dropzone";
import { useEffect } from "react";
import DatePicker from "react-datepicker";

import {
  handleScriptLoad,
  loadScript,
} from "../../../services/google/autocomplete";
import { useRef } from "react";
import { ImageCropper } from "../../../components/cropper/cropper";
import moment from "moment";

export const PropertyAddModel = ({ isOpen, property, onSave, onCancel }) => {
  const [formData, setFormData] = useState({});
  const autoCompleteRef = useRef(null);
  const [errorVal, setError] = useState("");
  const [isError, setIsError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isImageCropperVisible, setIsImageCropperVisible] = useState(false);
  const [file, setFile] = useState(null);

  useEffect(() => {
    if (isOpen) {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY}&libraries=places`,
        () => handleScriptLoad(onUpdateAddress, autoCompleteRef)
      );
    } else {
      resetForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  useEffect(() => {
    if (property && isOpen) {
      let type = propertiesTypes.find((item) => item.value === property.type);

      let address = {
        fullAddress: property.address?.fullAddress,
        latitude: property.address?.latitude,
        longitude: property.address?.longitude,
      };

      let bank = undefined;
      if (property.payoutAccount) {
        bank = getPayoutAccountData(property.payoutAccount);
      }
      setFormData({
        id: property.id,
        propertyname: property.name,
        type: type,
        address: address,
        bank: bank,
        budget:
          (property.annualBudget ?? 0) > 0
            ? numberFormatter("", "" + property.annualBudget)
            : "",
        year: property.yearConstructed
          ? moment("" + property.yearConstructed, "YYYY").toDate()
          : null,
        size:
          (property.sizeSqFt ?? 0) > 0
            ? numberFormatter("", "" + property.sizeSqFt)
            : "",
        stories:
          (property.stories ?? 0) > 0
            ? numberFormatter("", "" + property.stories)
            : "",
        units:
          (property.units ?? 0) > 0
            ? numberFormatter("", "" + property.units)
            : "",
        attachment: property.image
          ? [
              {
                preview: property.image.imageUrl,
                isOld: true,
                file: {
                  name: property.image.thumbUrl,
                  mimetype: property.image.mimetype,
                },
              },
            ]
          : [],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [property]);

  // OnChange function to get the input data
  const onInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onInputNumberChange = (e) => {
    let value = numberFormatter("", e.target.value);
    setFormData({ ...formData, [e.target.name]: value });
  };

  // function to reset the form
  const resetForm = () => {
    setFormData({});
    setLoading(false);
    setIsError(false);
    setError(null);
  };

  const onUpdateAddress = (data) => {
    setFormData((prev) => ({
      ...prev,
      address: {
        latitude: data?.lat,
        longitude: data?.lng,
        fullAddress: data?.fullAddress,
        city: data?.city,
        state: data?.state,
        country: data?.country,
        zipCode: data?.zipCode,
      },
    }));
  };
  // function to close the form modal
  const onFormCancel = () => {
    resetForm();
    onCancel();
  };

  // submit function to add a new item
  const onFormSubmit = () => {
    var error = false;
    if (
      formData.type === "" ||
      formData.type === null ||
      formData.type === undefined ||
      (formData?.propertyname?.length ?? 0) === 0 ||
      !formData?.address
      // ||
      // formData.bank === undefined
    ) {
      setIsError(true);
      error = true;
    } else {
      setIsError(false);
    }
    if (!error) {
      setLoading(true);
      var object = formData;
      createProperty(object, (object, error) => {
        if (error === null) {
          resetForm();
          onSave();
        } else {
          setError(error.message);
        }
        setLoading(false);
      });
    }
  };

  const handleImageDrop = (acceptedFiles) => {
    if (acceptedFiles?.length > 0) {
      setFile(acceptedFiles[0]);
      setIsImageCropperVisible(true);
    }
  };

  // Change Page
  const { errors, register, handleSubmit } = useForm();

  return (
    <React.Fragment>
      <Content>
        <Modal isOpen={isOpen} className="modal-dialog-centered" size="lg">
          <ModalBody>
            <a
              href="#cancel"
              onClick={(ev) => {
                ev.preventDefault();
                onFormCancel();
              }}
              className="close"
            >
              <Icon name="cross-sm"></Icon>
            </a>
            <div className="p-2">
              <h5 className="title">
                {property ? "Edit property" : "Add property"}
              </h5>
              {errorVal && (
                <div className="my-2">
                  <Alert color="danger" className="alert-icon">
                    {" "}
                    <Icon name="alert-circle" /> {errorVal}{" "}
                  </Alert>
                </div>
              )}
              <div className="mt-4">
                <Form
                  className="row gy-4"
                  onSubmit={handleSubmit(onFormSubmit)}
                  autoComplete="off"
                >
                  <Col md="6">
                    <div className="form-group">
                      <label className="form-label">
                        {AppText.proprtyName}
                      </label>
                      <input
                        type="text"
                        name="propertyname"
                        value={formData?.propertyname ?? ""}
                        onChange={(e) => onInputChange(e)}
                        className="form-control"
                        maxLength={30}

                      />
                      {isError &&
                        (formData?.propertyname?.length ?? 0) === 0 && (
                          <p className="text-danger fs-11px ff-italic">
                            This field is required
                          </p>
                        )}
                    </div>
                  </Col>
                  <Col md="6">
                    <div className="form-group">
                      <label className="form-label" htmlFor="property-address">
                        {AppText.address}
                      </label>
                      <div className="form-control-wrap">
                        <input
                          type="text"
                          id="property-address"
                          name="address"
                          placeholder=""
                          defaultValue={formData?.address?.fullAddress ?? ""}
                          className="form-control"
                          onChange={(e) =>
                            setFormData((prev) => ({
                              ...prev,
                              address: null,
                            }))
                          }
                          autoComplete="off"
                          ref={(event) => {
                            autoCompleteRef.current = event;
                          }}
                        />
                        {isError && !formData?.address && (
                          <p className="text-danger fs-11px ff-italic">
                            This field is required
                          </p>
                        )}
                      </div>
                    </div>
                  </Col>
                  <Col size="6">
                    <div className="form-group">
                      <label className="form-label">
                        {AppText.proprtyType}
                      </label>
                      <div className="form-control-wrap">
                        <RSelect
                          options={propertiesTypes}
                          placeholder=""
                          value={formData?.type}
                          onChange={(e) =>
                            setFormData({ ...formData, type: e })
                          }
                        />
                        {isError && !formData.type && (
                          <p className="text-danger fs-11px ff-italic">
                            This field is required
                          </p>
                        )}
                      </div>
                    </div>
                  </Col>
                  {/* <Col size="6">
                    <div className="form-group">
                      <label className="form-label">
                        {AppText.annualBudget}
                      </label>
                      <div className="input-group">
                        <div className="input-group-prepend">
                          <span className="input-group-text">$</span>
                        </div>
                        <input
                          type="text"
                          name="budget"
                          value={formData?.budget ?? ""}
                          onChange={(e) => onInputNumberChange(e)}
                          className="form-control"
                          maxLength={10}
                        />
                      </div>
                    </div>
                  </Col> */}
                  <Col size="6" className="">
                    <div className="form-group">
                      <div className="form-control-wrap">
                        <FormInput
                          labelGroupClass="mt-0"
                          label={AppText.annualBudget}
                          type={FormInputType.amount}
                          value={formData?.budget}
                          onTextChange={(text) =>
                            setFormData({ ...formData, budget: text })
                          }
                        />
                        {/* {isDataError &&
                          (formData?.rent === undefined ||
                            formData?.rent?.length === 0) && (
                            <p className="invalid mt-n20">
                              This field is required
                            </p>
                          )} */}
                      </div>
                    </div>
                  </Col>
                  <Col size="6">
                    <div className="form-group">
                      <label className="form-label">
                        {AppText.yearConstructed}
                      </label>
                      <DatePicker
                        selected={formData?.year}
                        onChange={(date) =>
                          setFormData({ ...formData, year: date })
                        }
                        dateFormat="yyyy"
                        name="year"
                        maxDate={new Date()}
                        showYearPicker
                        yearItemNumber={10}
                        className="form-control date-picker"
                      />
                    </div>
                  </Col>
                  <Col size="6">
                    <div className="form-group">
                      <label className="form-label">{AppText.bank}</label>
                      <div className="form-control-wrap">
                        <BankDropdown
                          value={formData?.bank}
                          placeholder={""}
                          onSelect={(e) => {
                            setFormData({ ...formData, bank: e });
                          }}
                        />
                        {/* {isError && !formData.bank && (
                          <p className="text-danger fs-11px ff-italic">
                            This field is required
                          </p>
                        )} */}
                      </div>
                    </div>
                  </Col>
                  <Col size="4">
                    <div className="form-group">
                      <label className="form-label">{AppText.sizeSqFt}</label>
                      <div className="input-group">
                        <input
                          type="text"
                          name="size"
                          value={formData?.size ?? ""}
                          onChange={(e) => onInputNumberChange(e)}
                          className="form-control"
                          maxLength={10}
                        />
                        <div className="input-group-append">
                          <span className="input-group-text">
                            ft<sup> 2</sup>
                          </span>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col size="4">
                    <div className="form-group">
                      <label className="form-label">{AppText.stories}</label>
                      <input
                        type="text"
                        name="stories"
                        defaultValue={formData?.stories ?? ""}
                        onChange={(e) => onInputNumberChange(e)}
                        className="form-control"
                        maxLength={3}
                      />
                    </div>
                  </Col>
                  <Col size="4">
                    <div className="form-group">
                      <label className="form-label">{AppText.units}</label>
                      <input
                        type="text"
                        name="units"
                        defaultValue={formData?.units ?? ""}
                        onChange={(e) => onInputNumberChange(e)}
                        className="form-control"
                        maxLength={4}
                      />
                    </div>
                  </Col>
                  <Col sm="12">
                    <label className="form-label">Property image</label>
                    <Dropzone
                      onDrop={(acceptedFiles) => handleImageDrop(acceptedFiles)}
                      accept={[".jpg", ".png", ".jpeg", ".svg"]}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <section>
                          <div
                            {...getRootProps()}
                            className="dropzone upload-zone dz-clickable"
                          >
                            <input {...getInputProps()} />
                            {(formData?.attachment?.length ?? 0) === 0 && (
                              <div className="dz-message margin-y-50">
                                <span className="dz-message-text">
                                  Drag and drop file
                                </span>
                                <span className="dz-message-or">or</span>
                                <strong className="text-primary">SELECT</strong>
                              </div>
                            )}
                            {formData?.attachment?.map((file, index) => (
                              <div
                                key={index}
                                className="dz-preview dz-processing dz-image-preview dz-error dz-complete"
                              >
                                {/* <Button className="btn-round btn-icon" color="danger" size="sm" onClick={() => {}}>
                                <Icon name="cross"/>
                                </Button> */}
                                <div className="dz-image">
                                  <img src={file.preview} alt="preview" />
                                </div>
                              </div>
                            ))}
                          </div>
                        </section>
                      )}
                    </Dropzone>
                    {(formData?.attachment?.length ?? 0) > 0 && (
                      <div
                        className="mt-1"
                        onClick={() =>
                          setFormData({ ...formData, attachment: [] })
                        }
                        style={{ cursor: "pointer" }}
                      >
                        <span className="text-danger">
                          Remove selected file
                        </span>
                      </div>
                    )}
                  </Col>
                  <Col size="12">
                    <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                      <li>
                        <Button
                          color="primary"
                          size="md"
                          disabled={loading}
                          type="submit"
                        >
                          {loading ? (
                            <Spinner size="sm" color="light" />
                          ) : (
                            "Save"
                          )}
                        </Button>
                      </li>
                      <li>
                        <Button
                          onClick={(ev) => {
                            ev.preventDefault();
                            onFormCancel();
                          }}
                          className="link link-light"
                        >
                          Cancel
                        </Button>
                      </li>
                    </ul>
                  </Col>
                </Form>
              </div>
            </div>
          </ModalBody>
        </Modal>
      </Content>
      {isImageCropperVisible && (
        <ImageCropper
          visible={isImageCropperVisible}
          file={file}
          onCancel={() => {
            setFile();
            setIsImageCropperVisible(false);
          }}
          onCrop={(file) => {
            if (file !== null && file !== undefined) {
              let data = {
                preview: URL.createObjectURL(file),
                isOld: false,
                file: file,
              };
              setFormData({ ...formData, attachment: [data] });
            }
          }}
        />
      )}
    </React.Fragment>
  );
};

export default PropertyAddModel;
