import moment from "moment";
import { apiService, toPageInfo } from "../../../../services/api";
import { APIServicePath } from "../../../../services/api/api-path";
import {
  numberFormatter,
  removeTimeFromServerDateString,
} from "../../../../utils";

export function fetchInvitation(callback) {
  apiService
    .postCall(APIServicePath.pendingInvitation)
    .then((auth) => {
      callback(auth, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function invitationModerate(params, callback) {
  apiService
    .postCall(APIServicePath.moderateTenantInvite, params)
    .then((data) => {
      callback(data, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function processTenantInvitationByToken(params, callback) {
  apiService
    .postCall(APIServicePath.processInviteToken, params)
    .then((auth) => {
      callback(auth, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function fetchTenantPaymentAndChanges(params, callback) {
  apiService
    .postCall(APIServicePath.tenantPaymentChargeList, params)
    .then((data) => {
      let list = toPaymentAndChangesList(data.list);
      let pageInfo = data?.pageInfo ? toPageInfo(data?.pageInfo) : null;
      let result = { list: list, pageInfo: pageInfo };
      callback(result, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function getContractDetail(callback) {
  apiService
    .postCall(APIServicePath.contractDetail)
    .then((data) => {
      let detail = data.autoPayData
        ? toAutoPaymentDetail(data.autoPayData)
        : null;
      let tenant = data.data ? toTenantDetails(data.data) : null;
      let object = { detail, tenant };
      callback(object, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function cancelAutoPaymentSettings(callback) {
  apiService
    .postCall(APIServicePath.cancelAutoPayment)
    .then((data) => {
      callback(data, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

// *****************************************************************

function toPaymentAndChangesList(response) {
  let list = [];
  for (let index = 0; index < response?.length ?? 0; index++) {
    const element = response[index];
    list.push(toPaymentAndChange(element));
  }
  return list;
}

function toPaymentAndChange(response) {
  let items = [];
  for (let index = 0; index < response?.chargeItems?.length ?? 0; index++) {
    const element = response?.chargeItems[index];
    items.push({
      id: element.itemId,
      amount: element.amount,
      title: element.title,
    });
  }
  return {
    amount: response.amount,
    balance: response.balance,
    id: response.id,
    date: response.createdAt,
    invoiceDate: response?.rentalInvoice?.invoiceDate,
    items: items,
    type: response.dataType,
    unit: {
      id: response?.unit?.id,
      name: response?.unit?.name,
      propertyId: response?.unit?.property?.id,
      propertyName: response?.unit?.property?.name,
    },
    status: response?.status,
    bank: {
      name: response?.bankInfo?.bankName,
      last4: response?.bankInfo?.last4,
    },
  };
}

function toAutoPaymentDetail(response) {
  return {
    id: response.id,
    bankId: response.paymentMethodId,
    startDate: moment(
      removeTimeFromServerDateString(response.startDate),
      "YYYY-MM-DD"
    ).toDate(),
    endDate: moment(
      removeTimeFromServerDateString(response.endDate),
      "YYYY-MM-DD"
    ).toDate(),
    selectedDate: { label: "" + response.payOnDays, value: response.payOnDays },
    amount: numberFormatter("", "" + response.allowedAmount),
  };
}

function toTenantDetails(response) {
  return {
    id: response.tenantId,
    pmDetail: toPMDetail(response.pm),
    dueDate: response.dueDate
      ? moment(
          removeTimeFromServerDateString(response.dueDate),
          "YYYY-MM-DD"
        ).toDate()
      : null,
    property: toTenantProperty(response.property),
  };
}

function toPMDetail(response) {
  return response
    ? {
        id: response?.id,
        companyName: response?.companyName,
        rentalInvoiceGenerateDay: response?.rentalInvoiceGenerateDay,
        dueDays: response?.dueDays,
      }
    : null;
}

function toTenantProperty(response) {
  return response
    ? {
        id: response?.id,
        address: response?.address,
        name: response?.name,
        image: response?.image,
      }
    : null;
}
