import React, { useEffect, useRef, useState } from "react";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import {
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  DropdownItem,
} from "reactstrap";
import {
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  UserAvatar,
  PaginationComponent,
  Button,
  DataTableHead,
  DataTableRow,
  DataTableItem,
  PreviewAltCard,
  RSelect,
  BlueText,
} from "../../../components/Component";
import { getVendorList } from "./VendorData";
import { findUpper } from "../../../utils/Utils";
import {
  listAsNewLineString,
  openLinkInNewTab,
  propertiesTypes,
  propertyTypesToString,
  toCapitalize,
  vendorStatusType,
} from "../../../utils";
import { getServices } from "../../auth/AuthData";
import { useHistory, useLocation } from "react-router";
import PropertyAddModel from "../properties/PropertyAdd";
import { Storage } from "../../../services/storage/storage";
import { VendorListFilter } from "./VendorListFilter";
import LoadingComponent from "../../../layout/spinner/LoadingSpinner";

const VendorList = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemPerPage, setItemPerPage] = useState(20);
  const [currentItems, setCurrentItems] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [checked, setChecked] = useState([]);
  const [onSearchText, setSearchText] = useState("");
  const toggle = () => setOnSearch(!onSearch);
  const [onSearch, setOnSearch] = useState(true);
  const dropdownPages = [5, 10, 15, 20];
  const [sort, setSortState] = useState("asc");
  const [serviceList, setServiceList] = useState([]);
  const [selectedServices, setSelectedServices] = useState([]);
  const [selectedPropertyTypes, setSelectedPropertyTypes] = useState([]);
  const [isResetFilter, setIsResetFilter] = useState(false);
  const [isFilterApply, setIsFilterApply] = useState(false);
  const [status, setStatus] = useState("ALL");
  const [isInitScreen, setIsInitScreen] = useState(true);
  const searchInput = useRef(null);

  const dropDownRef = useRef();
  const location = useLocation();
  const history = useHistory();
  const [modal, setModal] = useState({
    add: false,
  });

  useEffect(() => {
    if (isResetFilter) {
      onFilterApply(false);
      setIsResetFilter(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isResetFilter]);

  useEffect(() => {
    loadServiceList();
  }, []);

  function onFilterApply(isFilterApply) {
    loadList(isFilterApply);
    setIsFilterApply(isFilterApply);
    dropDownRef.current.toggle();
  }

  useEffect(() => {
    if (currentPage !== 0) {
      loadList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  useEffect(() => {
    if (currentPage === 1) {
      loadList();
    } else {
      setCurrentPage(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemPerPage, onSearchText, sort, status, isFilterApply]);

  useEffect(() => {
    if (searchInput.current && !onSearch) {
      searchInput.current.focus();
    }
  }, [onSearch]);

  // onChange function for searching name
  const onFilterChange = (e) => {
    setSearchText(e.target.value);
    setCurrentPage(1);
  };

  function loadServiceList() {
    getServices({}, (list, error) => {
      if (error === null) {
        setServiceList(list);
      }
      setIsInitScreen(false);
    });
  }

  function loadList() {
    let sortParams = { field: "companyName", order: sort };
    let serviceIds = selectedServices.map((item) => item.id);
    let propertyTypes = selectedPropertyTypes;
    let params = {
      pageInfo: { pageIndex: currentPage, itemsPerPage: itemPerPage },
      sorting: sortParams,
    };

    if (onSearchText.length > 0) {
      params["search"] = onSearchText;
    }

    if (isFilterApply) {
      if (serviceIds.length > 0) {
        params["serviceIds"] = serviceIds;
      }

      if (propertyTypes.length > 0) {
        params["propertyTypes"] = propertyTypes;
      }
    } else {
      resetFilter();
    }

    if (status !== "ALL") {
      params["isPreferredOnly"] = true;
    } else {
      params["isPreferredOnly"] = false;
    }

    getVendorList(params, (data, error) => {
      if (error === null) {
        setCurrentItems(data.list);
        if (currentPage === 1) {
          setTotalItems(data.pageInfo?.totalItems ?? 0);
          if (location.state?.isComeToPickVendor && location.state?.request) {
            let list = location.state?.request?.vendors.map((item) => {
              return { id: item.id, companyName: item.name };
            });
            setChecked(list);
          }
        }
      }
    });
  }

  function selectVendors() {
    if (checked.length > 0) {
      let vendorList = [];
      for (let index = 0; index < checked.length; index++) {
        const vendor = checked[index];
        vendorList.push({ id: vendor.id, name: vendor.companyName });
      }
      Storage.setVendorList(vendorList);
    }
    Storage.setQuoteRequest(location.state?.request);
    history.goBack();
  }

  function onAddRequestClick() {
    if (checked.length > 0) {
      let vendorList = [];
      for (let index = 0; index < checked.length; index++) {
        const vendor = checked[index];
        vendorList.push({ id: vendor.id, name: vendor.companyName });
      }
      history.push({
        pathname: `${process.env.PUBLIC_URL}/new-request`,
        state: { vendors: vendorList },
      });
    } else {
      history.push({
        pathname: `${process.env.PUBLIC_URL}/new-request`,
      });
    }
  }

  // function to change the selected property of an item
  const onSelectChange = (e, item) => {
    if (e.currentTarget.checked) {
      let list = [...checked];
      list.push(item);
      setChecked(list);
    } else {
      let list = checked.filter((data) => data.id !== item.id);
      setChecked(list);
    }
  };

  const resetFilter = () => {
    setSelectedPropertyTypes([]);
    setSelectedServices([]);
  };

  function onViewClick(item, event) {
    const name = item.companyName.replace(/\s+/g, "-").toLowerCase();
    let url = `${process.env.PUBLIC_URL}/vendor/${item.id}-${name}`;
    openLinkInNewTab(url, event);
  }

  function onChatClick(item) {}

  function onSendRequestClick(item) {}

  const onApplyText = (e) => {
    setStatus(e.value);
  };

  // Change Page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <React.Fragment>
      <Head title="Vendors"></Head>
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle tag="h3" page>
                Vendors
              </BlockTitle>
            </BlockHeadContent>
            <BlockHeadContent>
              {location.state?.isComeToPickVendor ? (
                <Button
                  color="primary"
                  disabled={checked.length === 0}
                  onClick={() => selectVendors()}
                >
                  <span>Save vendors</span>
                </Button>
              ) : (
                <Button color="primary" onClick={() => onAddRequestClick()}>
                  <span>Add request</span>
                </Button>
              )}
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>

        <Block>
          <div className="card-inner bg-white position-relative card-tools-toggle border-bottom">
            <div className="card-title-group">
              <div className="card-tools">
                <div className="form-inline flex-nowrap gx-3">
                  <div className="form-wrap">
                    <RSelect
                      options={vendorStatusType}
                      className="w-130px"
                      placeholder="Status"
                      defaultValue={vendorStatusType[0]}
                      onChange={(e) => onApplyText(e)}
                    />
                  </div>
                </div>
              </div>
              <div className="card-tools me-n1">
                <ul className="btn-toolbar gx-1">
                  <li>
                    <a
                      href="#search"
                      onClick={(ev) => {
                        ev.preventDefault();
                        toggle();
                      }}
                      className="btn btn-icon search-toggle toggle-search"
                    >
                      <Icon name="search"></Icon>
                    </a>
                  </li>
                  <li className="btn-toolbar-sep"></li>
                  <li>
                    <ul className="btn-toolbar gx-1">
                      <li>
                        <UncontrolledDropdown ref={dropDownRef}>
                          <DropdownToggle
                            tag="a"
                            className="btn btn-trigger btn-icon dropdown-toggle"
                          >
                            {isFilterApply && (
                              <div className="dot dot-primary"></div>
                            )}
                            <Icon name="filter-alt"></Icon>
                          </DropdownToggle>
                          <VendorListFilter
                            isVisible={
                              dropDownRef.current?.state?.isOpen ?? false
                            }
                            serviceList={serviceList}
                            propertiesTypes={propertiesTypes}
                            filteredServices={selectedServices}
                            filteredPropertyTypes={selectedPropertyTypes}
                            onFilterApply={(data) => {
                              const { services, propertyTypies } = data;
                              setSelectedServices(services);
                              setSelectedPropertyTypes(propertyTypies);
                              dropDownRef.current.toggle();
                              if (isFilterApply === true) {
                                setCurrentPage(0);
                                setTimeout(() => {
                                  setCurrentPage(1);
                                }, 200);
                              }
                              setIsFilterApply(true);
                            }}
                            onResetFilter={(e) => {
                              resetFilter();
                              dropDownRef.current.toggle();
                              setIsFilterApply(false);
                            }}
                          />
                        </UncontrolledDropdown>
                      </li>
                      <li>
                        <UncontrolledDropdown>
                          <DropdownToggle
                            color="tranparent"
                            className="btn btn-trigger btn-icon dropdown-toggle"
                          >
                            <Icon name="setting"></Icon>
                          </DropdownToggle>
                          <DropdownMenu end className="dropdown-menu-xs">
                            <ul className="link-check">
                              <li>
                                <span>Show</span>
                              </li>
                              {dropdownPages.map((item, index) => {
                                return (
                                  <React.Fragment key={index}>
                                    <li
                                      className={
                                        itemPerPage === item ? "active" : ""
                                      }
                                    >
                                      <DropdownItem
                                        tag="a"
                                        href="#dropdownitem"
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                          setItemPerPage(item);
                                        }}
                                      >
                                        {item}
                                      </DropdownItem>
                                    </li>
                                  </React.Fragment>
                                );
                              })}
                            </ul>
                            <ul className="link-check">
                              <li>
                                <span>Order</span>
                              </li>
                              <li className={sort === "desc" ? "active" : ""}>
                                <DropdownItem
                                  tag="a"
                                  href="#dropdownitem"
                                  onClick={(ev) => {
                                    ev.preventDefault();
                                    setSortState("desc");
                                    // sortFunc("dsc");
                                  }}
                                >
                                  DESC
                                </DropdownItem>
                              </li>
                              <li className={sort === "asc" ? "active" : ""}>
                                <DropdownItem
                                  tag="a"
                                  href="#dropdownitem"
                                  onClick={(ev) => {
                                    ev.preventDefault();
                                    setSortState("asc");
                                    // sortFunc("asc");
                                  }}
                                >
                                  ASC
                                </DropdownItem>
                              </li>
                            </ul>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
            <div className={`card-search search-wrap ${!onSearch && "active"}`}>
              <div className="card-body">
                <div className="search-content">
                  <Button
                    className="search-back btn-icon toggle-search active"
                    onClick={() => {
                      setSearchText("");
                      toggle();
                    }}
                  >
                    <Icon name="arrow-left"></Icon>
                  </Button>
                  <input
                    type="text"
                    className="border-transparent form-focus-none form-control"
                    placeholder="Search by company name"
                    value={onSearchText}
                    onChange={(e) => onFilterChange(e)}
                    ref={searchInput}
                  />
                  <Button className="search-submit btn-icon">
                    <Icon name="search"></Icon>
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div className="nk-tb-list bg-white">
            <DataTableHead className="nk-tb-item">
              <DataTableRow className="nk-tb-col-check"></DataTableRow>
              <DataTableRow>
                <span className="sub-text "></span>
              </DataTableRow>
              <DataTableRow>
                <span className="sub-text text-secondary">Company name</span>
              </DataTableRow>
              <DataTableRow size="md">
                <span className="sub-text text-secondary">
                  Primary business service
                </span>
              </DataTableRow>
              <DataTableRow size="xl">
                <span className="sub-text text-secondary">
                  Property types serviced
                </span>
              </DataTableRow>
              <DataTableRow size="lg">
                <span className="sub-text text-secondary">Address</span>
              </DataTableRow>
              <DataTableRow size="md">
                <span className="sub-text text-secondary">
                  Serving within miles
                </span>
              </DataTableRow>
              <DataTableRow className="nk-tb-col-tools text-end"></DataTableRow>
            </DataTableHead>
            {currentItems.length > 0
              ? currentItems.map((item, index) => (
                  <React.Fragment key={index}>
                    <DataTableItem>
                      <DataTableRow className="nk-tb-col-check">
                        <div className="custom-control custom-control-sm custom-checkbox notext">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            checked={checked.some(
                              (data) => data.id === item.id
                            )}
                            id={item.id + "uid1"}
                            key={Math.random()}
                            onChange={(e) => onSelectChange(e, item)}
                          />
                          <label
                            className="custom-control-label"
                            htmlFor={item.id + "uid1"}
                          ></label>
                        </div>
                      </DataTableRow>
                      <DataTableRow>
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                        <a
                          href={undefined}
                          className="project-title clickable"
                          onClick={(e) => onViewClick(item, e)}
                        >
                          <UserAvatar
                            className="sq vendor-list"
                            theme={"white"}
                            text={findUpper(toCapitalize(item.companyName))}
                            image={item?.image?.thumbUrl}
                          />
                        </a>
                      </DataTableRow>
                      <DataTableRow>
                        <BlueText
                          title={item.companyName}
                          onClick={(ev) => {
                            onViewClick(item, ev);
                          }}
                        />
                      </DataTableRow>
                      <DataTableRow
                        size="md"
                        className="w-max-250px text-secondary"
                      >
                        <span>
                          {listAsNewLineString(
                            item?.service?.map((item) => item.name) ?? []
                          )}
                        </span>
                      </DataTableRow>
                      <DataTableRow size="xl" className="text-secondary">
                        <span>
                          {propertyTypesToString(item.propertyTypes ?? [])}
                        </span>
                      </DataTableRow>
                      <DataTableRow size="lg" className="text-secondary">
                        <span>{item?.address?.fullAddress}</span>
                      </DataTableRow>
                      <DataTableRow size="md" className="text-secondary">
                        <span>{item.serviceRadius}</span>
                      </DataTableRow>
                      <DataTableRow className="nk-tb-col-tools text-end ">
                        <ul className="nk-tb-actions gx-1">
                          <li>
                            <UncontrolledDropdown>
                              <DropdownToggle
                                tag="a"
                                className="text-soft dropdown-toggle btn btn-icon btn-trigger"
                              >
                                <Icon name="more-h"></Icon>
                              </DropdownToggle>
                              <DropdownMenu end>
                                <ul className="link-list-opt no-bdr">
                                  <li onClick={(e) => onViewClick(item, e)}>
                                    <DropdownItem
                                      tag="a"
                                      href="#view"
                                      onClick={(ev) => {
                                        ev.preventDefault();
                                      }}
                                    >
                                      <Icon name="eye"></Icon>
                                      <span>View</span>
                                    </DropdownItem>
                                  </li>
                                  <li onClick={() => onChatClick(item)}>
                                    <DropdownItem
                                      tag="a"
                                      href="#chat"
                                      onClick={(ev) => {
                                        ev.preventDefault();
                                      }}
                                    >
                                      <Icon name="shield-star"></Icon>
                                      <span>Add to preferred vendors</span>
                                    </DropdownItem>
                                  </li>
                                  <li onClick={() => onChatClick(item)}>
                                    <DropdownItem
                                      tag="a"
                                      href="#chat"
                                      onClick={(ev) => {
                                        ev.preventDefault();
                                      }}
                                    >
                                      <Icon name="chat"></Icon>
                                      <span>Message</span>
                                    </DropdownItem>
                                  </li>
                                  <li onClick={() => onSendRequestClick(item)}>
                                    <DropdownItem
                                      tag="a"
                                      href="#sendrequest"
                                      onClick={(ev) => {
                                        ev.preventDefault();
                                      }}
                                    >
                                      <Icon name="send"></Icon>
                                      <span>Send request</span>
                                    </DropdownItem>
                                  </li>
                                </ul>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </li>
                        </ul>
                      </DataTableRow>
                    </DataTableItem>
                  </React.Fragment>
                ))
              : null}
          </div>
          <PreviewAltCard className={"border-top"}>
            {totalItems > 0 ? (
              <PaginationComponent
                itemPerPage={itemPerPage}
                totalItems={totalItems}
                paginate={paginate}
                currentPage={currentPage}
              />
            ) : (
              <div className="text-center">
                <span className="text-silent">
                  There are not vendors in your area yet
                </span>
              </div>
            )}
          </PreviewAltCard>
        </Block>

        <PropertyAddModel
          isOpen={modal.add || modal.edit}
          isEdit={modal.edit}
          onCancel={() => {
            setModal({ add: false });
          }}
          onSave={() => {
            setModal({ add: false });
          }}
        />
      </Content>
      <LoadingComponent isLoading={isInitScreen} />
    </React.Fragment>
  );
};
export default VendorList;
