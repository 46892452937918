import classNames from "classnames";
import React from "react";

export const ErrorText = ({ className, ...props }) => {
  var rowClass = classNames({
    [`${className}`]: className,
  });

  rowClass = rowClass + " mt-1 text-danger fs-11px ff-italic"
  return (
    <React.Fragment>
      <p className={rowClass}>
         {props.children ?? "This field is required"}
      </p>
    </React.Fragment>
  );
};