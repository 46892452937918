import React, { useEffect, useState } from "react";
import { DropdownMenu } from "reactstrap";
import DatePicker from "react-datepicker";
import { Col, RSelect, Row } from "../Component";
import LocationDropdown from "../dropdown/LocationDropdown";
import PaymentChargeDropdown from "../dropdown/PaymentChargeDropdown";

export const PaymentChargesFilter = ({
  tenantId,
  isVisible,
  selectedFilterData,
  locationList,
  paymentCharges,
  filteredLocation,
  filteredPaymentCharges,
  fromDate,
  toDate,
  onFilterApply,
  onResetFilter,
}) => {
  const [filterData, setFilterData] = useState({
    location: null,
    FromDate: null,
    ToDate: null,
    paymentCharge: null,
  });

  useEffect(() => {
    if (isVisible) {
      setFilterData(selectedFilterData);
    }
  }, [isVisible]);

  return (
    <DropdownMenu
      end
      className="filter-wg dropdown-menu-xl"
      style={{ overflow: "visible" }}
    >
      <div className="dropdown-head">
        <span className="sub-title dropdown-title">Filter</span>
      </div>
      <div className="dropdown-body dropdown-body-rg">
        <Row className="gx-6 gy-3">
          <Col size="6">
            <div className="form-group">
              <label className="overline-title overline-title-alt">From</label>
              <DatePicker
                selected={filterData?.FromDate}
                className="form-control"
                popperPlacement="top-end"
                onChange={(date) => {
                  if (filterData?.ToDate) {
                    var Difference_In_Time =
                      filterData?.ToDate.getTime() - date.getTime();
                    var Difference_In_Days =
                      Difference_In_Time / (1000 * 3600 * 24);

                    if (Difference_In_Days < 0) {
                      // setSelectedToDate();
                      setFilterData({ ...filterData, toDate: null });
                    }
                    // setSelectedFromDate(date);
                    setFilterData({ ...filterData, FromDate: date });
                  } else {
                    setFilterData({ ...filterData, FromDate: date });
                  }
                }}
              />
            </div>
          </Col>
          <Col size="6">
            <div className="form-group">
              <label className="overline-title overline-title-alt">To</label>
              <DatePicker
                selected={filterData?.ToDate}
                className="form-control"
                popperPlacement="top-end"
                onChange={(date) => {
                  if (filterData?.FromDate) {
                    var Difference_In_Time =
                      date.getTime() - filterData?.FromDate.getTime();
                    var Difference_In_Days =
                      Difference_In_Time / (1000 * 3600 * 24);
                    if (Difference_In_Days > 0) {
                      setFilterData({ ...filterData, ToDate: date });
                    }
                  } else {
                    setFilterData({ ...filterData, ToDate: date });
                  }
                }}
              />
            </div>
          </Col>
          <Col size="12">
            <div className="form-group">
              <label className="overline-title overline-title-alt">
                Location
              </label>
              <LocationDropdown
                value={filterData?.location}
                isMulti={true}
                tenantId={tenantId}
                isShowAll={false}
                onSelect={(e) => {
                  setFilterData({ ...filterData, location: e });
                }}
                className=""
              />
            </div>
          </Col>
          <Col size="12">
            <div className="form-group">
              <label className="overline-title overline-title-alt">
                Payment and Charges
              </label>
              <PaymentChargeDropdown
                value={filterData?.paymentCharge}
                tenantId={tenantId}
                isMulti={true}
                // isShowAll={true}
                onSelect={(e) =>
                  setFilterData({ ...filterData, paymentCharge: e })
                }
                className=""
              />
            </div>
          </Col>
          <Col size="12">
            <div className="form-group">
              <button
                type="button"
                className="btn btn-primary"
                onClick={(e) => {
                  e.preventDefault();
                  if (
                    filterData.FromDate === null &&
                    filterData.ToDate === null &&
                    filterData.location === null
                  ) {
                    onResetFilter();
                    setFilterData({});
                  } else {
                    let data = filterData;
                    onFilterApply(data);
                    // setFilterData({});
                  }
                }}
              >
                Filter
              </button>
            </div>
          </Col>
        </Row>
      </div>
      <div className="dropdown-foot between">
        <a
          href="#reset"
          className="clickable"
          onClick={(ev) => {
            ev.preventDefault();
            onResetFilter();
          }}
        >
          Reset Filter
        </a>
      </div>
    </DropdownMenu>
  );
};
