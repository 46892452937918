import React, { useEffect, useState } from "react";
import {
  Card,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  DropdownItem,
  Badge,
} from "reactstrap";
import {
  DataTableHead,
  DataTableRow,
  DataTableItem,
  Icon,
  ConfirmationAlert,
  ReactDataTable,
} from "../../../../components/Component";
import moment from "moment";
import {
  CURRENCY_CODE,
  DATE_FORMAT,
  numberFormatterWithDecimal,
  removeTimeFromServerDateString,
  showErrorToast,
  showSuccessToast,
} from "../../../../utils";
import { useHistory } from "react-router";
import { fetchInvoiceList, getInvoiceStatus, revokeCustomInvoice } from "../Payment/InvoiceData";
import AddInvoice from "../AddInvoice";
import TableTitle from "../../../../components/text/TableTitle";
import MoreOption from "../../../../components/more/MoreOption";

const Charges = ({ tenant, invoiceModal, onInvoiceModelClose }) => {
  const [chargeList, setChargeList] = useState([]);
  const [selectedInvoice, setSelectedInvoice] = useState();
  const [modal, setModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [itemPerPage, setItemPerPage] = useState(10);

  const history = useHistory();
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  useEffect(() => {
    getInvoiceList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (invoiceModal === true) {
      setModal(invoiceModal);
    }
  }, [invoiceModal]);

  function modelClose() {
    setModal(false);
    setSelectedInvoice();
    onInvoiceModelClose();
    // resetForm();
  }

  function getInvoiceList() {
    let params = {
      tenantId: tenant?.id,
    };
    fetchInvoiceList(params, (data, error) => {
      if (error === null) {
        setChargeList(data.list);
      }
    });
  }

  function onRevoke(item, index) {
    item &&
      ConfirmationAlert(
        "Revoke invoice!",
        "Are you sure you want to revoke invoice?",
        () => {
          revokeCustomInvoice({ id: item.id }, (data, error) => {
            if (error === null) {
              showSuccessToast("Your invoice have been revoked successfully");
              getInvoiceList();
            } else {
              showErrorToast(error.message);
            }
          });
        }
      );
  }

  function onEdit(item) {
    setSelectedInvoice(item);
    setModal(true);
  }

  return (
    <React.Fragment>
      <Card className="card-full border">
        <ReactDataTable
          data={chargeList}
          columns={ChargeTableData(onEdit, onRevoke)}
          totalItemCount={totalItems}
          className=" w-100 overflow-visiable"
          onRowSelected={(row) => {}}
          onRowClicked={(row) => {}}
        />
      </Card>
      <AddInvoice
        isOpen={modal}
        selectedInvoice={invoiceModal ? null : selectedInvoice}
        tenant={tenant}
        onCancel={() => {
          modelClose();
        }}
        onSave={() => {
          getInvoiceList();
        }}
      />
    </React.Fragment>
  );
};
export default Charges;

export const ChargeTableData = (onEdit, onRevoke) => {
  return [
    {
      name: <TableTitle title={"Date"} />,
      selector: (row) =>
        row.effectiveDate
          ? moment(removeTimeFromServerDateString(row.effectiveDate)).format(
            DATE_FORMAT
            )
          : undefined,
      sortable: true,
    },
    {
      name: <TableTitle title={"Invoice type"} />,
      selector: (row) =>
        row.invoiceType === "SINGLE" ? "One-time" : "Recurring",
      sortable: true,
    },
    {
      name: <TableTitle title={"Description"} />,
      cell: (row) => (
        <span className="">
          {row.items &&
            row.items?.length > 0 &&
            row.items.map((description, index) => {
              return (
                <React.Fragment key={index}>
                  <>
                    <span className="fSize14 text-secondary">
                      {description.title}
                    </span>
                    <br />
                  </>
                </React.Fragment>
              );
            })}
        </span>
      ),
    },
    {
      name: <TableTitle title={"Amount"} align={"end"} />,
      selector: (row) => "",
      right: true,
      cell: (row) => (
        <span className="text-end">
          {row.items &&
            row.items?.length > 0 &&
            row.items.map((description, index) => {
              return (
                <React.Fragment key={index}>
                  <>
                    <span className="fSize14 text-secondary">
                      {description.amount
                        ? numberFormatterWithDecimal(
                            CURRENCY_CODE,
                            "" + description?.amount
                          )
                        : "$0.00"}
                    </span>

                    <br />
                  </>
                </React.Fragment>
              );
            })}
        </span>
      ),
    },
    {
      name: <TableTitle title={"Total amount"} align="end" />,
      selector: (row) =>
        row.totalAmount
          ? numberFormatterWithDecimal(CURRENCY_CODE, "" + row.totalAmount)
          : "$0.00",
      sortable: true,
      right: true,
    },
    {
      name: <TableTitle title={"Status"} />,
      cell: (row) => {
        let status = getInvoiceStatus(row);
        return (
          <Badge
          className="badge-dim fSize14 p-1"
          color={
            status === "Active"
              ? "success"
              : status === "Charged"
              ? "info" 
              : status === "Revoked" || status === "Inactive"
              ? "danger" 
              : "warning"
          }
        >
          {status}
        </Badge>
        );
      },
    },
    {
      name: <TableTitle title={""} align="end" />,
      right: true,
      width: "60px",
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      cell: (row) => (
        <MoreOption
          options={[
            {
              icon: "edit",
              text: "Edit",
              hide: row.invoiceType === "SINGLE",
              action: () => {
                onEdit(row);
              },
            },
            {
              icon: "trash",
              text: "Revoke",
              action: () => {
                onRevoke(row);
              },
            },
          ]}
        />
      ),
    },
  ];
};
