import React from "react";
import {
  Card,
  CardBody,
  CardText,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import { Icon } from "../../components/Component";
import { SuggestionType } from "../../utils";

export const CanNotFindServiceModel = ({
  modal,
  type = SuggestionType.service,
  closeHandler,
  onSelectHandler,
}) => {
  return (
    <Modal isOpen={modal} toggle={closeHandler}>
      <ModalHeader
        toggle={closeHandler}
      >
        Can't find a {type === SuggestionType.service ? "service" : "association"}?
      </ModalHeader>
      <ModalBody>
        <Card className="card-bordered" onClick={() => onSelectHandler(0)}>
          <CardBody className="card-inner m-n2">
          <CardText className="text-black">
          <Icon name="na" className={"me-2"}/>
          {type === SuggestionType.service ? (
            <>I don't offer <b>any</b> of these services.</>
          ) : type === SuggestionType.association ? (
            <>I don't connect <b>any</b> of these associations.</>
          ) : null}
          </CardText>
          </CardBody>
        </Card>
        <Card className="card-bordered" onClick={() => onSelectHandler(1)}>
          <CardBody className="card-inner m-n2">
            <CardText className="text-black">
            <Icon name="plus" className={"me-2"}/>
               {type === SuggestionType.service ? (
            <>I do offer some of these services, but I want to <b>suggest</b> another.</>
          ) : type === SuggestionType.association ? (
            <>I want to <b>suggest</b> another association.</>
          ) : null}
            </CardText>
          </CardBody>
        </Card>
      </ModalBody>
    </Modal>
  );
};
