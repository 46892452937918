export let serviceOfferingsData = [
    {
        id: "individual-units",
        name: "units",
        text: "Services individual units",
    },
    {
        id: "supplies-goods",
        name: "goods",
        text: "Supplies goods/products",
    },
    {
        id: "unionized-business",
        name: "business",
        text: "Unionized business",
    }
];

export let associationsData = [
    {
        id: "associations-boma",
        name: "boma",
        text: "BOMA",
    },
    {
        id: "associations-acmo",
        name: "acmo",
        text: "ACMO",
    },
];

export let socialResponsibilityData = [
    {
        id: "diversity-policy",
        name: "diversity",
        text: "Diversity & inclusion policy",
    },
    {
        id: "environmental-policy",
        name: "environmental",
        text: "Environmental or sustainability policy",
    },
    {
        id: "covid-19-vaccinated",
        name: "covid19",
        text: "Staff are COVID-19 vaccinated",
    },
];