export const StorageKey = {
  authInfo: "com.altitude.auth.info",
  accessToken: "com.altitude.auth.access.token",
  lastSignedInInTime: "com.altitude.last.signed.in.time",
  isStaySignedIn: "com.altitude.last.logged.in.time",
  quoteRequestData: "com.altitude.quote.request.data",
  vendorList: "com.altitude.quote.request.vendors",
  mainMenuState: "com.altitude.main.menu.state",
  emailActions: "com.altitude.email.action.informations"

};

const BaseStorage = {
    getItem: key => {
        let result = localStorage.getItem(key);
        return JSON.parse(result);
    },
    setItem: (key, value) => {
        const item = JSON.stringify(value);
        return localStorage.setItem(key, item);
    },
    removeItem: key => {
        return localStorage.removeItem(key);
    },
    removeAll: () => {
        localStorage.clear();
    },
  };


  export const Storage = {
  
    setUserToken: (value) => {
        BaseStorage.setItem(StorageKey.accessToken, value);
    },
    getUserToken: () => {
        let value = BaseStorage.getItem(StorageKey.accessToken);
        return value;
    },
    setLoggedInUser: value => {
        BaseStorage.setItem(StorageKey.authInfo, value);
    },
    getLoggedInUser: () => {
        let value = BaseStorage.getItem(StorageKey.authInfo);
        return value;
    },
    setLastSignedInTime: value => {
        BaseStorage.setItem(StorageKey.lastSignedInInTime, value);
    },
    getLastSignedInTime: () => {
        return BaseStorage.getItem(StorageKey.lastSignedInInTime);
    },
    setIsStaySignedIn: value => {
        BaseStorage.setItem(StorageKey.isStaySignedIn, value);
    },
    getIsStaySignedIn: () => {
        return BaseStorage.getItem(StorageKey.isStaySignedIn);
    },
    setQuoteRequest: value => {
        BaseStorage.setItem(StorageKey.quoteRequestData, value);
    },
    getQuoteRequest: () => {
        let value = BaseStorage.getItem(StorageKey.quoteRequestData);
        return value;
    },
    removeQuoteRequest: () => {
        BaseStorage.removeItem(StorageKey.quoteRequestData)
    },
    setVendorList: value => {
        BaseStorage.setItem(StorageKey.vendorList, value);
    },
    getVendorList: () => {
        let value = BaseStorage.getItem(StorageKey.vendorList);
        return value;
    },
    removeVendorList: () => {
        BaseStorage.removeItem(StorageKey.vendorList)
    },
    isSignedIn: () => {
       let token = Storage.getUserToken();
       if (token) {
        let isStaySignedIn = Storage.getIsStaySignedIn();
        let lastSignedInTime = Storage.getLastSignedInTime();
        let time = Math.abs(Date.now()  - lastSignedInTime);
         const diffMins = Math.ceil(time / (1000 * 60));
         const diffDays = Math.ceil(time / (1000 * 60 * 60 * 24)); 

         if (isStaySignedIn && diffDays <= 7) {
            return true;
         } else if (isStaySignedIn === false && diffMins < 180) { // 3 hours
            Storage.setLastSignedInTime(Date.now());
            return true;
         } 
       } 
       Storage.clearAll();
       return false;
    },
    clearAll() {
       BaseStorage.removeAll();
    },
    setMainMenuState: value => {
        BaseStorage.setItem(StorageKey.mainMenuState, value);
    },
    getMainMenuState: () => {
        let value = BaseStorage.getItem(StorageKey.mainMenuState);
        return value;
    },
    setEmailActions: value => {
        BaseStorage.setItem(StorageKey.emailActions, value);
    },
    getEmailActions: () => {
        let value = BaseStorage.getItem(StorageKey.emailActions);
        return value;
    },
    removeEmailActions: () => {
        BaseStorage.removeItem(StorageKey.emailActions)
    },
  }