import React from "react";
import Tags from "@yaireo/tagify/dist/react.tagify";
import { useState } from "react";
import { useEffect } from "react";

const TagInput = ({ onChange, onInput, ...props }) => {
  const [tagifyProps, setTagifyProps] = useState({});

  // on component mount
  useEffect(() => {
    setTagifyProps({ loading: true });
    setTimeout(
      () =>
        setTagifyProps((lastProps) => ({
          ...lastProps,
          showFilteredDropdown: false,
        })),
      5000
    );
  }, []);
  return (
    <Tags
      onChange={(data) => {
        onChange(JSON.parse(data?.detail?.value ?? " "));
      }}
      {...props}
    />
  );
};

export default TagInput;
