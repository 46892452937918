import React, { useRef, useState } from "react";
import { Block, Button } from "../../../../../components/Component";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import moment from "moment/moment";
import {
  CURRENCY_CODE,
  DATE_FORMAT,
  covertToNumberOnly,
  numberFormatterWithDecimal,
  showErrorToast,
  showSuccessToast,
} from "../../../../../utils";
import { TenantAutoPaymentWizardContext } from "./AutoRentPayment";
import { setupAutoPayment } from "./AutoPaymentData";
import { Spinner } from "reactstrap";

export const TenantAutoPaymentConfirmation = (props) => {
  const { data, updateData } = React.useContext(TenantAutoPaymentWizardContext);
  const [loading, setLoading] = useState(false);

  const history = useHistory();
  const componentRef = useRef();

  function onSubmit() {
    let params = {
      startDate: moment(data.startDate).format("YYYY-MM-DD"),
      endDate: moment(data.endDate).format("YYYY-MM-DD"),
      allowedAmount: parseFloat(covertToNumberOnly(data.amount)),
      payOnDays: data.selectedDate.value,
      paymentMethodId: data.bank.id,
      paymentMethodType: "BANK",
    };
    setLoading(true);
    setupAutoPayment(params, (data, error) => {
      setLoading(false);
      if (error === null) {
        showSuccessToast("Your auto payment have been setup successfully");
        history.push(`${process.env.PUBLIC_URL}/`);
      } else {
        showErrorToast(error.message);
      }
    });
  }

  return (
    <Block>
      <div className="invoice-bills">
        <div ref={componentRef} style={{ backgroundColor: "white" }}>
          <div className="table-responsive ">
            <table className="table table-striped border-top border-1">
              <tbody>
                <tr>
                  <td className="">Bank</td>
                  <td className="">{data?.bank?.label ?? ""}</td>
                </tr>
                <tr>
                  <td className="">Start date</td>
                  <td className="">
                    {data?.startDate
                      ? moment(data?.startDate).format(DATE_FORMAT)
                      : ""}
                  </td>
                </tr>
                <tr>
                  <td className="">End date</td>
                  <td className="">
                    {data?.endDate
                      ? moment(data?.endDate).format(DATE_FORMAT)
                      : ""}
                  </td>
                </tr>
                <tr>
                  <td className="">Pay on</td>
                  <td className="">{data?.selectedDate?.label ?? ""}</td>
                </tr>
                <tr>
                  <td className="">Auto payment amount</td>
                  <td className="">
                    {numberFormatterWithDecimal(
                      CURRENCY_CODE,
                      "" + data?.amount
                    )}
                  </td>
                </tr>
                <tr>
                  <td className="">Service fee</td>
                  <td className="">$5.00</td>
                </tr>
                <tr>
                  <td className="">Total</td>
                  <td className="">
                    {numberFormatterWithDecimal(
                      CURRENCY_CODE,
                      "" + (parseFloat(covertToNumberOnly(data?.amount)) + 5)
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div className="mt-2 mb-5">
          <p>
            <br />
            By setting up AutoPay, I authorize the automatic withdrawal from my
            selected payment account the amount show or, if no amount is shown,
            the balance due each month. I understand and agree that my
            authorization will remain in place until I change or cancel it by
            logging into my Altitude account, and that it may take up to 7
            business days to process my change or cancellation before it will
            become effective. I understand and agree that I will be charged a
            non-refundable service fee of $5.00 for each AutoPay transaction and
            that this service fee is charged by and paid to the payment services
            provider for making payments through Altitude, this fee is not
            charged by the property, and this fee will not appear on my ledger.
          </p>
          <div className="form-check mt-3">
            <input
              className="form-check-input"
              type="checkbox"
              id="confirmationCheckbox"
              onChange={(e) => {
                // Update the confirmation status in the context
                updateData("confirmation", e.target.checked);
              }}
            />
            <label className="form-check-label" htmlFor="confirmationCheckbox">
              I have read and accept the Terms and Conditions.
            </label>
          </div>

          <div className="">
            {/* <p className="fw-bold"> Are you want you pay $15000 ?</p> */}
            <div className="nk-notes ff-italic fs-12px text-soft mt-3">
              <div className="form-group">
                <Button
                  type="button"
                  color="gray"
                  size="md"
                  className="btn-dim me-4"
                  onClick={() => {
                    // history.push(`${process.env.PUBLIC_URL}/`);
                    props.prev();
                  }}
                >
                  Back
                </Button>
                <Button
                  type="submit"
                  color="primary"
                  size="md"
                  // defaultValue={data?.paymentAmount}
                  className="me-2"
                  disabled={!data.confirmation || loading}
                  onClick={() => onSubmit()}
                >
                  {loading ? <Spinner size="sm" color="light" /> : "Submit"}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Block>
  );
};
