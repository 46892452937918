import React, { useState } from "react";
import {
  Block,
  BlockContent,
  BlockHead,
  BlockTitle,
  Button,
  Icon,
  PreviewCard,
  TooltipComponent,
} from "../../components/Component";
import Logo from "../../images/logo.svg";
import LogoDark from "../../images/logo-dark.svg";
import PageContainer from "../../layout/page-container/PageContainer";
import Head from "../../layout/head/Head";
import AuthFooter from "./AuthFooter";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Alert, Form, Spinner } from "reactstrap";
import {
  AppText,
  isValidPassword,
  NewPasswordType,
  PASSWORD_TOOL_TIP,
  showSuccessToast,
} from "../../utils";
import { changePassword, recoverNewPassword, resetPasssword } from "./AuthData";
import { ConfirmationType } from "./Confirmation";
import { Storage } from "../../services/storage/storage";

const CreatePassword = () => {
  const { errors, register, handleSubmit } = useForm();
  const [loading, setLoading] = useState(false);
  const [isShowSuccessMessage, setIsShowSuccessMessage] = useState(false);
  const [errorVal, setError] = useState();

  const history = useHistory();
  const [newPassState, setNewPassState] = useState(false);
  const location = useLocation();

  const onFormSubmit = (formData) => {
    let isValidPasssword = isValidPassword(formData.newpassword);
    if (!isValidPasssword) {
      setError(
        "Make sure that the new password contains all required characters mentioned in the popup on the right."
      );
    } else {
      setError();
      var data = {
        token: location.state.token,
        newPassword: formData.newpassword,
      };
      setLoading(true);
      if (location.state.type === NewPasswordType.changePassword) {
        changePassword(data, (data, error) => {
          if (error === null) {
            setIsShowSuccessMessage(true);
            setTimeout(() => {
              setLoading(false);
              showSuccessToast(
                "Account password has been changed successfully!"
              );
              history.push({
                pathname: `${process.env.PUBLIC_URL}/user-profile-setting`,
              });
            }, 0);
          } else {
            setLoading(false);
            setError(error.message);
          }
        });
      } else {
        recoverNewPassword(data, (data, error) => {
          if (error === null) {
            setIsShowSuccessMessage(true);
            setTimeout(() => {
              setLoading(false);
              history.push({
                pathname: `${process.env.PUBLIC_URL}/confirmation`,
                state: {
                  type: ConfirmationType.createPassword,
                  token: data.token,
                },
              });
            }, 0);
          } else {
            setLoading(false);
            Storage.clearAll();
            history.push(`${process.env.PUBLIC_URL}/auth-login`);
          }
        });
      }
    }
  };

  return (
    <React.Fragment>
      <Head title={"Reset your password"} />
      <PageContainer>
        {isShowSuccessMessage && (
          <></>
          // <div className="d-flex aligns-items-center justify-content-center">
          //   <Alert className="alert-icon" color="success">
          //     <Icon name="check-circle" />
          //     <strong>Password successfully changed.</strong>
          //   </Alert>
          // </div>
        )}
        <Block className="nk-block-middle nk-auth-body  wide-xs">
          <div className="brand-logo pb-4 text-left mx-4">
            <Link to={process.env.PUBLIC_URL + "/"} className="logo-link">
              <img
                className="logo-light logo-img logo-img-lg"
                src={Logo}
                alt="logo"
              />
              <img
                className="logo-dark logo-img logo-img-lg"
                src={LogoDark}
                alt="logo-dark"
              />
            </Link>
          </div>
          <PreviewCard className="card-bordered" bodyClass="card-inner-lg">
            <BlockHead>
              <BlockContent>
                <BlockTitle tag="h5">{AppText.resetYourPassword}</BlockTitle>
              </BlockContent>
            </BlockHead>
            {errorVal && (
              <div className="mb-3">
                <Alert color="danger" className="alert-icon">
                  {" "}
                  <Icon name="alert-circle" /> {errorVal}{" "}
                </Alert>
              </div>
            )}
            <Form
              className="form-validation"
              onSubmit={handleSubmit(onFormSubmit)}
            >
              <div className="form-group">
                <div className="form-label-group">
                  <label className="form-label" htmlFor="newpassword">
                    {AppText.newpassword}
                  </label>
                  <TooltipComponent
                    icon="help-fill"
                    iconClass="card-hint"
                    direction="right"
                    id="newpassword"
                    text={PASSWORD_TOOL_TIP}
                  />
                </div>
                <div className="form-control-wrap">
                  <a
                    href="#password"
                    onClick={(ev) => {
                      ev.preventDefault();
                      setNewPassState(!newPassState);
                    }}
                    className={`form-icon lg form-icon-right passcode-switch ${
                      newPassState ? "is-hidden" : "is-shown"
                    }`}
                  >
                    <Icon name="eye" className="passcode-icon icon-hide"></Icon>
                    <Icon
                      name="eye-off"
                      className="passcode-icon icon-show"
                    ></Icon>
                  </a>
                  <input
                    type={newPassState ? "text" : "password"}
                    id="newpassword"
                    name="newpassword"
                    ref={register({
                      required: true,
                    })}
                    className={`form-control-lg form-control ${
                      newPassState ? "is-hidden" : "is-shown"
                    }`}
                  />
                  {errors.newpassword &&
                    errors.newpassword.type === "required" && (
                      <span className="invalid">This field is required</span>
                    )}
                </div>
              </div>
              <div className="form-group">
                <Button
                  type="submit"
                  color="primary"
                  size="lg"
                  className="btn-block"
                >
                  {loading ? <Spinner size="sm" color="light" /> : "Continue"}
                </Button>
              </div>
            </Form>
            {location.state.type !== NewPasswordType.changePassword && (
              <div className="form-note-s2 text-center pt-4">
                <Link to={`${process.env.PUBLIC_URL}/auth-login`}>
                  <strong>Return to sign in</strong>
                </Link>
              </div>
            )}
          </PreviewCard>
        </Block>
        <div className="gap-100px" />
        <AuthFooter />
      </PageContainer>
    </React.Fragment>
  );
};
export default CreatePassword;
