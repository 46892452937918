let autoComplete;
export const loadScript = (url, callback) => {
  let script = document.createElement("script");
  script.type = "text/javascript";
  if (script.readyState) {
    script.onreadystatechange = function () {
      if (script.readyState === "loaded" || script.readyState === "complete") {
        script.onreadystatechange = null;
        callback();
      }
    };
  } else {
    script.onload = () => callback();
  }
  script.src = url;
  document.getElementsByTagName("head")[0].appendChild(script);
};

export function handleScriptLoad(updateQuery, autoCompleteRef) {
  autoComplete = new window.google.maps.places.Autocomplete(
    autoCompleteRef.current,
    { types: ["address"], componentRestrictions: { country: "us" } }
  );
  autoComplete.setFields([
    "address_components",
    "formatted_address",
    "geometry",
  ]);
  autoComplete.addListener("place_changed", () =>
    handlePlaceSelect(updateQuery)
  );
}

async function handlePlaceSelect(updateQuery) {
  const addressObject = autoComplete.getPlace();
  var data = {
    fullAddress: addressObject.formatted_address,
    lat: addressObject.geometry.location.lat(),
    lng: addressObject.geometry.location.lng(),
  };

  for (const component of addressObject.address_components) {
    const componentType = component.types[0];
    switch (componentType) {
      case "country":
        data["country"] = component.long_name;
        break;
      case "administrative_area_level_1":
        data["state"] = component.short_name;
        break;
      case "postal_code":
        data["zipCode"] = component.long_name;
        break;
      case "locality":
        data["city"] = component.long_name;
        break;
      case "sublocality":
      case "sublocality_level_1":
        data["subcity"] = component.long_name;
        break;
      default:
        break;
    }
  }
  updateQuery(data);
}
