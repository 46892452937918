import React, { useEffect } from "react";
import { Button, CardText } from "reactstrap";
import {
  Block,
  FormInput,
  FormInputType,
} from "../../../../../components/Component";
import {
  CURRENCY_CODE,
  DATE_FORMAT,
  covertToNumberOnly,
  numberFormatter,
  numberFormatterWithDecimal,
} from "../../../../../utils";
import { TenantPaymentWizardContext } from "./TenantRentPayment";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom";
import { fetchTenantPaymentDetails } from "../../TanantData";
import moment from "moment";

export const TenantPaymentDetails = (props) => {
  const { data, updateData } = React.useContext(TenantPaymentWizardContext);
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (location.state?.history) {
      updateData("paymentInvocieId", location.state?.history.id);
      updateData("paymentInvocieDate", location.state?.history.date);
      updateData("paymentRemain", location.state?.history);
      updateData(
        "totalAmountToPay",
        numberFormatter("", "" + location.state?.history.amount)
      );
      props.jump(3);
    } else if (location.state?.duePayment) {
      getRemainingPaymentDetails(location.state.duePayment?.unitTenantId);
    } else {
      history.goBack();
    }
  }, []);

  function getRemainingPaymentDetails(id) {
    fetchTenantPaymentDetails({ unitTenantId: id }, (result, error) => {
      if (error === null) {
        updateData("paymentRemain", result);
        updateData("unitTenantId", id);
        if (!data?.paymentAmount) {
          updateData("paymentAmount", "" + (result.amountNeedToPay ?? 0));
        }
      }
    });
  }

  return (
    <Block>
      <div className="invoice-bills">
        <div className="table-responsive">
          <table className="table table-striped">
            <thead>
              <tr>
                <th className="w-150px">Date</th>
                <th className="w-60">Charge</th>
                <th></th>
                <th></th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{moment().format(DATE_FORMAT)}</td>
                <td>Rent</td>
                <td></td>
                <td></td>
                <td>
                  {numberFormatterWithDecimal(
                    CURRENCY_CODE,
                    "" + (data?.paymentRemain?.amountNeedToPay ?? 0)
                  )}
                </td>
              </tr>
            </tbody>
            <tfoot className="">
              <tr className=" h-40">
                <td colSpan="2"></td>
                <td className="fw-bold text-end" colSpan="2">
                  Total balance
                </td>
                <td className="fw-bold">
                  {numberFormatterWithDecimal(
                    CURRENCY_CODE,
                    "" + (data?.paymentRemain?.amountNeedToPay ?? 0)
                  )}
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
        <div>
          <span className="d-flex align-items-center  h-40 ">
            <span className="fw-bold me-1">Payment account:</span>
            {data?.paymentRemain?.bank ? (
              <CardText className="text">
                {data?.paymentRemain?.bank?.name} {"**** **** **** "}{" "}
                {data?.paymentRemain?.bank?.last4}
              </CardText>
            ) : (
              // eslint-disable-next-line jsx-a11y/anchor-is-valid
              <a
                href={`${process.env.PUBLIC_URL}/user-profile-payment`}
                className="text-primary"
              >
                Connect bank account
              </a>
            )}
          </span>
          <p className="m-0 h-40 d-flex align-items-center ">
            {" "}
            <span className="fw-bold me-1">Amount due: </span>{" "}
            {numberFormatterWithDecimal(
              CURRENCY_CODE,
              "" + (data?.paymentRemain?.amountNeedToPay ?? 0)
            )}
          </p>
          {/* <div className="d-flex align-items-center h-40 form-validation">
            <p className="fw-bold me-1 m-0 white-space-nowrap">
              Payment amount:
            </p>
            <div className="form-control-wrap">
              <div className="input-group ">
                <span className="input-group-text">$</span>
                <input
                  type="number"
                  id="fv-full-name"
                  value={data?.paymentAmount ?? 0}
                  name="paymentAmount"
                  className="form-control w-max-150px"
                  onChange={(e) => updateData("paymentAmount", e.target.value)}
                />
              </div>
            </div>
          </div> */}
          <div className="d-flex align-items-center h-40 form-validation">
            <div className="form-group">
              <div className="form-control-wrap">
                <FormInput
                  labelGroupClass="m-0 "
                  baseClass={"d-flex align-items-center"}
                  labelClass={"fw-bold me-1 m-0 white-space-nowrap text-base"}
                  label={"Payment amount:"}
                  type={FormInputType.amount}
                  value={data?.paymentAmount}
                  onTextChange={(text) =>
                    updateData("paymentAmount", text)
                  }
                />
              </div>
            </div>
          </div>
        </div>
        {(data?.paymentRemain?.amountNeedToPay ?? 0) -
          parseFloat(covertToNumberOnly(data?.paymentAmount ?? 0)) <
          0 && (
          <span className="invalid">
            Don’t enter more than account balance value
          </span>
        )}
        <div className="nk-notes ff-italic fs-12px text-soft mt-5">
          <div className="form-group d-flex align-items-center">
            <Button
              type="button"
              color="gray"
              size="md"
              className="btn-dim me-4"
              onClick={() => {
                history.push(`${process.env.PUBLIC_URL}/`);
              }}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              color="primary"
              disabled={
                !(
                  parseFloat(covertToNumberOnly(data?.paymentAmount ?? 0)) !==
                    0.0 &&
                  parseFloat(covertToNumberOnly(data?.paymentAmount ?? 0)) >
                    0 &&
                  parseFloat(covertToNumberOnly(data?.paymentAmount ?? 0)) -
                    (data?.paymentRemain?.amountNeedToPay ?? 0) <=
                    0 &&
                  data?.paymentRemain?.bank
                )
              }
              size="md"
              // defaultValue={data?.paymentAmount}
              className="me-2"
              onClick={() => props.next()}
            >
              Continue
            </Button>
          </div>
        </div>
      </div>
    </Block>
  );
};
