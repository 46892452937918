import { apiService } from "../../../../../services/api";
import { APIServicePath } from "../../../../../services/api/api-path";



export function setupAutoPayment(params, callback) {
    apiService
      .postCall(APIServicePath.setupAutoPayment, params)
      .then((auth) => {
        callback(auth, null);
      })
      .catch((error) => {
        callback(null, error);
      });
  }
  