import React, { useEffect, useState, useContext } from "react";
import Head from "../../../layout/head/Head";
import ContentAlt from "../../../layout/content/ContentAlt";
import ChatBody from "./ChatBody";
import { Icon, UserAvatar } from "../../../components/Component";
import { UncontrolledDropdown } from "reactstrap";
import { ChatContext } from "./ChatContext";
import { ChatAsideBody } from "./ChatAsideBody";
import { Storage } from "../../../services/storage/storage";
import { findUpper } from "../../../utils/Utils";
import ChatService, {
  connectWithChat,
} from "../../../services/chat/chatservice";
import LoadingComponent from "../../../layout/spinner/LoadingSpinner";

var query = null;
export const Chat = ({ chatId }) => {
  const [selectedChannel, setSelectedChannel] = useState(null);
  const [filteredChatList, setFilteredChatList] = useState([]);
  const [filterText, setFilterText] = useState("");
  const [mobileView, setMobileView] = useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [errorVal, setError] = React.useState(false);
  const [isInitScreen, setIsInitScreen] = React.useState(true);

  const { chatState, currentUserChatIdState } = useContext(ChatContext);
  const [chat, setChat] = chatState;
  const [currentUserChatId, setCurrentUserChatId] = currentUserChatIdState;

  const user = Storage.getLoggedInUser()?.user;

  // Filtering users by search
  useEffect(() => {
    if (filterText !== "") {
      const filteredObject = chat.filter((item) => {
        return item.name.toLowerCase().includes(filterText.toLowerCase());
      });
      setFilteredChatList([...filteredObject]);
    } else {
      setFilteredChatList([...chat]);
    }
  }, [filterText, setFilteredChatList, chat]);

  useEffect(() => {
    query = null;
    if (ChatService.instance.isConnected() === false) {
      connectWithChat((status, error) => {
        if (status) {
          loadChat();
        }
      });
    } else {
      loadChat();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadChat = () => {
    setCurrentUserChatId(user?.chatId);
    if (chatId && user?.chatId) {
      createChat([user?.chatId, chatId], user?.accountType);
    } else {
      loadChatList(true);
    }
  };

  const onInputChange = (e) => {
    setFilterText(e.target.value);
  };

  const chatItemClick = (channel) => {
    if (selectedChannel === null || selectedChannel.url !== channel?.url) {
      setSelectedChannel(channel);
      if (window.innerWidth < 860) {
        setMobileView(true);
      } else {
        setMobileView(false);
      }
    }
  };

  const loadChatList = (isLoadFromStart = false) => {
    fetchChatList()
      .then((list) => {
        if (isLoadFromStart) {
          setChat(list);
          if (chatId) {
            chatItemClick(selectedChannel);
          } else if (list.length > 0) {
            chatItemClick(list[0]);
          }
        } else {
          setChat([...chat, ...list]);
        }
        setIsLoading(false);
        setIsInitScreen(false);
      })
      .catch((error) => {
        setError(true);
        setIsInitScreen(false);
      });
  };

  const createChat = (ids) => {
    ChatService.instance
      .createPersonalChat(ids, ids)
      .then((groupChannel) => {
        setSelectedChannel(groupChannel);
        loadChatList();
      })
      .catch((error) => {
        console.log("error: ", error);
        setIsInitScreen(false);
      });
  };

  async function fetchChatList() {
    return new Promise((resolve, reject) => {
      if (query === null) {
        query = ChatService.instance.getChannelListQuery(user?.accountType);
      }
      ChatService.instance
        .getChannelList(query)
        .then((result) => {
          resolve(result);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  return (
    <React.Fragment>
      <Head title="Chat"></Head>
      <ContentAlt>
        <div className="nk-chat">
          <div className={`nk-chat-aside ${mobileView ? "has-aside" : ""}`}>
            <div className="nk-chat-aside-head">
              <div className="nk-chat-aside-user">
                <UncontrolledDropdown>
                  <UserAvatar
                    text={findUpper(
                      (user?.firstName ?? "") + " " + (user?.lastName ?? "")
                    )}
                    theme="white"
                    className="sm"
                    image={user?.avatar?.thumbUrl}
                  />
                  <div className="title">Chats</div>
                </UncontrolledDropdown>
              </div>
            </div>
            <ChatAsideBody
              onInputChange={onInputChange}
              filteredChatList={filteredChatList}
              selectedChannel={selectedChannel}
              setSelectedChannel={setSelectedChannel}
              chatItemClick={chatItemClick}
            />
          </div>
          <ChatBody
            selectedChannel={selectedChannel}
            setSelectedChannel={setSelectedChannel}
            setMobileView={setMobileView}
            mobileView={mobileView}
            currentUserChatId={currentUserChatId}
            chatType={selectedChannel?.customType}
          />
        </div>
      </ContentAlt>
      <LoadingComponent isLoading={isInitScreen} />
    </React.Fragment>
  );
};

export default Chat;
