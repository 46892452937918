import React, { useEffect, useState } from "react";
import { amenitiesDropdown } from "./DropdownData";
import { RSelect } from "../Component";

export const AmenityDropdownType = {
  property: "PROPERTY",
  unit: "UNIT",
};

export const AmenityDropdown = ({
  value,
  type = AmenityDropdownType.unit,
  isMulti = false,
  isShowAll = false,
  onSelect,
  ...props
}) => {
  const [list, setList] = useState();

  useEffect(() => {
    fetchAmenities();
  }, []);

  const fetchAmenities = () => {
    amenitiesDropdown(type, (data, error) => {
      if (error === null) {
        if (isShowAll === false) {
          setList(data);
        } else {
          let all = {
            id: 0,
            label: "All",
            value: "All",
          };
          setList([all, ...data]);
        }
      }
    });
  };

  return (
    <RSelect
      options={list ?? []}
      isMulti={isMulti}
      placeholder={""}
      value={value ?? (isShowAll ? (list ? list[0] : null) : null)}
      onChange={(e) => {
        if (isShowAll === false) {
          onSelect(e);
        } else {
          e.id === 0 ? onSelect(undefined) : onSelect(e);
        }
      }}
      {...props}
    />
  );
};
