import React, { useEffect, useRef, useState } from "react";
import Head from "../../../layout/head/Head";
import Content from "../../../layout/content/Content";
import {
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  DropdownItem,
  Badge,
} from "reactstrap";
import {
  Block,
  BlockHead,
  BlockBetween,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Button,
  PaginationComponent,
  PreviewAltCard,
  DataTableHead,
  DataTableRow,
  DataTableItem,
  RSelect,
  TooltipComponent,
  UserAvatar,
  BlueText,
} from "../../../components/Component";

import { findUpper, setDeadlineDays } from "../../../utils/Utils";
import { useHistory } from "react-router";
import { createRemoveQuotation, getPMRequestList } from "./RequestData";
import moment from "moment";
import {
  CURRENCY_CODE,
  DATE_FORMAT,
  listAsNewLineString,
  numberFormatter,
  openLinkInNewTab,
  PROJECT_START_FLEXIBLE_TOOL_TIP,
  removeTimeFromServerDateString,
  statusColor,
  StatusType,
  statusTypes,
  toCapitalize,
} from "../../../utils";
import Swal from "sweetalert2";
import { getServices } from "../../auth/AuthData";
import { propertyListAsSelector } from "../properties/PropertyData";
import { RequestListFilter } from "./RequestListFilter";
import LoadingComponent from "../../../layout/spinner/LoadingSpinner";
import exportFromJSON from "export-from-json";

export const RequestList = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemPerPage, setItemPerPage] = useState(20);
  const [sort, setSortState] = useState("desc");
  const [onSearchText, setSearchText] = useState("");
  const [onSearch, setonSearch] = useState(true);
  const [totalItems, setTotalItems] = useState(0);
  const [currentItems, setCurrentItems] = useState([]);
  const [serviceList, setServiceList] = useState([]);
  const [propertyList, setPropertyList] = useState([]);
  const [status, setStatus] = useState("ALL");

  const [selectedServices, setSelectedServices] = useState([]);
  const [selectedProperties, setSelectedProperties] = useState([]);
  const [selectedStartDate, setSelectedStartdate] = useState();
  const [selectedEndDate, setSelectedEnddate] = useState();
  const [isFilterApply, setIsFilterApply] = useState(false);
  const [isInitScreen, setIsInitScreen] = useState(true);

  const dropdownPages = [5, 10, 15, 20];
  const dropDownRef = useRef();

  const toggle = () => setonSearch(!onSearch);
  const history = useHistory();
  const searchInput = useRef(null);

  useEffect(() => {
    loadServiceList();
  }, []);

  useEffect(() => {
    if (currentPage !== 0) {
      loadList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  useEffect(() => {
    if (currentPage === 1) {
      loadList();
    } else {
      setCurrentPage(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemPerPage, sort, onSearchText, status, isFilterApply]);

  useEffect(() => {
    if (searchInput.current && !onSearch) {
      searchInput.current.focus();
    }
  }, [onSearch]);

  function loadList() {
    let sortParams = { field: "id", order: sort };
    let params = {
      pageInfo: { pageIndex: currentPage, itemsPerPage: itemPerPage },
      sorting: sortParams,
    };
    if (onSearchText.length > 0) {
      params["search"] = onSearchText;
    }
    if (status !== "ALL") {
      params["status"] = status.toUpperCase();
    }

    if (isFilterApply) {
      if (selectedStartDate) {
        params["startDate"] = moment(selectedStartDate).format("YYYY-MM-DD");
      }

      if (selectedEndDate) {
        params["endDate"] = moment(selectedEndDate).format("YYYY-MM-DD");
      }

      if (selectedServices.length > 0) {
        params["serviceIds"] = selectedServices.map((item) => item.id);
      }

      if (selectedProperties) {
        params["propertyIds"] = selectedProperties.map((item) => item.id);
      }
    } else {
      resetFilter();
    }

    getPMRequestList(params, (data, error) => {
      if (error === null) {
        setCurrentItems(data.list);
        if (currentPage === 1) {
          setTotalItems(data.pageInfo?.totalItems ?? 0);
        }
      }
      setIsInitScreen(false);
    });
  }

  // function to change the complete a project property
  const removeRequest = (item) => {
    Swal.fire({
      title: "Are you sure?",
      text: `You want to remove ${item.title} request?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, remove it!",
    }).then((result) => {
      if (result.isConfirmed) {
        createRemoveQuotation(item.id, (data, error) => {
          if (error === null) {
            setCurrentItems(
              currentItems.filter((value) => value.id !== item.id)
            );
          }
        });
      }
    });
  };

  function loadServiceList() {
    getServices({}, (list, error) => {
      if (error === null) {
        setServiceList(list);
      }
    });
    propertyListAsSelector(1, 100, (data, error) => {
      if (error === null) {
        setPropertyList(data);
      }
    });
  }

  // submit function to update a new item
  // function that loads the want to editted data
  const onEditClick = (item) => {
    history.push({
      pathname: `${process.env.PUBLIC_URL}/new-request`,
      state: { item: item },
    });
  };

  const onViewClick = (item) => {
    const name = item.title.trim().replace(/\s+/g, "-").toLowerCase();
    history.push(`${process.env.PUBLIC_URL}/request/${item.id}-${name}`);
  };

  function onViewVendorClick(item, event) {
    const name = item.companyName.replace(/\s+/g, "-").toLowerCase();
    let url = `${process.env.PUBLIC_URL}/vendor/${item.id}-${name}`;
    openLinkInNewTab(url, event);
  }

  const onApplyText = (e) => {
    setStatus(e.value);
  };

  function resetFilter() {
    setSelectedProperties([]);
    setSelectedServices([]);
    setSelectedStartdate();
    setSelectedEnddate();
  }

  function exportFile() {
    const exportType = exportFromJSON.types.csv;
    const fileName = "request-data";
    let list = [];
    for (let index = 0; index < currentItems.length; index++) {
      let item = currentItems[index];
      list.push({
        id: "" + item.id,
        title: item.title,
        service: item.services.map((item) => item.name).join(", "),
        property: item.properties.map((item) => item.name).join(", "),
        start: item.startDate
          ? moment(removeTimeFromServerDateString(item.startDate)).format(
            DATE_FORMAT
            )
          : "",
        end: item.endDate
          ? moment(removeTimeFromServerDateString(item.endDate)).format(
            DATE_FORMAT
            )
          : "",
        vendor: item.vendors.map((item) => item.companyName).join(", "),
        quote: item.approvedBid ? "$" + item.approvedBid?.price : "",
        budget: item.budget > 0 ? "$" + item.budget : "",
      });
    }
    exportFromJSON({ data: list, fileName: fileName, exportType: exportType });
  }

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <React.Fragment>
      <Head title="Requests"></Head>
      <Content className="bg-blue-dim">
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle page>Requests</BlockTitle>
              {/* <BlockDes className="text-soft">
                <></>
              </BlockDes> */}
            </BlockHeadContent>
            <BlockHeadContent>
              <ul className="nk-block-tools g-3">
                <li>
                  <Button
                    color="light"
                    outline
                    className="btn-white"
                    onClick={() => exportFile()}
                  >
                    <Icon name="download-cloud"></Icon>
                    <span>Export</span>
                  </Button>
                </li>
                <li className="nk-block-tools-opt">
                  <Button
                    color="primary"
                    onClick={(ev) => {
                      ev.preventDefault();
                      history.push({
                        pathname: `${process.env.PUBLIC_URL}/new-request`,
                      });
                    }}
                  >
                    <span>Add request</span>
                  </Button>
                </li>
              </ul>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        <Block className={"card-inner-group"}>
          <div className="card-inner bg-white position-relative card-tools-toggle">
            <div className="card-title-group">
              <div className="card-tools">
                <div className="form-inline flex-nowrap gx-3">
                  <div className="form-wrap flex-grow-1">
                    <RSelect
                      options={statusTypes}
                      className="w-130px"
                      placeholder="Status"
                      defaultValue={statusTypes[0]}
                      onChange={(e) => onApplyText(e)}
                    />
                  </div>
                </div>
              </div>
              <div className="card-tools me-n1">
                <ul className="btn-toolbar gx-1">
                  <li>
                    <a
                      href="#search"
                      onClick={(ev) => {
                        ev.preventDefault();
                        toggle();
                      }}
                      className="btn btn-icon search-toggle toggle-search"
                    >
                      <Icon name="search"></Icon>
                    </a>
                  </li>
                  <li className="btn-toolbar-sep"></li>
                  <li>
                    <ul className="btn-toolbar gx-1">
                      <li>
                        <UncontrolledDropdown ref={dropDownRef}>
                          <DropdownToggle
                            tag="a"
                            className="btn btn-trigger btn-icon dropdown-toggle"
                          >
                            {isFilterApply && (
                              <div className="dot dot-primary"></div>
                            )}
                            <Icon name="filter-alt"></Icon>
                          </DropdownToggle>
                          <RequestListFilter
                            isVisible={
                              dropDownRef.current?.state?.isOpen ?? false
                            }
                            serviceList={serviceList}
                            propertyList={propertyList}
                            filteredServices={selectedServices}
                            filteredProperties={selectedProperties}
                            startDate={selectedStartDate}
                            endDate={selectedEndDate}
                            onFilterApply={(data) => {
                              const {
                                services,
                                properties,
                                startDate,
                                endDate,
                              } = data;
                              setSelectedStartdate(startDate);
                              setSelectedEnddate(endDate);
                              setSelectedServices(services);
                              setSelectedProperties(properties);
                              dropDownRef.current.toggle();
                              if (isFilterApply === true) {
                                setCurrentPage(0);
                                setTimeout(() => {
                                  setCurrentPage(1);
                                }, 200);
                              }
                              setIsFilterApply(true);
                            }}
                            onResetFilter={(e) => {
                              resetFilter();
                              dropDownRef.current.toggle();
                              setIsFilterApply(false);
                            }}
                          />
                        </UncontrolledDropdown>
                      </li>
                      <li>
                        <UncontrolledDropdown>
                          <DropdownToggle
                            color="tranparent"
                            className="btn btn-trigger btn-icon dropdown-toggle"
                          >
                            <Icon name="setting"></Icon>
                          </DropdownToggle>
                          <DropdownMenu end className="dropdown-menu-xs">
                            <ul className="link-check">
                              <li>
                                <span>Show</span>
                              </li>
                              {dropdownPages.map((item, index) => {
                                return (
                                  <React.Fragment key={index}>
                                    <li
                                      className={
                                        itemPerPage === item ? "active" : ""
                                      }
                                    >
                                      <DropdownItem
                                        tag="a"
                                        href="#dropdownitem"
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                          setItemPerPage(item);
                                        }}
                                      >
                                        {item}
                                      </DropdownItem>
                                    </li>
                                  </React.Fragment>
                                );
                              })}
                            </ul>
                            <ul className="link-check">
                              <li>
                                <span>Order</span>
                              </li>
                              <li className={sort === "desc" ? "active" : ""}>
                                <DropdownItem
                                  tag="a"
                                  href="#dropdownitem"
                                  onClick={(ev) => {
                                    ev.preventDefault();
                                    setSortState("desc");
                                    // sortFunc("dsc");
                                  }}
                                >
                                  DESC
                                </DropdownItem>
                              </li>
                              <li className={sort === "asc" ? "active" : ""}>
                                <DropdownItem
                                  tag="a"
                                  href="#dropdownitem"
                                  onClick={(ev) => {
                                    ev.preventDefault();
                                    setSortState("asc");
                                  }}
                                >
                                  ASC
                                </DropdownItem>
                              </li>
                            </ul>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
            <div className={`card-search search-wrap ${!onSearch && "active"}`}>
              <div className="card-body">
                <div className="search-content">
                  <Button
                    className="search-back btn-icon toggle-search active"
                    onClick={() => {
                      setSearchText("");
                      toggle();
                    }}
                  >
                    <Icon name="arrow-left"></Icon>
                  </Button>
                  <input
                    type="text"
                    className="border-transparent form-focus-none form-control"
                    placeholder="Search by request title"
                    value={onSearchText}
                    ref={searchInput}
                    onChange={(e) => setSearchText(e.target.value)}
                  />
                  <Button className="search-submit btn-icon">
                    <Icon name="search"></Icon>
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div className="overflow-x-auto">
            <div className="nk-tb-list nk-tb-ulist bg-white">
              <DataTableHead className="nk-tb-item nk-tb-head">
                <DataTableRow>
                  <span className="sub-text text-secondary">Title</span>
                </DataTableRow>
                <DataTableRow>
                  <span className="sub-text text-secondary">Status</span>
                </DataTableRow>
                <DataTableRow>
                  <span className="sub-text text-secondary">Service</span>
                </DataTableRow>
                <DataTableRow size="xxl">
                  <span className="sub-text text-secondary">Property</span>
                </DataTableRow>
                <DataTableRow>
                  <span className="sub-text text-secondary">Start</span>
                </DataTableRow>
                <DataTableRow size="lg">
                  <span className="sub-text text-secondary">End</span>
                </DataTableRow>
                <DataTableRow size="lg">
                  <span className="sub-text text-secondary">{"Vendor"}</span>
                </DataTableRow>
                <DataTableRow className={"text-end"}>
                  <span className="sub-text text-secondary">{"Quote"}</span>
                </DataTableRow>
                <DataTableRow className={"text-end"}>
                  <span className="sub-text text-secondary">{"Budget"}</span>
                </DataTableRow>
                <DataTableRow className="nk-tb-col-tools text-end">
                  <span className="sub-text text-secondary">{""}</span>
                </DataTableRow>
                <DataTableRow className="nk-tb-col-tools text-end">
                  <></>
                </DataTableRow>
              </DataTableHead>
              {currentItems.length > 0
                ? currentItems.map((item, index) => {
                    var days = item.deadlineAt
                      ? setDeadlineDays(item.deadlineAt)
                      : Number.MAX_SAFE_INTEGER;
                    return (
                      <DataTableItem key={index}>
                        <DataTableRow className="w-max-250px">
                          <BlueText
                            title={item.title}
                            onClick={(ev) => {
                              ev.preventDefault();
                              onViewClick(item);
                            }}
                          />
                        </DataTableRow>
                        <DataTableRow>
                          {(item.totalActiveBids ?? 0) > 0 &&
                          item.status === StatusType.published ? (
                            <Badge
                              className="badge-dim fSize14 p-1 bg-orange-dim text-orange border-0"
                              color={"orange"}
                            >
                              <span>{"Quoted"}</span>
                            </Badge>
                          ) : (
                            <Badge
                              className={`badge-dim fSize14 p-1 bg-${statusColor(
                                item.status
                              )}-dim text-${statusColor(item.status)} border-0`}
                              color={statusColor(item.status)}
                            >
                              <span>{toCapitalize(item.status)}</span>
                            </Badge>
                          )}
                        </DataTableRow>
                        <DataTableRow className="w-max-250px text-secondary">
                          <span>
                            {listAsNewLineString(
                              item?.services?.map((item) => item.name) ?? []
                            )}
                          </span>
                        </DataTableRow>
                        <DataTableRow
                          className="w-max-250px text-secondary"
                          size="xxl"
                        >
                          <span>
                            {listAsNewLineString(
                              item?.properties?.map((item) => item.name) ?? []
                            )}
                          </span>
                        </DataTableRow>

                        <DataTableRow className="text-secondary">
                          <span>
                            {item.startDate
                              ? moment(
                                  removeTimeFromServerDateString(item.startDate)
                                ).format(DATE_FORMAT)
                              : "Not specified"}
                          </span>
                          {item.isStartDateFlexible === 1 && (
                            <TooltipComponent
                              icon="info-fill"
                              iconClass="card-hint"
                              direction="right"
                              id="password"
                              text={PROJECT_START_FLEXIBLE_TOOL_TIP}
                            />
                          )}
                        </DataTableRow>
                        <DataTableRow size="lg" className="text-secondary">
                          <span>
                            {item.endDate
                              ? moment(
                                  removeTimeFromServerDateString(item.endDate)
                                ).format(DATE_FORMAT)
                              : "Not specified"}
                          </span>
                        </DataTableRow>
                        <DataTableRow size="lg" className="text-secondary">
                          {(item.vendors?.length ?? 0) === 0
                            ? "Not confirmed"
                            : item.vendors.map((item, index) => {
                                return (
                                  <React.Fragment key={index}>
                                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                    <BlueText
                                      isShowImage={true}
                                      image={item?.image?.thumbnailUrl}
                                      imageClass={"sq"}
                                      title={item.companyName}
                                      onClick={(ev) => {
                                        ev.preventDefault();
                                        onViewVendorClick(item, ev);
                                      }}
                                    />
                                  </React.Fragment>
                                );
                              })}
                        </DataTableRow>
                        <DataTableRow className="text-end text-secondary">
                          {item.approvedBid ? (
                            <span>
                              {numberFormatter(
                                CURRENCY_CODE,
                                "" + item.approvedBid?.price ?? 0
                              )}
                            </span>
                          ) : (
                            <Badge
                              className="badge-dim fSize14 p-1"
                              color={
                                days === Number.MAX_SAFE_INTEGER
                                  ? "gray"
                                  : days > 5
                                  ? "success"
                                  : days <= 5 && days > 2
                                  ? "warning"
                                  : days <= 2 && "danger"
                              }
                            >
                              <Icon name="clock"></Icon>
                              <span>
                                {item.status === StatusType.draft
                                  ? "Not requested"
                                  : days === Number.MAX_SAFE_INTEGER
                                  ? "In pending"
                                  : days <= 0
                                  ? days === 0
                                    ? "Due Today"
                                    : "Overdue"
                                  : days === 1
                                  ? "Due Tomorrow"
                                  : days + " Days Left"}
                              </span>
                            </Badge>
                          )}
                        </DataTableRow>
                        <DataTableRow className={"text-end text-secondary"}>
                          <span>
                            {item.budget
                              ? numberFormatter(CURRENCY_CODE, "" + item.budget)
                              : "Not specified"}
                          </span>
                        </DataTableRow>
                        <DataTableRow className="nk-tb-col-tools text-end">
                          <></>
                        </DataTableRow>
                        <DataTableRow className="nk-tb-col-tools text-end">
                          <ul className="nk-tb-actions gx-1">
                            <li>
                              <UncontrolledDropdown>
                                <DropdownToggle
                                  tag="a"
                                  className="text-soft dropdown-toggle btn btn-icon btn-trigger"
                                >
                                  <Icon name="more-h"></Icon>
                                </DropdownToggle>
                                <DropdownMenu end>
                                  <ul className="link-list-opt no-bdr">
                                    <li onClick={() => onViewClick(item)}>
                                      <DropdownItem
                                        tag="a"
                                        href="#view"
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                        }}
                                      >
                                        <Icon name="eye"></Icon>
                                        <span>View</span>
                                      </DropdownItem>
                                    </li>
                                    {(item.status === StatusType.published ||
                                      item.status === StatusType.draft) && (
                                      <li onClick={() => onEditClick(item)}>
                                        <DropdownItem
                                          tag="a"
                                          href="#edit"
                                          onClick={(ev) => {
                                            ev.preventDefault();
                                          }}
                                        >
                                          <Icon name="edit"></Icon>
                                          <span>Edit</span>
                                        </DropdownItem>
                                      </li>
                                    )}
                                    <li onClick={() => removeRequest(item)}>
                                      <DropdownItem
                                        tag="a"
                                        href="#markasdone"
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                        }}
                                      >
                                        <Icon name="trash"></Icon>
                                        <span>Remove</span>
                                      </DropdownItem>
                                    </li>
                                  </ul>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </li>
                          </ul>
                        </DataTableRow>
                      </DataTableItem>
                    );
                  })
                : null}
            </div>
          </div>
          <PreviewAltCard className={"border-top"}>
            {totalItems > 0 ? (
              <PaginationComponent
                itemPerPage={itemPerPage}
                totalItems={totalItems}
                paginate={paginate}
                currentPage={currentPage}
              />
            ) : (
              <div className="text-center">
                <span className="text-silent">
                  {"Let's get started. Add your first request!"}
                </span>
              </div>
            )}
          </PreviewAltCard>
        </Block>
      </Content>
      <LoadingComponent isLoading={isInitScreen} />
    </React.Fragment>
  );
};

export default RequestList;
