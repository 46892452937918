import classNames from "classnames";
import React from "react";
import { AppText } from "../../utils";

export const FileSizeText = ({ className, ...props }) => {
  var rowClass = classNames({
    [`${className}`]: className,
  });

  rowClass = rowClass + " text-gray fs-10px m-1"
  return (
    <React.Fragment>
      <span className={rowClass}>
        {AppText.maxFileSize}
      </span>
    </React.Fragment>
  );
};


