import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiService } from "../api";

const userSlice = createSlice({
  name: "user",
  initialState: {
    loading: false,
    currentUser: null,
    activityTime: new Date(),
  },
  reducers: {
    updateActivty(state, action) {
      state.activityTime = new Date();
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadCurrentUser.pending, (state, action) => {
      state.loading = true
    });
    builder.addCase(loadCurrentUser.fulfilled, (state, action) => {
      state.currentUser = action.payload;
      state.activityTime = new Date();
      state.loading = false
    });
    builder.addCase(loadCurrentUser.rejected, (state, action) => {
      state.loading = false
    });
  },
});

export const { updateActivty } = userSlice.actions;
export default userSlice.reducer;

// THUNK-------------------->
export const loadCurrentUser = createAsyncThunk("current-user-api", () => {
  let data = apiService.fetchCurrentUser();
  return data;
});
