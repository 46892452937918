import React, { useEffect, useState } from "react";
import { DropdownMenu } from "reactstrap";
import { Col, RSelect, Row } from "../../../components/Component";
import DatePicker from "react-datepicker";

export const VendorRequestListFilter = ({
  isVisible,
  serviceList,
  propertyTypes,
  filteredServices,
  filteredPropertyTypes,
  startDate,
  endDate,
  currentProjectStatus,
  onFilterApply,
  onResetFilter,
}) => {
  const [selectedServices, setSelectedServices] = useState([]);
  const [selectedPropertyTypes, setSelectedPropertyTypes] = useState([]);
  const [selectedStartDate, setSelectedStartDate] = useState();
  const [selectedEndDate, setSelectedEndDate] = useState();
  const [projectStatus, setProjectStatus] = useState();

  useEffect(() => {
    if (isVisible) {
      setSelectedServices(filteredServices);
      setSelectedPropertyTypes(filteredPropertyTypes);
      setSelectedStartDate(startDate);
      setSelectedEndDate(endDate);
      setProjectStatus(currentProjectStatus);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible]);

  return (
    <DropdownMenu
      end
      className="filter-wg dropdown-menu-xl"
      style={{ overflow: "visible" }}
    >
      <div className="dropdown-head">
        <span className="sub-title dropdown-title">Filter</span>
      </div>
      <div className="dropdown-body dropdown-body-rg">
        <Row className="gx-6 gy-3">
          <Col size="12">
            <div className="form-group">
              <label className="overline-title overline-title-alt">
                Service
              </label>
              <RSelect
                options={serviceList}
                isMulti
                placeholder="Select services"
                value={selectedServices}
                onChange={(e) => setSelectedServices(e)}
              />
            </div>
          </Col>
          <Col size="12">
            <div className="form-group">
              <label className="overline-title overline-title-alt">
                Property
              </label>
              <RSelect
                options={propertyTypes}
                isMulti
                placeholder="Select Property"
                value={selectedPropertyTypes}
                onChange={(e) => setSelectedPropertyTypes(e)}
              />
            </div>
          </Col>
          <Col size="6">
            <div className="form-group">
              <label className="overline-title overline-title-alt">Start</label>
              <DatePicker
                selected={selectedStartDate}
                className="form-control"
                popperPlacement="top-end"
                onChange={(date) => {
                  if (selectedEndDate) {
                    var Difference_In_Time =
                      selectedEndDate.getTime() - date.getTime();
                    var Difference_In_Days =
                      Difference_In_Time / (1000 * 3600 * 24);

                    if (Difference_In_Days < 0) {
                      setSelectedEndDate();
                    }
                    setSelectedStartDate(date);
                  } else {
                    setSelectedStartDate(date);
                  }
                }}
              />
            </div>
          </Col>
          <Col size="6">
            <div className="form-group">
              <label className="overline-title overline-title-alt">End</label>
              <DatePicker
                selected={selectedEndDate}
                className="form-control"
                popperPlacement="top-end"
                onChange={(date) => {
                  if (selectedStartDate) {
                    var Difference_In_Time =
                      date.getTime() - selectedStartDate.getTime();
                    var Difference_In_Days =
                      Difference_In_Time / (1000 * 3600 * 24);
                    if (Difference_In_Days > 0) {
                      setSelectedEndDate(date);
                    }
                  } else {
                    setSelectedEndDate(date);
                  }
                }}
              />
            </div>
          </Col>
          <Col xl="12">
            <div className="form-group">
              <label className="overline-title overline-title-alt">
                Current project status
              </label>
              <ul className="custom-control-group g-3 align-center">
                <li>
                  <div className="custom-control custom-control-sm custom-radio">
                    <input
                      type="radio"
                      checked={projectStatus === "READY_TO_HIRE"}
                      className="custom-control-input"
                      id="ready-to-hire-1"
                      name="projectStatus"
                      value="READY_TO_HIRE"
                      onChange={(event) => setProjectStatus(event.target.value)}
                    />

                    <label
                      className="custom-control-label fSize14"
                      htmlFor="ready-to-hire-1"
                    >
                      Ready to hire
                    </label>
                  </div>
                </li>
                <li>
                  <div className="custom-control custom-control-sm custom-radio">
                    <input
                      type="radio"
                      checked={projectStatus === "PLANNING_&_BUDGETING"}
                      className="custom-control-input"
                      id="planning-and-budgeting-1"
                      name="projectStatus"
                      value="PLANNING_&_BUDGETING"
                      onChange={(event) => setProjectStatus(event.target.value)}
                    />
                    <label
                      className="custom-control-label fSize14"
                      htmlFor="planning-and-budgeting-1"
                    >
                      Planning & budgeting
                    </label>
                  </div>
                </li>
              </ul>
            </div>
          </Col>
          <Col size="12">
            <div className="form-group">
              <button
                type="button"
                className="btn btn-primary"
                onClick={(e) => {
                  e.preventDefault();
                  if (
                    selectedServices.length === 0 &&
                    selectedPropertyTypes.length === 0 &&
                    selectedStartDate === undefined &&
                    selectedEndDate === undefined &&
                    projectStatus === undefined
                  ) {
                    onResetFilter();
                  } else {
                    let data = {
                      services: selectedServices,
                      propertyTypes: selectedPropertyTypes,
                      startDate: selectedStartDate,
                      endDate: selectedEndDate,
                      projectStatus: projectStatus,
                    };
                    onFilterApply(data);
                  }
                }}
              >
                Filter
              </button>
            </div>
          </Col>
        </Row>
      </div>
      <div className="dropdown-foot between">
        <a
          href="#reset"
          className="clickable"
          onClick={(ev) => {
            ev.preventDefault();
            onResetFilter();
          }}
        >
          Reset Filter
        </a>
      </div>
    </DropdownMenu>
  );
};
