import React from "react";
import Button from "./Button";
import { Spinner } from "reactstrap";

export const ActionButton = ({ title, size, loading, disabled, ...props }) => {
  return (
    <Button
      size={size}
      className={`${
        disabled
          ? "btn-light"
          : size
          ? `custom-solid-btn-${size}`
          : "custom-solid-btn-sm"
      }` + ` ${props.className ? props.className : ''}`}
      color={disabled ? "light" : "primary"}
      disabled={loading || disabled}
      onClick={props.onClick}
    >
      {loading ? <Spinner size="sm" color="light" /> : title}
    </Button>
  );
};

export const CancelButton = ({ title = "Cancel", ...props }) => {
  return (
    <Button onClick={props.onClick} className="link link-light">
      {title}
    </Button>
  );
};
