import React, { useEffect, useState } from "react";
import { RSelect } from "../Component";

export const PayonDropdown = ({
  value,
  start,
  end,
  onSelect,
  isShowNone,
  ...props
}) => {
  const [list, setList] = useState();

  useEffect(() => {
    if (start > 0 && end >= 0) {
      prepareValues();
    }
  }, [start, end]);

  const prepareValues = () => {
    let items = [];

    let loopCount =
      end === 0 ? 27 : start < end ? end - start : 28 - start + end;
    for (let index = 0; index <= loopCount; index++) {
      let item = start + index;
      if (item > 28) {
        item = item - 28;
      }
      items.push({
        label: "" + item,
        value: item,
      });
    }

    if (isShowNone) {
      items.splice(0, 0, { label: "None", value: 0 });
    }
    setList(items);
  };

  return (
    <RSelect
      options={list ?? []}
      placeholder={""}
      value={value}
      onChange={(e) => {
        onSelect(e);
      }}
      {...props}
    />
  );
};
