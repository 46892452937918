import React from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { Col, Form } from "reactstrap";
import { AltitudeModal } from "../../../components/Component";
import { useEffect } from "react";
import { fetchSavePropertyContact } from "./PropertyData";
import {
  AppText,
  COUNTRY_CODE,
  covertToNumberOnly,
  formatPhoneNumber,
  isValidEmail,
} from "../../../utils";

const AddContact = ({
  isOpen,
  isEdit,
  propertyDetails,
  data,
  item,
  onSave,
  onCancel,
}) => {
  const [formData, setFormData] = useState({});
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [errorVal, setError] = useState();
  const [loading, setLoading] = useState(false);
  const { errors, register, handleSubmit } = useForm();
  const [IsDataError, setIsDataError] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setFormData(item);
    } else {
      resetForm();
    }
  }, [item]);

  const onInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value?.trim() });
  };

  const resetForm = () => {
    setFormData({});
    setLoading(false);
    setSelectedAddress(null);
    setIsDataError(false);
    setError();
  };

  const onFormCancel = () => {
    resetForm();
    onCancel();
  };

  const onFormSubmit = () => {
    var isError = false;
    if (
      (formData?.firstName?.length ?? 0) === 0 ||
      (formData?.lastName?.length ?? 0) === 0 ||
      (formData?.role?.length ?? 0) === 0 ||
      (formData?.email?.length ?? 0) === 0 ||
      !isValidEmail(formData?.email) ||
      (formData?.phone?.length ?? 0) !== 10
    ) {
      isError = true;
      setIsDataError(true);
    }

    if (!isError) {
      callSavePropertyContact();
    }
  };
  // API------------------------------------

  const callSavePropertyContact = () => {
    setLoading(true);
    let params = {
      id: isEdit ? item.id : null,
      propertyId: propertyDetails?.id,
      firstName: formData?.firstName,
      lastName: formData?.lastName,
      role: formData?.role,
      email: formData?.email,
      phone: formData?.phone,
    };
    fetchSavePropertyContact(params, (data, error) => {
      if (error === null) {
        onSave();
        onFormCancel();
      } else {
        setError(error?.message);
      }
      setLoading(false);
    });
  };

  return (
    <React.Fragment>
      <AltitudeModal
        size={"md"}
        isShowFooter={true}
        isOpen={isOpen}
        loading={loading}
        toggle={() => onFormCancel()}
        title={isEdit ? "Update contacts" : "Add contacts"}
        onSave={() => onFormSubmit()}
        error={errorVal}
      >
        <Form className="row gy-4" autoComplete="off">
          <Col md="6">
            <div className="form-group">
              <label className="form-label">First name</label>
              <input
                type="text"
                name="firstName"
                value={formData?.firstName ?? ""}
                onChange={(e) => onInputChange(e)}
                className="form-control"
                maxLength={30}
              />

              {IsDataError &&
                (formData?.firstName?.length === 0 ||
                  formData?.firstName === undefined) && (
                  <span className="invalid">This field is required</span>
                )}
            </div>
          </Col>
          <Col md="6">
            <div className="form-group">
              <label className="form-label">Last name</label>
              <input
                type="text"
                name="lastName"
                value={formData?.lastName ?? ""}
                onChange={(e) => onInputChange(e)}
                className="form-control"
                maxLength={30}
              />

              {IsDataError &&
                (formData?.lastName?.length === 0 ||
                  formData?.lastName === undefined) && (
                  <span className="invalid">This field is required</span>
                )}
            </div>
          </Col>
          <Col md="6">
            <div className="form-group">
              <label className="form-label">Role</label>
              <input
                type="text"
                name="role"
                value={formData?.role ?? ""}
                onChange={(e) => onInputChange(e)}
                className="form-control"
                maxLength={30}
              />
              {IsDataError &&
                (formData?.role?.length === 0 ||
                  formData?.role === undefined) && (
                  <span className="invalid">This field is required</span>
                )}
            </div>
          </Col>
          <Col md="6">
            <div className="form-group">
              <label className="form-label">Email</label>
              <div className="form-control-wrap">
                <input
                  type="text"
                  name="email"
                  value={formData?.email ?? ""}
                  onChange={(e) => onInputChange(e)}
                  className="form-control"
                  maxLength={30}
                />
                {IsDataError &&
                  (formData?.email?.length === 0 ||
                    formData?.email === undefined) && (
                    <span className="invalid">This field is required</span>
                  )}

                {IsDataError &&
                  formData?.email &&
                  !isValidEmail(formData?.email) && (
                    <span className="invalid">{AppText.invalidEmail}</span>
                  )}
              </div>
            </div>
          </Col>
          <Col md="6">
            <div className="form-group">
              <label className="form-label">Phone</label>
              <div className="form-control-wrap">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text">{COUNTRY_CODE}</span>
                  </div>
                  <input
                    type="text"
                    name="phone"
                    value={formatPhoneNumber("", formData?.phone)}
                    maxLength={14}
                    minLength={14}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        [e.target.name]: covertToNumberOnly(e.target.value),
                      });
                    }}
                    className="form-control"
                  />
                </div>
              </div>
              {IsDataError &&
                (formData?.phone?.length === 0 ||
                  formData?.phone === undefined) && (
                  <span className="invalid">This field is required</span>
                )}

              {IsDataError &&
                formData?.phone &&
                (formData?.phone?.length ?? 0) !== 10 && (
                  <span className="invalid">{AppText.invalidMobile}</span>
                )}
            </div>
          </Col>
        </Form>
      </AltitudeModal>
    </React.Fragment>
  );
};

export default AddContact;
