import React from "react";
import { Badge } from "reactstrap";
import { MaintenanceRequestStatusType, toCapitalize } from "../../utils";

const StatusBadge = ({ className, color, text, ...props }) => {
  const colorSelect = (Text) => {
    switch (Text) {
      case MaintenanceRequestStatusType.open:
        return "orange";
      case MaintenanceRequestStatusType.inprogress:
        return "teal";
      case MaintenanceRequestStatusType.closed:
        return "warning";
      default:
        break;
    }
  };
  let selectedColor = colorSelect(text);

  return (
    <Badge
      className={`badge-dim fSize14 p-1 bg-${selectedColor}-dim text-${selectedColor} border-0 badge bg-${selectedColor} ${className ? className : ""}`}
      color={selectedColor}
    >
      <span>
        {toCapitalize(text ?? "")
          .split("_")
          .join(" ")}
      </span>
    </Badge>
  );
};

export default StatusBadge;
