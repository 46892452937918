import React, { useState } from "react";
import DataTable from "react-data-table-component";
import { DataTablePagination, PreviewAltCard } from "../Component";

const CustomCheckbox = React.forwardRef(({ onClick, ...rest }, ref) => (
  <div className="custom-control custom-control-sm custom-checkbox notext">
    <input
      id={rest.name}
      type="checkbox"
      className="custom-control-input"
      ref={ref}
      onClick={onClick}
      {...rest}
    />
    <label className="custom-control-label" htmlFor={rest.name} />
  </div>
));

const ReactDataTable = ({
  data,
  columns,
  pagination,
  actions,
  className,
  totalItemCount,
  itemPerPage,
  selectableRows,
  onRowClicked,
  expandableRows,
  noDataNode,
  paginationStyle,
}) => {
  const [rowsPerPageS] = useState(10);

  const customStyles = {
    table: {
      style: {
        overflow: "visible",
      },
    },
    tableWrapper: {
      style: {
        display: "table",
        overflow: "visible",
      },
    },
    responsiveWrapper: {
      style: {},
    },
  };

  return (
    <DataTable
      data={data}
      customStyles={customStyles}
      columns={columns}
      className={className}
      selectableRows={selectableRows}
      noHeader={false}
      noTableHead={false}
      onRowClicked={onRowClicked}
      selectableRowsComponent={CustomCheckbox}
      noDataComponent={
        noDataNode ? (
          noDataNode
        ) : (
          <div className="p-2">There are no records found</div>
        )
      }
      sortIcon={
        <div>
          <span>&darr;</span>
          <span>&uarr;</span>
        </div>
      }
      pagination={pagination}
      paginationComponent={({
        currentPage,
        rowsPerPage,
        rowCount,
        onChangePage,
        onChangeRowsPerPage,
      }) => (
        <PreviewAltCard
          className={
            "remove-shadow " + (paginationStyle ? paginationStyle : "")
          }
        >
          <DataTablePagination
            customItemPerPage={itemPerPage  ?? rowsPerPageS}
            itemPerPage={rowsPerPage}
            totalItems={totalItemCount}
            paginate={onChangePage}
            currentPage={currentPage}
            onChangeRowsPerPage={onChangeRowsPerPage}
            itemCount={false}
          />
        </PreviewAltCard>
      )}
    ></DataTable>
  );
};

export default ReactDataTable;
