import { RSelect } from "../Component";
import React, { useEffect, useState } from "react";
import { fetchBankList, fetchTenantBankList } from "./DropdownData";
import { UserAccountType } from "../../utils/Utils";

export const BankDropdown = ({
  value,
  isMulti = false,
  isShowAll = false,
  onSelect,
  placeholder,
  accountType = UserAccountType.manager,
  selectedId,
  ...props
}) => {
  const [list, setList] = useState();
  useEffect(() => {
    if(accountType === UserAccountType.manager) {
      fetchBanks();
    } else if(accountType === UserAccountType.tenant) {
      fetchTenantBanks();
    }
  }, []);

  useEffect(() => {
    if(selectedId && !value) {
      let bank = list.find(item => item.id === selectedId);
      bank && onSelect(bank);
    }
  }, [list]);


  const fetchBanks = () => {
    fetchBankList((data, error) => {
      if (error === null) {
        if (isShowAll === false) {
          setList(data);
        } else {
          let all = {
            id: 0,
            label: "All",
            value: "All",
          };
          setList([all, ...data]);
        }
      }
    });
  };

  const fetchTenantBanks = () => {
    fetchTenantBankList((data, error) => {
      if (error === null) {
        if (isShowAll === false) {
          setList(data);
        } else {
          let all = {
            id: 0,
            label: "All",
            value: "All",
          };
          setList([all, ...data]);
        }
      }
    });
  };

  return (
    <RSelect
      options={list ?? []}
      isMulti={isMulti}
      placeholder={placeholder}
      value={value ?? (isShowAll ? (list ? list[0] : null) : null)}
      onChange={(e) => {
        if (isShowAll === false) {
          onSelect(e);
        } else {
          e.id === 0 ? onSelect(undefined) : onSelect(e);
        }
      }}
      {...props}
    />
  );
};
