import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { Alert, Spinner } from "reactstrap";
import { Block, Icon, PreviewCard } from "../../components/Component";
import PageContainer from "../../layout/page-container/PageContainer";
import { UserAccountType } from "../../utils/Utils";
import { verifyEmailToken } from "./AuthData";
import AuthFooter from "./AuthFooter";
import Logo from "../../images/logo.svg";
import LogoDark from "../../images/logo-dark.svg";
import { Storage } from "../../services/storage/storage";

const VerifyEmail = (props) => {
   
    const [loading, setLoading] = useState();
    const history = useHistory();
    const [errorVal, setError] = useState("");
    const [isShowSuccessMessage, setIsShowSuccessMessage] = useState(false);

    useEffect(() => {
        let path = props.location.search.replace("?email=", "");
        let list = path.split("&tkn=");
      if (list.length === 2) {
        veifyEmail(list[0], list[1]);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    
    function checkAndLoad() {
        window.history.pushState(
          `${process.env.PUBLIC_URL ? process.env.PUBLIC_URL : "/"}`,
          "auth-login",
          `${process.env.PUBLIC_URL ? process.env.PUBLIC_URL : "/"}`
        );
        window.location.reload();
    }

    function veifyEmail(email, token) {
        let params = {email: email, token: token};
        setLoading(true);
        verifyEmailToken(params, (data, error) => {
            if (error === null) {
              setIsShowSuccessMessage(true);
              setTimeout(() => {
                if (
                  data.user.isVendorProfileSetup === false &&
                  data.user.accountType === UserAccountType.vendor
                ) {
                  setLoading(false);
                  history.push(`${process.env.PUBLIC_URL}/vendor-setup`);
                } else {
                  Storage.setLastSignedInTime(Date.now());
                  Storage.setIsStaySignedIn(false);
                  checkAndLoad();
                }
              }, 1500);
            } else {
              setLoading(false);
              setError(error.message);
            }
      });
    }
    return (
        <PageContainer>
        {loading ? (
          <>
           <div className="spinner">
              <Spinner className="spinner-border-md" color="primary" />
            </div>
          {isShowSuccessMessage && (
          <div className="d-flex aligns-items-center justify-content-center">
            <Alert className="alert-icon" color="success">
              <Icon name="check-circle" />
              <strong>The email for your account has been successfully confirmed.</strong>
            </Alert>
          </div>
        )}
          </>
           

        ) : (
          <>
          <Block className="nk-block-middle nk-auth-body  wide-xs">
          <div className="brand-logo pb-4 text-left mx-4">
            <Link to={process.env.PUBLIC_URL + "/"} className="logo-link">
              <img
                className="logo-light logo-img logo-img-lg"
                src={Logo}
                alt="logo"
              />
              <img
                className="logo-dark logo-img logo-img-lg"
                src={LogoDark}
                alt="logo-dark"
              />
            </Link>
          </div>
          <PreviewCard className="card-bordered" bodyClass="card-inner-lg">
            
            {errorVal && (
              <div className="mb-3">
                {errorVal === "Your email is already verified." ? (
                <Alert className="alert-icon" color="success">
                <Icon name="check-circle" />
                <strong>{errorVal}</strong>
              </Alert>
                ) : (
                  <Alert color="danger" className="alert-icon">
                  {" "}
                  <Icon name="alert-circle" /> {errorVal} {" "}
                </Alert>
                )}
                
              </div>
            )}
            <div className="form-note-s2 text-center pt-4">
              <Link to={`${process.env.PUBLIC_URL}/auth-login`}>
                <strong>Return to sign in</strong>
              </Link>
            </div>
          </PreviewCard>
        </Block>
        <div className="gap-100px" />
        <AuthFooter />
          </>
        )}
        </PageContainer>
    );
}

export default VerifyEmail;