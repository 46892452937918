import React, { useEffect } from "react";
import { Icon, PaymentChargesFilter } from "../Component";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import { useRef } from "react";
import { useState } from "react";

const Toolbar = ({
  tenantId,
  textSearch,
  isFilterApply,
  selectedFilterData,
  onFilterApply,
  onResetFilter,
  dropdownPages,
  setItemPerPage,
  setSortState,
  itemPerPage,
  sort,
}) => {
  const dropDownRef = useRef();
  const [isFilterShow, setIsFilterShow] = useState(false);

  return (
    <ul className="btn-toolbar gx-1">
      {/* <li>
        <a
          href="#search"
          onClick={(ev) => {
            ev.preventDefault();
            textSearch();
          }}
          className="btn btn-icon search-toggle toggle-search"
        >
          <Icon name="search"></Icon>
        </a>
      </li>
      <li className="btn-toolbar-sep"></li> */}
      <li>
        <UncontrolledDropdown
          ref={dropDownRef}
          onToggle={(event, isOpen) => {
            setIsFilterShow(isOpen);
          }}
        >
          <DropdownToggle
            tag="a"
            className="btn btn-trigger btn-icon dropdown-toggle"
          >
            {isFilterApply && <div className="dot dot-primary"></div>}
            <Icon name="filter-alt"></Icon>
          </DropdownToggle>
          <PaymentChargesFilter
            tenantId={tenantId}
            isVisible={isFilterShow}
            selectedFilterData={selectedFilterData}
            onFilterApply={(data) => {
              onFilterApply(data);
              dropDownRef.current.toggle();
            }}
            onResetFilter={() => {
              onResetFilter();
              dropDownRef.current.toggle();
            }}
          />
        </UncontrolledDropdown>
      </li>
      <li>
        <UncontrolledDropdown>
          <DropdownToggle
            color="tranparent"
            className="btn btn-trigger btn-icon dropdown-toggle"
          >
            <Icon name="setting"></Icon>
          </DropdownToggle>
          <DropdownMenu end className="dropdown-menu-xs">
            <ul className="link-check">
              <li>
                <span>Show</span>
              </li>
              {dropdownPages.map((item, index) => {
                return (
                  <li
                    key={index}
                    className={itemPerPage === item ? "active" : ""}
                  >
                    <DropdownItem
                      tag="a"
                      href="#dropdownitem"
                      onClick={(ev) => {
                        ev.preventDefault();
                        setItemPerPage(item);
                      }}
                    >
                      {item}
                    </DropdownItem>
                  </li>
                );
              })}
            </ul>
            {/* <ul className="link-check">
              <li>
                <span>Order</span>
              </li>
              <li className={sort === "desc" ? "active" : ""}>
                <DropdownItem
                  tag="a"
                  href="#dropdownitem"
                  onClick={(ev) => {
                    ev.preventDefault();
                    setSortState("desc");
                    // sortFunc("dsc");
                  }}
                >
                  DESC
                </DropdownItem>
              </li>
              <li className={sort === "asc" ? "active" : ""}>
                <DropdownItem
                  tag="a"
                  href="#dropdownitem"
                  onClick={(ev) => {
                    ev.preventDefault();
                    setSortState("asc");
                  }}
                >
                  ASC
                </DropdownItem>
              </li>
            </ul> */}
          </DropdownMenu>
        </UncontrolledDropdown>
      </li>
    </ul>
  );
};

export default Toolbar;
