import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Form, Modal, ModalBody, Spinner } from "reactstrap";
import {
  Button,
  Col,
  FormInput,
  FormInputType,
  Icon,
} from "../../../components/Component";
import {
  AppText,
  covertToNumberOnly,
  numberFormatter,
  showErrorToast,
} from "../../../utils";
import { fileUploader, getTax, sendBid, updateSentBid } from "./RequestData";
import { FileSizeText } from "../../../components/text/FileSizeText";
import { bytesToMegaBytes } from "../../../utils/Utils";

export const RequestBid = ({ visible, data, requestId, onClosed, onError }) => {
  const [files, setFiles] = useState([]);
  const [budget, setBudget] = useState();
  const [loading, setLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [tax, setTax] = useState();

  const { handleSubmit } = useForm();

  useEffect(() => {
    if (visible) {
      if (data) {
        setBudget(numberFormatter("", "" + data.quote));
      } else {
        setBudget();
      }
      setFiles([]);
      setIsError(false);
      if (tax === undefined) {
        fetchTaxPercentage();
      }
    }
  }, [visible]);

  function uploadFile(data) {
    if (bytesToMegaBytes(data.size) > 100) {
      showErrorToast(AppText.maxFileSizeError);
    } else {
      var file = data;
      file.isUploading = true;
      file.isError = false;
      file.uploadedFileId = null;
      file.uploadId = Date.now();
      file.fileName = data.name;

      let list = files ? [...files] : [];
      list.push(file);
      setFiles(list);
      upload(file, list.length - 1);
    }
  }

  function fetchTaxPercentage(formData) {
    getTax((data, error) => {
      setTax(data);
    });
  }

  function onFormSubmit(formData) {
    let price = budget ? parseFloat(covertToNumberOnly(budget ?? 0)) : 0;
    if (price < 10) {
      setIsError(true);
      return;
    } else {
      setIsError(false);
    }
    let fileIds = files
      .filter((item) => item.isError === false && item.uploadedFileId !== null)
      .map((item) => item.uploadedFileId);
    let params = { quotationId: requestId, price: price, fileIds: fileIds };
    setLoading(true);
    if (data) {
      params["id"] = data.id;
      updateSentBid(params, (data, error) => {
        setLoading(false);
        if (error === null) {
          onClosed(true);
        } else {
          showErrorToast(
            error.message.length > 0 ? error.message : AppText.connectError
          );
          onError();
        }
      });
    } else {
      sendBid(params, (data, error) => {
        setLoading(false);
        if (error === null) {
          onClosed(true);
        } else {
          showErrorToast(
            error.message.length > 0 ? error.message : AppText.connectError
          );
          onError();
        }
      });
    }
  }

  //***************************API*************************/
  function upload(file, index) {
    fileUploader(file, "QUOTATION_BID", (data, error) => {
      let list = [...files];
      if (error === null) {
        file.uploadedFileId = data.id;
        file.isUploading = false;
        list[index] = file;
      } else {
        list.splice(index, 1);
        showErrorToast(
          error.message.length > 0 ? error.message : AppText.connectError
        );
      }
      setFiles(list);
    });
  }

  //*******************************************************/

  return (
    <Modal
      isOpen={visible}
      toggle={() => onClosed(false)}
      className="modal-dialog-centered"
      size="md"
    >
      <ModalBody>
        <a
          href="#cancel"
          onClick={(ev) => {
            ev.preventDefault();
            onClosed(false);
          }}
          className="close"
        >
          <Icon name="cross-sm"></Icon>
        </a>
        <div className="p-2">
          <h5 className="title">Provide quote</h5>
          <div className="mt-4">
            <Form
              className="row gy-4 form-validate"
              onSubmit={handleSubmit(onFormSubmit)}
            >
              {/* <Col md="12">
                <div className="form-group">
                  <label className="form-label">Quote</label>
                  <div className="form-control-wrap">
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <span className="input-group-text">$</span>
                      </div>
                      <input
                        type="text"
                        id="quote"
                        name="quote"
                        maxLength={7}
                        value={budget ?? ""}
                        className="form-control"
                        onChange={(e) => {
                          setBudget(numberFormatter("", e.target.value));
                        }}
                      />
                    </div>

                    {isError && (
                      <>
                        <span className="invalid">
                          Your quote should be at least $10.
                        </span>
                        <br />
                      </>
                    )}
                    <label className="mt-1 text-secondary">
                      {tax ? (
                        <>
                          {tax}% transaction fee will be subtracted from the
                          amount above.
                        </>
                      ) : (
                        <>
                          Please check the fee, which will be subtracted from
                          the amount above.
                        </>
                      )}
                    </label>
                  </div>
                </div>
              </Col> */}
              <Col md="12">
                <div className="form-group">
                  <div className="form-control-wrap">
                    <FormInput
                      labelGroupClass="mt-n1"
                      label={"Quote"}
                      baseClass={"m-0"}
                      type={FormInputType.amount}
                      value={budget}
                      onTextChange={
                        (text) => setBudget(text)
                      }
                    />
                    {isError && (
                      <>
                        <span className="invalid">
                          Your quote should be at least $10.
                        </span>
                        <br />
                      </>
                    )}
                    <label className="mt-1 text-secondary">
                      {tax ? (
                        <>
                          {tax}% transaction fee will be subtracted from the
                          amount above.
                        </>
                      ) : (
                        <>
                          Please check the fee, which will be subtracted from
                          the amount above.
                        </>
                      )}
                    </label>
                  </div>
                </div>
              </Col>
              <Col size="12">
                {files && (
                  <div className="form-group mb-0">
                    {files.map((item, index) => {
                      return (
                        <div
                          key={index}
                          className="mb-n1"
                          style={{ marginTop: 8, fontSize: 14 }}
                        >
                          <span>
                            <Icon name="clip" />{" "}
                          </span>
                          <span
                            className={
                              item.isError ? "text-danger" : "text-grey"
                            }
                          >
                            {item?.fileName}
                          </span>
                          <span>
                            {" "}
                            {item.isUploading ? (
                              <Spinner size="sm" color="dark" />
                            ) : (
                              <Icon
                                name="cross-circle"
                                className={"btn ms-n3"}
                                style={{ color: "red" }}
                                onClick={() => {
                                  let temp = files.filter(
                                    (item1) => item1 !== item
                                  );
                                  setFiles(temp);
                                }}
                              />
                            )}
                          </span>
                        </div>
                      );
                    })}
                  </div>
                )}
                <div className="form-group mb-0" style={{ marginTop: 14 }}>
                  <label
                    // className="btn"
                    htmlFor="files"
                    style={{
                      color: "#2C75FF",
                      fontSize: 14,
                      marginBottom: 14,
                    }}
                  >
                    <Icon name="clip" className={"mb-1"} /> Attach files
                  </label>
                  <FileSizeText />
                  <input
                    type="file"
                    name="files"
                    accept="image/*,.pdf,.doc,.docx,.xml"
                    className="form-control"
                    id="files"
                    style={{ display: "none" }}
                    onChange={(e) => {
                      let temp = Array.prototype.slice.call(e.target.files);
                      if (temp.length > 0) {
                        uploadFile(temp[0]);
                      }
                    }}
                  />
                </div>
              </Col>
              <Col size="12">
                <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                  <li>
                    <Button color="primary" size="md" type="submit">
                      {loading ? (
                        <Spinner size="sm" color="light" />
                      ) : (
                        "Provide quote"
                      )}
                    </Button>
                  </li>
                  <li>
                    <Button
                      onClick={(ev) => {
                        ev.preventDefault();
                        onClosed(false);
                      }}
                      className="link link-light"
                    >
                      Cancel
                    </Button>
                  </li>
                </ul>
              </Col>
            </Form>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};
