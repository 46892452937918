import React, { useEffect, useState } from "react";
// import { propertyList } from "./DropdownData";
import { RSelect } from "../Component";
import { propertyList } from "../../pages/panel/properties/PropertyData";
import { fetchChargeItemHistoryList } from "./DropdownData";
import { dropDownConvertor } from "../../pages/panel/maintenance-request/MaintenanceRequestData";

const PaymentChargeDropdown = ({
  tenantId,
  value,
  isMulti = false,
  onSelect,
  disabled = false,
  ...props
}) => {
  const [list, setList] = useState();

  useEffect(() => {
    !disabled && fetchChargeItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchChargeItems = () => {
    tenantId &&
      fetchChargeItemHistoryList(tenantId, (data, error) => {
        if (error === null) {
          let cData = dropDownConvertor(data?.list);
          let item = {
            id: 0,
            value: "Payment",
            label: "Payment",
          };
          cData.push(item);
          cData.sort();
          let result = [...cData].sort((a, b) =>
            a.label.localeCompare(b.label)
          );

          setList(result);
        }
      });
  };
  return (
    <>
      <RSelect
        options={list ?? []}
        isMulti={isMulti}
        isDisabled={disabled}
        placeholder={""}
        value={value ?? null}
        onChange={(e) => {
          onSelect(e);
        }}
        {...props}
      />
    </>
  );
};

export default PaymentChargeDropdown;
