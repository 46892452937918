import React, { useEffect, useState } from "react";
import Head from "../../../layout/head/Head";
import Content from "../../../layout/content/Content";
import {
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  DropdownItem,
  Badge,
} from "reactstrap";
import {
  Block,
  BlockHead,
  BlockBetween,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Button,
  PaginationComponent,
  PreviewAltCard,
  DataTableHead,
  DataTableRow,
  DataTableItem,
  RSelect,
  TooltipComponent,
  BlockDes,
  BlueText,
} from "../../../components/Component";
import { useHistory } from "react-router";
import {
  getVendorRequestList,
  markAsCompleted,
  removeRequestByVendor,
} from "./RequestData";
import moment from "moment";
import {
  CURRENCY_CODE,
  DATE_FORMAT,
  listAsNewLineString,
  numberFormatter,
  PROJECT_START_FLEXIBLE_TOOL_TIP,
  propertiesTypes,
  removeTimeFromServerDateString,
  showErrorToast,
  statusColor,
  StatusType,
  statusTypes,
  toCapitalize,
} from "../../../utils";
import Swal from "sweetalert2";
import { Storage } from "../../../services/storage/storage";
import { useRef } from "react";
import { getServices } from "../../auth/AuthData";
import { RequestBid } from "./RequestBid";
import { VendorRequestListFilter } from "./VendorRequestListFilter";
import LoadingComponent from "../../../layout/spinner/LoadingSpinner";

export const VendorRequestList = () => {
  // Get current list, pagination
  const [itemPerPage, setItemPerPage] = useState(20);
  const [tablesm, updateTableSm] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [sort, setSortState] = useState("desc");
  const [onSearchText, setSearchText] = useState("");
  const [onSearch, setonSearch] = useState(true);
  const [totalItems, setTotalItems] = useState(0);
  const [currentItems, setCurrentItems] = useState([]);
  const [status, setStatus] = useState("ALL");
  const [serviceList, setServiceList] = useState([]);

  const dropdownPages = [5, 10, 15, 20];
  const [selectedServices, setSelectedServices] = useState([]);
  const [selectedPropertyType, setSelectedPropertyType] = useState([]);
  const [selectedStartDate, setSelectedStartdate] = useState();
  const [selectedEndDate, setSelectedEnddate] = useState();
  const [projectStatus, setProjectStatus] = useState();
  const [isFilterApply, setIsFilterApply] = useState(false);
  const [modal, setModal] = useState(false);
  const [selectedItemForQuote, setSelectedItemForQuote] = useState();
  const [quote, setQuote] = useState();
  const [isInitScreen, setIsInitScreen] = useState(true);
  const searchInput = useRef(null);

  const dropDownRef = useRef();

  const toggle = () => setonSearch(!onSearch);

  useEffect(() => {
    loadServiceList();
  }, []);

  useEffect(() => {
    if (currentPage !== 0) {
      loadList(isFilterApply);
    }
  }, [currentPage]);

  useEffect(() => {
    if (selectedItemForQuote?.myBid?.price) {
      setModal(true);
    }
  }, [selectedItemForQuote]);

  useEffect(() => {
    if (searchInput.current && !onSearch) {
      searchInput.current.focus();
    }
  }, [onSearch]);

  useEffect(() => {
    if (currentPage === 1) {
      loadList(isFilterApply);
    } else {
      setCurrentPage(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemPerPage, sort, onSearchText, status, isFilterApply]);

  function loadServiceList() {
    let req = {};
    let user = Storage.getLoggedInUser()?.user;
    if (user) {
      req["vendorId"] = user.companyInfo?.id;
    }
    getServices(req, (list, error) => {
      if (error === null) {
        setServiceList(list);
      }
    });
  }

  function loadList(isFilterApplied = false) {
    let sortParams = { field: "id", order: sort };
    let params = {
      pageInfo: { pageIndex: currentPage, itemsPerPage: itemPerPage },
      sorting: sortParams,
    };
    if (onSearchText.length > 0) {
      params["search"] = onSearchText;
    }
    if (status !== "ALL") {
      params["status"] = status.toUpperCase();
    }

    if (isFilterApplied) {
      if (selectedStartDate) {
        params["startDate"] = moment(selectedStartDate).format("YYYY-MM-DD");
      }

      if (selectedEndDate) {
        params["endDate"] = moment(selectedEndDate).format("YYYY-MM-DD");
      }

      if (selectedServices.length > 0) {
        params["serviceIds"] = selectedServices.map((item) => item.id);
      }

      if (selectedPropertyType.length > 0) {
        params["propertyTypes"] = selectedPropertyType.map((item) => item.id);
      }

      if (projectStatus) {
        params["currentProjStatus"] = projectStatus;
      }
    } else {
      resetFilter();
    }

    getVendorRequestList(params, (data, error) => {
      if (error === null) {
        setCurrentItems(data.list);
        if (currentPage === 1) {
          setTotalItems(data.pageInfo?.totalItems ?? 0);
        }
      }
      setIsInitScreen(false)
    });
  }

  const onViewClick = (item) => {
    const name = item?.title.trim().replace(/\s+/g, "-").toLowerCase();
    history.push(`${process.env.PUBLIC_URL}/request/${item.id}-${name}`);
  };

  const onProvideQuoteClick = (item) => {
    setSelectedItemForQuote(item);
    if (item?.myBid) {
      setQuote({
        id: item?.myBid?.id,
        name: "Demo-Data",
        quote: item?.myBid?.price ?? "",
        status: item?.myBid?.status,
        vendorId: item.id,
      });
    }
    setModal(true);
  };

  const onApplyText = (e) => {
    setStatus(e.value);
  };

  const refresh = (status) => {
    if (status) {
      loadList(isFilterApply);
    }
    setModal(false);
    setQuote();
    setSelectedItemForQuote();
  }

  // function to change the complete a project property
  const removeRequest = (item) => {
    Swal.fire({
      title: "Are you sure?",
      text: `You want to remove ${item.title} request?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, remove it!",
    }).then((result) => {
      if (result.isConfirmed) {
        removeRequestByVendor(item.id, (data, error) => {
          if (error === null) {
            setCurrentItems(
              currentItems.filter((value) => value.id !== item.id)
            );
          }
        });
      }
    });
  };

  function markAsCompletedByVendor(item) {
    Swal.fire({
      title: "Are you sure?",
      text: `You want to complete ${item.title}?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, complete!",
    }).then((result) => {
      if (result.isConfirmed) {
        markAsCompleted(item.id, (data, error) => {
          if (error === null) {
            const updatedItems = currentItems.map((user) => {
              if (user.id === item.id) {
                return { ...user, status: StatusType.complete };
              }
              return user;
            });
            setCurrentItems(updatedItems);
          } else {
            showErrorToast(error.message);
          }
        });
      }
    });
  }

  function resetFilter() {
    setSelectedPropertyType([]);
    setSelectedServices([]);
    setSelectedStartdate();
    setSelectedEnddate();
    setProjectStatus();
  }

  // Change Page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const history = useHistory();

  return (
    <React.Fragment>
      <Head title="Requests"></Head>
      <Content className="bg-blue-dim">
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle page>Requests</BlockTitle>
              <BlockDes className="text-soft">
                {totalItems === 0
                  ? ""
                  : totalItems === 1
                  ? "There is one quote request that matches your service type and area."
                  : `There are ${totalItems} quote requests that match your service type and area.`}
              </BlockDes>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        <Block className={"card-inner-group"}>
          <div className="card-inner bg-white position-relative card-tools-toggle">
            <div className="card-title-group">
              <div className="card-tools">
                <div className="form-inline flex-nowrap gx-3">
                  <div className="form-wrap">
                    <RSelect
                      options={statusTypes}
                      className="w-130px"
                      placeholder="Status"
                      defaultValue={statusTypes[0]}
                      onChange={(e) => onApplyText(e)}
                    />
                  </div>
                </div>
              </div>
              <div className="card-tools me-n1">
                <ul className="btn-toolbar gx-1">
                  <li>
                    <a
                      href="#search"
                      onClick={(ev) => {
                        ev.preventDefault();
                        toggle();
                      }}
                      className="btn btn-icon search-toggle toggle-search"
                    >
                      <Icon name="search"></Icon>
                    </a>
                  </li>
                  <li className="btn-toolbar-sep"></li>
                  <li>
                    <div className="toggle-wrap">
                      <Button
                        className={`btn-icon btn-trigger toggle ${
                          tablesm ? "active" : ""
                        }`}
                        // onClick={() => updateTableSm(true)}
                      >
                        <Icon name="menu-right"></Icon>
                      </Button>
                      <div
                        className={`toggle-content ${
                          tablesm ? "content-active" : ""
                        }`}
                      >
                        <ul className="btn-toolbar gx-1">
                          <li className="toggle-close">
                            <Button
                              className="btn-icon btn-trigger toggle"
                              onClick={() => updateTableSm(false)}
                            >
                              <Icon name="arrow-left"></Icon>
                            </Button>
                          </li>
                          <li>
                            <UncontrolledDropdown ref={dropDownRef}>
                              <DropdownToggle
                                tag="a"
                                className="btn btn-trigger btn-icon dropdown-toggle"
                              >
                                {isFilterApply && (
                                  <div className="dot dot-primary"></div>
                                )}
                                <Icon name="filter-alt"></Icon>
                              </DropdownToggle>
                              <VendorRequestListFilter
                                isVisible={
                                  dropDownRef.current?.state?.isOpen ?? false
                                }
                                serviceList={serviceList}
                                propertyTypes={propertiesTypes}
                                filteredServices={selectedServices}
                                filteredPropertyTypes={selectedPropertyType}
                                currentProjectStatus={projectStatus}
                                startDate={selectedStartDate}
                                endDate={selectedEndDate}
                                onFilterApply={(data) => {
                                  const {
                                    services,
                                    propertyTypes,
                                    startDate,
                                    endDate,
                                    projectStatus,
                                  } = data;
                                  setSelectedStartdate(startDate);
                                  setSelectedEnddate(endDate);
                                  setSelectedServices(services);
                                  setSelectedPropertyType(propertyTypes);
                                  setProjectStatus(projectStatus)
                                  dropDownRef.current.toggle();
                                  if (isFilterApply === true) {
                                    setCurrentPage(0);
                                    setTimeout(() => {
                                      setCurrentPage(1);
                                    }, 200);
                                  }
                                  setIsFilterApply(true);
                                }}
                                onResetFilter={(e) => {
                                  resetFilter();
                                  dropDownRef.current.toggle();
                                  setIsFilterApply(false);
                                }}
                              />
                            </UncontrolledDropdown>
                          </li>
                          <li>
                            <UncontrolledDropdown>
                              <DropdownToggle
                                color="tranparent"
                                className="btn btn-trigger btn-icon dropdown-toggle"
                              >
                                <Icon name="setting"></Icon>
                              </DropdownToggle>
                              <DropdownMenu end className="dropdown-menu-xs">
                                <ul className="link-check">
                                  <li>
                                    <span>Show</span>
                                  </li>
                                  {dropdownPages.map((item, index) => {
                                    return (
                                      <li
                                        key={index}
                                        className={
                                          itemPerPage === item ? "active" : ""
                                        }
                                      >
                                        <DropdownItem
                                          tag="a"
                                          href="#dropdownitem"
                                          onClick={(ev) => {
                                            ev.preventDefault();
                                            setItemPerPage(item);
                                          }}
                                        >
                                          {item}
                                        </DropdownItem>
                                      </li>
                                    );
                                  })}                                  
                                </ul>
                                <ul className="link-check">
                                  <li>
                                    <span>Order</span>
                                  </li>
                                  <li
                                    className={sort === "desc" ? "active" : ""}
                                  >
                                    <DropdownItem
                                      tag="a"
                                      href="#dropdownitem"
                                      onClick={(ev) => {
                                        ev.preventDefault();
                                        setSortState("desc");
                                        // sortFunc("dsc");
                                      }}
                                    >
                                      DESC
                                    </DropdownItem>
                                  </li>
                                  <li
                                    className={sort === "asc" ? "active" : ""}
                                  >
                                    <DropdownItem
                                      tag="a"
                                      href="#dropdownitem"
                                      onClick={(ev) => {
                                        ev.preventDefault();
                                        setSortState("asc");
                                      }}
                                    >
                                      ASC
                                    </DropdownItem>
                                  </li>
                                </ul>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div className={`card-search search-wrap ${!onSearch && "active"}`}>
              <div className="card-body">
                <div className="search-content">
                  <Button
                    className="search-back btn-icon toggle-search active"
                    onClick={() => {
                      setSearchText("");
                      toggle();
                    }}
                  >
                    <Icon name="arrow-left"></Icon>
                  </Button>
                  <input
                    type="text"
                    className="border-transparent form-focus-none form-control"
                    placeholder="Search by user or email"
                    value={onSearchText}
                    ref={searchInput}
                    onChange={(e) => setSearchText(e.target.value)}
                  />
                  <Button className="search-submit btn-icon">
                    <Icon name="search"></Icon>
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div className="overflow-x-auto">
          <div className="nk-tb-list nk-tb-ulist bg-white">
            <DataTableHead className="nk-tb-item nk-tb-head">
              <DataTableRow>
                <span className="sub-text text-secondary">Title</span>
              </DataTableRow>
              <DataTableRow>
                <span className="sub-text text-secondary">Status</span>
              </DataTableRow>
              <DataTableRow size="md">
                <span className="sub-text text-secondary">Service</span>
              </DataTableRow>
              <DataTableRow size="xxl">
                <span className="sub-text text-secondary">Property</span>
              </DataTableRow>
              <DataTableRow>
                <span className="sub-text text-secondary">Project start</span>
              </DataTableRow>
              <DataTableRow size="lg">
                <span className="sub-text text-secondary">Project end</span>
              </DataTableRow>
              <DataTableRow size="lg">
                <span className="sub-text text-secondary">Project status</span>
              </DataTableRow>
              <DataTableRow>
                <span className="sub-text text-secondary">Property manager</span>
              </DataTableRow>
              <DataTableRow className="text-end">
                <span className="sub-text sub-text-line-2 w-min-100px text-secondary">
                  Submitted quote
                </span>
              </DataTableRow>
              <DataTableRow className="nk-tb-col-tools"></DataTableRow>
            </DataTableHead>
            {currentItems.length > 0
              ? currentItems.map((item) => {
                  return (
                    <DataTableItem key={item.id}>
                      <DataTableRow className="w-max-250px">
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                        <BlueText
                          title={item.title}
                          onClick={(ev) => {
                            ev.preventDefault()
                            onViewClick(item, ev);
                          }}
                        />
                      </DataTableRow>
                      <DataTableRow>
                        {item?.myBid && item.status === StatusType.published ? (
                          <Badge
                            className="badge-dim fSize14 p-1 bg-orange-dim text-orange border-0"
                            color={"orange"}
                          >
                            <span>{"Quoted"}</span>
                          </Badge>
                        ) : (
                          <Badge
                            className={`badge-dim fSize14 p-1 bg-${statusColor(
                              item.status
                            )}-dim text-${statusColor(item.status)} border-0`}
                            color={statusColor(item.status)}
                          >
                            <span>{toCapitalize(item.status)}</span>
                          </Badge>
                        )}
                      </DataTableRow>
                      <DataTableRow size="md" className="w-max-250px text-secondary">
                        <span>
                          {listAsNewLineString(
                            item?.services?.map((item) => item.name) ?? []
                          )}
                        </span>
                      </DataTableRow>
                      <DataTableRow className="w-max-250px text-secondary" size="xxl">
                        <span>
                          {listAsNewLineString(
                            item?.properties?.map((item) => item.name) ?? []
                          )}
                        </span>
                      </DataTableRow>
                      <DataTableRow className="text-secondary">
                        <span>
                          {moment(
                            removeTimeFromServerDateString(item.startDate)
                          ).format(DATE_FORMAT)}
                        </span>
                        {item.isStartDateFlexible === 1 && (
                          <TooltipComponent
                            icon="info-fill"
                            iconClass="card-hint"
                            direction="right"
                            id="password"
                            text={PROJECT_START_FLEXIBLE_TOOL_TIP}
                          />
                        )}
                      </DataTableRow>
                      <DataTableRow size="lg" className="text-secondary">
                        <span>
                          {item.endDate
                            ? moment(
                                removeTimeFromServerDateString(item.endDate)
                              ).format(DATE_FORMAT)
                            : "Not specified"}
                        </span>
                      </DataTableRow>
                      <DataTableRow size="lg" className="text-secondary">
                        {item.projectStatus === "READY_TO_HIRE"
                          ? "Ready to hire"
                          : item.projectStatus === "PLANNING_&_BUDGETING"
                          ? "Planning & Budgeting"
                          : "Not specified"}
                      </DataTableRow>
                      <DataTableRow className="text-secondary">{item?.pm?.companyName ?? ""}</DataTableRow>
                      <DataTableRow className="nk-tb-col-tools text-end w-min-100px text-secondary">
                        <span>
                          {item.myBid
                            ? numberFormatter(
                                CURRENCY_CODE,
                                "" + item.myBid?.price ?? 0
                              )
                            : "Not submitted yet"}
                        </span>
                      </DataTableRow>
                      <DataTableRow className="nk-tb-col-tools text-end">
                        <ul className="nk-tb-actions gx-1">
                          <li>
                            <UncontrolledDropdown>
                              <DropdownToggle
                                tag="a"
                                className="text-soft dropdown-toggle btn btn-icon btn-trigger"
                              >
                                <Icon name="more-h"></Icon>
                              </DropdownToggle>
                              <DropdownMenu end>
                                <ul className="link-list-opt no-bdr">
                                  <li onClick={() => onViewClick(item)}>
                                    <DropdownItem
                                      tag="a"
                                      href="#view"
                                      onClick={(ev) => {
                                        ev.preventDefault();
                                      }}
                                    >
                                      <Icon name="eye"></Icon>
                                      <span>View</span>
                                    </DropdownItem>
                                  </li>
                                  {(item.status === StatusType.confirmed ||
                                    item.status === StatusType.reopened ||
                                    item.status === StatusType.published) && (
                                    <li
                                      onClick={() => {
                                        if (
                                          item.myBid &&
                                          (item.status ===
                                            StatusType.confirmed ||
                                            item.status === StatusType.reopened)
                                        ) {
                                          markAsCompletedByVendor(item);
                                        } else {
                                          onProvideQuoteClick(item);
                                        }
                                      }}
                                    >
                                      <DropdownItem
                                        tag="a"
                                        href="#quote"
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                        }}
                                      >
                                        <Icon name="edit"></Icon>
                                        <span>
                                          {item.myBid &&
                                          (item.status ===
                                            StatusType.confirmed ||
                                            item.status === StatusType.reopened)
                                            ? "Complete"
                                            : item.myBid &&
                                              item.status ===
                                                StatusType.published
                                            ? "Update quote"
                                            : "Provide quote"}
                                        </span>
                                      </DropdownItem>
                                    </li>
                                  )}
                                  <li onClick={() => removeRequest(item)}>
                                    <DropdownItem
                                      tag="a"
                                      href="#markasdone"
                                      onClick={(ev) => {
                                        ev.preventDefault();
                                      }}
                                    >
                                      <Icon name="trash"></Icon>
                                      <span>Remove</span>
                                    </DropdownItem>
                                  </li>
                                </ul>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </li>
                        </ul>
                      </DataTableRow>
                    </DataTableItem>
                  );
                })
              : null}
          </div>
          </div>
          <PreviewAltCard className={"border-top"}>
            {totalItems > 0 ? (
              <PaginationComponent
                itemPerPage={itemPerPage}
                totalItems={totalItems}
                paginate={paginate}
                currentPage={currentPage}
              />
            ) : (
              <div className="text-center">
                <span className="text-silent">
                  {
                    "There is no quote request that matches your service type and area."
                  }
                </span>
              </div>
            )}
          </PreviewAltCard>
        </Block>
      </Content>
      <RequestBid
        visible={modal}
        requestId={selectedItemForQuote?.id}
        data={quote ?? undefined}
        onClosed={(status) => {
          refresh(status);
        }}
        onError={() => {
          refresh(false);
        }}
      />
      <LoadingComponent isLoading={isInitScreen} />
    </React.Fragment>
  );
};

export default VendorRequestList;
