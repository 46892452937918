import React, { useEffect, useState } from "react";
import { unitList } from "./DropdownData";
import { RSelect } from "../Component";

export const UnitDropdown = ({
  value,
  isMulti = false,
  propertyId,
  onSelect,
  disabled = false,
  ...props
}) => {
  const [list, setList] = useState();

  useEffect(() => {
    if (!disabled) {
      fetchUnits();
    }
  }, []);

  useEffect(() => {
    if (propertyId && !disabled) {
      fetchUnits();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [propertyId]);

  const fetchUnits = () => {
    unitList(propertyId ?? null, (data, error) => {
      if (error === null) {
        setList(data);
      }
    });
  };

  return (
    <>
      <RSelect
        options={list ?? []}
        isMulti={isMulti}
        placeholder={""}
        value={value ?? null}
        isDisabled={disabled}
        onChange={(e) => {
          onSelect(e);
        }}
        {...props}
      />
    </>
  );
};
