import React from "react";
import { useState } from "react";
import { Col, Form } from "reactstrap";
import { AltitudeModal, AmenityDropdown } from "../../../components/Component";
import { useEffect } from "react";
import { fetchAddListingInformation } from "./PropertyData";
import { AmenityDropdownType } from "../../../components/dropdown/AmenityDropdown";

const AddListingInformation = ({
  isOpen,
  isEdit,
  propertyDetails,
  data,
  item,
  onSave,
  onCancel,
}) => {
  const [formData, setFormData] = useState();
  const [errorVal, setError] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setFormData({
        ...item,
        amenities: item?.amenities?.map((item) => ({
          id: item.id,
          label: item.name,
          value: item.name,
        })),
      });
    }
  }, [item]);

  const onInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const resetForm = () => {
    setFormData({});
    setLoading(false);
    setError(null);
  };

  const onFormCancel = () => {
    resetForm();
    onCancel();
  };

  const onFormSubmit = () => {
    callAddListingInformation();
  };

  // API------------------------------------>
  const callAddListingInformation = () => {
    setLoading(true);
    let params = {
      propertyId: propertyDetails?.id,
      includedInRent: formData?.includedInRent,
      description: formData?.description,
      amenitiesIds: formData?.amenities.map((item, index) => item.id),
    };
    fetchAddListingInformation(params, (data, error) => {
      if (error === null) {
        setLoading(false);
        onSave();
        resetForm();
      } else {
        setError(error.message);
        setLoading(false);
      }
    });
  };

  return (
    <AltitudeModal
      size={"md"}
      isShowFooter={true}
      isOpen={isOpen}
      loading={loading}
      disabled={loading}
      toggle={() => onFormCancel()}
      title={isEdit ? "Update listing information" : "Add listing information"}
      onSave={() => onFormSubmit()}
      error={errorVal}
    >
      <Form className="row" autoComplete="off">
        <Col md="12" className="">
          <div className="form-group">
            <label className="form-label">Discription</label>
            <textarea
              type="text"
              name="description"
              value={formData?.description ?? ""}
              onChange={(e) => onInputChange(e)}
              className="form-control"
            />
          </div>
        </Col>
        <Col md="12" className="mt-4">
          <div className="form-group">
            <label className="form-label">Amenities</label>
            <div className="form-control-wrap">
              <AmenityDropdown
                value={formData?.amenities ?? "N/a"}
                type={AmenityDropdownType.property}
                isMulti={true}
                onSelect={(e) => {
                  setFormData({ ...formData, amenities: e });
                }}
              />
            </div>
          </div>
        </Col>
        <Col md="12" className="mt-4">
          <div className="form-group">
            <label className="form-label">Included in rent</label>
            <textarea
              type="text"
              name="includedInRent"
              value={formData?.includedInRent ?? ""}
              onChange={(e) => onInputChange(e)}
              className="form-control"
            />
          </div>
        </Col>
      </Form>
    </AltitudeModal>
  );
};

export default AddListingInformation;
