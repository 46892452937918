import { apiService } from "../../services/api";
import { AppText, covertToNumberOnly } from "../../utils";
import { UserAccountType } from "../../utils/Utils";

export function signup(data, callback) {
  let params = {
    firstName: data.firstname,
    lastName: data.lastname,
    userType:
      data.type === UserAccountType.vendor
        ? "VENDOR"
        : data.type === UserAccountType.manager
        ? "PM"
        : "TENANT",
    companyName: data.company,
    fullAddress: data?.addressData?.fullAddress,
    city: data?.addressData?.city ?? data?.addressData?.subcity,
    state: data?.addressData?.state,
    country: data?.addressData?.country,
    zipCode: data?.addressData?.zipCode,
    latitude: data?.addressData?.lat,
    longitude: data?.addressData?.lng,
    phone: covertToNumberOnly(data.phone),
    email: data.email,
    password: data.passcode,
  };

  if (
    data.type !== UserAccountType.tenant &&
    (params.latitude === undefined || params.longitude === undefined)
  ) {
    callback(null, { message: AppText.addressError });
    return;
  }

  apiService
    .signUp(params)
    .then((auth) => {
      callback(auth, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function signin(data, callback) {
  apiService
    .signIn(data)
    .then((auth) => {
      callback(auth, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function resetPasssword(data, callback) {
  apiService
    .resetPassword(data)
    .then((data) => {
      callback(data, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function changePassword(data, callback) {
  apiService
    .changePassword(data)
    .then((data) => {
      callback(data, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function changeEmail(data, callback) {
  apiService
    .changeEmail(data)
    .then((data) => {
      callback(data, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function verifyEmailChangeOTP(data, callback) {
  apiService
    .verifyEmailChangeOTP(data)
    .then((data) => {
      callback(data, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function checkPassword(data, callback) {
  apiService
    .checkPassword(data)
    .then((data) => {
      callback(data, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function recoverNewPassword(data, callback) {
  let params = {
    recoveryToken: data.token,
    newPassword: data.newPassword,
  };
  apiService
    .recoverPassword(params)
    .then((auth) => {
      callback(auth, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function verifyEmailToken(data, callback) {
  apiService
    .verifyEmailToken(data)
    .then((auth) => {
      callback(auth, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function verifyResetPasswordToken(data, callback) {
  apiService
    .verifyResetPasswordToken(data)
    .then((auth) => {
      callback(auth, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function resendVerifyEmail(data, callback) {
  apiService
    .resendVerifyEmail(data)
    .then((auth) => {
      callback(auth, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function generateSession(data, callback) {
  apiService
    .generateSession(data)
    .then((auth) => {
      callback(auth, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function vendorSetup(image, data, callback) {
  apiService
    .vendorSetup(image, data)
    .then((object) => {
      callback(object, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function getServices(req = {}, callback) {
  apiService
    .fetchServices(req)
    .then((list) => {
      callback(list, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function fetchAssociationList(req = {}, callback) {
  apiService
    .fetchAssociations(req)
    .then((list) => {
      callback(list, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function fetchUserInfo(callback) {
  apiService
    .fetchCurrentUser()
    .then((user) => {
      callback(user, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function addSuggestServices(data, callback) {
  apiService
    .addSuggestService(data)
    .then((list) => {
      callback(list, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function addSuggestAssociations(data, callback) {
  apiService
    .addSuggestAssociation(data)
    .then((list) => {
      callback(list, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function updateUser(data, callback) {
  apiService
    .updateUser(data)
    .then((data) => {
      callback(data, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function updateUserTenant(data, callback) {
  apiService
    .updateUserTenant(data)
    .then((data) => {
      callback(data, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function updatePMCompanyInfo(data, callback) {
  apiService
    .updatePMCompanyInfo(data)
    .then((list) => {
      callback(list, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function preferredVendor(data, callback) {
  apiService
    .preferredVendor(data)
    .then((list) => {
      callback(list, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function setImage(req = {}, callback) {
  apiService
    .uploadImage(req)
    .then((list) => {
      callback(list, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}