import React, { useEffect, useState } from "react";
import { DropdownMenu } from "reactstrap";
import {
  CheckInputOptions,
  Col,
  PropertyDropdown,
  RSelect,
  RadioButtonOptions,
  Row,
} from "../../../components/Component";
import DatePicker from "react-datepicker";
import { UnitDropdown } from "../../../components/dropdown/UnitDropdown";
import CategoryDropdown from "../../../components/dropdown/CategoryDropdown";
import MaintenanceStatusDropdown from "../../../components/dropdown/MaintenanceStatusDropdown";
import { RadioOptionTypes, URGENCY } from "../../../utils";
import { useSelector } from "react-redux";
import { UserAccountType } from "../../../utils/Utils";

const MaintenanceRequestListFilter = ({
  isVisible,
  filteredData,
  onFilterApply,
  onResetFilter,
}) => {
  const user = useSelector((state) => state.user.currentUser);
  const [filterData, setFilterData] = useState({
    property: null,
    unit: null,
    category: null,
    tenantId: null,
    pmId: null,
    scheduleStart: null,
    scheduleEnd: null,
    createStart: null,
    createEnd: null,
    urgency: null,
    status: null,
  });

  useEffect(() => {
    return () => {
      setFilterData();
    };
  }, []);

  useEffect(() => {
    if (
      filterData?.property?.length > 1 ||
      filterData?.property?.length === 0
    ) {
      setFilterData({ ...filterData, unit: [] });
    }
  }, [filterData?.property]);

  useEffect(() => {
    if (isVisible) {
      setFilterData(filteredData);
    }
  }, [isVisible]);

  return (
    <DropdownMenu
      end
      className="filter-wg dropdown-menu-xl"
      style={{ overflow: "visible" }}
    >
      <div className="dropdown-head">
        <span className="sub-title dropdown-title">Filter</span>
      </div>
      <div className="dropdown-body dropdown-body-rg">
        <Row className="gx-6 gy-3">
          {user.accountType === UserAccountType.manager && (
            <>
              <Col size="12">
                <div className="form-group">
                  <label className="overline-title overline-title-alt">
                    Property
                  </label>
                  <div className="form-control-wrap">
                    <PropertyDropdown
                      isMulti={true}
                      value={filterData?.property}
                      onSelect={(e) => {
                        setFilterData({ ...filterData, property: e });
                      }}
                    />
                  </div>
                </div>
              </Col>
              <Col size="12">
                <div className="form-group">
                  <label className="overline-title overline-title-alt">
                    Unit
                  </label>
                  <div className="form-control-wrap">
                    <UnitDropdown
                      value={
                        filterData?.property?.length === 0 ||
                        filterData?.property?.length > 1
                          ? []
                          : filterData?.unit
                      }
                      isMulti={true}
                      propertyId={
                        filterData?.property?.length > 0 &&
                        filterData?.property[0].id
                      }
                      disabled={
                        !filterData?.property ||
                        filterData?.property.length > 1 ||
                        filterData?.property.length === 0
                      }
                      onSelect={(e) => {
                        setFilterData({ ...filterData, unit: e });
                      }}
                    />
                  </div>
                </div>
              </Col>
            </>
          )}

          <Col size="12">
            <div className="form-group">
              <label className="overline-title overline-title-alt">
                Category
              </label>
              <div className="form-control-wrap">
                <CategoryDropdown
                  isMulti={true}
                  value={filterData?.category}
                  onSelect={(e) => {
                    setFilterData({ ...filterData, category: e });
                  }}
                />
              </div>
            </div>
          </Col>
          <Col size="12">
            <div className="form-group">
              <label className="overline-title overline-title-alt">
                Status
              </label>
              <div className="form-control-wrap">
                <MaintenanceStatusDropdown
                  value={filterData?.status}
                  isMulti={true}
                  onSelect={(e) => {
                    setFilterData({ ...filterData, status: e });
                  }}
                />
              </div>
            </div>
          </Col>
          <Col md="12">
            <CheckInputOptions
              name="urgency"
              label={"Urgency"}
              filterLabel={true}
              labelClassName={"mb-3"}
              size={6}
              options={Object.values(URGENCY)}
              onChange={(selectedIndexes) => {
                setFilterData({
                  ...filterData,
                  urgency: selectedIndexes,
                });
              }}
              indexes={filterData?.urgency ?? []}
            />
          </Col>

          <Col size="6">
            <div className="form-group">
              <label className="overline-title overline-title-alt">
                Scheduled date
                <br />
                from
              </label>
              <DatePicker
                selected={filterData?.scheduleStart}
                className="form-control"
                popperPlacement="top-end"
                onChange={(date) => {
                  if (filterData?.scheduleEnd) {
                    var Difference_In_Time =
                      filterData?.scheduleEnd.getTime() - date.getTime();
                    var Difference_In_Days =
                      Difference_In_Time / (1000 * 3600 * 24);

                    if (Difference_In_Days < 0) {
                      setFilterData({ ...filterData, scheduleEnd: null });
                    }
                    setFilterData({ ...filterData, scheduleStart: date });
                  } else {
                    setFilterData({ ...filterData, scheduleStart: date });
                  }
                }}
              />
            </div>
          </Col>
          <Col size="6">
            <div className="form-group">
              <label className="overline-title overline-title-alt">
                Scheduled date
                <br />
                to
              </label>
              <DatePicker
                selected={filterData?.scheduleEnd}
                className="form-control"
                popperPlacement="top-end"
                onChange={(date) => {
                  if (filterData?.scheduleStart) {
                    var Difference_In_Time =
                      date.getTime() - filterData?.scheduleStart.getTime();
                    var Difference_In_Days =
                      Difference_In_Time / (1000 * 3600 * 24);
                    if (Difference_In_Days > 0) {
                      setFilterData({ ...filterData, scheduleEnd: date });
                    }
                  } else {
                    setFilterData({ ...filterData, scheduleEnd: date });
                  }
                }}
              />
            </div>
          </Col>
          <Col size="6">
            <div className="form-group">
              <label className="overline-title overline-title-alt">
                Created date from
              </label>
              <DatePicker
                selected={filterData?.createStart}
                className="form-control"
                popperPlacement="top-end"
                onChange={(date) => {
                  if (filterData?.createEnd) {
                    var Difference_In_Time =
                      filterData?.createEnd.getTime() - date.getTime();
                    var Difference_In_Days =
                      Difference_In_Time / (1000 * 3600 * 24);

                    if (Difference_In_Days < 0) {
                      setFilterData({ ...filterData, createEnd: null });
                    }
                    setFilterData({ ...filterData, createStart: date });
                  } else {
                    setFilterData({ ...filterData, createStart: date });
                  }
                }}
              />
            </div>
          </Col>
          <Col size="6">
            <div className="form-group">
              <label className="overline-title overline-title-alt">
                Created date to
              </label>
              <DatePicker
                selected={filterData?.createEnd}
                className="form-control"
                popperPlacement="top-end"
                onChange={(date) => {
                  if (filterData?.createStart) {
                    var Difference_In_Time =
                      date.getTime() - filterData?.createStart.getTime();
                    var Difference_In_Days =
                      Difference_In_Time / (1000 * 3600 * 24);
                    if (Difference_In_Days > 0) {
                      setFilterData({ ...filterData, createEnd: date });
                    }
                  } else {
                    setFilterData({ ...filterData, createEnd: date });
                  }
                }}
              />
            </div>
          </Col>
          <Col size="12">
            <div className="form-group">
              <button
                type="button"
                className="btn btn-primary"
                onClick={(e) => {
                  e.preventDefault();
                  if (
                    filterData?.property === null &&
                    filterData?.unit === null &&
                    filterData?.category === null &&
                    filterData?.scheduleStart === null &&
                    filterData?.scheduleEnd === null &&
                    filterData?.createStart === null &&
                    filterData?.createEnd === null &&
                    filterData?.urgency === null &&
                    filterData?.status === null
                  ) {
                    onResetFilter();
                  } else {
                    let data = filterData;
                    onFilterApply(data);
                  }
                }}
              >
                Filter
              </button>
            </div>
          </Col>
        </Row>
      </div>
      <div className="dropdown-foot between">
        <a
          href="#reset"
          className="clickable"
          onClick={(ev) => {
            ev.preventDefault();
            onResetFilter();
          }}
        >
          Reset Filter
        </a>
      </div>
    </DropdownMenu>
  );
};

export default MaintenanceRequestListFilter;
