import moment from "moment";
import { StatusType, toCapitalize } from "../../utils";
import { UserAccountType } from "../../utils/Utils";
import { Storage } from "../storage/storage";

export class APIDataConverter {
  toLegalDocs(legalDocsResponse) {
    return {
      termsAndConditions: legalDocsResponse?.termsAndConditions ?? "",
      privacyPolicy: legalDocsResponse?.privacyPolicy ?? "",
    };
  }

  toAuthorization(authResponse) {
    let authorization = {};
    authorization.accessToken = authResponse.accessToken ?? "";
    authorization.user = this.toUser(authResponse.sessionUser);
    authorization.user.property = this.toTenantProperty(
      authResponse.currentTenantData
    );
    Storage.setLoggedInUser(authorization);
    Storage.setUserToken(authResponse.accessToken ?? null);
    return authorization;
  }

  toSessionUser(response) {
    let user = this.toUser(response.sessionUser);
    user.property = this.toTenantProperty(response.currentTenantData);
    updateSessionUserInfo(user);
    return user;
  }

  toUser(response) {
    let user = {};
    user.id = response.id;
    user.accountType =
      response.userType === "PM"
        ? UserAccountType.manager
        : response.userType === "VENDOR"
        ? UserAccountType.vendor
        : UserAccountType.tenant;

    user.email = response.email;
    user.firstName = response.firstName;
    user.lastName = response.lastName;
    user.phone = response.phone;
    user.avatar = response.avatar;
    user.isEmailVerified = response.isEmailVerified;
    user.chatId = response.sbId;
    user.chatAccessToken = response.sbToken;
    user.isVendorProfileSetup = response.vendor
      ? response.vendor?.isProfileSetupCompleted === 1
      : false;
    if (response.pm) {
      user.companyInfo = this.toManagerCompany(response.pm);
    } else if (response.vendor) {
      user.companyInfo = this.toVendorCompany(response.vendor);
    }
    // user.property = toProperty(response.)
    return user;
  }

  toTenantProperty(data) {
    if (!data) return null;
    if (!data.property) return null;
    return {
      id: data.property.id,
      pmId: data.property.pmId,
      pmChatId: data?.pmUser?.sbId,
      name: data.property.name,
      unitTenantId: data.id,
      totalDue: data.totalDueAmount,
      underProcessingAmount: data.totalProcessingAmount ?? 0,
      unitId: data.unit?.id,
      unitName: data.unit?.unitName,
      tenantId: data.tenantId,
    };
  }

  toVendorCompany(data) {
    let vendor = {};
    vendor.id = data?.id ?? "";
    vendor.userId = data?.userId ?? "";
    vendor.companyName = data?.companyName ?? "";
    vendor.address = data?.address ?? "";
    vendor.website = data?.website ?? "";
    vendor.businessSize = data?.businessSize ?? "";
    vendor.serviceRadius = data?.serviceRadius ?? 0;
    vendor.propertyTypes = data?.propertyTypes ?? [];
    vendor.image = data?.image ?? {};
    vendor.description = data?.description ?? "";
    if (data.associations) {
      let list = [];
      for (let index = 0; index < data.associations.length; index++) {
        const element = data.associations[index];
        list.push(this.toAssociation(element));
      }
      vendor.associations = list;
    }
    vendor.isServiceIndividualUnits =
      (data?.isServiceIndividualUnits ?? 0) === 1;
    vendor.isServiceSuppliesGoods = (data?.isServiceSuppliesGoods ?? 0) === 1;
    vendor.isServiceUnionizedBusiness =
      (data?.isServiceUnionizedBusiness ?? 0) === 1;
    vendor.isSocialDiversityInclusionPolicy =
      (data?.isSocialDiversityInclusionPolicy ?? 0) === 1;
    vendor.isSocialEnvironSustainPolicy =
      (data?.isSocialEnvironSustainPolicy ?? 0) === 1;
    vendor.isSocialStaffCovidVaccinated =
      (data?.isSocialStaffCovidVaccinated ?? 0) === 1;
    vendor.isServiceSkipped = (data?.isServiceSkipped ?? 0) === 1;
    vendor.isProfileSetupCompleted = (data?.isProfileSetupCompleted ?? 0) === 1;
    vendor.status = data?.status ?? "";
    return vendor;
  }

  toManagerCompany(data) {
    let pm = {};
    pm.id = data?.id ?? "";
    pm.userId = data?.userId ?? "";
    pm.companyName = data?.companyName ?? "";
    pm.address = data?.address ?? "";
    pm.status = data?.status ?? "";
    pm.invoiceDay = data?.rentalInvoiceGenerateDay;
    pm.invoiceDueDay = data?.dueDays;
    pm.lateFee = data?.lateFees;
    return pm;
  }

  toAvatar(response) {
    let avatar = {};
    avatar.thumb = response.thumbnailUrl;
    avatar.url = response.imageUrl ?? "";
    avatar.mimetype = response.mimetype ?? "";
    return avatar;
  }

  toPageInfo(pageInfo) {
    let isNextPageExist =
      pageInfo.currentPage < pageInfo.totalPages ? true : false;
    return {
      isNextPageExist: isNextPageExist,
      currentPage: pageInfo.currentPage,
      totalPages: pageInfo.totalPages,
      totalItems: pageInfo.totalItems,
    };
  }

  toCards(list) {
    if (list?.length > 0) {
      return this.toCard(list[0]);
    }
    return null;
  }

  toCard(response) {
    let card = {};
    card.id = response.id;
    card.company = response.brand;
    card.expMonth = response.expMonth;
    card.expYear = response.expYear;
    card.last4 = response.last4;
    card.isDefault = response.isDefault;
    return card;
  }

  toPropertyList(response) {
    let list = response.list;
    let pageInfo = this.toPageInfo(response.pageInfo);
    return { list, pageInfo };
  }

  toServiceList(response) {
    let list = [];
    for (let i = 0; i < response.list.length; i++) {
      let value = response.list[i];
      list.push(this.toService(value));
    }
    let result = [...list].sort((a, b) => a.label.localeCompare(b.label));
    return result;
  }

  toService(response) {
    let item = {
      id: response.id,
      label: response.name,
      value: response.name,
    };
    return item;
  }

  toAssociationList(response) {
    let list = [];
    for (let i = 0; i < response.list.length; i++) {
      let value = response.list[i];
      list.push(this.toAssociation(value));
    }
    let result = [...list].sort((a, b) => a.label.localeCompare(b.label));
    return result;
  }

  toAssociation(response) {
    let item = {
      id: response.id,
      label: response.name,
      value: response.name,
    };
    return item;
  }

  toBidList(response) {
    let list = [];
    for (let i = 0; i < response?.list?.length ?? 0; i++) {
      let value = response.list[i];
      list.push(this.toBid(value));
    }
    let pageInfo = this.toPageInfo(response.pageInfo);
    return { list, pageInfo };
  }

  toBid(response) {
    let item = {
      id: response.id,
      status: response.status,
      quote: response.price,
      files: response.files,
      vendorId: response?.vendor?.id,
      name: response?.vendor?.companyName,
      thumbUrl: response?.vendor?.image?.thumbnailUrl,
      chatId: response?.vendor?.sbId,
    };
    return item;
  }

  toInvitedVendorList(response) {
    let list = [];
    for (let i = 0; i < response?.list?.length ?? 0; i++) {
      let value = response.list[i];
      list.push(this.toInvitedVendor(value));
    }
    return list;
  }

  toInvitedVendor(response) {
    let item = {
      id: response?.bidData?.id,
      status: response?.bidData?.status,
      quote: response?.bidData?.price,
      files: response?.bidData?.files,
      vendorId: response?.id,
      name: response.companyName,
      thumbUrl: response.image?.thumbnailUrl,
      chatId: response?.sbId,
    };
    return item;
  }

  toTimeLineList(response) {
    let list = [];
    for (let i = 0; i < response?.list?.length ?? 0; i++) {
      let value = response.list[i];
      list.push(this.toTimeLine(value));
    }
    return list;
  }

  toTimeLine(response) {
    var text = "";
    if (response?.eventValue === StatusType.published) {
      text = "Request is published";
    } else if (response?.eventValue === StatusType.confirmed) {
      text = "Vendor is confirmed";
    } else if (response?.eventValue === StatusType.complete) {
      text = "Work is confirmed";
    } else if (response?.eventValue === StatusType.closed) {
      text = "Work is accepted";
    }

    let item = {
      id: response.id,
      date: moment(response.createdAt).format("DD MMM"),
      text: toCapitalize(response?.eventValue ?? ""),
      subtitle: text,
      time: moment(response.createdAt).format("hh:mm a"),
      outline: false,
      fill: "bg-primary",
      year: moment(response.createdAt).format("MMMM, YYYY"),
    };
    return item;
  }

  toProperty(item) {}
}

/////// UPDATE SESSION USER /////////
function updateSessionUserInfo(user) {
  let info = Storage.getLoggedInUser() ?? {};
  info.user = user;
  Storage.setLoggedInUser(info);
}

export function toPageInfo(pageInfo) {
  let isNextPageExist =
    pageInfo.currentPage < pageInfo.totalPages ? true : false;
  return {
    isNextPageExist: isNextPageExist,
    currentPage: pageInfo.currentPage,
    totalPages: pageInfo.totalPages,
    totalItems: pageInfo.totalItems,
  };
}