import React, { useEffect, useState } from "react";
import Head from "../../../head/Head";
import { Spinner } from "reactstrap";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Button,
  UserAvatar,
} from "../../../../components/Component";
import { findUpper, UserAccountType } from "../../../../utils/Utils";
import {
  fetchAssociationList,
  fetchUserInfo,
  getServices,
  vendorSetup,
} from "../../../../pages/auth/AuthData";
import { formatPhoneNumber, openWebsite } from "../../../../utils";
import {
  getServiceOfferFlagsAsText,
  getSocialFlagsAsText,
} from "../../../../pages/panel/customer/VendorData";
import { ManagerUpdateProfileModal } from "./ManagerUpdateProfileModal";
import { VendorUpdateProfileModal } from "./VendorUpdateProfileModal";
import { ImageCropper } from "../../../../components/cropper/cropper";
import { TenantUpdateProfileModel } from "./TenantUpdateProfileModel";

const UserProfileRegularPage = ({
  sm,
  updateSm,
  setProfileName,
  modal,
  setModal,
  ...props
}) => {
  const [modalTab, setModalTab] = useState("1");
  const [user, setUser] = useState({});
  const [services, setServices] = useState([]);
  const [associations, setAssociations] = useState([]);
  const [isImageCropperVisible, setIsImageCropperVisible] = useState(false);
  const [file, setFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  function fetchServices(id) {
    getServices({ vendorId: parseInt(id) }, (array, error) => {
      if (error === null) {
        setServices(array);
      }
      fetchAssociations();
    });
  }

  function fetchAssociations() {
    fetchAssociationList({}, (array, error) => {
      if (error === null) {
        setAssociations(array);
      }
    });
  }

  function fetchUser() {
    fetchUserInfo((data, error) => {
      if (data) {
        setUser(data);
        if (data.accountType === UserAccountType.vendor) {
          fetchServices(data?.companyInfo?.id);
        }
      } else {
        console.log("Error : ", error);
      }
    });
  }

  useEffect(() => {
    fetchUser();
  }, []);

  function uploadLogo(file) {
    setIsLoading(true);
    vendorSetup(file, {}, (data, error) => {
      if (error === null) {
        fetchUser();
      } else {
        console.log("Error : ", error);
      }
      setIsLoading(false);
    });
  }

  return (
    <React.Fragment>
      <Head title="Settings"></Head>
      <BlockHead size="lg">
        <BlockBetween>
          <BlockHeadContent>
            <BlockTitle tag="h4">Account information</BlockTitle>
            <BlockDes>
              {/* <p>Basic info, like your name and address, that you use on Nio Platform.</p> */}
            </BlockDes>
          </BlockHeadContent>
          <BlockHeadContent className="align-self-start d-lg-none">
            <Button
              className={`toggle btn btn-icon btn-trigger mt-n1 ${
                sm ? "active" : ""
              }`}
              onClick={() => updateSm(!sm)}
            >
              <Icon name="menu-alt-r"></Icon>
            </Button>
          </BlockHeadContent>
        </BlockBetween>
      </BlockHead>


      <Block>
        <div className="nk-data data-list ">
          <div
            className="data-head data-col"
            style={{ cursor: "pointer" }}
            onClick={() => {
              setModalTab("1");
              setModal(true);
            }}
          >
            <div className="data-col">
              <h6 className="overline-title">User</h6>
            </div>
            <div className="data-col-end">
              <span className="data-more">
                <Icon name="edit"></Icon>
              </span>
            </div>
          </div>

          <div className="data-item">
            <div className="data-col">
              <span className="data-label">First name</span>
              <span className="data-value">{user?.firstName}</span>
            </div>
          </div>

          <div className="data-item">
            <div className="data-col">
              <span className="data-label">Last name</span>
              <span className="data-value">{user?.lastName}</span>
            </div>
          </div>

          {user?.accountType === UserAccountType.tenant && (
            <div className="data-item">
              <div className="data-col">
                <span className="data-label">Phone</span>
                <span className="data-value">
                  {formatPhoneNumber("", user?.phone)}
                </span>
              </div>
            </div>
          )}
        </div>

        {(user?.accountType === UserAccountType.vendor || user?.accountType === UserAccountType.manager) && (
          <div className="nk-data data-list">
            <div
              className="data-head data-col"
              style={{ cursor: "pointer" }}
              onClick={() => {
                setModalTab("2");
                setModal(true);
              }}
            >
              <div className="data-col">
                <h6 className="overline-title">Company</h6>
              </div>
              <div className="data-col data-col-end">
                <span className="data-more">
                  <Icon name="edit"></Icon>
                </span>
              </div>
            </div>

            <div className="data-item">
              <div className="data-col">
                <span className="data-label">Company name</span>
                <span className="data-value">
                  {user?.companyInfo?.companyName}
                </span>
              </div>
            </div>

            <div className="data-item">
              <div className="data-col">
                <span className="data-label">Office address</span>
                <span className="data-value">
                  {user?.companyInfo?.address?.fullAddress}
                </span>
              </div>
            </div>

            <div className="data-item">
              <div className="data-col">
                <span className="data-label">Phone</span>
                <span className="data-value">
                  {formatPhoneNumber("", user?.phone)}
                </span>
              </div>
            </div>

            {user?.accountType === UserAccountType.vendor && (
              <>
                <div className="data-item">
                  <div className="data-col">
                    <span className="data-label">Website</span>
                    <span className="data-value">
                      {(user?.companyInfo?.website?.length ?? 0) > 0 ? (
                        <a
                          href={undefined}
                          className="text-blue"
                          onClick={(ev) =>
                            openWebsite(user?.companyInfo?.website, ev)
                          }
                        >
                          {user?.companyInfo?.website}
                        </a>
                      ) : (
                        <>{"N/A"}</>
                      )}
                    </span>
                  </div>
                </div>

                <div className="data-item">
                  <div className="data-col">
                    <span className="data-label">Business size</span>
                    <span className="data-value">
                      {user?.companyInfo?.businessSize}
                    </span>
                  </div>
                </div>

                <div className="data-item">
                  <div className="data-col">
                    <span className="data-label">
                      Primary business service(s)
                    </span>
                    <span className="data-value">
                      {services.length > 0 ? (
                        services.map((item, index) => {
                          return (
                            <React.Fragment key={index}>
                              <span>{item.label}</span>
                              <br />
                            </React.Fragment>
                          );
                        })
                      ) : (
                        <span>N/A</span>
                      )}
                    </span>
                  </div>
                </div>

                <div className="data-item">
                  <div className="data-col">
                    <span className="data-label">Service radius</span>
                    <span className="data-value">
                      {user?.companyInfo?.serviceRadius}
                    </span>
                  </div>
                </div>

                <div className="data-item">
                  <div className="data-col">
                    <span className="data-label">Property types serviced</span>
                    <span className="data-value">
                      {user?.companyInfo?.propertyTypes.map((item) => {
                        return (
                          <>
                            <span>{item}</span>
                            <br />
                          </>
                        );
                      })}
                    </span>
                  </div>
                </div>

                <div className="data-item">
                  <div className="data-col">
                    <span className="data-label">Associations</span>
                    <span className="data-value">
                      {user?.companyInfo?.associations.map((item) => {
                        return (
                          <>
                            <span>{item?.value ?? ""}</span>
                            <br />
                          </>
                        );
                      })}
                    </span>
                  </div>
                </div>

                <div className="data-item">
                  <div className="data-col">
                    <span className="data-label">Service offerings</span>
                    <span className="data-value">
                      {getServiceOfferFlagsAsText(user.companyInfo)}
                    </span>
                  </div>
                </div>

                <div className="data-item">
                  <div className="data-col">
                    <span className="data-label">
                      Corporate social responsibility
                    </span>
                    <span className="data-value">
                      {getSocialFlagsAsText(user.companyInfo)}
                    </span>
                  </div>
                </div>

                <div className="data-item">
                  <div className="data-col">
                    <span className="data-label">Business description</span>
                    <span className="data-value">
                      {user?.companyInfo?.description}
                    </span>
                  </div>
                </div>

                <div className="data-item">
                  <div className="data-col">
                    <span className="data-label">Logo</span>
                    <span className="data-value">
                      <UserAvatar
                        className="sq"
                        theme={"white"}
                        text={findUpper(user?.companyInfo?.companyName ?? "")}
                        image={user?.companyInfo?.image?.imageUrl}
                      />
                    </span>
                    <div className="data-col data-col-end">
                      <span className="data-more">
                        {isLoading ? (
                          <Spinner size="sm" color="dark" />
                        ) : (
                          <Icon
                            name="edit"
                            onClick={(ev) => {
                              ev.preventDefault();
                              const input = document.createElement("input");
                              input.type = "file";
                              input.accept = "image/*";
                              input.onchange = (event) => {
                                const file = event.target.files[0];
                                setFile(file);
                                setIsImageCropperVisible(true);
                              };
                              input.click();
                            }}
                          ></Icon>
                        )}
                      </span>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        )}
      </Block>

      {user?.accountType === UserAccountType.manager ? (
        <ManagerUpdateProfileModal
          modal={modal}
          modalTab={modalTab}
          setModalTab={setModalTab}
          user={user}
          onClosed={() => {
            setModal(false);
          }}
          onSaved={(isNeedToReload) => {
            setModal(false);
            if (isNeedToReload) {
              window.location.reload();
            } else {
              fetchUser();
            }
          }}
        />
      ) : user?.accountType === UserAccountType.vendor ? (
        <>
          <VendorUpdateProfileModal
            modal={modal}
            modalTab={modalTab}
            associations={associations}
            setModalTab={setModalTab}
            user={user}
            services={services}
            onClosed={() => {
              setModal(false);
            }}
            onSaved={(isNeedToReload) => {
              setModal(false);
              if (isNeedToReload) {
                window.location.reload();
              } else {
                fetchUser();
              }
            }}
          />
          {isImageCropperVisible && (
            <ImageCropper
              visible={isImageCropperVisible}
              file={file}
              onCancel={() => {
                setFile();
                setIsImageCropperVisible(false);
              }}
              onCrop={(file) => {
                if (file !== null && file !== undefined) {
                  uploadLogo(file);
                }
              }}
            />
          )}
        </>
      ) : (
        <TenantUpdateProfileModel
            modal={modal}
            user={user}
            onClosed={() => {
              setModal(false);
            }}
            onSaved={() => {
              setModal(false);
              window.location.reload();
            }}
          />
      )}
    </React.Fragment>
  );
};
export default UserProfileRegularPage;
