import React, { useEffect, useState } from "react";
import Head from "../../../layout/head/Head";
import Content from "../../../layout/content/Content";
import {
  BackButton,
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Button,
  Col,
  DatePicker,
  DateRangePicker,
  ErrorText,
  Icon,
  PreviewCard,
  PropertyDropdown,
  RSelect,
  RadioButtonOptions,
  Row,
} from "../../../components/Component";
import { useHistory } from "react-router";
import moment from "moment";
import { FileSizeText } from "../../../components/text/FileSizeText";
import classNames from "classnames";
import { useForm } from "react-hook-form";
import { fileUploader } from "../request/RequestData";
import {
  AppText,
  EMAIL_REGX,
  PHONE_REGX_ONLYDIGIT,
  DATE_TIME_FORMAT,
  RadioOptionTypes,
  URGENCY,
  convertToDropdownItem,
  convertToDropdownList,
  covertToNumberOnly,
  formatPhoneNumber,
  showErrorToast,
  showSuccessToast,
} from "../../../utils";
import ImageContainer from "../properties/ImageContainer";
import { ImageCropper } from "../../../components/cropper/cropper";
import { Spinner } from "reactstrap";
import {
  dropDownConvertor,
  fetchCategoryList,
  getTenantList,
  maintenanceRequestSaveApi,
} from "./MaintenanceRequestData";
import { UnitDropdown } from "../../../components/dropdown/UnitDropdown";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import { Storage } from "../../../services/storage/storage";
import { UserAccountType } from "../../../utils/Utils";
import Dropzone from "react-dropzone";

import CategoryDropdown from "../../../components/dropdown/CategoryDropdown";

import TagInput from "../../../components/input/TagInput/TagInput";

export const AddMaintenanceRequest = () => {
  const history = useHistory();
  const location = useLocation();
  const user = Storage.getLoggedInUser()?.user;

  const { handleSubmit } = useForm();

  const [request, setRequest] = useState({});
  const [isError, setIsError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [category, setCategory] = useState();
  const formClass = classNames({
    "form-validate": true,
    "is-alter": false,
  });

  useEffect(() => {
    callCategoryList();
  }, []);

  useEffect(() => {
    if (location?.state?.data) {
      setRequest({
        id: location?.state?.data?.id,
        property: location?.state?.data?.property,
        unit: location?.state?.data?.unit,
        category: location?.state?.data?.category,
        description: location?.state?.data?.description ?? "",
        isEnterPermission:
          location?.state?.data?.isEnterPermission === 1 ? true : false,
        entryInstruction: location?.state?.data?.entryInstruction ?? "",
        urgency: location?.state?.data?.urgency === "HIGH" ? true : false,
        contactPhones: location?.state?.data?.contactPhones ?? "",
        contactEmails: location?.state?.data?.contactEmails ?? "",
        files: location?.state?.data?.files,
        scheduledDate: location?.state?.data?.scheduleFor
          ? moment(location?.state?.data?.scheduleFor).toDate()
          : null,
        scheduledTimeStart: location?.state?.data?.scheduleFor
          ? moment(location?.state?.data?.scheduleFor).toDate()
          : null,
        scheduledTimeEnd: location?.state?.data?.scheduleEnd
          ? moment(location?.state?.data?.scheduleEnd).toDate()
          : null,
        removeFiles: [],
      });
    } else if (user.accountType === UserAccountType.tenant && user.property) {
      let property = {
        id: user.property.id,
        label: user.property.name,
        value: user.property.name,
      };
      let unit = {
        id: user.property.unitId,
        label: user.property.unitName,
        value: user.property.unitName,
      };
      setRequest({
        property: property,
        unit: unit,
        contactEmails: user.email,
        contactPhones: user.phone,
        isEnterPermission: false,
        urgency: false,
      });
    } else {
      setRequest({
        isEnterPermission: false,
        urgency: false,
      });
    }
  }, [location]);

  const onFormSubmit = () => {
    if (
      !request.property ||
      !request.unit ||
      !request.category ||
      (request?.description?.length ?? 0) === 0 ||
      request.isEnterPermission === undefined ||
      request.urgency === undefined ||
      (request.contactPhones?.length ?? 0) === 0 ||
      (request.contactEmails?.length ?? 0) === 0
    ) {
      setIsError(true);
    } else {
      setIsError(false);
      saveMaintenanceRequest();
    }
  };

  const handleImageDrop = (acceptedFiles) => {
    if (acceptedFiles?.length > 0) {
      let File = acceptedFiles[0];
      File.url = URL.createObjectURL(acceptedFiles[0]);
      File.isUploading = true;
      let files = request.files ? [...request.files] : [];
      files.push(File);
      setRequest({
        ...request,
        files: files,
      });
      upload(File, files.length - 1);
    }
  };

  // API----------------------------

  function upload(file, index) {
    fileUploader(
      file,
      file?.type.includes("image/")
        ? "MAINTENANCE_REQ_IMAGE"
        : "MAINTENANCE_REQ_VIDEO",
      (data, error) => {
        let files = request.files ? [...request.files] : [];
        if (error === null) {
          file.uploadedFileId = data.id;
          file.isUploading = false;
          file.isOldFile = false;
          file.thumbUrl = data.thumbUrl;
          files[index] = file;
        } else {
          files.splice(index, 1);
          showErrorToast(error.message);
        }
        setRequest((prev) => ({
          ...prev,
          files: files,
        }));
      }
    );
  }

  const removeFile = (index) => {
    let updatedFile = request.files ? [...request.files] : [];
    let removeFile = request.removeFiles ? [...request.removeFiles] : [];
    let rFile = request.files[index];
    removeFile.push(rFile);
    updatedFile.splice(index, 1);
    setRequest({ ...request, files: updatedFile, removeFiles: removeFile });
  };

  const callCategoryList = () => {
    let params = {
      type: "MR",
    };
    fetchCategoryList(params, (data, error) => {
      if (error === null) {
        let cData = dropDownConvertor(data.list);
        setCategory(cData);
      }
    });
  };

  const getTenantDetails = (unitId) => {
    getTenantList(unitId, (data, error) => {
      if (error === null) {
        setRequest((prev) => ({
          ...prev,
          contactPhones: data.phones,
          contactEmails: data.emails,
        }));
      }
    });
  };

  const saveMaintenanceRequest = () => {
    let serverDateStart =
      request?.scheduledDate && request?.scheduledTimeStart
        ? moment(request?.scheduledDate).format("YYYY-MM-DD") +
          " " +
          moment(request?.scheduledTimeStart).format("HH:mm:ss")
        : null;
    let serverDateEnd =
      request?.scheduledDate && request?.scheduledTimeEnd
        ? moment(request?.scheduledDate).format("YYYY-MM-DD") +
          " " +
          moment(request?.scheduledTimeEnd).format("HH:mm:ss")
        : null;

    setLoading(true);
    let params = {
      id: location?.state?.data?.id ?? null,
      propertyId: request?.property?.id,
      unitId: request?.unit?.id,
      categoryId: request?.category?.id,
      description: request?.description,
      isEnterPermission: request?.isEnterPermission,
      entryInstruction: request?.entryInstruction,
      urgency: request?.urgency ? "HIGH" : "LOW",
      contactPhones: request?.contactPhones,
      contactEmails: request?.contactEmails,
      scheduleFor: serverDateStart
        ? moment(serverDateStart).utc().format("YYYY-MM-DD HH:mm:ss")
        : null,
      scheduleEnd: serverDateEnd
        ? moment(serverDateEnd).utc().format("YYYY-MM-DD HH:mm:ss")
        : null,
      fileIds:
        request?.files
          ?.filter(
            (item) => item.isOldFile === false && item.uploadedFileId !== null
          )
          ?.map((item) => item.uploadedFileId) ?? [],
      removeFileIds:
        request?.removeFiles
          ?.filter((item) => item.isOldFile === true)
          ?.map((item) => item.id) ?? [],
    };

    maintenanceRequestSaveApi(params, (data, error) => {
      if (error === null) {
        showSuccessToast("Maintenance request has been saved successfully.");
        if(location?.state?.data?.id) {
          history.goBack();
        } else {
          user.accountType === UserAccountType.tenant
            ? history.push(`${process.env.PUBLIC_URL}/requests`)
            : history.push(`${process.env.PUBLIC_URL}/tenant-requests`);
        }
      } else {
        showErrorToast(error.message);
      }
      setLoading(false);
    });
  };

  return (
    <React.Fragment>
      <Head title="Requests"></Head>
      <Content>
        <Block>
          <BlockHead size="sm">
            <BlockBetween>
              <BlockHeadContent>
                <BlockTitle page>
                  {location?.state?.data
                    ? "Edit maintenance request"
                    : "New maintenance request"}
                </BlockTitle>
                <BlockDes className="text-soft">
                  <ul className="list-inline">
                    <li>
                      <span className="text-base">
                        Request ID #{request?.id ?? ""}
                      </span>
                    </li>
                    <li>
                      <span className="text-base">
                        Created on{" "}
                        {request.createdAt
                          ? moment(request.createdAt).format(DATE_TIME_FORMAT)
                          : moment().format(DATE_TIME_FORMAT)}
                      </span>
                    </li>
                  </ul>
                </BlockDes>
              </BlockHeadContent>
              <BlockHeadContent>
                <BackButton />
              </BlockHeadContent>
            </BlockBetween>
          </BlockHead>
        </Block>
        <Block className={"justify-left"}>
          <BlockHead size="lg" wide="sm">
            <BlockHeadContent>
              <PreviewCard>
                <form
                  className={formClass}
                  onSubmit={handleSubmit(onFormSubmit)}
                >
                  <Row className="g-4">
                    <Col md="6" className="mt-3 ">
                      <div className="form-group">
                        <label className="form-label">Property</label>
                        <div className="form-control-wrap">
                          <PropertyDropdown
                            value={request?.property}
                            disabled={
                              user.accountType === UserAccountType.tenant
                            }
                            onSelect={(e) => {
                              if (
                                e.id !== request?.property?.id ||
                                !request?.property
                              ) {
                                setRequest({
                                  ...request,
                                  property: e,
                                  unit: null,
                                  contactEmails: null,
                                  contactPhones: null,
                                });
                              }
                            }}
                          />
                        </div>
                        {isError && !request?.property ? <ErrorText /> : null}
                      </div>
                    </Col>
                    <Col md="6" className="mt-3 ">
                      <div className="form-group">
                        <label className="form-label">Unit</label>
                        <div className="form-control-wrap">
                          <UnitDropdown
                            value={request?.unit}
                            propertyId={request?.property?.id}
                            disabled={
                              user.accountType === UserAccountType.tenant
                            }
                            onSelect={(e) => {
                              setRequest({
                                ...request,
                                unit: e,
                                contactEmails: null,
                                contactPhones: null,
                              });
                              e.id && getTenantDetails(e.id);
                            }}
                          />
                        </div>
                        {isError && !request?.unit ? <ErrorText /> : null}
                      </div>
                    </Col>
                    <Col size="12">
                      <div className="form-group">
                        <label className="form-label">Category</label>
                        <div className="form-control-wrap">
                          <CategoryDropdown
                            value={request?.category}
                            onSelect={(e) => {
                              setRequest({
                                ...request,
                                category: e,
                              });
                            }}
                          />
                        </div>
                      </div>
                    </Col>
                    <Col md="12">
                      <RadioButtonOptions
                        name="urgency"
                        label={"Urgency"}
                        labelClassName={"mb-3"}
                        size={6}
                        optionType={RadioOptionTypes.urgency}
                        onChange={(index) =>
                          setRequest({
                            ...request,
                            urgency: index === 1,
                          })
                        }
                        activeIndex={
                          request.urgency === true
                            ? 1
                            : request.urgency === false
                            ? 0
                            : null
                        }
                      />
                      {isError && request?.urgency === undefined && (
                        <ErrorText />
                      )}
                    </Col>
                    {user?.accountType === UserAccountType.manager && (
                      <>
                        <Col md="6">
                          <div className="form-group">
                            <label className="form-label" htmlFor="deadline-1">
                              Scheduled for
                            </label>
                            <div className="form-control-wrap">
                              <DatePicker
                                selected={request?.scheduledDate}
                                className="form-control rounded-0 rounded-end border-start-0"
                                onChange={(date) => {
                                  setRequest({
                                    ...request,
                                    scheduledDate: date,
                                  });
                                }}
                                popperPlacement="bottom-end"
                              />
                            </div>
                          </div>
                        </Col>
                        <Col md="6">
                          <div className="form-group">
                            <label className="form-label" htmlFor="deadline-1">
                              {"Time"}
                            </label>
                            <div className="form-control-wrap">
                              <DateRangePicker
                                start={request?.scheduledTimeStart ?? null}
                                end={request?.scheduledTimeEnd ?? null}
                                showTimeSelectOnly
                                showTimeSelect
                                timeIntervals={15}
                                timeCaption="Time"
                                dateFormat="h:mm aa"
                                onChangeStart={(date) => {
                                  setRequest((prev) => ({
                                    ...request,
                                    scheduledTimeStart: date,
                                  }));
                                }}
                                onChangeEnd={(date) => {
                                  setRequest((prev) => ({
                                    ...request,
                                    scheduledTimeEnd: date,
                                  }));
                                }}
                              />
                            </div>
                          </div>
                        </Col>
                      </>
                    )}
                    <Col md="12">
                      <div className="form-group">
                        <label
                          className="form-label"
                          htmlFor="request-description-1"
                        >
                          Description
                        </label>
                        <div className="form-control-wrap">
                          <textarea
                            type="text"
                            id="request-description-1"
                            name="requestdiscription"
                            className="form-control"
                            value={request?.description ?? ""}
                            onChange={(event) => {
                              setRequest((prev) => ({
                                ...prev,
                                description: event.target.value,
                              }));
                            }}
                          />
                          {isError &&
                          (request?.description?.length ?? 0) === 0 ? (
                            <ErrorText />
                          ) : null}
                        </div>
                      </div>
                    </Col>
                    <Col md="12">
                      <RadioButtonOptions
                        name="permissionToEnter"
                        label={"Permission to enter"}
                        size={6}
                        optionType={RadioOptionTypes.yesOrNo}
                        onChange={(index) =>
                          setRequest({
                            ...request,
                            isEnterPermission: index === 1,
                          })
                        }
                        activeIndex={
                          request.isEnterPermission === true
                            ? 1
                            : request.isEnterPermission === false
                            ? 0
                            : null
                        }
                      />

                      {isError && request?.isEnterPermission === undefined ? (
                        <ErrorText />
                      ) : null}
                    </Col>
                    <Col md="12">
                      <div className="form-group">
                        <label
                          className="form-label"
                          htmlFor="request-entryInstructions-1"
                        >
                          Entry instructions
                        </label>
                        <div className="form-control-wrap">
                          <textarea
                            type="text"
                            id="request-entryInstructions-1"
                            name="entryInstructions"
                            className="form-control min-h-60"
                            value={request?.entryInstruction ?? ""}
                            onChange={(event) => {
                              setRequest((prev) => ({
                                ...prev,
                                entryInstruction: event.target.value ?? "",
                              }));
                            }}
                          />
                        </div>
                      </div>
                    </Col>
                    <Col sm="12">
                      <div className="form-group">
                        <label
                          className="form-label"
                          htmlFor="request-contact-1"
                        >
                          Contact phone
                        </label>
                        <div className="form-control-wrap">
                          <TagInput
                            value={request?.contactPhones}
                            settings={{
                              pattern: PHONE_REGX_ONLYDIGIT,
                              keepInvalidTags: true,
                              validate: (data) => {
                                return PHONE_REGX_ONLYDIGIT.test(data.value);
                              },
                            }}
                            onChange={(data) => {
                              setRequest((prev) => ({
                                ...prev,
                                contactPhones:
                                  data.length > 0
                                    ? data.map((item) => item.value).toString()
                                    : " ",
                              }));
                            }}
                          />
                        </div>
                      </div>
                    </Col>
                    <Col sm="12">
                      <div className="form-group">
                        <label
                          className="form-label"
                          htmlFor="request-contact-1"
                        >
                          Contact Email
                        </label>
                        <div className="form-control-wrap">
                          <TagInput
                            value={request?.contactEmails}
                            settings={{
                              pattern: EMAIL_REGX,
                              keepInvalidTags: true,
                              validate: (data) => {
                                return EMAIL_REGX.test(data.value);
                              },
                            }}
                            onChange={(data) => {
                              setRequest((prev) => ({
                                ...prev,
                                contactEmails:
                                  data.length > 0
                                    ? data.map((item) => item.value).toString()
                                    : " ",
                              }));
                            }}
                          />
                        </div>
                      </div>
                    </Col>
                    <Col sm="12" className={"w-100"}>
                      <label className="form-label">Images and videos</label>
                      <Dropzone
                        onDrop={(acceptedFiles) =>
                          handleImageDrop(acceptedFiles)
                        }
                        noClick={true}
                        accept={[".jpg", ".png", ".jpeg", ".svg", "video/*"]}
                      >
                        {({ getRootProps, getInputProps, open }) => (
                          <section>
                            <div
                              {...getRootProps()}
                              className="dropzone upload-zone dz-clickable"
                            >
                              <input {...getInputProps()} />
                              <Button
                                className={"dropzon-button"}
                                type="button"
                                onClick={open}
                              />
                              {(request?.files?.length ?? 0) === 0 && (
                                <div className="dz-message pt-4">
                                  <span className="dz-message-text">
                                    Drag and drop file
                                  </span>
                                  <span className="dz-message-or">or</span>
                                  <strong className="text-primary">
                                    Select file
                                  </strong>
                                </div>
                              )}
                              {request?.files?.map((file, index) => (
                                <div
                                  key={index}
                                  className="dz-preview dz-processing dz-image-preview dz-error dz-complete position-relative"
                                >
                                  {file.isUploading ? (
                                    <Button
                                      className="btn-round btn-icon min-w-30 min-h-30 d-flex justify-content-center align-item-center top-4 right-4 z-15 position-absolute "
                                      color="primary"
                                      size="sm"
                                    >
                                      <Spinner
                                        className=""
                                        size="sm"
                                        color="white"
                                      />
                                    </Button>
                                  ) : (
                                    <Button
                                      className="btn-round btn-icon top-4 right-4 z-15 position-absolute"
                                      color="danger"
                                      size="sm"
                                      type="button"
                                      onClick={() => {
                                        removeFile(index);
                                      }}
                                    >
                                      <Icon name="cross" />
                                    </Button>
                                  )}
                                  <div className="dz-image">
                                    {file?.type === "video/quicktime" ||
                                    file?.type === "video/webm" ||
                                    file?.type === "video/mp4" ||
                                    file?.mimeType === "video/quicktime" ||
                                    file?.mimeType === "video/webm" ||
                                    file?.mimeType === "video/mp4" ? (
                                      <img
                                        className="h-100 w-100"
                                        src={
                                          file?.thumbUrl
                                        }
                                        alt="preview"
                                      />
                                    ) : (
                                      <img
                                        className="h-100 w-100"
                                        src={
                                          file?.url
                                            ? file?.url
                                            : file?.thumbUrl
                                            ? file?.thumbUrl
                                            : null
                                        }
                                        alt="preview"
                                      />
                                    )}
                                  </div>
                                </div>
                              ))}
                            </div>
                          </section>
                        )}
                      </Dropzone>
                    </Col>
                    <Col xl="12" style={{ marginTop: 50 }}>
                      <Button color="primary" type="submit" disabled={loading}>
                        {loading ? (
                          <Spinner size="sm" color="light" />
                        ) : request.id ? (
                          "Save"
                        ) : (
                          "Submit request"
                        )}
                      </Button>

                      <Button
                        color="gray"
                        type="button"
                        onClick={() => {
                          history.goBack();
                        }}
                        className={"btn-dim ms-2"}
                      >
                        Cancel
                      </Button>
                    </Col>
                  </Row>
                </form>
              </PreviewCard>
            </BlockHeadContent>
          </BlockHead>
        </Block>
      </Content>
    </React.Fragment>
  );
};
