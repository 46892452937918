import React, { useEffect, useState } from "react";
import { Icon, TooltipComponent } from "../../Component";
import {
  COUNTRY_CODE,
  CURRENCY_CODE,
  EMAIL_REGX,
  PASSWORD_REGX,
  PASSWORD_TOOL_TIP,
  PHONE_REGX,
  POSTALCODE_REGX,
} from "../../../utils";

export const FormInputType = {
  default: "default",
  email: "email",
  number: "number",
  amount: "amount",
  password: "password",
  passwordWithoutValidation: "passwordWithoutValidation",
  phone: "phone",
  name: "name",
  postalCode: "postalCode",
};

export const FormInput = ({
  label,
  isShowLabel = true,
  value,
  type,
  required,
  placeholder = null,
  baseClass,
  labelGroupClass,
  labelClass,
  inputClass,
  maxLength = 50,
  errorText,
  onError = () => {},
  onTextChange = () => {},
  disabled = false,
}) => {
  const [error, setError] = useState(null);
  const [passState, setPassState] = useState(false);
  const isPasswordField =
    type === FormInputType.password ||
    type === FormInputType.passwordWithoutValidation;

  useEffect(() => {
    setError(errorText);
  }, [errorText]);

  const handleInputChange = (e) => {
    let inputValue = e.target.value;
    // Perform formatting if requires based on field type
    switch (type) {
      case FormInputType.amount:
        inputValue = formatAmount("", inputValue);
        break;
      default:
        break;
    }
    // Update the input value and length
    onTextChange(inputValue);

    // validate error
    isValidateInput();
  };

  const validateInput = () => {
    value = value?.trim();
    onTextChange(value);
    setError(isValidateInput());
  };

  const isValidateInput = () => {
    if (value === null || value === undefined || value?.length === 0) {
      setError(null);
      return;
    }

    var errorMessage = null;
    switch (type) {
      case FormInputType.email:
        errorMessage = validateEmail(value);
        break;
      case FormInputType.password:
        errorMessage = validatePassword(value);
        break;
      case FormInputType.number:
        errorMessage = validateNumber(value);
        break;
      case FormInputType.name:
        errorMessage = validateName(value);
        break;
      case FormInputType.phone:
        errorMessage = validatePhoneNumber(value);
        break;
      case FormInputType.postalCode:
        errorMessage = validatePostalCode(value);
        break;
      default:
        break;
    }
    onError(errorMessage !== null);
    return errorMessage;
  };

  return (
    <div className={"form-group " + (baseClass ? baseClass : "")}>
      {isShowLabel && (
        <div
          className={
            "form-label-group " + (labelGroupClass ? labelGroupClass : "")
          }
        >
          <label
            className={"form-label " + (labelClass ? labelClass : "")}
            htmlFor={label?.toLowerCase()}
          >
            {label} {required && <span className="text-danger fs-6">*</span>}
          </label>
          {type === FormInputType.password && (
            <TooltipComponent
              icon="help-fill"
              iconClass="card-hint"
              direction="right"
              id="newpassword"
              text={PASSWORD_TOOL_TIP}
            />
          )}
        </div>
      )}
      <div className="form-control-wrap">
        {isPasswordField && (
          <a
            href="#password"
            onClick={(ev) => {
              ev.preventDefault();
              setPassState(!passState);
            }}
            className={`form-icon lg form-icon-right passcode-switch ${
              passState ? "is-hidden" : "is-shown"
            }`}
          >
            <Icon name="eye" className="passcode-icon icon-show"></Icon>
            <Icon name="eye-off" className="passcode-icon icon-hide"></Icon>
          </a>
        )}

        <div
          className={
            type === FormInputType.phone || type === FormInputType.amount
              ? `input-group`
              : ""
          }
        >
          {type === FormInputType.phone && (
            <span className="input-group-text" id="basic-addon1">
              {COUNTRY_CODE}
            </span>
          )}
          {type === FormInputType.amount && (
            <span className="input-group-text" id="basic-addon1">
              {CURRENCY_CODE}
            </span>
          )}
          <input
            type={isPasswordField ? (passState ? "text" : "password") : "text"}
            id={label?.toLowerCase()}
            value={value ?? ""}
            placeholder={placeholder}
            onChange={handleInputChange}
            maxLength={maxLength}
            className={
              "form-control form-input-field " + (inputClass ? inputClass : "")
            }
            onBlur={() => validateInput()}
            onFocus={() => setError(null)}
            autoComplete="off"
            autoCorrect="off"
            disabled={disabled}
          />
        </div>
        {error && <span className="invalid">{error}</span>}
      </div>
    </div>
  );
};

const validateEmail = (value) => {
  const regex = EMAIL_REGX;
  return regex.test(value) ? null : "Invalid email address.";
};

// Validate phone number
const validatePhoneNumber = (value) => {
  const regex = PHONE_REGX;
  return regex.test(value) ? null : "Invalid phone number.";
};

const validatePassword = (value) => {
  const regex = PASSWORD_REGX;
  return regex.test(value) ? null : "Invalid password";
};

// Validate name
const validateName = (value) => {
  const regex = /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/;
  return regex.test(value) ? null : "Invalid name.";
};

// Validate number
const validateNumber = (value) => {
  const regex = /^[0-9]+$/;
  return regex.test(value) ? null : "Invalid number.";
};

const validatePostalCode = (value) => {
  const regex = POSTALCODE_REGX;
  return regex.test(value) ? null : "Invalid PostalCode.";
};

// Validate SSN (Social Security Number)
const validateSSN = (value) => {
  const regex = /^\d{3}-\d{2}-\d{4}$/;
  return regex.test(value) ? null : "Invalid SSN.";
};

function formatAmount(prefix, value) {
  if (value === undefined || value === null || value === "") {
    return "";
  }
  var amount = value.replace(/[^\d.]/g, "");
  amount = prefix + amount.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  amount =
    amount.indexOf(".") >= 0
      ? amount.substr(0, amount.indexOf(".")) +
        amount.substr(amount.indexOf("."), 3)
      : amount;
  return amount;
}
