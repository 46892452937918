import React from "react";
import {
  Alert,
  Col,
  Form,
  Modal,
  ModalBody,
  ModalHeader,
  Spinner,
} from "reactstrap";
import { Button, Icon } from "../../components/Component";
import { addSuggestAssociations, addSuggestServices } from "./AuthData";
import { SuggestionType } from "../../utils";

export const SuggestService = ({
  modal,
  type = SuggestionType.service,
  closeHandler,
  onSelectHandler,
}) => {
  const [services, setServices] = React.useState([""]);
  const [loading, setLoading] = React.useState();
  const [errorVal, setError] = React.useState("");

  function addService() {
    let list = [...services];
    list.push("");
    setServices(list);
  }

  function removeService(index) {
    if (index === 0) return;
    let list = [...services];
    list.splice(index, 1);
    setServices(list);
  }

  function updateService(index, text) {
    let list = [...services];
    list[index] = text;
    setServices(list);
  }

  function confirmService() {
    let list = services.filter((item) => item !== "" && item !== null);
    if (list.length > 0) {
      setLoading(true);
      if(type === SuggestionType.service) {
        addSuggestServices({ name: list }, (data, error) => {
          setLoading(false);
          if (error === null) {
            onSelectHandler();
          } else {
            setError(error.message);
          }
        });
      } else if (type === SuggestionType.association) {
        addSuggestAssociations({ name: list }, (data, error) => {
          setLoading(false);
          if (error === null) {
            onSelectHandler();
          } else {
            setError(error.message);
          }
        });
      } 
    } else {
      setError("Please add your service name.");
    }
  }
  return (
    <Modal isOpen={modal} toggle={() => closeHandler()}>
      <ModalHeader toggle={() => closeHandler()}>
        Suggest a {type === SuggestionType.service ? "service" : "association"}
      </ModalHeader>
      {errorVal && (
        <div className="mx-4 my-2">
          <Alert color="danger" className="alert-icon">
            {" "}
            <Icon name="alert-circle" /> {errorVal}{" "}
          </Alert>
        </div>
      )}
      <ModalBody>
        <div className="form-group">
          {type === SuggestionType.service ? (
            <p>
              Can't find a service that you offer? Suggest a new service, we'll
              review it and add it to our list!
            </p>
          ) : type === SuggestionType.association ? (
            <p>
              Can't find a association that you connected? Suggest a new
              association, we'll review it and add it to our list!
            </p>
          ) : null}
        </div>
        {services.map((item, index) => {
          return (
            <div className="form-control-wrap mb-2" key={index}>
              <div className="input-group">
                <input
                  type="text"
                  id={"input-" + index}
                  name={"input-" + index}
                  className="form-control-lg form-control"
                  placeholder={
                    type === SuggestionType.service
                      ? "Type a service (i.e. Window cleaning)"
                      : type === SuggestionType.association
                      ? "Type a association (i.e. IFMA)"
                      : ""
                  }
                  onChange={(ev) => {
                    updateService(index, ev.target.value);
                  }}
                />
                {index > 0 && (
                  <div className="input-group-append">
                    <Button
                      outline
                      color="danger"
                      className="btn-dim"
                      onClick={() => {
                        removeService(index);
                      }}
                    >
                      <Icon name="cross" />
                    </Button>
                  </div>
                )}
              </div>
            </div>
          );
        })}
        <div className="form-group text-center">
          <Button
            type="text"
            onClick={() => addService()}
            className="text-primary"
          >
            <Icon name="plus" className={"me-1"} />
            <p>Add another service</p>
          </Button>
        </div>
        <div className="form-group text-center">
          <Button
            color={"primary"}
            onClick={() => confirmService()}
            disabled={loading}
          >
            {loading ? <Spinner size="sm" color="light" /> : "Confirm"}
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
};
