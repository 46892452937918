import SendbirdChat from '@sendbird/chat'
import { GroupChannelModule } from '@sendbird/chat/groupChannel';
import { Storage } from "../storage/storage";
import { UserAccountType } from '../../utils/Utils';

export const ChatType = {
  tenant_pm: "personal-tenant-pm",
  vendor_pm: "personal-vendor-pm",
  group: "group",
};

//https://sendbird.com/docs/chat/v3/javascript/getting-started/about-chat-sdk
export default class ChatService {
  static instance = ChatService.instance || new ChatService();

  constructor() {
    new SendbirdChat.init({appId: process.env.REACT_APP_CHAT_APP_ID, modules: [
      new GroupChannelModule(),
    ]})
  }

  isConnected() {
    return SendbirdChat.instance.currentUser !== null;
  }

  async connect(userId, token) {
    return new Promise(async (resolve, reject) => {
      if (!userId) {
        reject("UserID is required.");
        return;
      }
      if (!token) {
        reject("token is null.");
        return;
      }
      let sb = SendbirdChat.instance;
      const result = await sb.connect(userId, token);
      if(result) {
        resolve("connected");
      } else {
        reject("error");
      }
    });
  }

  disconnect() {
    return new Promise((resolve, reject) => {
      let sb = SendbirdChat.instance;
      if (sb) {
        sb.disconnect(() => {
          resolve(null);
        });
      } else {
        resolve(null);
      }
    });
  }

  unreadCount() {
    return new Promise((resolve, reject) => {
      let sb = SendbirdChat.instance;
      sb.getTotalUnreadMessageCount([], "all", function (count, error) {
        if (error) {
          reject(error);
        } else {
          resolve(count);
        }
      });
    });
  }

  createGroupChat(operatorId, userIds, name, image) {
    return new Promise((resolve, reject) => {
      return this.createChatChannel(
        operatorId,
        userIds,
        ChatType.group,
        name,
        image,
      )
        .then(channel => {
          resolve(channel);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  createPersonalChat(operatorId, userIds, chatType) {
    return this.createChatChannel(operatorId, userIds, chatType);
  }

  async createChatChannel(operatorId, userIds, type, name, image) {
      let sb = SendbirdChat.instance;
      var params = {};
      params.isPublic = false;
      params.isEphemeral = false;
      params.isSuper = false;
      params.invitedUserIds = userIds;
      if (operatorId !== undefined && operatorId !== null) {
        if (typeof operatorId === "string") {
          params.operatorUserIds = [operatorId];
        } else {
          params.operatorUserIds = operatorId;
        }
      }
      params.customType = type;

      if (type === ChatType.group) {
        params.isDistinct = false;
        params.name = name;
        if (typeof image === "string") {
          params.coverUrl = image;
        } else {
          params.coverImage = image;
        }
      } else {
        params.isDistinct = true;
      }
      const channel = await sb.groupChannel.createChannel(params);
      return channel;
  }

  getChannelListQuery(accountType) {
    let sb = SendbirdChat.instance;
    var listQuery = sb.groupChannel.createMyGroupChannelListQuery();
    listQuery.includeEmpty = accountType ===UserAccountType.tenant ? true : false;
    listQuery.memberStateFilter = "all"; // 'all', 'joined_only', 'invited_only', 'invited_by_friend', and 'invited_by_non_friend'
    listQuery.order = "latest_last_message"; // 'chronological', 'latest_last_message', '
    listQuery.limit = 100; // The value of pagination limit could be set up to 100.
    return listQuery;
  }

  getChannelSearchQuery() {
    let sb = SendbirdChat.instance;
    var listQuery = sb.groupChannel.createMyGroupChannelListQuery();
    listQuery.includeEmpty = false;
    // listQuery.memberStateFilter = "all"; // 'all', 'joined_only', 'invited_only', 'invited_by_friend', and 'invited_by_non_friend'
    listQuery.order = "latest_last_message"; // 'chronological', 'latest_last_message', '
    listQuery.limit = 100; // The value of pagination limit could be set up to 100.
    //listQuery.channelNameContainsFilter = text;
    //listQuery.nicknameContainsFilter = text;
    return listQuery;
  }

  checkChannelExist(userids) {
    let sb = SendbirdChat.instance;
    var listQuery = sb.groupChannel.createMyGroupChannelListQuery();
    listQuery.includeEmpty = true;
    listQuery.memberStateFilter = "joined_only";
    listQuery.order = "latest_last_message"; // 'chronological', 'latest_last_message', '
    listQuery.limit = 1;
    listQuery.customTypesFilter = [ChatType.personal];
    listQuery.userIdsIncludeFilter = userids;
    listQuery.userIdsIncludeFilterQueryType = "AND";
    return this.getChannelList(listQuery);
  }

  async getChannelList(query) {
    const channels = await query.next();
    return channels;
  }

  getMessageListQuery(channel) {
    var params= {};
    params.limit = 30;
    params.reverse = false;
    var listQuery = channel.createPreviousMessageListQuery(params);
    return listQuery;
  }

  async getMessageList(query) {
    const messages = await query.load();
    return messages;
  }

  getGroupChannel(channelUrl) {
    return new Promise((resolve, reject) => {
      let sb = SendbirdChat.instance;
      sb.groupChannel.getChannel(channelUrl, (channel, error) => {
        if (error) {
          reject(error);
        } else {
          resolve(channel);
        }
      });
    });
  }

  inviteGroupChannel(channel, userIds, callback) {
    channel.inviteWithUserIds(userIds, (groupChannel, error) => {
      callback(groupChannel, error);
    });
  }

  sendTextMessage(channel, text) {
    return new Promise((resolve, reject) => {
      channel.sendUserMessage({message: text}) 
      .onSucceeded((message) => {
        resolve(message);
      })
      .onFailed((error, message) => {
        reject(error);
      });
    });
  }

  async markAsRead(channel) {
    await channel.markAsRead();
  }

  // Informed to Manager for remove feature:
  // Ref: https://messapps.slack.com/archives/C01Q9CLC23T/p1625121173002100
  async deleteAllMessage(channel) {
    return new Promise(async (resolve, reject) => {
      let result = await channel.resetMyHistory();
      result ? resolve(result) :  reject("error");
    });
  }

  async deleteChannel(channel) {
    await channel.delete();
  }

  async deleteMessage(channel, message) {
    await channel.deleteMessage(message);
  }

  deleteChannelByOperatorOnly(channel) {
    return new Promise((resolve, reject) => {
      channel.delete((response, error) => {
        error ? reject(error) : resolve(response);
      });
    });
  }

  leaveChannel(channel) {
    return new Promise((resolve, reject) => {
      channel.leave((response, error) => {
        error ? reject(error) : resolve(response);
      });
    });
  }

  async hideChannel(channel, params, callback) {
    let result = await channel.hide(params);
    callback(result)
  }

  updateChatGroup(channel, name, image) {
    return new Promise((resolve, reject) => {
      channel.updateChannel(name, image, channel.data, (response, error) => {
        error ? reject(error) : resolve(response);
      });
    });
  }

  setGroupOperators(channel, operators) {
    return new Promise((resolve, reject) => {
      channel.addOperators(operators, (response, error) => {
        error ? reject(error) : resolve(response);
      });
    });
  }

  removeGroupOperator(channel, operators) {
    return new Promise((resolve, reject) => {
      channel.removeOperators(operators, (response, error) => {
        error ? reject(error) : resolve(response);
      });
    });
  }
}

export function connectWithChat(callback) {
    let user = Storage.getLoggedInUser()?.user
    let userId = user?.chatId ?? "";
    let token = user?.chatAccessToken ?? "";

    if (userId !== "" && token !== "") {
      ChatService.instance
        .connect(userId, token)
        .then(results => {
          callback(true, null);
        })
        .catch(err => {
          callback(false, err);
        });
    }
}
