import React from "react";

const ImageContainer = ({ className, img, item, onClick }) => {
  return (
    <div
      className={`image-container mb-1 me-1 min-w-254 min-h-254  ${className}`}
      style={{ position: "relative" }}
    >
      <div
        className="gallery-image popup-image "
        onClick={(ev) => {
          onClick();
        }}
      >
        <img className="w-100 rounded " src={img} alt="" />
      </div>
     
    </div>
  );
};

export default ImageContainer;
