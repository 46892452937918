import React from "react";
import { useState } from "react";
import { Col, Form } from "reactstrap";
import { AltitudeModal } from "../../../components/Component";
import { useEffect } from "react";
import DatePicker from "react-datepicker";
import { fetchAddAppliance } from "./PropertyData";
import moment from "moment/moment";
import { removeTimeFromServerDateString } from "../../../utils";

const AddAppliance = ({
  isOpen,
  isEdit,
  propertyDetails,
  data,
  item,
  onSave,
  onCancel,
}) => {
  const [formData, setFormData] = useState({});
  const [errorVal, setError] = useState("");
  const [isDataError, setIsDataError] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (item) {
      if (isOpen) {
        let object = {
          appliances: item?.title,
          unit: item?.totalCount,
          dateInstall: moment(
            removeTimeFromServerDateString(item?.installationDate, "YYYY-MM-DD")
          ).toDate(),
        };
        setFormData(object);
      }
    }
  }, [item]);

  const onInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const resetForm = () => {
    setFormData({});
    setLoading(false);
    setError(null);
  };

  const onFormCancel = () => {
    resetForm();
    onCancel();
  };

  const onFormSubmit = () => {
    var isError = false;
    if (
      (formData?.appliances?.length ?? 0) === 0 ||
      (formData?.unit ?? 0) === 0 ||
      !formData?.dateInstall
    ) {
      isError = true;
    }
    setIsDataError(isError);

    if (!isError) {
      callAddAppliance();
      setLoading(true);
      data(formData);
      setLoading(false);
      onSave();
    }
  };

  // API------------------------------------------->

  const callAddAppliance = () => {
    let params = {
      id: item?.id ? item?.id : null,
      propertyId: propertyDetails?.id,
      title: formData?.appliances,
      totalCount: parseInt(formData?.unit),
      installationDate: moment(formData?.dateInstall).format("YYYY-MM-DD"),
    };
    fetchAddAppliance(params, (data, error) => {
      if (error === null) {
        onSave();
        resetForm();
      } else {
        setError(error.message);
      }
      setLoading(false);
    });
  };

  return (
    <AltitudeModal
      size={"lg"}
      isShowFooter={true}
      isOpen={isOpen}
      loading={loading}
      toggle={() => onFormCancel()}
      title={isEdit ? "Update appliance" : "Add appliance"}
      onSave={() => onFormSubmit()}
      error={errorVal}
    >
      <Form className="row gy-4" autoComplete="off">
        <Col md="12">
          <div className="form-group">
            <label className="form-label">Appliance</label>
            <input
              type="text"
              name="appliances"
              value={formData?.appliances ?? ""}
              onChange={(e) => onInputChange(e)}
              className="form-control"
              maxLength={30}
            />
            {isDataError && (formData.appliances?.length ?? 0) === 0 && (
              <span className="invalid">This field is required</span>
            )}
          </div>
        </Col>
        <Col md="6">
          <div className="form-group">
            <label className="form-label">Unit</label>
            <input
              type="number"
              name="unit"
              value={formData?.unit ?? ""}
              onChange={(e) => onInputChange(e)}
              className="form-control"
              maxLength={30}
            />
            {isDataError && (formData?.unit ?? 0) === 0 && (
              <span className="invalid">This field is required</span>
            )}
          </div>
        </Col>
        <Col md="6">
          <div className="form-group">
            <label className="form-label">Date installed</label>
            <DatePicker
              selected={formData?.dateInstall ? formData?.dateInstall : null}
              className="form-control"
              popperPlacement="top-end"
              onChange={(date) => {
                setFormData({ ...formData, dateInstall: date });
              }}
            />
            {isDataError && !formData?.dateInstall && (
              <span className="invalid">This field is required</span>
            )}
          </div>
        </Col>
      </Form>
    </AltitudeModal>
  );
};

export default AddAppliance;
