import React, { useEffect, useState, useContext, useRef } from "react";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Spinner,
  UncontrolledDropdown,
} from "reactstrap";
import SimpleBar from "simplebar-react";
import { UserAvatar, Icon, Button } from "../../../components/Component";
import { findUpper } from "../../../utils/Utils";
import { ChatContext } from "./ChatContext";
import classNames from "classnames";
import { MeChat, YouChat } from "./ChatPartials";
import ChatService from "../../../services/chat/chatservice";
import { GroupChannelHandler } from "@sendbird/chat/groupChannel";
import SendbirdChat from "@sendbird/chat";

var query = null;
var channel = null;
var isMessageSending = false;
export const ChatBody = ({
  selectedChannel,
  mobileView,
  setMobileView,
  setSelectedChannel,
  isFloatingUI = false,
  currentUserChatId,
  otherUserChatId = null,
  chatType,
  onClose,
}) => {
  const {
    deleteConvo,
    hideChannel,
    chatState,
    updateChannel,
    // eslint-disable-next-line react-hooks/rules-of-hooks
  } = isFloatingUI ? "" : useContext(ChatContext);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [chat, setChat] = isFloatingUI ? useState() : chatState;

  const [otherUser, setOtherUser] = useState({});
  const [sidebar, setsidebar] = useState(false);
  const [inputText, setInputText] = useState("");
  const [messages, setMessages] = React.useState([]);
  const messagesRef = useRef();
  messagesRef.current = messages;

  const messagesEndRef = useRef(null);
  let CHAT_CHANNEL_HANDLER = "CHAT_CHANNEL_HANDLER";

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      const scrollHeight = messagesEndRef.current.scrollHeight;
      const height = messagesEndRef.current.clientHeight;
      const maxScrollTop = scrollHeight - height;
      messagesEndRef.current.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  useEffect(() => {
    if (!isFloatingUI) {
      let channels = chat.filter((item) => item.url === channel?.url);
      if (channels?.length === 1) {
        let channel = channels[0];
        if (channel.lastMessage === null) {
          setMessages([]);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chat]);

  const resizeFunc = () => {
    if (window.innerWidth > 1550) {
      setsidebar(true);
    } else {
      setsidebar(false);
    }
  };

  useEffect(() => {
    if (otherUserChatId !== null) {
      query = null;
      channel = null;
      createChannel();
    } else if (selectedChannel !== null) {
      query = null;
      channel = selectedChannel;
      exploreChannel();
    }
    setupHandler();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedChannel, otherUserChatId]);

  useEffect(() => {
    window.addEventListener("resize", resizeFunc);
    resizeFunc();
    return () => {
      window.removeEventListener("resize", resizeFunc);
    };
  }, []);

  const onInputChange = (e) => {
    setInputText(e.target.value);
  };

  const toggleMenu = () => {
    setsidebar(!sidebar);
  };

  function createChannel() {
    let ids = [otherUserChatId, currentUserChatId];
    ChatService.instance
      .createPersonalChat(ids, ids, chatType)
      .then((groupChannel) => {
        channel = groupChannel;
        exploreChannel();
      })
      .catch((error) => {
        console.log("error: ", error);
      });
  }

  function exploreChannel() {
    setOtherUser(getOtherUser());
    fetchMessages(true);
  }

  function getOtherUser() {
    var otherChatUser;
    let list = channel.members.filter(
      (user) => user.userId !== currentUserChatId
    );
    if (list?.length === 1) {
      otherChatUser = list[0];
    }
    return otherChatUser;
  }

  function fetchMessages(isLoadFromStart = false) {
    if (isLoadFromStart) {
      query = null;
    }

    fetchMessagesList()
      .then((list) => {
        var messageList = [];
        for (let item of list) {
          messageList.push(prepareMessage(item));
        }
        if (isLoadFromStart) {
          setMessages(messageList);
        } else {
          setMessages([...messages, ...messageList]);
        }
        ChatService.instance?.markAsRead(channel);
        !isFloatingUI && updateChannel(channel);
        // setIsLoading(false);
      })
      .catch((err) => {
        // setIsLoading(false);
        console.log(err);
      });
  }

  async function fetchMessagesList() {
    return new Promise((resolve, reject) => {
      if (query === null) {
        query = ChatService.instance.getMessageListQuery(channel);
      }
      ChatService.instance
        .getMessageList(query)
        .then((result) => {
          resolve(result);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  const prepareMessage = (item) => {
    return {
      id: item.messageId,
      chat: [item.message],
      date: new Date(item.createdAt),
      me: item.sender.userId === currentUserChatId,
      user: {
        id: item.sender.userId,
        name: item.sender.nickname,
        // image: "",
      },
      object: item,
    };
  };

  const onTextSubmit = (e) => {
    e.preventDefault();
    let text = inputText.trim();
    if (text.length !== 0 && !isMessageSending) {
      isMessageSending = true;
      ChatService.instance
        .sendTextMessage(channel, text)
        .then((message) => {
          let item = prepareMessage(message);
          let list = [...messages];
          list.push(item);
          setMessages(list);
          setInputText("");
          channel.lastMessage = message;
          isMessageSending = false;
          !isFloatingUI && updateChannel(channel);
        })
        .catch((error) => {
          isMessageSending = false;
        });
    } else {
      setInputText("");
    }
  };

  const onRemoveMessage = (item) => {
    let idx = messages.findIndex((e) => e.id === item.id);
    ChatService.instance.deleteMessage(channel, item.object);
    let list = [...messages];
    list.splice(idx, 1);
    setMessages(list);
  };

  function setupHandler(messages) {
    const sb = SendbirdChat.instance;
    // Remove All Channel Handler
    sb.groupChannel.removeAllGroupChannelHandlers();

    // Handle all channel Messages
    const groupChannelHandler = new GroupChannelHandler({
      onMessageReceived: (groupChannel, message) => {
        if (groupChannel.url === channel?.url) {
          channel = groupChannel;
          let item = prepareMessage(message);
          let list = [...messagesRef.current];
          list.push(item);
          setMessages(list);
          groupChannel.markAsRead();
        } else {
          groupChannel.markAsDelivered();
        }
        !isFloatingUI && updateChannel(groupChannel);
      },
      onUndeliveredMemberStatusUpdated: (groupChannel) => {
        !isFloatingUI && updateChannel(groupChannel);
      },

      onUnreadMemberStatusUpdated: (groupChannel) => {
        !isFloatingUI && updateChannel(groupChannel);
      },
      // onMessageDeleted: (groupChannel, messageId) => {
      //   if (groupChannel.url === channel.url) {
      //     channel = groupChannel;
      //     channel.lastMessage = messages.length > 0 ? messages[messages.length - 1].object : null;
      //   }
      //   updateChannel(groupChannel);
      // },
    });

    sb.groupChannel.addGroupChannelHandler(
      CHAT_CHANNEL_HANDLER,
      groupChannelHandler
    );
  }
  const chatBodyClass = classNames({
    "nk-chat-body": true,
    "show-chat": mobileView,
    // "profile-shown": sidebar && window.innerWidth > 1550,
  });

  return (
    <React.Fragment>
      {channel ? (
        <>
          {Object.keys(channel).length > 0 && (
            <div className={chatBodyClass}>
              <div className="nk-chat-head">
                <ul className="nk-chat-head-info">
                  <li
                    className="nk-chat-body-close"
                    onClick={() => (isFloatingUI ? null : setMobileView(false))}
                  >
                    <a
                      href="#hide-chat"
                      onClick={(ev) => {
                        ev.preventDefault();
                      }}
                      className="btn btn-icon btn-trigger nk-chat-hide ms-n1"
                    >
                      <Icon name="arrow-left"></Icon>
                    </a>
                  </li>
                  <li className="nk-chat-head-user w-100">
                    <div className="user-card">
                      <UserAvatar
                        image={otherUser?.plainProfileUrl}
                        theme={"white"}
                        text={findUpper(otherUser?.nickname ?? "")}
                        className={""}
                      >
                        {otherUser?.connectionStatus === "online" ? (
                          <span className="status dot dot-lg dot-success"></span>
                        ) : (
                          <span className="status dot dot-lg dot-gray"></span>
                        )}
                      </UserAvatar>
                      <div className="user-info">
                        <div className="lead-text">
                          {otherUser?.nickname ?? ""}
                        </div>
                        <div className="sub-text">
                          <span className="d-none d-sm-inline me-1">
                            {otherUser?.metaData?.subName ?? ""}
                          </span>
                        </div>
                        {/* <div className="sub-text">
                          <span className="d-none d-sm-inline me-1">
                            {otherUser?.connectionStatus === "online"
                              ? "Online"
                              : "Offline"}{" "}
                          </span>
                        </div> */}
                      </div>
                    </div>
                  </li>
                </ul>
                {!isFloatingUI ? (
                  <ul className="nk-chat-head-tools">
                    <li className="d-none d-sm-block">
                      <UncontrolledDropdown>
                        <DropdownToggle
                          tag="a"
                          className="dropdown-toggle btn btn-icon btn-trigger text-primary"
                        >
                          <Icon name="setting-fill"></Icon>
                        </DropdownToggle>
                        <DropdownMenu end className="dropdown-menu">
                          <ul className="link-list-opt no-bdr">
                            <li>
                              <DropdownItem
                                tag="a"
                                className="dropdown-item"
                                // disabled={Uchat.archive}
                                href="#dropdown"
                                onClick={(ev) => {
                                  ev.preventDefault();
                                  !isFloatingUI && hideChannel(channel);
                                  setSelectedChannel(null);
                                }}
                              >
                                <Icon name="archive"></Icon>
                                <span>Archive</span>
                              </DropdownItem>
                            </li>
                            <li>
                              <DropdownItem
                                tag="a"
                                className="dropdown-item"
                                href="#dropdown"
                                onClick={(ev) => {
                                  ev.preventDefault();
                                  !isFloatingUI && deleteConvo(channel);
                                }}
                              >
                                <Icon name="cross-c"></Icon>
                                <span>Remove all messages</span>
                              </DropdownItem>
                            </li>
                          </ul>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </li>
                  </ul>
                ) : (
                  <ul className="nk-chat-head-tools">
                    <li className="d-none d-sm-block">
                      <Button
                        className={"btn btn-icon text-primary"}
                        onClick={() => onClose()}
                      >
                        <Icon name="cross"></Icon>
                      </Button>
                    </li>
                  </ul>
                )}
              </div>
              <SimpleBar
                className={`nk-chat-panel ${
                  isFloatingUI ? "h-max-300px h-min-300px" : ""
                }`}
                scrollableNodeProps={{ ref: messagesEndRef }}
              >
                {messages.map((item, idx) => {
                  if (item.me) {
                    return (
                      <MeChat
                        key={idx}
                        item={item}
                        chat={channel}
                        onRemoveMessage={onRemoveMessage}
                      ></MeChat>
                    );
                  } else {
                    return (
                      <YouChat key={idx} item={item} chat={channel}></YouChat>
                    );
                  }
                })}
              </SimpleBar>
              <div className="nk-chat-editor">
                <div className="nk-chat-editor-upload  ms-n1">
                  <div className={`chat-upload-option`}>
                    <ul className="">
                      <li>
                        <a
                          href="#img"
                          onClick={(ev) => {
                            ev.preventDefault();
                          }}
                        >
                          <Icon name="img-fill"></Icon>
                        </a>
                      </li>
                      <li>
                        <a
                          href="#camera"
                          onClick={(ev) => {
                            ev.preventDefault();
                          }}
                        >
                          <Icon name="camera-fill"></Icon>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="nk-chat-editor-form">
                  <div className="form-control-wrap">
                    <textarea
                      className="form-control form-control-simple no-resize"
                      rows="1"
                      id="default-textarea"
                      onChange={(e) => onInputChange(e)}
                      value={inputText}
                      placeholder="Type your message..."
                      onKeyDown={(e) => {
                        e.code === "Enter" && onTextSubmit(e);
                      }}
                    ></textarea>
                  </div>
                </div>
                <ul className="nk-chat-editor-tools g-2">
                  <li>
                    <Button
                      color="primary"
                      onClick={(e) => onTextSubmit(e)}
                      disabled={inputText.trim().length === 0 || isMessageSending}
                      className="btn-round btn-icon"
                    >
                      <Icon name="send-alt"></Icon>
                    </Button>
                  </li>
                </ul>
              </div>
              {window.innerWidth < 1550 && sidebar && (
                <div
                  onClick={() => toggleMenu()}
                  className="nk-chat-profile-overlay"
                />
              )}
            </div>
          )}
        </>
      ) : (
        <>
          {isFloatingUI ? (
            <div className="h-150px align-center justify-center">
              <Spinner size="sm" color="gray" />
            </div>
          ) : (
            <div className="nk-chat-body">
              <div className="nk-chat-blank">
                <div className="nk-chat-blank-icon">
                  <Icon
                    name="chat"
                    className="icon-circle icon-circle-xxl bg-white"
                  ></Icon>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </React.Fragment>
  );
};

export default ChatBody;
