import { useState } from "react";
import { Col, Form } from "reactstrap";
import { AltitudeModal, AmenityDropdown } from "../../../components/Component";
import { useEffect } from "react";
import ImageContainer from "./ImageContainer";
import VideoContainer from "./VideoContainer";

const PreviewModal = ({ isOpen, isEdit, data, item, onSave, onCancel }) => {
  const [loading, setLoading] = useState(false);
  const onFormCancel = () => {
    onCancel();
  };

  return (
    <AltitudeModal
      size={"lg"}
      //   isShowFooter={true}
      isOpen={isOpen}
      loading={loading}
      disabled={loading}
      toggle={() => onFormCancel()}
    >
      <div>
        {(item?.mimeType ?? "").includes("image/") ? (
          <ImageContainer img={item.fileUrl} />
        ) : (
          <VideoContainer
            vedioUrl={item?.fileUrl}
            autoPlay={isOpen}
            controls={isOpen}
            isPlaying={true}
          />
        )}
      </div>
    </AltitudeModal>
  );
};

export default PreviewModal;
