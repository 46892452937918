import React from "react";
import { useLocation } from "react-router";
import Chat from "./Chat";
import { ChatContextProvider } from "./ChatContext";

const ChatContainer = () => {
  const location = useLocation();
  const chatId = location.state?.chatId;
  return (
    <ChatContextProvider>
      <Chat chatId={chatId}/>
    </ChatContextProvider>
  );
};
export default ChatContainer;
