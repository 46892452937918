import React, { Suspense, useLayoutEffect } from "react";
import { Switch, Route } from "react-router-dom";

// import EcomSupport from "../pages/panel/support/Messages";
import VendorList from "../pages/panel/customer/VendorList";
import Settings from "../pages/panel/settings/Settings";

// import PMDashboard from "../pages/panel/index";
import Calendar from "../pages/panel/calendar/Calendar";
import AddRequest from "../pages/panel/request/AddRequest";
import QuoteRequests from "../pages/panel/request/RequestList";
import PropertyListPage from "../pages/panel/properties/PropertyList";
import PropertyDetail from "../pages/panel/properties/PropertyDetail";
import VendorDetails from "../pages/panel/customer/VendorDetails";
import RequestDetails from "../pages/panel/request/RequestDetails";
import VendorRequestList from "../pages/panel/request/VendorRequestList";
import { RedirectAs404, UserAccountType } from "../utils/Utils";
import { useState } from "react";
import { Storage } from "../services/storage/storage";
import UserProfileLayout from "../layout/header/dropdown/user/UserProfileLayout";
import CreatePassword from "../pages/auth/CreatePassword";
import VerifyPassword from "../pages/auth/VerifyPassword";
import ChangeEmail from "../pages/auth/ChangeEmail";
import ChatContainer from "../pages/panel/chat/ChatContainer";
import HomeTenant from "../pages/panel/tenant/Dashboard/HomeTenant";
import TenantList from "../pages/panel/tenant/TenantList";
import EmailRequestProcess from "../pages/auth/EmailRequestProcess";
import TenantRentPayment from "../pages/panel/tenant/Payment/manualPayment/TenantRentPayment";
import TenantDetail from "../pages/panel/tenant/TenantDetail";
import AutoRentPayment from "../pages/panel/tenant/Payment/autoPayment/AutoRentPayment";
import { MaintenanceRequestList } from "../pages/panel/maintenance-request/MaintenanceRequestList";
import { AddMaintenanceRequest } from "../pages/panel/maintenance-request/AddMaintenanceRequest";
import MaintentenanceDetails from "../pages/panel/maintenance-request/MaintentenanceDetails";
import TenentBlack from "../pages/panel/tenant/TenentBlack";

const Pages = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });

  const [user] = useState(Storage.getLoggedInUser()?.user);
  return (
    <Suspense fallback={<div />}>
      <Switch>
        {/* Home page */}
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/`}
          component={
            user?.accountType === UserAccountType.vendor
              ? VendorRequestList
              : user?.accountType === UserAccountType.manager
              ? QuoteRequests
              : HomeTenant
          }
        ></Route>

        {/* Pages only for Tenant */}
        {(user?.accountType === UserAccountType.tenant ||
          user?.accountType === UserAccountType.manager) && (
          <Route
            exact
            path={
              `${process.env.PUBLIC_URL}` +
              `${
                user?.accountType === UserAccountType.tenant
                  ? "/requests"
                  : "/tenant-requests"
              }`
            }
            component={MaintenanceRequestList}
          ></Route>
        )}

        {(user?.accountType === UserAccountType.tenant ||
          user?.accountType === UserAccountType.manager) && (
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/maintenance-request`}
            component={AddMaintenanceRequest}
          ></Route>
        )}

        {(user?.accountType === UserAccountType.tenant ||
          user?.accountType === UserAccountType.manager) && (
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/maintenance-details/:id`}
            component={MaintentenanceDetails}
          ></Route>
        )}

        {(user?.accountType === UserAccountType.tenant ||
          user?.accountType === UserAccountType.manager) && (
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/rent-payment`}
            component={TenantRentPayment}
          ></Route>
        )}

        {/* //auto-rent payment */}

        {user?.accountType === UserAccountType.tenant && (
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/auto-rent-payment`}
            component={AutoRentPayment}
          ></Route>
        )}

        {/* Pages only for Vendor */}

        {/* Pages only for Manager */}
        {user?.accountType === UserAccountType.manager && (
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/tenants`}
            component={TenantList}
          ></Route>
        )}

        {user?.accountType === UserAccountType.manager && (
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/properties`}
            component={PropertyListPage}
          ></Route>
        )}

        {user?.accountType === UserAccountType.manager && (
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/property-detail/:id`}
            component={PropertyDetail}
          ></Route>
        )}

        {user?.accountType === UserAccountType.manager && (
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/tenant-detail/:id`}
            component={TenantDetail}
          ></Route>
        )}

        {user?.accountType === UserAccountType.manager && (
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/new-request`}
            component={AddRequest}
          ></Route>
        )}

        {user?.accountType === UserAccountType.manager && (
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/vendors`}
            component={VendorList}
          ></Route>
        )}

        {user?.accountType === UserAccountType.manager && (
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/vendor/:id`}
            component={VendorDetails}
          ></Route>
        )}

        {/* Common Pages */}
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/calendar`}
          component={Calendar}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/chat`}
          component={ChatContainer}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/request/:id`}
          component={RequestDetails}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/settings`}
          component={Settings}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/user-profile`}
          component={UserProfileLayout}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/user-profile-notification`}
          component={UserProfileLayout}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/user-profile-payment`}
          component={UserProfileLayout}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/user-profile-activity`}
          component={UserProfileLayout}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/user-profile-setting`}
          component={UserProfileLayout}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/user-invoice-setting`}
          component={UserProfileLayout}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/verify-create-password`}
          component={VerifyPassword}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/new-password`}
          component={CreatePassword}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/new-email`}
          component={ChangeEmail}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/email-request-process`}
          component={EmailRequestProcess}
        ></Route>
        <Route component={RedirectAs404}></Route>
      </Switch>
    </Suspense>
  );
};
export default Pages;
