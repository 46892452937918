import React from "react";
import {
  Block,
  BlockBetween,
  BlockHead,
  BlockTitle,
  DataTable,
  DataTableBody,
  DataTableHead,
  DataTableItem,
  DataTableRow,
  Icon,
} from "../../../components/Component";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import { useState } from "react";
import AddContact from "./AddContact";
import { useEffect } from "react";
import AddOwner from "./AddOwner";
import {
  COUNTRY_CODE,
  CURRENCY_CODE,
  DATE_FORMAT,
  formatPhoneNumber,
  getPayoutAccountData,
  numberFormatter,
  propertiesTypes,
  showErrorToast,
} from "../../../utils";
import AddListingInformation from "./AddListingInformation";
import AddAppliance from "./AddAppliance";
import { ImageCropper } from "../../../components/cropper/cropper";
import Swal from "sweetalert2";
import {
  fetchPropertyDetails,
  fetchRemoveAppliance,
  fetchRemoveContact,
  fetchRemoveOwner,
  fetchRemovePropertyMedia,
  fetchSavePropertyMedia,
} from "./PropertyData";

import moment from "moment/moment";
import Slider from "react-slick";
// import { SlickArrowLeft, SlickArrowRight } from "./SlideRightLeft";

import LoadingComponent from "../../../layout/spinner/LoadingSpinner";
import { fileUploader } from "../request/RequestData";
import ImageContainer from "./ImageContainer";
import { SlickArrowLeft } from "./SlickArrowLeft";
import { SlickArrowRight } from "./SlickArrowRight";
import PropertyAddModel from "./PropertyAdd";

const PropertyGeneral = ({ property, onReloadPropertyRequest}) => {
  const [file, setFile] = useState([]);
  const [isImageCropperVisible, setIsImageCropperVisible] = useState(false);
  const [PropertyDetails, setPropertyDetails] = useState();
  const [selectedContact, setSelectedContact] = useState();
  const [selectedOwner, setSelectedOwner] = useState();
  const [selectedAppliance, setSelectedAppliance] = useState();
  const [listingInformation, setListingInformation] = useState();

  const [modal, setModal] = useState({
    edit: false,
    add: false,
  });

  const [propertyModal, setPropertyModal] = useState(false);

  const [rentalOwnnerModal, setRentalOwnnerModal] = useState({
    edit: false,
    add: false,
  });
  const [listingInformationModal, setListingInformationModal] = useState({
    edit: false,
    add: false,
  });
  const [applianceModal, setApplianceModal] = useState({
    edit: false,
    add: false,
  });

  useEffect(() => {
    setPropertyDetails(property);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [property]);
  

  function modelClose() {
    setModal({ edit: false }, { add: false });
  }

  // slider------------------------------>
  const sliderSettings = {
    className: "slider-init row",
    slidesToShow: 1,
    centerMode: false,
    slidesToScroll: 1,
    infinite: false,
    arrows: true,
    prevArrow: <SlickArrowLeft />,
    nextArrow: <SlickArrowRight />,
    responsive: [
      { breakpoint: 3000, settings: { slidesToShow: 3 } },
      { breakpoint: 1302, settings: { slidesToShow: 2 } },
      { breakpoint: 687, settings: { slidesToShow: 1 } },
    ],
  };

  function upload(file) {
    fileUploader(file, "PROPERTY_IMAGE", (data, error) => {
      // let files = [...request.files];
      if (error === null) {
        file.uploadedFileId = data.id;
        file.isUploading = false;
        callPropertyMediaSave(file);
      } else {
        showErrorToast(error.message);
      }
    });
  }

  const onRemoveContact = (contact) => {
    Swal.fire({
      title: "Are you sure?",
      text: `You want to remove contact?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, remove it!",
    }).then((result) => {
      if (result.isConfirmed) {
        let params = {
          id: contact.id,
        };

        fetchRemoveContact(params, (data, error) => {
          if (error === null) {
            loadPropertyDetails();
          }
        });
      }
    });
  };
  const onRemoveRentalOwners = (owner) => {
    Swal.fire({
      title: "Are you sure?",
      text: `You want to remove rental owners?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, remove it!",
    }).then((result) => {
      if (result.isConfirmed) {
        let params = {
          id: owner.id,
        };

        fetchRemoveOwner(params, (data, error) => {
          if (error === null) {
            loadPropertyDetails();
          }
        });
      }
    });
  };
  const onRemoveAppliance = (item) => {
    Swal.fire({
      title: "Are you sure?",
      text: `You want to remove ${item?.name} appliances?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, remove it!",
    }).then((result) => {
      if (result.isConfirmed) {
        let params = { id: item?.id, propertyId: property?.id };
        fetchRemoveAppliance(params, (data, error) => {
          if (error === null) {
            loadPropertyDetails();
          }
        });
      }
    });
  };

  // PropertyDetails?.type

  // API--------------------------------
  const loadPropertyDetails = () => {
    onReloadPropertyRequest();
  };

  const callRemovePropertyMedia = (id) => {
    let params = { id: id };
    fetchRemovePropertyMedia(params, (data, error) => {
      if (error === null) {
        loadPropertyDetails();
      }
    });
  };

  const callPropertyMediaSave = (file) => {
    let params = {
      id: property?.id,
      fileIds: [file?.uploadedFileId],
    };
    fetchSavePropertyMedia(params, (data, error) => {
      if (error === null) {
        loadPropertyDetails();
      }
    });
  };

  let bank =
    PropertyDetails && getPayoutAccountData(PropertyDetails.payoutAccount);
  return (
    <div className="card-aside-wrap ">
      <div className="card-content">
        <div className="card-inner p-0 max-w-856">
          <Block>
            
            <BlockHead>
              <BlockBetween>
              <BlockTitle tag="h5">Property details</BlockTitle>
                <a
                  href="#addnote"
                  onClick={(ev) => {
                    ev.preventDefault();
                    setPropertyModal(true);
                  }}
                  className="link link-sm"
                >
                  <p className="fSize14 fw-normal">Edit property</p>
                </a>
              </BlockBetween>
            </BlockHead>
            <div className="profile-ud-list">
              <div className="profile-ud-item">
                <div className="profile-ud wider">
                  <span className="profile-ud-label">Address</span>
                  <span className="profile-ud-value">
                    {PropertyDetails?.address?.fullAddress}
                  </span>
                </div>
              </div>
              <div className="profile-ud-item">
                <div className="profile-ud wider">
                  <span className="profile-ud-label">Type</span>
                  <span className="profile-ud-value">
                    {
                      propertiesTypes.filter(
                        (item) => item?.value === PropertyDetails?.type
                      )[0]?.label
                    }
                  </span>
                </div>
              </div>
              <div className="profile-ud-item">
                <div className="profile-ud wider">
                  <span className="profile-ud-label">Annual budget</span>
                  <span className="profile-ud-value">
                    {PropertyDetails?.annualBudget
                      ? numberFormatter(
                          CURRENCY_CODE,
                          "" + PropertyDetails?.annualBudget ?? 0
                        )
                      : ""}
                    {/* {PropertyDetails?.annualBudget} */}
                  </span>
                </div>
              </div>
              <div className="profile-ud-item">
                <div className="profile-ud wider">
                  <span className="profile-ud-label">Year constructed</span>
                  <span className="profile-ud-value">
                    {PropertyDetails?.yearConstructed ?? ""}
                  </span>
                </div>
              </div>
              <div className="profile-ud-item">
                <div className="profile-ud wider">
                  <span className="profile-ud-label">Size</span>
                  <span className="profile-ud-value">
                    {/* {PropertyDetails?.sizeSqFt} */}
                    {PropertyDetails?.sizeSqFt ? (
                      <>
                        {numberFormatter(
                          "",
                          "" + PropertyDetails?.sizeSqFt ?? 0
                        )}
                        <span className="ms-1">
                          ft<sup> 2</sup>
                        </span>
                      </>
                    ) : (
                      ""
                    )}
                  </span>
                </div>
              </div>
              <div className="profile-ud-item">
                <div className="profile-ud wider">
                  <span className="profile-ud-label">Stories</span>
                  <span className="profile-ud-value">
                    {PropertyDetails?.stories}
                  </span>
                </div>
              </div>
              <div className="profile-ud-item">
                <div className="profile-ud wider">
                  <span className="profile-ud-label">Number of units </span>
                  <span className="profile-ud-value">
                    {PropertyDetails?.units}
                  </span>
                </div>
              </div>
              <div className="profile-ud-item">
                <div className="profile-ud wider">
                  <span className="profile-ud-label">Operating account</span>
                  <span className="profile-ud-value">
                    {bank?.value ?? "Not Specified"}
                  </span>
                </div>
              </div>
            </div>
          </Block>
          {/* Contacts */}
          <Block>
            <BlockHead size="sm">
              <BlockBetween>
                <BlockTitle tag="h5">Contacts</BlockTitle>
                <a
                  href="#addnote"
                  onClick={(ev) => {
                    ev.preventDefault();
                    setModal({ ...modal, add: true });
                  }}
                  className="link link-sm"
                >
                  <p className="fSize14 fw-normal">Add contact</p>
                </a>
              </BlockBetween>
            </BlockHead>
            <Block>
              <DataTable className="card-stretch remove-shadow border">
                <DataTableBody>
                  <DataTableHead>
                    <DataTableRow>
                      <span className="sub-text text-secondary">Name</span>
                    </DataTableRow>
                    <DataTableRow>
                      <span className="sub-text text-secondary">Role</span>
                    </DataTableRow>
                    <DataTableRow>
                      <span className="sub-text text-secondary">Email</span>
                    </DataTableRow>
                    <DataTableRow>
                      <span className="sub-text text-secondary">Phone</span>
                    </DataTableRow>
                    <DataTableRow>
                      <span className="sub-text text-secondary"></span>
                    </DataTableRow>
                  </DataTableHead>
                  {/*Head*/}
                  {PropertyDetails?.contact?.length > 0
                    ? PropertyDetails?.contact.map((contact, index) => (
                        <React.Fragment key={index}>
                          <DataTableItem
                            className={"cursor"}
                            onClick={() => "showStaffClick(item)"}
                          >
                            <DataTableRow
                              size="sm"
                              className="w-max-250px text-secondary"
                            >
                              {/* <span className="mt-1">
                              {contact?.firstName} {contact?.lastName}
                            </span> */}
                              <div className="project-info">
                                <h6 className="title fSize14">
                                  {contact?.firstName} {contact?.lastName}
                                </h6>
                              </div>
                              {/* </Link> */}
                            </DataTableRow>
                            <DataTableRow className="w-max-250px text-secondary">
                              <span>{contact?.role} </span>
                            </DataTableRow>
                            <DataTableRow className="w-max-250px text-secondary">
                              <span>{contact?.email}</span>
                            </DataTableRow>
                            <DataTableRow className="w-max-250px text-secondary">
                              <span>
                                {formatPhoneNumber(
                                  COUNTRY_CODE + " ",
                                  contact?.phone
                                ) ?? "N/A"}
                              </span>
                            </DataTableRow>
                            <DataTableRow className="nk-tb-col-tools text-end">
                              <ul className="nk-tb-actions gx-1">
                                <li>
                                  <UncontrolledDropdown>
                                    <DropdownToggle
                                      tag="a"
                                      className="text-soft dropdown-toggle btn btn-icon btn-trigger"
                                    >
                                      <Icon name="more-h"></Icon>
                                    </DropdownToggle>
                                    <DropdownMenu end>
                                      <ul className="link-list-opt no-bdr">
                                        <li>
                                          <DropdownItem
                                            tag="a"
                                            onClick={(ev) => {
                                              ev.preventDefault();
                                              setSelectedContact(contact);
                                              setModal({ edit: true });
                                            }}
                                          >
                                            <Icon name="edit"></Icon>
                                            <span>Edit</span>
                                          </DropdownItem>
                                        </li>
                                        <li>
                                          <DropdownItem
                                            tag="a"
                                            onClick={(ev) => {
                                              ev.preventDefault();
                                              onRemoveContact(contact);
                                            }}
                                          >
                                            <Icon name="trash"></Icon>
                                            <span>Remove</span>
                                          </DropdownItem>
                                        </li>
                                      </ul>
                                    </DropdownMenu>
                                  </UncontrolledDropdown>
                                </li>
                              </ul>
                            </DataTableRow>
                          </DataTableItem>
                        </React.Fragment>
                      ))
                    : null}
                </DataTableBody>
                {(PropertyDetails?.contact?.length ?? 0) === 0 ? (
                  <div className="text-center p-3">
                    <span className="text-silent">{"NO DATA FOUND"}</span>
                  </div>
                ) : null}
              </DataTable>
            </Block>
          </Block>
          {/* Rental owners */}
          <Block>
            <BlockHead size="sm">
              <BlockBetween>
                <BlockTitle tag="h5">Rental owners</BlockTitle>
                <a
                  href="#addnote"
                  onClick={(ev) => {
                    ev.preventDefault();
                    setRentalOwnnerModal({ ...rentalOwnnerModal, add: true });
                  }}
                  className="link link-sm"
                >
                  <p className="fSize14 fw-normal">Add owner</p>
                </a>
              </BlockBetween>
            </BlockHead>
            <Block>
              <DataTable className="card-stretch remove-shadow border">
                <DataTableBody>
                  <DataTableHead>
                    <DataTableRow>
                      <span className="sub-text text-secondary">Owner</span>
                    </DataTableRow>
                    <DataTableRow size="mb">
                      <span className="sub-text text-secondary">
                        Ownership percentage.
                      </span>
                    </DataTableRow>
                    <DataTableRow></DataTableRow>
                  </DataTableHead>
                  {/*Head*/}

                  {PropertyDetails?.owner?.length > 0 &&
                    PropertyDetails?.owner.map((owner, index) => (
                      <React.Fragment key={index}>
                        <DataTableItem
                          className={"cursor"}
                          onClick={() => "showStaffClick(item)"}
                        >
                          <DataTableRow className="w-max-250px text-secondary">
                            <div className="project-info">
                              <h6 className="title fSize14">
                                {owner?.firstName} {owner?.lastName}
                              </h6>
                            </div>

                            {/* </Link> */}
                          </DataTableRow>
                          <DataTableRow
                            size="mb"
                            className="w-max-250px text-secondary"
                          >
                            <div className="user-info">
                              <span className="tb-lead">
                                {owner?.percentage}%
                              </span>
                            </div>
                          </DataTableRow>
                          <DataTableRow className="nk-tb-col-tools text-end">
                            <ul className="nk-tb-actions gx-1">
                              <li>
                                <UncontrolledDropdown>
                                  <DropdownToggle
                                    tag="a"
                                    className="text-soft dropdown-toggle btn btn-icon btn-trigger"
                                  >
                                    <Icon name="more-h"></Icon>
                                  </DropdownToggle>
                                  <DropdownMenu end>
                                    <ul className="link-list-opt no-bdr">
                                      <li>
                                        <DropdownItem
                                          tag="a"
                                          onClick={(ev) => {
                                            ev.preventDefault();
                                            setSelectedOwner(owner);
                                            setRentalOwnnerModal({
                                              ...rentalOwnnerModal,
                                              edit: true,
                                            });
                                          }}
                                        >
                                          <Icon name="edit"></Icon>
                                          <span>Edit</span>
                                        </DropdownItem>
                                      </li>
                                      <li>
                                        <DropdownItem
                                          tag="a"
                                          onClick={(ev) => {
                                            ev.preventDefault();

                                            onRemoveRentalOwners(owner);
                                          }}
                                        >
                                          <Icon name="trash"></Icon>
                                          <span>Remove</span>
                                        </DropdownItem>
                                      </li>
                                    </ul>
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              </li>
                            </ul>
                          </DataTableRow>
                        </DataTableItem>
                      </React.Fragment>
                    ))}
                </DataTableBody>
                {(PropertyDetails?.owner?.length ?? 0) === 0 ? (
                  <div className="text-center p-3">
                    <span className="text-silent">{"NO DATA FOUND"}</span>
                  </div>
                ) : null}
              </DataTable>
            </Block>
          </Block>
          {/* Listing information */}
          <Block className="">
            <BlockHead size="sm">
              <BlockBetween>
                <BlockTitle tag="h5">Listing information</BlockTitle>
                <a
                  href="#addnote"
                  onClick={(ev) => {
                    ev.preventDefault();
                    setListingInformationModal({
                      ...listingInformationModal,
                      add: true,
                    });
                  }}
                  className="link link-sm"
                >
                  <p className="fSize14 fw-normal">Edit listing information</p>
                </a>
              </BlockBetween>
            </BlockHead>
            <Block className="p-3 border rounded">
              <div className="mb-3">
                <p tag="h6">Discription</p>
                <div className="px-3 text-dark">
                  {PropertyDetails?.description
                    ? PropertyDetails?.description
                    : "N/A"}{" "}
                </div>
              </div>
              <div className="mb-3">
                <p tag="h6">Amenities</p>
                <div className="px-3">
                  {(PropertyDetails?.amenities?.length ?? 0) > 0
                    ? PropertyDetails?.amenities.map((aminities, index) => (
                        <React.Fragment key={"aminities" + index}>
                          <div className="custom-control custom-checkbox custom-control-pro no-control m-1">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              id={"service_clicked" + index}
                              name="sizeCheck"
                            />
                            <label
                              className="custom-control-label"
                              htmlFor={"service_clicked" + index}
                            >
                              {aminities.name}
                            </label>
                          </div>
                        </React.Fragment>
                      ))
                    : "N/A"}
                </div>
              </div>
              <div>
                <p tag="h6">Included in rent</p>
                <div className="px-3 text-dark">
                  {PropertyDetails?.includedInRent
                    ? PropertyDetails?.includedInRent
                    : "N/A"}{" "}
                </div>
              </div>
            </Block>
          </Block>

          {/* Appliances */}
          <Block>
            <BlockHead size="sm">
              <BlockBetween>
                <BlockTitle tag="h5">Appliances</BlockTitle>
                <a
                  href="#addnote"
                  onClick={(ev) => {
                    ev.preventDefault();
                    setApplianceModal({
                      ...applianceModal,
                      add: true,
                    });
                  }}
                  className="link link-sm"
                >
                  <p className="fSize14 fw-normal">Add appliance</p>
                </a>
              </BlockBetween>
            </BlockHead>
            <DataTable className="card-stretch remove-shadow border">
              <DataTableBody>
                <DataTableHead>
                  <DataTableRow>
                    <span className="sub-text text-secondary">Appliance</span>
                  </DataTableRow>
                  <DataTableRow size="mb">
                    <span className="sub-text text-secondary">Unit</span>
                  </DataTableRow>
                  <DataTableRow size="mb">
                    <span className="sub-text text-secondary">
                      Date installed
                    </span>
                  </DataTableRow>
                  <DataTableRow></DataTableRow>
                </DataTableHead>
                {/*Head*/}

                {PropertyDetails?.appliance?.length > 0 &&
                  PropertyDetails?.appliance?.map((applianceItem, index) => (
                    <React.Fragment key={"applianceItem" + index}>
                      <DataTableItem
                        className={"cursor"}
                        onClick={() => "showStaffClick(item)"}
                      >
                        <DataTableRow>
                          {/* <span className="w-max-250px text-secondary"></span> */}
                          <div className="project-info">
                            <h6 className="title fSize14">
                              {applianceItem?.title}
                            </h6>
                          </div>
                          {/* </Link> */}
                        </DataTableRow>
                        <DataTableRow size="mb">
                          <span className="w-max-250px text-secondary">
                            {applianceItem?.totalCount}
                          </span>
                        </DataTableRow>
                        <DataTableRow size="mb">
                          <span className="w-max-250px text-secondary">
                            {moment(applianceItem?.installationDate).format(
                              DATE_FORMAT
                            )}
                          </span>
                        </DataTableRow>
                        <DataTableRow className="nk-tb-col-tools text-end">
                          <ul className="nk-tb-actions gx-1">
                            <li>
                              <UncontrolledDropdown>
                                <DropdownToggle
                                  tag="a"
                                  className="text-soft dropdown-toggle btn btn-icon btn-trigger"
                                >
                                  <Icon name="more-h"></Icon>
                                </DropdownToggle>
                                <DropdownMenu end>
                                  <ul className="link-list-opt no-bdr">
                                    <li>
                                      <DropdownItem
                                        tag="a"
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                          setSelectedAppliance(applianceItem);
                                          setApplianceModal({
                                            ...applianceModal,
                                            edit: true,
                                          });
                                        }}
                                      >
                                        <Icon name="edit"></Icon>
                                        <span>Edit</span>
                                      </DropdownItem>
                                    </li>
                                    <li>
                                      <DropdownItem
                                        tag="a"
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                          onRemoveAppliance(applianceItem);
                                        }}
                                      >
                                        <Icon name="trash"></Icon>
                                        <span>Remove</span>
                                      </DropdownItem>
                                    </li>
                                  </ul>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </li>
                          </ul>
                        </DataTableRow>
                      </DataTableItem>
                    </React.Fragment>
                  ))}
              </DataTableBody>
              {(PropertyDetails?.appliance?.length ?? 0) === 0 ? (
                <div className="text-center p-3">
                  <span className="text-silent">{"NO DATA FOUND"}</span>
                </div>
              ) : null}
            </DataTable>
          </Block>
          {/* images /video */}
          {/*** Tempory hide the Image and video section */}
          {/* <Block>
            <BlockHead size="sm">
              <BlockBetween>
                <BlockTitle tag="h5">Images and videos</BlockTitle>
                <BlockBetween>
                  <a
                    className="link link-sm mx-1"
                    href="#image"
                    onClick={(ev) => {
                      ev.preventDefault();
                      const input = document.createElement("input");
                      input.type = "file";
                      input.accept = "image/*";
                      input.onchange = (event) => {
                        const file = event.target.files[0];
                        setFile(file);
                        setIsImageCropperVisible(true);
                      };
                      input.click();
                    }}
                  >
                    <p className="fSize14 fw-normal">Upload files</p>
                  </a>
                </BlockBetween>
              </BlockBetween>
            </BlockHead>
          </Block>
          <Block className={"p-5 w-100"}>
            {(PropertyDetails?.propertyMedia?.length ?? 0) > 0 && (
              <Slider className="" {...sliderSettings}>
                {PropertyDetails?.propertyMedia?.map((item, index) => {
                  return (
                    <div className="image-slider-view" key={index}>
                      <ImageContainer
                        img={item?.fileUrl}
                        item={item}
                        onDelete={() => callRemovePropertyMedia(item?.id)}
                      />
                    </div>
                  );
                })}
              </Slider>
            )}
          </Block>
          {isImageCropperVisible && (
            <ImageCropper
              visible={isImageCropperVisible}
              file={file}
              onCancel={() => {
                setFile();
                setIsImageCropperVisible(false);
              }}
              onCrop={(file) => {
                if (file !== null && file !== undefined) {
                  let File = file;
                  File.isUploading = true;
                  upload(File);
                }
              }}
            />
          )} */}

          {/** Add block to manage space at bottom */}
          <Block />

          {/* modal---------------------> */}
          {(modal.add || modal.edit) && (
            <AddContact
              isOpen={modal.add || modal.edit}
              isEdit={modal.edit}
              propertyDetails={property}
              item={selectedContact}
              onCancel={() => {
                modelClose();
                setSelectedContact();
              }}
              onSave={() => {
                loadPropertyDetails();
              }}
            />
          )}

          {(rentalOwnnerModal.add || rentalOwnnerModal.edit) && (
            <AddOwner
              isOpen={rentalOwnnerModal.add || rentalOwnnerModal.edit}
              isEdit={rentalOwnnerModal.edit}
              currentHoldingPercentage={
                PropertyDetails?.owner?.reduce(function (prev, current) {
                  if(selectedOwner && selectedOwner.id === current.id) {
                    return prev;
                  } else {
                    return prev + +current.percentage;
                  }
                }, 0) ?? 0
              }
              propertyDetails={property}
              item={selectedOwner}
              data={(data) => {
                // const list = [...contactFormData];
                // list.push(data);
              }}
              onCancel={() => {
                setRentalOwnnerModal({ edit: false }, { add: false });
                setSelectedOwner();
              }}
              onSave={() => {
                loadPropertyDetails();
              }}
            />
          )}
          {(listingInformationModal.add || listingInformationModal.edit) && (
            <AddListingInformation
              isOpen={
                listingInformationModal.add || listingInformationModal.edit
              }
              isEdit={listingInformationModal.edit}
              propertyDetails={property}
              item={{
                description: PropertyDetails?.description,
                includedInRent: PropertyDetails?.includedInRent,
                amenities: PropertyDetails?.amenities,
              }}
              data={(data) => {
                let list = [];
                data?.amenities?.map((item, index) => {
                  return list.push(index);
                });
                // const list = [...contactFormData];
                // list.push(data);
                setListingInformation({ ...data, amenities: list });
              }}
              onCancel={() => {
                setListingInformationModal({ edit: false }, { add: false });
              }}
              onSave={() => {
                setListingInformationModal({ edit: false }, { add: false });
                loadPropertyDetails();
              }}
            />
          )}
          {(applianceModal.add || applianceModal.edit) && (
            <AddAppliance
              isOpen={applianceModal.add || applianceModal.edit}
              isEdit={applianceModal.edit}
              propertyDetails={property}
              item={selectedAppliance}
              data={(data) => {
                // const list = [...contactFormData];
                // list.push(data);
                setListingInformation(data);
              }}
              onCancel={() => {
                setApplianceModal({ edit: false }, { add: false });
              }}
              onSave={() => {
                setApplianceModal({ edit: false }, { add: false });
                loadPropertyDetails();
              }}
            />
          )}

          {propertyModal && (
             <PropertyAddModel
             isOpen={propertyModal}
             property={PropertyDetails}
             onCancel={() => {
               setPropertyModal(false);
             }}
             onSave={() => {
              setPropertyModal(false);
              loadPropertyDetails();
             }}
           />
          )}
        </div>
      </div>
    </div>
  );
};

export default PropertyGeneral;
