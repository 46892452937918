import React, { useEffect, useRef, useState } from "react";
import Head from "../../../layout/head/Head";
import Content from "../../../layout/content/Content";
import {
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  ConfirmationAlert,
  Icon,
  ReactDataTable,
} from "../../../components/Component";
import {
  MaintenanceTableData,
  maintenanceRequestList,
} from "./MaintenanceRequestData";
import {
  Button,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import { useHistory } from "react-router";
import { URGENCY } from "../../../utils";
import LoadingComponent from "../../../layout/spinner/LoadingSpinner";
import MaintenanceRequestListFilter from "./MaintenanceRequestListFilter";
import moment from "moment/moment";
import MaintenanceStatusDropdown from "../../../components/dropdown/MaintenanceStatusDropdown";

export const MaintenanceRequestList = () => {
  const dropDownRef = useRef();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemPerPage, setItemPerPage] = useState(20);
  const [sort, setSortState] = useState("desc");
  const [onSearchText, setSearchText] = useState("");
  const [onSearch, setonSearch] = useState(true);
  const [totalItems, setTotalItems] = useState(0);
  const [maintenanceList, setMaintenanceList] = useState([]);
  const [selectedFilterData, setSelectedFilterData] = useState();
  const [isFilterApply, setIsFilterApply] = useState(false);
  const [isInitScreen, setIsInitScreen] = useState(true);
  const [isFilterShow, setIsFilterShow] = useState(false);

  const dropdownPages = [5, 10, 15, 20];
  const history = useHistory();
  const searchInput = useRef(null);

  useEffect(() => {
    if (currentPage !== 0) {
      loadMaintenanceRequests();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  useEffect(() => {
    if (currentPage === 1) {
      loadMaintenanceRequests();
    } else {
      setCurrentPage(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemPerPage, sort, onSearchText, isFilterApply]);

  useEffect(() => {
    if (searchInput.current && !onSearch) {
      searchInput.current.focus();
    }
  }, [onSearch]);

  const toggle = () => setonSearch(!onSearch);

  const onEdit = (row) => {
    history.push({
      pathname: `${process.env.PUBLIC_URL}/maintenance-request`,
      state: { data: row },
    });
  };

  const onView = (row) => {
    history.push({
      pathname: `${process.env.PUBLIC_URL}/maintenance-details/${row.id}-${row.description}`,
    });
  };

  const removeRequest = (item) => {
    ConfirmationAlert(
      "Are you sure?",
      `You want to remove ${item.title} request?`,
      () => {
        // createRemoveQuotation(item.id, (data, error) => {
        //   if (error === null) {
        //     setCurrentItems(
        //       currentItems.filter((value) => value.id !== item.id)
        //     );
        //   }
        // });
      }
    );
  };

  function resetFilter() {
    setSelectedFilterData({});
  }

  const loadMaintenanceRequests = () => {
    let urgency = null;
    if((selectedFilterData?.urgency?.length ?? 0) === 1) {
      urgency = selectedFilterData?.urgency[0] === 0 ? URGENCY.low : URGENCY.high;
    }
    let params = {
      pageInfo: { pageIndex: currentPage, itemsPerPage: itemPerPage },
      // sorting: {
      //   field: "property",
      //   order: "asc",
      // },
      propertyIds:
        selectedFilterData?.property?.length > 0
          ? selectedFilterData?.property.map((item) => item.id)
          : null,
      unitIds:
        selectedFilterData?.unit?.length > 0
          ? selectedFilterData?.unit.map((item) => item.id)
          : null,
      categoryIds:
        selectedFilterData?.category?.length > 0
          ? selectedFilterData?.category.map((item) => item.id)
          : null,
      // tenantId: 0,
      // pmId: 0,
      scheduleStart: selectedFilterData?.scheduleStart
        ? moment(selectedFilterData?.scheduleStart).format("YYYY-MM-DD")
        : null,
      scheduleEnd: selectedFilterData?.scheduleEnd
        ? moment(selectedFilterData?.scheduleEnd).format("YYYY-MM-DD")
        : null,
      createStart: selectedFilterData?.createStart
        ? moment(selectedFilterData?.createStart).format("YYYY-MM-DD")
        : null,
      createEnd: selectedFilterData?.createEnd
        ? moment(selectedFilterData?.createEnd).format("YYYY-MM/-DD")
        : null,
      // search: "string",
      urgency: urgency,
      statuses: selectedFilterData?.status?.map(item => item.value) ?? null,
    };

    if (onSearchText.length > 0) {
      params["search"] = onSearchText;
    }

    maintenanceRequestList(params, (data, error) => {
      if (error === null) {
        setMaintenanceList(data.list);
        if (currentPage === 1) {
          setTotalItems(data?.pageInfo?.totalItems);
        }
      }
      setIsInitScreen(false);
    });
  };

  return (
    <React.Fragment>
      <Head title="Requests"></Head>
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle page>Maintenance requests</BlockTitle>
            </BlockHeadContent>
            <BlockHeadContent>
              <ul className="nk-block-tools g-3">
                <li className="nk-block-tools-opt">
                  <Button
                    color="primary"
                    onClick={(ev) => {
                      ev.preventDefault();
                      history.push({
                        pathname: `${process.env.PUBLIC_URL}/maintenance-request`,
                      });
                    }}
                  >
                    <span>Add request</span>
                  </Button>
                </li>
              </ul>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>

        <Block className={"card-inner-group"}>
          <div className="card-inner bg-white position-relative card-tools-toggle">
            <div className="card-title-group">
              <div className="card-tools">
                <div className="form-inline flex-nowrap gx-3">
                  <div className="form-wrap flex-grow-1">
                  <MaintenanceStatusDropdown
                  value={selectedFilterData?.status}
                  isMulti={true}
                  onSelect={(e) => {
                    setSelectedFilterData(prev => ({
                      ...prev,
                      status: e,
                    }))
                    let isFilter = e.length > 0 ? true : false;
                    setIsFilterApply(isFilter);
                    if (isFilterApply === isFilter) {
                      setCurrentPage(0);
                      setTimeout(() => {
                        setCurrentPage(1);
                      }, 200);
                    }
                  }}
                />
  
                  </div>
                </div>
              </div>
              <div className="card-tools me-n1">
                <ul className="btn-toolbar gx-1">
                  <li>
                    <a
                      href="#search"
                      onClick={(ev) => {
                        ev.preventDefault();
                        toggle();
                      }}
                      className="btn btn-icon search-toggle toggle-search"
                    >
                      <Icon name="search"></Icon>
                    </a>
                  </li>
                  <li>
                    <ul className="btn-toolbar gx-1">
                      <li>
                        <UncontrolledDropdown
                          ref={dropDownRef}
                          onToggle={(event, isOpen) => {
                            setIsFilterShow(isOpen);
                          }}
                        >
                          <DropdownToggle
                            tag="a"
                            className="btn btn-trigger btn-icon dropdown-toggle"
                          >
                            {isFilterApply && (
                              <div className="dot dot-primary"></div>
                            )}
                            <Icon name="filter-alt"></Icon>
                          </DropdownToggle>
                          {isFilterShow && (
                            <MaintenanceRequestListFilter
                              isVisible={isFilterShow}
                              filteredData={selectedFilterData}
                              onFilterApply={(data) => {
                                dropDownRef.current.toggle();
                                setSelectedFilterData(data);
                                if (isFilterApply === true) {
                                  setCurrentPage(0);
                                  setTimeout(() => {
                                    setCurrentPage(1);
                                  }, 200);
                                }
                                setIsFilterApply(true);
                              }}
                              onResetFilter={(e) => {
                                dropDownRef.current.toggle();
                                resetFilter();
                                setIsFilterApply(false);
                              }}
                            />
                          )}
                        </UncontrolledDropdown>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <ul className="btn-toolbar gx-1">
                      <li>
                        <UncontrolledDropdown>
                          <DropdownToggle
                            color="tranparent"
                            className="btn btn-trigger btn-icon dropdown-toggle"
                          >
                            <Icon name="setting"></Icon>
                          </DropdownToggle>
                          <DropdownMenu end className="dropdown-menu-xs">
                            <ul className="link-check">
                              <li>
                                <span>Show</span>
                              </li>
                              {dropdownPages.map((item, index) => {
                                return (
                                  <React.Fragment key={index}>
                                    <li
                                      className={
                                        itemPerPage === item ? "active" : ""
                                      }
                                    >
                                      <DropdownItem
                                        tag="a"
                                        href="#dropdownitem"
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                          setItemPerPage(item);
                                        }}
                                      >
                                        {item}
                                      </DropdownItem>
                                    </li>
                                  </React.Fragment>
                                );
                              })}
                            </ul>
                            {/* <ul className="link-check">
                              <li>
                                <span>Order</span>
                              </li>
                              <li className={sort === "desc" ? "active" : ""}>
                                <DropdownItem
                                  tag="a"
                                  href="#dropdownitem"
                                  onClick={(ev) => {
                                    ev.preventDefault();
                                    setSortState("desc");
                                    // sortFunc("dsc");
                                  }}
                                >
                                  DESC
                                </DropdownItem>
                              </li>
                              <li className={sort === "asc" ? "active" : ""}>
                                <DropdownItem
                                  tag="a"
                                  href="#dropdownitem"
                                  onClick={(ev) => {
                                    ev.preventDefault();
                                    setSortState("asc");
                                  }}
                                >
                                  ASC
                                </DropdownItem>
                              </li>
                            </ul> */}
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
            <div className={`card-search search-wrap ${!onSearch && "active"}`}>
              <div className="card-body">
                <div className="search-content">
                  <Button
                    className="search-back bg-transparent btn-icon toggle-search active"
                    onClick={() => {
                      setSearchText("");
                      toggle();
                    }}
                  >
                    <Icon name="arrow-left text-secondary"></Icon>
                  </Button>
                  <input
                    type="text"
                    className="border-transparent form-focus-none form-control"
                    placeholder="Search by request title"
                    value={onSearchText}
                    ref={searchInput}
                    onChange={(e) => setSearchText(e.target.value)}
                  />
                  <Button className="search-submit btn-icon bg-transparent border-0">
                    <Icon name="search text-secondary"></Icon>
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div className={""}>
            <ReactDataTable
              data={maintenanceList}
              columns={MaintenanceTableData(onEdit, onView)}
              totalItemCount={totalItems}
              itemPerPage={itemPerPage}
              className=" w-100 overflow-auto"
              pagination
              noDataNode={
                <div className="text-center mt-2 mb-4">
                  <span className="text-silent">
                    {"You did not have any submitted maintenance requests yet."}
                  </span>
                </div>
              }
              onPageChanged={(page, count) => {
                setCurrentPage(page);
              }}
              paginationStyle={"border-top"}
              onRowSelected={(row) => {}}
              onRowClicked={(row) => {}}
            />
          </div>
        </Block>
      </Content>
      <LoadingComponent isLoading={isInitScreen} />
    </React.Fragment>
  );
};
