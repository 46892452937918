import React from "react";
import {
  AltitudeModal,
} from "../../../../components/Component";
import { Container } from "reactstrap";
import { useEffect } from "react";

const VerifyMicroDeposite = ({ visible, url, onClosed }) => {
  useEffect(() => {
    if (visible) {
    }
  }, [visible]);

  return (
    <>
      <AltitudeModal
        size={"md"}
        isOpen={visible}
        toggle={() => onClosed()}
        isShowFooter={false}
        title={"Verify your account"}
      >
        <Container>
          {url && visible && (
            <>
              {/* eslint-disable-next-line jsx-a11y/iframe-has-title, react/style-prop-object */}
              <iframe
                src={url}
                width="500"
                height="800"
                // style="border: none;"
              ></iframe>
            </>
          )}
        </Container>
      </AltitudeModal>
    </>
  );
};

export default VerifyMicroDeposite;
