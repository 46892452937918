import React, { useEffect, useState } from "react";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import {
  Badge,
  Card,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Spinner,
  UncontrolledDropdown,
} from "reactstrap";
import {
  Button,
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Row,
  Col,
  TooltipComponent,
  UserAvatar,
  DataTableHead,
  DataTableRow,
  DataTableItem,
  PreviewCard,
} from "../../../components/Component";
import {
  bytesToMegaBytes,
  findUpper,
  UserAccountType,
} from "../../../utils/Utils";
import { useHistory } from "react-router-dom";
import Timeline from "./Timeline";
import {
  bidAction,
  fileUploader,
  getBidingList,
  getInvitedVendorList,
  getRequestDetails,
  getTimeline,
  markAsCompleted,
  moderateRequest,
  uploadRequestExtraFileId,
} from "./RequestData";
import { Storage } from "../../../services/storage/storage";
import {
  AppText,
  COUNTRY_CODE,
  CURRENCY_CODE,
  DATE_FORMAT,
  DATE_TIME_FORMAT,
  formatPhoneNumber,
  listAsNewLineString,
  newlineText,
  numberFormatter,
  openLinkInNewTab,
  quoteRequestRecurringTypeText,
  removeTimeFromServerDateString,
  showErrorToast,
  statusColor,
  StatusType,
  toCapitalize,
} from "../../../utils";
import moment from "moment";
import { RequestBid } from "./RequestBid";
import Swal from "sweetalert2";
import { ChatFloatingUI } from "../chat/ChatFloatingUI";
import LoadingComponent from "../../../layout/spinner/LoadingSpinner";
import { FileSizeText } from "../../../components/text/FileSizeText";
import { ChatType } from "../../../services/chat/chatservice";

const RequestDetails = ({ match }) => {
  const [user] = useState(Storage.getLoggedInUser()?.user);
  const isVendor = user?.accountType === UserAccountType.vendor;
  const [request, setRequest] = useState();
  const [bidList, setBidList] = useState([]);
  const [timeLine, setTimeLine] = useState([]);
  const [invitedList, setInvitedList] = useState();
  const [quotedList, setQuotedList] = useState();

  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [actionIndex, setActionIndex] = useState();
  const [isExtraFileUploading, setIsExtraFileUploading] = useState(false);
  const [fileNotUploadAlertShown, setFileNotUploadAlertShown] = useState(false);
  const [chatId, setChatId] = useState();
  const [isInitScreen, setIsInitScreen] = useState(true);
  const [modal, setModal] = useState(false);

  const history = useHistory();

  useEffect(() => {
    const idStr = match.params.id;
    if (idStr !== undefined || null || "") {
      let list = idStr.split("-");
      const id = list.length > 0 ? list[0] : "";
      refresh(id);
    } else {
      history.goBack();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match.params.id]);

  useEffect(() => {
    if (invitedList && quotedList && !isVendor) {
      setBidList([...invitedList, ...quotedList]);
    } else if (quotedList && isVendor) {
      setBidList(quotedList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quotedList, invitedList]);

  // ********************Request Details********************
  function loadRequest(id) {
    getRequestDetails({ id: parseInt(id) }, (data, error) => {
      if (error === null) {
        setRequest(data);
      }
      setIsInitScreen(false);
    });
  }

  function loadInvitedVendor(id) {
    let params = {
      quotationId: parseInt(id),
    };
    getInvitedVendorList(params, (data, error) => {
      if (error === null) {
        setInvitedList(data);
        if (currentPage === 1) {
          setTotalItems(data.pageInfo?.totalItems ?? 0);
        }
      }
      loadQuoteList(id);
    });
  }

  function loadQuoteList(id) {
    let params = {
      quotationId: parseInt(id),
      isInvitedExcluded: isVendor ? false : true,
    };
    getBidingList(params, (data, error) => {
      if (error === null) {
        setQuotedList(data?.list ?? []);
        if (currentPage === 1) {
          setTotalItems(data.pageInfo?.totalItems ?? 0);
        }
      }
      loadTimeline(id);
    });
  }

  function loadTimeline(id) {
    getTimeline(parseInt(id), (data, error) => {
      if (error === null) {
        setTimeLine(data);
      }
    });
  }

  function actionOnBid(item, isAccept, index) {
    setActionIndex(index);
    let req = { id: item.id, status: isAccept ? "APPROVED" : "REJECTED" };
    bidAction(req, (data, error) => {
      if (error === null) {
        if (isAccept) {
          window.location.reload();
        } else {
          let list = [...bidList];
          list[index] = data;
          setBidList(list);
        }
      } else {
        showErrorToast(error.message ?? AppText.connectError);
      }
      setActionIndex();
    });
  }

  function onMessageClick(chatId) {
    setChatId(chatId);
  }

  function onViewClick(item, event) {
    const name = item.name.replace(/\s+/g, "-").toLowerCase();
    let url = `${process.env.PUBLIC_URL}/vendor/${item.vendorId}-${name}`;
    openLinkInNewTab(url, event);
  }

  function uploadExtraFile(file) {
    if (bytesToMegaBytes(file.size) > 100) {
      showErrorToast(AppText.maxFileSizeError);
    } else {
      setIsExtraFileUploading(true);
      fileUploader(file, "QUOTATION_PROCESSING", (data, error) => {
        // data.id;
        if (error === null) {
          updateExtraFileId(data.id);
        } else {
          setIsExtraFileUploading(false);
          showErrorToast(
            error.message.length > 0 ? error.message : AppText.connectError
          );
        }
      });
    }
  }

  function updateExtraFileId(fileId) {
    uploadRequestExtraFileId(request.id, fileId, (data, error) => {
      setIsExtraFileUploading(false);
      if (error === null) {
        let list = [...request.files];
        list.push(data.list[0]);
        setRequest((prev) => ({
          ...prev,
          files: list,
        }));
      } else {
        showErrorToast(error.message ?? AppText.connectError);
      }
    });
  }

  function markAsCompletedByVendor() {
    // user user file

    let timeline = timeLine.filter(
      (item) => item.text === toCapitalize(StatusType.reopened)
    );
    let files = [];
    if (timeline.length > 0) {
      let date = moment(timeline[0].createdAt);
      files = request.files.filter(
        (item) => item.userId === user.id && moment(item.createdAt) > date
      );
    } else {
      files = request.files.filter((item) => item.userId === user.id);
    }

    if (files.length === 0 && fileNotUploadAlertShown === false) {
      setFileNotUploadAlertShown(true);
      Swal.fire({
        title: "Attach files",
        text: `Attach files that describe the results of your work so the property manager can use them as references`,
        icon: "warning",
      });
    } else {
      Swal.fire({
        title: "Are you sure?",
        text: `You want to complete ${request.title}?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, complete!",
      }).then((result) => {
        if (result.isConfirmed) {
          markAsCompleted(request.id, (data, error) => {
            if (error === null) {
              setRequest((prev) => ({
                ...prev,
                status: StatusType.complete,
              }));
            } else {
              showErrorToast(error.message ?? AppText.connectError);
            }
          });
        }
      });
    }
  }

  function moderateRequestByPM(isApproved) {
    Swal.fire({
      title: "Are you sure?",
      text: `Do you want to ${
        isApproved ? "confirm and pay" : "reopen this request"
      }?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: `Yes, ${isApproved ? "pay" : "reopen"}!`,
    }).then((result) => {
      if (result.isConfirmed) {
        moderateRequest(request.id, isApproved, (data, error) => {
          if (error === null) {
            loadRequest(request.id);
          } else {
            showErrorToast(error.message ?? AppText.connectError);
          }
        });
      }
    });
  }

  function onEditClick() {
    history.push({
      pathname: `${process.env.PUBLIC_URL}/new-request`,
      state: { item: request },
    });
  }

  function refresh(id) {
    loadRequest(id);
    isVendor ? loadQuoteList(id) : loadInvitedVendor(id);
  }

  const openMail = () => {
    window.open(process.env.REACT_APP_CONTACT_US_MAIL);
  };
  // *******************************************************
  return (
    <React.Fragment>
      <Head title={request?.title ?? "Loading"}></Head>
      {request && (
        <Content>
          {isVendor &&
          request.status !== StatusType.published &&
          bidList?.length === 1 &&
          bidList[0].status !== "APPROVED" ? (
            <Block className="nk-block-middle nk-auth-body wide-xs">
              <PreviewCard className="card-bordered" bodyClass="card-inner-lg">
                <BlockTitle page>
                  Your quote for this request was not chosen.
                </BlockTitle>
                <br />
                Thank you for the provided quote for {request.title} requested
                by{" "}
                <strong>
                  {(request?.contacts?.length ?? 0) > 0
                    ? request?.contacts[0].pm?.companyName ?? ""
                    : "PM"}
                </strong>{" "}
                and all your efforts you put into request assessment.
                <br />
                Unfortunately,{" "}
                <strong>
                  {(request?.contacts?.length ?? 0) > 0
                    ? request?.contacts[0].pm?.companyName ?? ""
                    : "PM"}
                </strong>{" "}
                has chosen not to move forward with your proposal at this time
                because of another offer that matches better with their needs.
                <br />
                <br />
                Please keep providing your quotes as maintenance work never
                ends.
                {/* Someone else is chosen by PM, you can check {"  "} */}
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                {/* <a
                    href=""
                    onClick={(ev) => {
                      ev.preventDefault();
                      history.push(`${process.env.PUBLIC_URL}/`);
                    }}
                    className="clickable"
                  >
                    other requests
                  </a>
                  . */}
              </PreviewCard>
            </Block>
          ) : (
            <>
              <BlockHead size="sm">
                <BlockBetween className="g-3">
                  <BlockHeadContent>
                    <BlockTitle page>{request.title}</BlockTitle>
                    <BlockDes className="text-soft">
                      <ul className="list-inline">
                        <li>
                          <span className="text-base">
                            Request ID #{request.id}
                          </span>
                        </li>
                        <li>
                          <span className="text-base">
                            Created on{" "}
                            {request.createdAt
                              ? moment(request.createdAt).format(
                                DATE_TIME_FORMAT
                                )
                              : ""}
                          </span>
                        </li>
                      </ul>
                    </BlockDes>
                  </BlockHeadContent>
                  <BlockHeadContent>
                    <ul className="nk-block-tools g-3">
                      <li>
                        <Button
                          color="light"
                          outline
                          className="bg-white d-none d-sm-inline-flex"
                          onClick={() => history.goBack()}
                        >
                          <Icon name="arrow-left"></Icon>
                          <span>Back</span>
                        </Button>
                      </li>
                      {isVendor && (
                        <Button
                          color="primary"
                          className="ms-1"
                          onClick={() => {
                            if ((request?.contacts?.length ?? 0) > 0) {
                              let contact = request?.contacts[0];
                              onMessageClick(contact.sbId);
                            }
                          }}
                        >
                          <span>Chat</span>
                        </Button>
                      )}
                      <li>
                        {isVendor && request.status === StatusType.published ? (
                          <Button
                            color="primary"
                            className="m-1"
                            onClick={() => setModal(true)}
                          >
                            {bidList.length === 0 ? (
                              <span>Provide quote</span>
                            ) : (
                              <span>Update quote</span>
                            )}
                          </Button>
                        ) : isVendor &&
                          (request.status === StatusType.confirmed ||
                            request.status === StatusType.reopened) &&
                          bidList.length === 1 &&
                          bidList[0].status === "APPROVED" ? (
                          <Button
                            color="primary"
                            className="m-1"
                            onClick={() => markAsCompletedByVendor()}
                          >
                            <span>Complete</span>
                          </Button>
                        ) : isVendor === false &&
                          request.status === StatusType.complete ? (
                          <>
                            <Button
                              color="primary"
                              className="m-1"
                              onClick={() => moderateRequestByPM(true)}
                            >
                              <span>Close</span>
                            </Button>
                            <Button
                              color="danger"
                              className="m-1"
                              onClick={() => moderateRequestByPM(false)}
                            >
                              <span>Reopen</span>
                            </Button>
                          </>
                        ) : isVendor === false &&
                          (request.status === StatusType.published ||
                            request.status === StatusType.draft) ? (
                          <Button
                            color="primary"
                            className="my-1 "
                            onClick={() => onEditClick()}
                          >
                            <span>Edit</span>
                          </Button>
                        ) : null}
                      </li>
                    </ul>
                  </BlockHeadContent>
                </BlockBetween>
              </BlockHead>

              <Block>
                <Row className="gy-5">
                  <Col lg="5">
                    <BlockHead>
                      <BlockHeadContent>
                        <BlockTitle tag="h5">Details</BlockTitle>
                        {/* <p>Submission date, approve date, status etc.</p> */}
                      </BlockHeadContent>
                    </BlockHead>
                    <Card className="card-bordered">
                      <ul className="data-list is-compact">
                        <li className="data-item">
                          <div className="data-col">
                            <div className="data-label" style={{ flex: 1 }}>
                              Title
                            </div>
                            <div className="data-value" style={{ flex: 2 }}>
                              {request.title}
                            </div>
                          </div>
                        </li>
                        <li className="data-item">
                          <div className="data-col">
                            <div className="data-label" style={{ flex: 1 }}>
                              Description
                            </div>
                            <div className="data-value" style={{ flex: 2 }}>
                              {request.workDesc
                                ? newlineText(request.workDesc)
                                : "Not specified"}
                            </div>
                          </div>
                        </li>
                        <li className="data-item">
                          <div className="data-col">
                            <div className="data-label" style={{ flex: 1 }}>
                              Status
                            </div>
                            <div className="data-value" style={{ flex: 2 }}>
                              {(bidList?.filter((e) => e.id !== undefined)
                                ?.length ?? 0) > 0 &&
                              request.status === StatusType.published ? (
                                <Badge
                                  className="badge-dim fSize14 bg-orange-dim p-1 text-orange border-0"
                                  color={"orange"}
                                >
                                  <span>{"Quoted"}</span>
                                </Badge>
                              ) : (
                                <>
                                  <Badge
                                    className={`badge-dim fSize14 p-1 bg-${statusColor(
                                      request.status
                                    )}-dim text-${statusColor(
                                      request.status
                                    )} border-0`}
                                    color={statusColor(request.status)}
                                  >
                                    <span>{toCapitalize(request.status)}</span>
                                  </Badge>
                                  {request.status === StatusType.closed && (
                                    <>
                                      {request.paymentStage ===
                                      "PAYOUT_PROCESSING" ? (
                                        <p className="text-gray mt-1">
                                          Payment in processing
                                        </p>
                                      ) : request.paymentStage ===
                                        "PAYOUT_FAILED" ? (
                                        <p className="text-gray mt-1">
                                          {" "}
                                          Payment interrupted, please{" "}
                                          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                          <a
                                            href=""
                                            onClick={(ev) => {
                                              ev.preventDefault();
                                              openMail();
                                            }}
                                            className="clickable"
                                          >
                                            contact us
                                          </a>{" "}
                                          for further details
                                        </p>
                                      ) : request.paymentStage ===
                                        "VENDOR_RECEIVED" ? (
                                        <p className="text-gray mt-1">
                                          Payment completed
                                        </p>
                                      ) : (
                                        <></>
                                      )}
                                    </>
                                  )}
                                </>
                              )}
                            </div>
                          </div>
                        </li>
                        <li className="data-item">
                          <div className="data-col">
                            <div className="data-label" style={{ flex: 1 }}>
                              Contact
                            </div>
                            <div className="data-value" style={{ flex: 2 }}>
                              {(request?.contacts?.length ?? 0) > 0 && (
                                <ul>
                                  <li>
                                    {request?.contacts[0].firstName +
                                      " " +
                                      request?.contacts[0].lastName}
                                  </li>
                                  <li>{request?.contacts[0].email}</li>
                                  <li>
                                    {formatPhoneNumber(
                                      COUNTRY_CODE + " ",
                                      request?.contacts[0].phone
                                    )}
                                  </li>
                                </ul>
                              )}
                            </div>
                          </div>
                        </li>
                        <li className="data-item">
                          <div className="data-col">
                            <div className="data-label" style={{ flex: 1 }}>
                              Service(s)
                            </div>
                            <div className="data-value" style={{ flex: 2 }}>
                              <span>
                                {listAsNewLineString(
                                  request?.services?.map((item) => item.name) ??
                                    []
                                )}
                              </span>
                            </div>
                          </div>
                        </li>
                        <li className="data-item">
                          <div className="data-col">
                            <div className="data-label" style={{ flex: 1 }}>
                              Start
                            </div>

                            <div className="data-value" style={{ flex: 2 }}>
                              {request.startDate
                                ? moment(
                                    removeTimeFromServerDateString(
                                      request.startDate
                                    )
                                  ).format(DATE_FORMAT)
                                : "Not specified"}{" "}
                              {request.isStartDateFlexible === 1 &&
                                "(Flexible)"}
                            </div>
                          </div>
                        </li>
                        <li className="data-item">
                          <div className="data-col">
                            <div className="data-label" style={{ flex: 1 }}>
                              End
                            </div>
                            <div className="data-value" style={{ flex: 2 }}>
                              {" "}
                              {request.endDate
                                ? moment(
                                    removeTimeFromServerDateString(
                                      request.endDate
                                    )
                                  ).format(DATE_FORMAT)
                                : "Not specified"}
                            </div>
                          </div>
                        </li>
                        <li className="data-item">
                          <div className="data-col">
                            <div className="data-label" style={{ flex: 1 }}>
                              Deadline
                            </div>
                            <div className="data-value" style={{ flex: 2 }}>
                              {" "}
                              {request.deadlineAt
                                ? moment(request.deadlineAt).format(
                                  DATE_TIME_FORMAT
                                  )
                                : "Not specified"}
                            </div>
                          </div>
                        </li>
                        <li className="data-item">
                          <div className="data-col">
                            <div className="data-label" style={{ flex: 1 }}>
                              Recurring
                            </div>
                            <div className="data-value" style={{ flex: 2 }}>
                              {request.isRecurring === true
                                ? quoteRequestRecurringTypeText(
                                    request.recurringType
                                  )
                                : "One-time"}
                            </div>
                          </div>
                        </li>
                        <li className="data-item">
                          <div className="data-col">
                            <div className="data-label" style={{ flex: 1 }}>
                              Property
                            </div>
                            <div className="data-value" style={{ flex: 2 }}>
                              {(request?.properties ?? []).length > 0 ? (
                                <>
                                  {(request?.properties ?? []).map(
                                    (item, index) => {
                                      return (
                                        <React.Fragment key={index}>
                                          {item.name}
                                          <br />
                                          <p className="text-gray">
                                            {item.address?.fullAddress}
                                          </p>
                                        </React.Fragment>
                                      );
                                    }
                                  )}
                                </>
                              ) : (
                                <>{"Not specified"}</>
                              )}
                            </div>
                          </div>
                        </li>
                        <li className="data-item">
                          <div className="data-col">
                            <div className="data-label" style={{ flex: 1 }}>
                              Payment via <br />
                              {process.env.REACT_APP_APP_NAME}
                            </div>
                            <div className="data-value" style={{ flex: 2 }}>
                              {request.isPayRequired ? "Yes" : "No"}
                            </div>
                          </div>
                        </li>
                        <li className="data-item">
                          <div className="data-col">
                            <div className="data-label" style={{ flex: 1 }}>
                              Current project status
                            </div>
                            <div className="data-value" style={{ flex: 2 }}>
                              {request.projectStatus
                                ? request.projectStatus === "READY_TO_HIRE"
                                  ? "Ready to hire"
                                  : request.projectStatus ===
                                    "PLANNING_&_BUDGETING"
                                  ? "Planning & Budgeting"
                                  : "-"
                                : "Not specified"}
                            </div>
                          </div>
                        </li>
                        {!isVendor && (
                          <li className="data-item">
                            <div className="data-col">
                              <div className="data-label" style={{ flex: 1 }}>
                                Budget
                              </div>
                              <div className="data-value" style={{ flex: 2 }}>
                                {request.budget
                                  ? numberFormatter(
                                      CURRENCY_CODE,
                                      "" + request.budget
                                    )
                                  : "Not specified"}
                              </div>
                            </div>
                          </li>
                        )}
                      </ul>
                    </Card>

                    {timeLine && timeLine.length > 0 && (
                      <>
                        <BlockHead>
                          <BlockHeadContent>
                            <BlockTitle tag="h5">History</BlockTitle>
                          </BlockHeadContent>
                        </BlockHead>
                        <Card className="'card-bordered">
                          <Timeline data={timeLine} />
                        </Card>
                      </>
                    )}
                  </Col>

                  <Col lg="7">
                    <BlockHead>
                      <BlockHeadContent>
                        <BlockTitle tag="h5">Vendor quotes</BlockTitle>
                      </BlockHeadContent>
                    </BlockHead>
                    <Card className="card-bordered">
                      <div className="nk-tb-list nk-tb-ulist bg-white">
                        <DataTableHead className="nk-tb-item nk-tb-head">
                          <DataTableRow>
                            <span className="sub-text"></span>
                          </DataTableRow>
                          <DataTableRow>
                            <span className="sub-text text-secondary">
                              Company
                            </span>
                          </DataTableRow>
                          <DataTableRow>
                            <span className="sub-text text-secondary">
                              Status
                            </span>
                          </DataTableRow>
                          <DataTableRow className="text-end">
                            <span className="sub-text text-secondary">
                              Quote
                            </span>
                          </DataTableRow>
                          <DataTableRow>
                            <span className="sub-text text-secondary">
                              Files
                            </span>
                          </DataTableRow>
                          <DataTableRow>
                            <span className="sub-text"></span>
                          </DataTableRow>
                          {!isVendor && (
                            <DataTableRow>
                              <></>
                            </DataTableRow>
                          )}
                        </DataTableHead>
                        {bidList?.map((item, index) => {
                          return (
                            <React.Fragment key={index}>
                              <DataTableItem
                                className={"hover-table-row"}
                                style={{ verticalAlign: "middle" }}
                              >
                                <DataTableRow
                                  style={{ cursor: "pointer" }}
                                  onClick={(ev) => onViewClick(item, ev)}
                                >
                                  <UserAvatar
                                    className="sm sq"
                                    theme={"white"}
                                    text={findUpper(item.name)}
                                    image={item.thumbUrl}
                                  />
                                </DataTableRow>
                                <DataTableRow
                                  style={{ cursor: "pointer" }}
                                  onClick={(ev) => onViewClick(item, ev)}
                                  className="text-secondary"
                                >
                                  {item.name}
                                </DataTableRow>
                                <DataTableRow className="text-secondary">
                                  {toCapitalize(
                                    item?.status ??
                                      (request.status === StatusType.draft
                                        ? "Not requested"
                                        : "Quote requested")
                                  )}
                                </DataTableRow>
                                <DataTableRow className="text-end text-secondary">
                                  {item.quote
                                    ? numberFormatter(
                                        CURRENCY_CODE,
                                        "" + item.quote
                                      )
                                    : "Not specified"}
                                </DataTableRow>
                                <DataTableRow>
                                  {(item.files?.length ?? 0) > 0 ? (
                                    // eslint-disable-next-line jsx-a11y/anchor-is-valid
                                    <a
                                      href=""
                                      className="clickable"
                                      onClick={(ev) => {
                                        ev.preventDefault();
                                        item?.files?.forEach((element) => {
                                          openLinkInNewTab(element.fileUrl, ev);
                                        });
                                      }}
                                    >
                                      {item.files.length} file attached
                                    </a>
                                  ) : (
                                    <p className="text-secondary">
                                      {"No files attached"}
                                    </p>
                                  )}
                                </DataTableRow>
                                <DataTableRow>
                                  {!isVendor && (
                                    <>
                                      {actionIndex === index && item ? (
                                        <td itemScope="col text-end">
                                          <Spinner size="sm" color="primary" />
                                        </td>
                                      ) : (
                                        <td itemScope="col">
                                          {/* {item.id && ( */}
                                          <ul className="nk-reply-form-actions g-1 hide-on-hover">
                                            <li
                                              onClick={() =>
                                                onMessageClick(item.chatId)
                                              }
                                            >
                                              <TooltipComponent
                                                tag="a"
                                                containerClassName="btn-trigger btn btn-icon"
                                                icon="chat-circle-fill"
                                                id="ibx-msg-dlt"
                                                direction="top"
                                                text="Message"
                                              />
                                            </li>
                                            {item.status ===
                                              StatusType.quoted &&
                                            request.status ===
                                              StatusType.published ? (
                                              <>
                                                <li
                                                  onClick={() =>
                                                    actionOnBid(
                                                      item,
                                                      true,
                                                      index
                                                    )
                                                  }
                                                >
                                                  <TooltipComponent
                                                    tag="a"
                                                    containerClassName="btn-trigger btn btn-icon"
                                                    icon="check-circle-fill"
                                                    id="ibx-msg-dlt1"
                                                    direction="top"
                                                    text="Accept"
                                                  />
                                                </li>
                                                <li
                                                  onClick={() =>
                                                    actionOnBid(
                                                      item,
                                                      false,
                                                      index
                                                    )
                                                  }
                                                >
                                                  <TooltipComponent
                                                    tag="a"
                                                    containerClassName="btn-trigger btn btn-icon"
                                                    icon="cross-circle-fill"
                                                    id="ibx-msg-dlt2"
                                                    direction="top"
                                                    text="Decline"
                                                  />
                                                </li>
                                              </>
                                            ) : (
                                              <>
                                                <li className="m-1">
                                                  <Icon
                                                    name="check-circle-fill"
                                                    style={{ color: "#f0f0f0" }}
                                                  ></Icon>
                                                </li>
                                                <li className="m-1">
                                                  <Icon
                                                    name="cross-circle-fill"
                                                    style={{ color: "#f0f0f0" }}
                                                  ></Icon>
                                                </li>
                                              </>
                                            )}
                                          </ul>
                                          {/* )} */}
                                        </td>
                                      )}
                                    </>
                                  )}
                                </DataTableRow>
                                {!isVendor && (
                                  <DataTableRow className="nk-tb-col-tools text-end">
                                    <ul className="nk-tb-actions gx-1">
                                      <li>
                                        {/* {item.id && ( */}
                                        <UncontrolledDropdown>
                                          <DropdownToggle
                                            tag="a"
                                            className="text-soft dropdown-toggle btn btn-icon btn-trigger"
                                          >
                                            <Icon name="more-v"></Icon>
                                          </DropdownToggle>
                                          <DropdownMenu end>
                                            <ul className="link-list-opt no-bdr">
                                              <li onClick={() => {}}>
                                                <DropdownItem
                                                  tag="a"
                                                  href="#view"
                                                  onClick={(ev) => {
                                                    ev.preventDefault();
                                                    onMessageClick(item.chatId);
                                                  }}
                                                >
                                                  <Icon name="chat-circle-fill"></Icon>
                                                  <span>Message</span>
                                                </DropdownItem>
                                              </li>

                                              {item.status ===
                                                StatusType.quoted &&
                                                request.status ===
                                                  StatusType.published && (
                                                  <>
                                                    <li onClick={() => {}}>
                                                      <DropdownItem
                                                        tag="a"
                                                        href="#accpet"
                                                        onClick={(ev) => {
                                                          ev.preventDefault();
                                                          actionOnBid(
                                                            item,
                                                            true,
                                                            index
                                                          );
                                                        }}
                                                      >
                                                        <Icon name="check-circle-fill"></Icon>
                                                        <span>Accept</span>
                                                      </DropdownItem>
                                                    </li>
                                                    <li onClick={() => {}}>
                                                      <DropdownItem
                                                        tag="a"
                                                        href="#reject"
                                                        onClick={(ev) => {
                                                          ev.preventDefault();
                                                          actionOnBid(
                                                            item,
                                                            false,
                                                            index
                                                          );
                                                        }}
                                                      >
                                                        <Icon name="cross-circle-fill"></Icon>
                                                        <span>Reject</span>
                                                      </DropdownItem>
                                                    </li>
                                                  </>
                                                )}
                                            </ul>
                                          </DropdownMenu>
                                        </UncontrolledDropdown>
                                        {/* )} */}
                                      </li>
                                    </ul>
                                  </DataTableRow>
                                )}
                              </DataTableItem>
                            </React.Fragment>
                          );
                        })}
                      </div>
                      {(bidList?.length ?? 0) === 0 && (
                        <div className="text-center my-3">
                          <span className="text-silent">
                            {isVendor
                              ? "You did not provide a quote yet"
                              : "No quote is provided yet"}
                          </span>
                        </div>
                      )}
                    </Card>
                    <BlockHead className="">
                      <BlockBetween>
                        <BlockHeadContent>
                          <BlockTitle tag="h5">Files</BlockTitle>
                        </BlockHeadContent>
                        {(isVendor === false ||
                          request.status === StatusType.confirmed ||
                          request.status === StatusType.reopened) && (
                          <BlockHeadContent>
                            <div className="form-group mb-0">
                              {isExtraFileUploading ? (
                                <Spinner size="sm" color="primary" />
                              ) : (
                                <>
                                  <FileSizeText />
                                  <label
                                    // className="btn"
                                    htmlFor="files"
                                    style={{
                                      color: "#2C75FF",
                                      fontSize: 14,
                                    }}
                                  >
                                    <Icon name="clip" className={"mb-1"} />{" "}
                                    Attach files
                                  </label>
                                  <input
                                    type="file"
                                    name="files"
                                    accept="image/*,.pdf,.doc,.docx,.xml"
                                    className="form-control"
                                    id="files"
                                    style={{ display: "none" }}
                                    onChange={(e) => {
                                      let temp = Array.prototype.slice.call(
                                        e.target.files
                                      );
                                      if (temp.length > 0) {
                                        uploadExtraFile(temp[0]);
                                      }
                                    }}
                                  />
                                </>
                              )}
                            </div>
                          </BlockHeadContent>
                        )}
                      </BlockBetween>
                    </BlockHead>
                    <Card className="card-bordered">
                      <ul className="data-list is-compact">
                        {(request?.files?.length ?? 0) > 0 ? (
                          <>
                            {request?.files?.map((item, index) => {
                              return (
                                <React.Fragment key={index}>
                                  <li className="data-item" key={index}>
                                    <div className="data-col">
                                      <div className="data-value">
                                        <a
                                          href="#files"
                                          className="clickable"
                                          onClick={(ev) => {
                                            ev.preventDefault();
                                            openLinkInNewTab(item.fileUrl, ev);
                                          }}
                                        >
                                          {item.name ?? item.fileKey}
                                        </a>
                                      </div>
                                    </div>
                                  </li>
                                </React.Fragment>
                              );
                            })}
                          </>
                        ) : (
                          <p className="text-center my-2">No files attached</p>
                        )}
                      </ul>
                    </Card>
                  </Col>
                </Row>
              </Block>
            </>
          )}
        </Content>
      )}
      {request && isVendor && (
        <RequestBid
          visible={modal}
          requestId={request?.id}
          data={(bidList?.length ?? 0) > 0 ? bidList[0] : null}
          onClosed={(status) => {
            if (status) {
              loadQuoteList(request?.id);
            }
            setModal(false);
          }}
          onError={() => {
            setModal(false);
            refresh(request.id);
          }}
        />
      )}
      {chatId && (
        <ChatFloatingUI
          loggedInId={user.chatId}
          otherChatId={chatId}
          onClosed={() => setChatId()}
          chatType={ChatType.vendor_pm}
        />
      )}
      <LoadingComponent isLoading={isInitScreen} />
    </React.Fragment>
  );
};

export default RequestDetails;
