import React, { useState } from "react";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import CalenderApp from "../../../components/partials/calender/Calender";
import { Button } from "reactstrap";
import {
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  PreviewAltCard,
} from "../../../components/Component";

// import { events } from "../../../components/partials/calender/CalenderData";
import { getCalenderEvents } from "../request/RequestData";
import moment from "moment";
import { Storage } from "../../../services/storage/storage";
import { useHistory } from "react-router";
import { UserAccountType } from "../../../utils/Utils";
import { CalendarEventType } from "../../../utils";
import LoadingComponent from "../../../layout/spinner/LoadingSpinner";

const Calender = () => {
  const [events, setEvents] = useState();
  const history = useHistory();
  const [isInitScreen, setIsInitScreen] = useState(true);
  const [user] = useState(Storage.getLoggedInUser()?.user);


  function getRequestList(start, end) {
    let user = Storage.getLoggedInUser()?.user;
    getCalenderEvents(
      {
        startDate: moment(start).format("YYYY-MM-DD"),
        endDate: moment(end).format("YYYY-MM-DD"),
      },
      user.accountType,
      (data, error) => {
        if (error === null) {
          setEvents(data);
        }
        setIsInitScreen(false);
      }
    );
  }

  const onViewClick = (item) => {
    const { title, extendedProps, publicId } = item.event._def;

    if (publicId) {
      const name = title.trim().replace(/\s+/g, "-").toLowerCase();

      let path = ``;
      switch (extendedProps.type) {
        case CalendarEventType.maintenanceRequest:
          path = `/maintenance-details/${publicId}-${name}`;
          break;
        case CalendarEventType.quotationRequest:
          path = `/request/${publicId}-${name}`;
          break;
        default:
          break;
      }
      history.push({
        pathname: `${process.env.PUBLIC_URL}${path}`,
      });
    }
  };

  return (
    <React.Fragment>
      <Head title="Calendar" />
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle page>Calendar</BlockTitle>
            </BlockHeadContent>
            <BlockHeadContent>
            {user?.accountType === UserAccountType.manager && (
                <Button
                  color="primary"
                  onClick={() => {
                    history.push({
                      pathname: `${process.env.PUBLIC_URL}/new-request`,
                    });
                  }}
                >
                  <span>Add request</span>
                </Button>
            )}
            {(user?.accountType === UserAccountType.tenant || user?.accountType === UserAccountType.manager) && (
                <Button
                  color="primary"
                  className="ms-2"
                  onClick={() => {
                    history.push({
                      pathname: `${process.env.PUBLIC_URL}/maintenance-request`,
                    });
                  }}
                >
                  <span>{user?.accountType === UserAccountType.tenant ? "Add request" : "Add maintenance request"}</span>
                </Button>
            )}
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        <Block>
          <PreviewAltCard>
            <CalenderApp
              events={events}
              onEvent={(info) => onViewClick(info)}
              onDateChange={(start, end) => getRequestList(start, end)}
            />
          </PreviewAltCard>
        </Block>
      </Content>
      <LoadingComponent isLoading={isInitScreen} />
    </React.Fragment>
  );
};
export default Calender;
