import React, { createRef, useEffect, useState } from "react";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import Content from "../../layout/content/Content";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import { Icon } from "../Component";

export const ImageCropper = ({ visible, file, onCancel, onCrop }) => {
  const cropperRef = createRef();
  const [image, setImage] = useState();
  const [cropImage, setCropImage] = useState(file);

  useEffect(() => {
    if (visible) {
      setImageFile(file);
    } else {
      setImage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  const setImageFile = (file) => {
    const reader = new FileReader();
    reader.onload = () => {
      setImage(reader.result);
    };
    reader.readAsDataURL(file);
  };

  async function cropAndSubmit() {
    if (typeof cropperRef.current?.cropper !== "undefined") {
      const file = await fetch(
        cropperRef.current?.cropper.getCroppedCanvas().toDataURL()
      )
        .then((res) => res.blob())
        .then((blob) => {
          return new File(
            [blob],
            cropImage?.name ? cropImage?.name : "newAvatar.png",
            { type: "image/png" }
          );
        });
      onCrop(file);
      onCancel();
    }
  }

  return (
    <React.Fragment>
      <Modal isOpen={visible} className="modal-dialog-centered" size="md">
        <ModalHeader
          toggle={onCancel}
          close={
            <button className="close" onClick={onCancel}>
              <Icon name="cross" />
            </button>
          }
        ></ModalHeader>
        <ModalBody>
          <Cropper
            ref={cropperRef}
            style={{ maxHeight: 400, width: "100%" }}
            zoomTo={0}
            initialAspectRatio={1}
            aspectRatio={1}
            autoCropArea={0.7}
            src={image}
            viewMode={1}
            minCropBoxHeight={150}
            minCropBoxWidth={150}
            background={false}
            responsive={true}
            cropBoxResizable={true}
            checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
            guides={true}
          />
          <ul className="align-center flex-wrap flex-sm-nowrap gx-3 gy-2 mt-4">
            <li>
              <Button
                color="primary"
                size="md"
                type="button"
                onClick={(ev) => {
                  ev.preventDefault();
                  cropAndSubmit();
                }}
              >
                Crop
              </Button>
            </li>
            <li>
              <Button
                onClick={(ev) => {
                  ev.preventDefault();
                  onCancel();
                }}
                color="lighter"
                size="md"
                type="button"
              >
                Cancel
              </Button>
            </li>
          </ul>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};
