import React, { useState } from "react";
import { PreviewCard } from "../../../components/Component";
import ChatBody from "./ChatBody";

export const ChatFloatingUI = ({ loggedInId, otherChatId, onClosed, chatType }) => {
  const [mobileView, setMobileView] = useState(false);

  return (
    <PreviewCard className="chat-section position-fixed bottom-20 right-20 w-450 h-550">
      {loggedInId && otherChatId && (
        <ChatBody
        channel={null}
        setMobileView={setMobileView}
        mobileView={mobileView}
        isFloatingUI={true}
        currentUserChatId={loggedInId}
        otherUserChatId={otherChatId}
        onClose={() => onClosed()}
        chatType={chatType}
      />
      )}
      
    </PreviewCard>
  );
};
