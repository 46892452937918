import React, { useEffect, useState, Fragment } from "react";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Button,
  ErrorText,
  FormInput,
  FormInputType,
  Icon,
  PreviewCard,
  RSelect,
} from "../../../components/Component";
import {
  Row,
  Col,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Spinner,
  Badge,
} from "reactstrap";
import Content from "../../../layout/content/Content";
import DatePicker from "react-datepicker";
import { useForm } from "react-hook-form";
import classNames from "classnames";
import { propertyListAsSelector } from "../properties/PropertyData";
import {
  createQuoteRequest,
  fileUploader,
  getRequestDetails,
  updateQuoteRequest,
} from "./RequestData";
import { Storage } from "../../../services/storage/storage";
import moment from "moment";
import { useHistory, useLocation } from "react-router";
import { getServices } from "../../auth/AuthData";
import {
  AppText,
  DATE_TIME_FORMAT,
  StatusType,
  covertToNumberOnly,
  numberFormatter,
  openLinkInNewTab,
  quoteRequestRecurringType,
  removeTimeFromServerDateString,
  showErrorToast,
  showInfoToast,
} from "../../../utils";
import Head from "../../../layout/head/Head";
import { components } from "react-select";
import PropertyAddModel from "../properties/PropertyAdd";
import { FileSizeText } from "../../../components/text/FileSizeText";
import { bytesToMegaBytes } from "../../../utils/Utils";

const ServiceType = {
  oneTime: "One-time",
  recurring: "Recurring",
};
const VendorSelection = {
  openQuote: "Open quote",
  inviteOnly: "Invite only",
};

const PaymentOptionType = {
  withPayment: "Yes",
  withoutPayment: "No",
};

const AddRequest = () => {
  const [loading, setLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState();
  const [selectedTime, setSelectedTime] = useState();
  const [idealStartDate, setIdealStartDate] = useState();
  const [idealEndDate, setIdealendDate] = useState();
  const [request, setRequest] = useState({});
  const [serviceType, setServiceType] = useState();
  const [propertyList, setPropertyList] = useState([]);
  const [serviceList, setServiceList] = useState([]);
  const [contactPersonsList, setContactPersonsList] = useState([]);
  const [requestId, setRequestId] = useState(null);
  const [isBackFromPickVendors, setIsBackFromPickVendors] = useState(false);
  const [isShowAddProperty, setIsShowAddProperty] = useState(false);
  const [isError, setIsError] = useState(false);

  const { errors, register, handleSubmit } = useForm();
  const formClass = classNames({
    "form-validate": true,
    "is-alter": false,
  });

  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    const data = location.state?.item;
    let savedRequest = Storage.getQuoteRequest();
    if ((data !== undefined || null || "") && savedRequest === null) {
      // When come for editing request
      loadRequest(data.id);
    } else {
      let authInfo = Storage.getLoggedInUser() ?? null;
      if (authInfo?.user) {
        let savedRequest = Storage.getQuoteRequest();
        savedRequest?.id && setRequestId(savedRequest.id);
        if (savedRequest) {
          // When user back from vendor selection screen
          setIsBackFromPickVendors(true);
          setRequest(savedRequest);
          Storage.removeQuoteRequest();

          setIdealStartDate(
            savedRequest.startDate
              ? moment(savedRequest.startDate, "YYYY-MM-DD").toDate()
              : undefined
          );
          setIdealendDate(
            savedRequest.endDate
              ? moment(savedRequest.endDate, "YYYY-MM-DD").toDate()
              : undefined
          );
          if (savedRequest.deadlineDate && savedRequest.deadlineTime) {
            let dateTime =
              savedRequest.deadlineDate + " " + savedRequest.deadlineTime;
            let date = moment(dateTime, "YYYY-MM-DD HH:mm:ss").toDate();
            setSelectedDate(date);
            setSelectedTime(date);
          }
        } else {
          // When user enter for new request
          setContactPersons(
            authInfo.user.firstName,
            authInfo.user.lastName,
            authInfo.user.id
          );

          setRequest((prev) => ({
            ...prev,
            isPublic: true,
            files: [],
          }));

          if (location.state?.services) {
            setRequest((prev) => ({
              ...prev,
              serviceIds: location.state.services,
            }));
          }
        }

        if (location.state?.vendors) {
          setRequest((prev) => ({
            ...prev,
            vendors: location.state.vendors,
          }));
        } else {
          let list = Storage.getVendorList();
          // When come from vendor list by picking vendors for request.
          setRequest((prev) => ({
            ...prev,
            vendors: list ?? [],
          }));
          Storage.removeVendorList();
        }
      }
      loadServices();
      loadProperty();
    }
  }, []);

  useEffect(() => {
    setRequest((prev) => ({
      ...prev,
      isRecurring: serviceType === ServiceType.recurring,
    }));
  }, [serviceType]);

  const onFormSubmit = () => {
    if (
      (request?.title?.length ?? 0) !== 0 &&
      (request?.serviceIds?.length ?? 0) > 0 &&
      (request?.propertyIds?.length ?? 0) > 0 &&
      (request?.contactUserIds?.length ?? 0) > 0 &&
      request?.startDate !== null &&
      request?.startDate !== undefined &&
      request?.isPayRequired !== null &&
      request?.isPayRequired !== undefined &&
      (request?.isPublic === true ||
        (request?.isPublic === false && (request?.vendors?.length ?? 0) > 0))
    ) {
      setIsError(false);
      saveOnServer(false);
    } else {
      setIsError(true);
    }
  };

  const saveToDraft = () => {
    if ((request?.title?.length ?? 0) > 0) {
      saveOnServer(true);
    } else {
      showErrorToast("Add your request title to save as draft");
    }
  };

  const uploadFile = (data) => {
    if (bytesToMegaBytes(data.size) > 100) {
      showErrorToast(AppText.maxFileSizeError);
    } else {
      var file = data;
      file.isUploading = true;
      file.isError = false;
      file.uploadedFileId = null;
      file.fileName = data.name;
      file.isOldFile = false;
      let files = request.files ? [...request.files] : [];
      files.push(file);
      setRequest((prev) => ({
        ...prev,
        files: files,
      }));
      upload(file, files.length - 1);
    }
  };

  // *************************API's**************************************
  const saveOnServer = (isDrafted) => {
    setLoading(true);
    let deadline = undefined;
    if (request.deadlineDate && request.deadlineTime) {
      deadline = moment(
        request.deadlineDate + " " + request.deadlineTime,
        "YYYY-MM-DD HH:mm:ss"
      )
        .utc()
        .format("YYYY-MM-DD HH:mm:ss");
    }

    let params = {
      propertyIds: request?.propertyIds?.map((item) => item.id),
      contactUserIds: request?.contactUserIds?.map((item) => item.id),
      serviceIds: request?.serviceIds?.map((item) => item.id),
      vendorIds: request?.vendors?.map((item) => item.id),
      deadlineAt: deadline,
      budget: request.budget
        ? parseFloat(covertToNumberOnly(request.budget ?? 0))
        : 0,
      endDate: request.endDate,
      startDate: request.startDate,
      title: request.title,
      workDescription: request.workDescription,
      isRecurring: request.isRecurring,
      isPublic: request.isPublic ?? true,
      isPayRequired: request.isPayRequired,
      isDrafted: isDrafted,
      isStartDateFlexible: request.isStartDateFlexible ?? false,
      fileIds: request.files
        .filter(
          (item) =>
            item.isOldFile === false &&
            item.isError === false &&
            item.uploadedFileId !== null
        )
        .map((item) => item.uploadedFileId),
    };

    if (request.currentProjectStatus) {
      params["projectStatus"] =
        request.currentProjectStatus === "PLANNING_&_BUDGETING"
          ? "PLANNING_&_BUDGETING"
          : "READY_TO_HIRE";
    }

    if (request.isRecurring) {
      params["recurringType"] = request.recurringType;
    }

    if (requestId) {
      params["id"] = requestId;
      params["removeFileIds"] = request.removeFileIds;

      updateQuoteRequest(params, (data, error) => {
        if (error === null) {
          history.goBack();
        } else {
          setLoading(false);
          showErrorToast(
            error.message.length > 0 ? error.message : AppText.connectError
          );
        }
      });
    } else {
      createQuoteRequest(params, (data, error) => {
        if (error === null) {
          history.push(`${process.env.PUBLIC_URL}/`);
          if (!isDrafted) {
            showInfoToast("Your request has been published successfully!");
          }
        } else {
          setLoading(false);
          showErrorToast(
            error.message.length > 0 ? error.message : AppText.connectError
          );
        }
      });
    }
  };

  function loadProperty() {
    propertyListAsSelector(1, 100, (data, error) => {
      if (error === null) {
        setPropertyList(data);
        if (data.length === 1 && requestId === null && !isBackFromPickVendors) {
          setRequest((prev) => ({
            ...prev,
            propertyIds: data,
          }));
        }
      }
    });
  }

  function loadServices() {
    getServices({}, (list, error) => {
      if (error === null) {
        setServiceList(list);
      }
    });
  }

  function loadRequest(id) {
    getRequestDetails({ id: id }, (data, error) => {
      if (error === null) {
        if ((data.contacts?.length ?? 0) > 0) {
          setContactPersons(
            data.contacts[0].firstName,
            data.contacts[0].lastName,
            data.contacts[0].id
          );
        }
        setRequestId(data.id);
        let start = data.startDate
          ? removeTimeFromServerDateString(data.startDate)
          : undefined;
        let end = data.endDate
          ? removeTimeFromServerDateString(data.endDate)
          : undefined;
        setIdealStartDate(
          start ? moment(start, "YYYY-MM-DD").toDate() : undefined
        );
        setIdealendDate(end ? moment(end, "YYYY-MM-DD").toDate() : undefined);
        var properties = [];
        for (let index = 0; index < data.properties?.length ?? 0; index++) {
          const element = data.properties[index];
          properties.push({
            label: element.name,
            value: element.name,
            id: element.id,
          });
        }

        var services = [];
        for (let index = 0; index < data.services?.length ?? 0; index++) {
          const element = data.services[index];
          services.push({
            label: element.name,
            value: element.name,
            id: element.id,
          });
        }

        let deadlineDate = null;
        let deadlineTime = null;
        if (data.deadlineAt) {
          let date = moment(data.deadlineAt).toDate();

          if (date) {
            setSelectedDate(date);
            setSelectedTime(date);
            deadlineDate = moment(date).format("YYYY-MM-DD");
            deadlineTime = moment(date).format("HH:mm:ss");
          }
        }

        var vendors = [];
        for (let index = 0; index < data.vendors?.length ?? 0; index++) {
          const element = data.vendors[index];
          vendors.push({
            name: element.companyName,
            id: element.id,
          });
        }

        var files = [];
        for (let index = 0; index < data.files?.length ?? 0; index++) {
          let object = data.files[index];
          var file = {
            isUploading: false,
            isError: false,
            uploadedFileId: object.id,
            fileName: object.name,
            fileKey: object.fileKey,
            fileUrl: object.fileUrl,
            isOldFile: true,
          };
          files.push(file);
        }

        setRequest((prev) => ({
          ...prev,
          id: data.id,
          isPublic: data.isPublic ?? true,
          isPayRequired: data.isPayRequired,
          startDate: start,
          endDate: end ?? undefined,
          title: data.title,
          workDescription: data.workDesc,
          propertyIds: properties,
          serviceIds: services,
          isStartDateFlexible: data.isStartDateFlexible === 1 ? true : false,
          budget: data.budget > 0 ? numberFormatter("", "" + data.budget) : "",
          isRecurring: data.isRecurring === 1 ? true : false,
          deadlineDate: deadlineDate ?? null,
          deadlineTime: deadlineTime ?? null,
          vendors: vendors,
          files: files,
          createdAt: data.createdAt,
          status: data.status,
          removeFileIds: [],
        }));
      }
      loadServices();
      loadProperty();
    });
  }

  function upload(file, index) {
    fileUploader(file, "QUOTATION_REQUEST", (data, error) => {
      let files = [...request.files];
      if (error === null) {
        file.uploadedFileId = data.id;
        file.isUploading = false;
        files[index] = file;
      } else {
        files.splice(index, 1);
        showErrorToast(
          error.message.length > 0 ? error.message : AppText.connectError
        );
      }
      setRequest((prev) => ({
        ...prev,
        files: files,
      }));
    });
  }
  // *************************End API's**************************************

  // *************************Functions's**************************************

  function setContactPersons(firstName, lastName, id) {
    let name = firstName + " " + lastName;
    let user = {
      value: name,
      label: name,
      id: id,
    };
    setContactPersonsList([user]);
    setRequest((prev) => ({
      ...prev,
      contactUserIds: [user],
    }));
  }

  function selectVendors() {
    // Storage.setQuoteRequest(request);
    history.push({
      pathname: `${process.env.PUBLIC_URL}/vendors`,
      state: { isComeToPickVendor: true, request: request },
    });
  }

  const Menu = (props) => {
    return (
      <Fragment>
        <components.Menu {...props}>
          <div>
            <div>{props.children}</div>
            <div className="mx-2 my-1">
              <span className="text">
                Can't find a Property? Click{" "}
                <a
                  href="#here"
                  className="text-azure"
                  onClick={(ev) => {
                    setIsShowAddProperty(true);
                  }}
                >
                  here
                </a>
              </span>
            </div>
          </div>
        </components.Menu>
      </Fragment>
    );
  };
  const Option = (props) => {
    return (
      <Fragment>
        <components.Option {...props}>{props.children}</components.Option>
      </Fragment>
    );
  };

  const openMail = () => {
    window.open(process.env.REACT_APP_CONTACT_US_MAIL);
  };
  const openTelegramChat = () => {
    window.open(process.env.REACT_APP_FeedBack_Help);
  };

  // *************************End Functions's**************************************

  return (
    <React.Fragment>
      <Head title="New request"></Head>
      <Content>
        <Block>
          <BlockHead size="lg">
            <BlockBetween>
              <BlockHeadContent>
                <BlockTitle tag="h3" page>
                  {requestId ? "Update request" : "New request"}
                </BlockTitle>
                <BlockDes className="text-soft">
                  <ul className="list-inline">
                    <li>
                      <span className="text-base">
                        Request ID #{requestId ?? ""}
                      </span>
                    </li>
                    <li>
                      <span className="text-base">
                        Created on{" "}
                        {request.createdAt
                          ? moment(request.createdAt).format(DATE_TIME_FORMAT)
                          : moment().format(DATE_TIME_FORMAT)}
                      </span>
                    </li>
                  </ul>
                </BlockDes>
              </BlockHeadContent>
              <BlockHeadContent>
                <div className="toggle-wrap nk-block-tools-toggle">
                  <div className="toggle-expand-content">
                    <ul className="nk-block-tools g-3">
                      <li>
                        <Button
                          color="light"
                          outline
                          className="bg-white d-none d-sm-inline-flex "
                          onClick={() => history.goBack()}
                        >
                          <Icon name="arrow-left"></Icon>
                          <span>Back</span>
                        </Button>
                        <a
                          href="#back"
                          onClick={(ev) => {
                            ev.preventDefault();
                            history.goBack();
                          }}
                          className="btn btn-icon btn-outline-light bg-white d-inline-flex d-sm-none"
                        >
                          <Icon name="arrow-left"></Icon>
                        </a>
                      </li>
                      <li className="nk-block-tools-opt">
                        {requestId ? (
                          <Button
                            color="primary"
                            type="button"
                            onClick={() => onFormSubmit()}
                          >
                            {loading ? (
                              <Spinner size="sm" color="light" />
                            ) : (
                              "Save"
                            )}
                          </Button>
                        ) : (
                          ""
                        )}
                      </li>
                    </ul>
                  </div>
                </div>
              </BlockHeadContent>
            </BlockBetween>
          </BlockHead>
        </Block>
        <Block className={"justify-left"}>
          <BlockHead size="lg" wide="sm">
            <BlockHeadContent>
              <PreviewCard>
                <form
                  className={formClass}
                  onSubmit={handleSubmit(onFormSubmit)}
                >
                  <Row className="g-4">
                    <Col md="6">
                      <div className="form-group">
                        <label className="form-label" htmlFor="request-title-1">
                          Quote request title
                        </label>
                        <div className="form-control-wrap">
                          <input
                            type="text"
                            id="request-title-1"
                            name="requestTitle"
                            className="form-control"
                            value={request.title ?? ""}
                            onChange={(event) => {
                              setRequest((prev) => ({
                                ...prev,
                                title: event.target.value,
                              }));
                            }}
                          />
                          {isError && (request?.title?.length ?? 0) === 0 && (
                            <span className="invalid">
                              This field is required
                            </span>
                          )}
                        </div>
                      </div>
                    </Col>

                    <Col md="6">
                      <div className="form-group">
                        <label
                          className="form-label"
                          htmlFor="service-needed-1"
                        >
                          Service needed
                        </label>
                        <div className="form-control-wrap">
                          <RSelect
                            options={serviceList}
                            value={request?.serviceIds}
                            isMulti
                            onChange={(e) =>
                              setRequest((prev) => ({
                                ...prev,
                                serviceIds: e,
                              }))
                            }
                          />
                          {isError &&
                            (request?.serviceIds?.length ?? 0) === 0 && (
                              <ErrorText />
                            )}
                        </div>
                      </div>
                    </Col>

                    <Col md="12">
                      <div className="form-group">
                        <label className="form-label" htmlFor="property-1">
                          Property
                        </label>
                        <div className="form-control-wrap">
                          <RSelect
                            options={propertyList}
                            components={{ Menu, Option }}
                            value={request?.propertyIds}
                            isMulti
                            // noOptionsMessage="All properties are already selected"
                            onChange={(e) =>
                              setRequest((prev) => ({
                                ...prev,
                                propertyIds: e,
                              }))
                            }
                          />
                          {isError &&
                            (request?.propertyIds?.length ?? 0) === 0 && (
                              <ErrorText />
                            )}
                        </div>
                      </div>
                    </Col>

                    <Col md="12" className="mb-0">
                      <div className="form-group">
                        <label
                          className="form-label"
                          htmlFor="service-needed-1"
                        >
                          Description of work
                        </label>
                        <div className="form-control-wrap">
                          <textarea
                            className="form-control fSize14"
                            name="descriptionOfWork"
                            value={request.workDescription ?? ""}
                            onChange={(e) =>
                              setRequest((prev) => ({
                                ...prev,
                                workDescription: e.target.value,
                              }))
                            }
                          />
                        </div>
                        {request.files && (
                          <div className="form-group mb-0">
                            {request.files.map((item, index) => {
                              return (
                                <p
                                  key={index}
                                  className="mb-n1"
                                  style={{ marginTop: 8, fontSize: 14 }}
                                >
                                  <span>
                                    <Icon name="clip" />{" "}
                                  </span>
                                  <span
                                    className={
                                      item.isError ? "text-danger" : "text-grey"
                                    }
                                  >
                                    {item?.fileName}
                                  </span>
                                  <span>
                                    {" "}
                                    {item.isUploading ? (
                                      <Spinner size="sm" color="dark" />
                                    ) : (
                                      <Icon
                                        name="cross-circle"
                                        className={"btn ms-n3"}
                                        style={{ color: "red" }}
                                        onClick={() => {
                                          let temp = request.files.filter(
                                            (item1) => item1 !== item
                                          );
                                          setRequest((prev) => ({
                                            ...prev,
                                            files: temp,
                                            removeFileIds: item.isOldFile
                                              ? [
                                                  ...prev.removeFileIds,
                                                  item.uploadedFileId,
                                                ]
                                              : prev.removeFileIds,
                                          }));
                                        }}
                                      />
                                    )}
                                  </span>
                                </p>
                              );
                            })}
                          </div>
                        )}
                        <div
                          className="form-group mb-0"
                          style={{ marginTop: 14 }}
                        >
                          <label
                            // className="btn"
                            htmlFor="files"
                            style={{
                              color: "#2C75FF",
                              fontSize: 14,
                              marginBottom: 14,
                            }}
                          >
                            <Icon name="clip" className={"mb-1"} /> Attach files
                          </label>
                          <FileSizeText />
                          <input
                            type="file"
                            name="files"
                            accept="image/*,.pdf,.doc,.docx,.xml"
                            className="form-control"
                            id="files"
                            style={{ display: "none" }}
                            onChange={(e) => {
                              let temp = Array.prototype.slice.call(
                                e.target.files
                              );

                              if (temp.length > 0) {
                                uploadFile(temp[0]);
                              }
                            }}
                          />
                        </div>
                      </div>
                    </Col>

                    <Col md="6" className="mt-0">
                      <div className="form-group">
                        <label className="form-label" htmlFor="organizer-1">
                          Who should vendor contact to organize site visit?
                        </label>
                        <div className="form-control-wrap">
                          <RSelect
                            options={contactPersonsList}
                            value={request?.contactUserIds}
                            isMulti
                            onChange={(e) => {
                              setRequest((prev) => ({
                                ...prev,
                                contactUserIds: e,
                              }));
                            }}
                          />
                          {isError &&
                            (request?.contactUserIds?.length ?? 0) === 0 && (
                              <ErrorText />
                            )}
                        </div>
                      </div>
                    </Col>

                    <Col md="6">
                      {/* <p className="mt-5">
                    <Link to="#" className="fs-12px">
                      Add secondary point of contact
                    </Link>
                  </p> */}
                    </Col>

                    <Col md="12">
                      <div className="form-group">
                        <ul className="custom-control-group g-3 align-items-start">
                          <li>
                            <div>
                              <label
                                className="form-label"
                                htmlFor="ideal-start-date-1"
                              >
                                Project start date
                              </label>
                            </div>
                            <div className="form-control-wrap">
                              <div className="input-group">
                                <div className="input-group-prepend">
                                  <Icon
                                    className="input-group-text "
                                    name="calender-date"
                                  />
                                  <DatePicker
                                    selected={idealStartDate}
                                    className="form-control w-150px rounded-0 rounded-end border-start-0"
                                    onChange={(date) => {
                                      if (idealEndDate) {
                                        var Difference_In_Time =
                                          idealEndDate.getTime() -
                                          date.getTime();
                                        var Difference_In_Days =
                                          Difference_In_Time /
                                          (1000 * 3600 * 24);

                                        if (Difference_In_Days < 0) {
                                          setIdealendDate();
                                          let updatedRequest = request;
                                          delete updatedRequest.endDate;
                                          setRequest(updatedRequest);
                                        }
                                        setIdealStartDate(date);
                                        setRequest({
                                          ...request,
                                          startDate:
                                            moment(date).format("yyyy-MM-DD"),
                                        });
                                      } else {
                                        setIdealStartDate(date);
                                        setRequest({
                                          ...request,
                                          startDate:
                                            moment(date).format("yyyy-MM-DD"),
                                        });
                                      }
                                    }}
                                    popperPlacement="top-end"
                                  />
                                </div>
                              </div>
                              {isError &&
                                (request?.startDate === null ||
                                  request?.startDate === undefined) && (
                                  <ErrorText className="mb-n2" />
                                )}
                            </div>
                          </li>
                          <li>
                            <div>
                              <label
                                className="form-label"
                                htmlFor="ideal-end-date-1"
                              >
                                End date (optional)
                              </label>
                            </div>
                            <div className="input-group">
                              <div className="input-group-prepend">
                                <Icon
                                  className="input-group-text"
                                  name="calender-date"
                                />
                                <DatePicker
                                  selected={idealEndDate}
                                  className=" form-control w-150px rounded-0 rounded-end border-start-0"
                                  onChange={(date) => {
                                    if (idealStartDate) {
                                      var Difference_In_Time =
                                        date.getTime() -
                                        idealStartDate.getTime();
                                      var Difference_In_Days =
                                        Difference_In_Time / (1000 * 3600 * 24);
                                      if (Difference_In_Days > 0) {
                                        setIdealendDate(date);
                                        setRequest({
                                          ...request,
                                          endDate:
                                            moment(date).format("yyyy-MM-DD"),
                                        });
                                      }
                                    } else {
                                      setIdealendDate(date);
                                      setRequest({
                                        ...request,
                                        endDate: date,
                                      });
                                    }
                                  }}
                                  popperPlacement="top-end"
                                />
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </Col>

                    <Col md="12">
                      <div className="form-group">
                        <div className="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="customCheck1"
                            onChange={(e) =>
                              setRequest({
                                ...request,
                                isStartDateFlexible: e.currentTarget.checked,
                              })
                            }
                          />
                          <label
                            className="custom-control-label fSize14"
                            htmlFor="customCheck1"
                          >
                            Start date is flexible
                          </label>
                        </div>
                      </div>
                    </Col>

                    <Col md="12">
                      <div className="form-group">
                        <label className="form-label" htmlFor="deadline-1">
                          Deadline for quote submission
                        </label>
                        <ul className="custom-control-group g-3 align-center form-froup">
                          <li>
                            <div className="input-group">
                              <div className="input-group-prepend">
                                <Icon
                                  className="input-group-text"
                                  name="calender-date"
                                />
                                <DatePicker
                                  selected={selectedDate}
                                  className=" form-control w-150px rounded-0 rounded-end border-start-0"
                                  onChange={(date) => {
                                    setSelectedDate(date);
                                    setRequest({
                                      ...request,
                                      deadlineDate:
                                        moment(date).format("yyyy-MM-DD"),
                                    });
                                  }}
                                  popperPlacement="top-end"
                                />
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="input-group">
                              <div className="input-group-prepend">
                                <Icon
                                  className="input-group-text"
                                  name="clock"
                                />
                                <DatePicker
                                  selected={selectedTime}
                                  onChange={(date) => {
                                    setSelectedTime(date);
                                    setRequest({
                                      ...request,
                                      deadlineTime:
                                        moment(date).format("HH:mm:ss"),
                                    });
                                  }}
                                  showTimeSelect
                                  showTimeSelectOnly
                                  timeIntervals={15}
                                  timeCaption="Time"
                                  dateFormat="h:mm aa"
                                  className=" form-control w-150px rounded-0 rounded-end border-start-0
                                "
                                  popperPlacement="top-end"
                                />
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </Col>

                    <Col md="6">
                      <div className="form-group">
                        <label className="form-label" htmlFor="looking-for-1">
                          Is it a one-time or recurring service request?
                        </label>
                        {isError && (request.isRecurring === null || request.isRecurring === undefined) && (
                          <span className="invalid">
                            This field is required
                          </span>
                        )}
                        <ul className="custom-control-group g-3 align-center">
                          <li>
                            <div
                              className="custom-control custom-control-sm custom-radio"
                              onChange={(event) => {
                                setServiceType(ServiceType.oneTime);
                              }}
                            >
                              <input
                                type="radio"
                                defaultChecked
                                className="custom-control-input"
                                id="one-time-1"
                                name="lookingFor"
                                value={ServiceType.oneTime}
                              />
                              <label
                                className="custom-control-label fSize14"
                                htmlFor="one-time-1"
                              >
                                {ServiceType.oneTime}
                              </label>
                            </div>
                          </li>
                          <li>
                            <div
                              className="custom-control custom-control-sm custom-radio"
                              onChange={(event) => {
                                setServiceType(ServiceType.recurring);
                              }}
                            >
                              <input
                                type="radio"
                                className="custom-control-input"
                                id="recurring-1"
                                name="lookingFor"
                                value="recurring"
                              />
                              <label
                                className="custom-control-label fSize14"
                                htmlFor="recurring-1"
                              >
                                Recurring
                              </label>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </Col>

                    {serviceType === ServiceType.recurring && (
                      <Col md="6">
                        <div className="form-group">
                          <label
                            className="form-label"
                            htmlFor="recurring-type-1"
                          >
                            How often would you like this job to be done?
                          </label>
                          <div className="form-control-wrap">
                            <RSelect
                              options={quoteRequestRecurringType}
                              onChange={(e) =>
                                setRequest({
                                  ...request,
                                  recurringType: e.value,
                                })
                              }
                            />
                          </div>
                        </div>
                      </Col>
                    )}

                    <Col xl="12">
                      <div className="form-group">
                        <label
                          className="form-label"
                          htmlFor="project-status-1"
                        >
                          Current project status
                        </label>
                        <ul className="custom-control-group g-3 align-center">
                          <li>
                            <div className="custom-control custom-control-sm custom-radio">
                              <input
                                type="radio"
                                className="custom-control-input"
                                id="ready-to-hire-1"
                                name="projectStatus"
                                value="READY_TO_HIRE"
                                onChange={(event) =>
                                  setRequest({
                                    ...request,
                                    currentProjectStatus: event.target.value,
                                  })
                                }
                              />
                              {errors.projectStatus && (
                                <span className="invalid">
                                  This field is required
                                </span>
                              )}
                              <label
                                className="custom-control-label fSize14"
                                htmlFor="ready-to-hire-1"
                              >
                                Ready to hire
                              </label>
                            </div>
                          </li>
                          <li>
                            <div className="custom-control custom-control-sm custom-radio">
                              <input
                                type="radio"
                                className="custom-control-input"
                                id="planning-and-budgeting-1"
                                name="projectStatus"
                                value="PLANNING_&_BUDGETING"
                                onChange={(event) =>
                                  setRequest({
                                    ...request,
                                    currentProjectStatus: event.target.value,
                                  })
                                }
                              />
                              <label
                                className="custom-control-label fSize14"
                                htmlFor="planning-and-budgeting-1"
                              >
                                Planning & budgeting
                              </label>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </Col>

                    <Col md="8">
                      <div className="form-group">
                        <div className="form-control-wrap">
                          <label
                            className="form-label"
                            htmlFor="select-payment-1"
                          >
                            Payment via {process.env.REACT_APP_APP_NAME}
                          </label>
                          {isError && (request.isPayRequired === null || request.isPayRequired === undefined) && (
                            <span className="invalid">
                              <ErrorText className="mt-n1 mb-2" />
                            </span>
                          )}
                        </div>
                        <ul className="custom-control-group g-3 align-center">
                          <li>
                            <div className="custom-control custom-control-sm custom-radio">
                              <input
                                type="radio"
                                className="custom-control-input "
                                checked={request?.isPayRequired ?? null}
                                id="payment-process-1"
                                name="paymentProcess"
                                value={PaymentOptionType.withPayment}
                                onChange={(event) => {
                                  setRequest({
                                    ...request,
                                    isPayRequired: true,
                                  });
                                }}
                              />
                              <label
                                className="fSize14 custom-control-label"
                                htmlFor="payment-process-1"
                              >
                                {PaymentOptionType.withPayment}
                                <label className="mt-2">
                                  You will be charged when you approve one of
                                  the provided quotes. However, the full charge
                                  amount stays in escrow until you confirm the
                                  vendor's work by clicking Close button. Once
                                  you click Close button, money transfer towards
                                  vendor's bank account will be initiated.
                                  Should you have any questions{" "}
                                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                  <a
                                    href=""
                                    onClick={(ev) => {
                                      ev.preventDefault();
                                      openMail();
                                    }}
                                    className="clickable"
                                  >
                                    send us an email
                                  </a>{" "}
                                  or ask in our{" "}
                                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                  <a
                                    href=""
                                    onClick={(ev) => {
                                      ev.preventDefault();
                                      openTelegramChat();
                                    }}
                                    className="clickable"
                                  >
                                    {" "}
                                    community chat.
                                  </a>
                                </label>
                              </label>
                            </div>
                          </li>

                          <li>
                            <div className="custom-control custom-control-sm custom-radio">
                              <input
                                type="radio"
                                className="custom-control-input"
                                checked={
                                  request?.isPayRequired === false ?? undefined
                                }
                                id="payment-without-process-1"
                                name="paymentProcess"
                                value={PaymentOptionType.withoutPayment}
                                onChange={(event) => {
                                  setRequest({
                                    ...request,
                                    isPayRequired: false,
                                  });
                                }}
                              />
                              <label
                                className="custom-control-label fSize14"
                                htmlFor="payment-without-process-1"
                              >
                                {PaymentOptionType.withoutPayment}
                                <label className="mt-2">
                                  In this case, request goes without payment
                                  processing. You won't be charge neither when
                                  you publish request nor upon its completion.
                                  To publish this way, you must have a
                                  subscription.{" "}
                                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                  <a
                                    href=""
                                    onClick={(ev) => {
                                      ev.preventDefault();
                                      openMail();
                                    }}
                                    className="clickable"
                                  >
                                    Contact us
                                  </a>{" "}
                                  for more details.
                                </label>
                              </label>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </Col>

                    {/* <Col lg="6">
                      <div className="form-group">
                        <label className="form-label" htmlFor="budget-1">
                          Budget (optional and hidden from vendor)
                        </label>
                        <div className="form-control-wrap">
                          <div className="input-group">
                            <div className="input-group-prepend">
                              <span className="input-group-text">$</span>
                            </div>
                            <input
                              type="text"
                              id="budget-1"
                              name="budget"
                              value={request.budget ?? ""}
                              maxLength={7}
                              className="form-control"
                              onChange={(e) => {
                                setRequest({
                                  ...request,
                                  budget: numberFormatter("", e.target.value),
                                });
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </Col> */}
                    <Col lg="6">
                      <div className="form-group">
                        <div className="form-control-wrap">
                          <FormInput
                            labelGroupClass="mt-n1"
                            label={"Budget (optional and hidden from vendor)"}
                            type={FormInputType.amount}
                            value={request.budget}
                            onTextChange={(text) =>
                              // setFormData({
                              //   ...formData,
                              //   rent: text,
                              // })
                              setRequest({
                                ...request,
                                budget: text,
                              })
                            }
                          />
                        </div>
                      </div>
                    </Col>

                    <Col md="8">
                      <div className="form-group">
                        <label
                          className="form-label"
                          htmlFor="select-vendors-1"
                        >
                          Select vendors
                        </label>
                        {isError && (request.isPublic === null || request.isPublic === undefined) && (
                          <span className="invalid">
                            This field is required
                          </span>
                        )}
                        <ul className="custom-control-group g-3 align-center">
                          <li>
                            <div className="custom-control custom-control-sm custom-radio">
                              <input
                                type="radio"
                                className="custom-control-input "
                                checked={request?.isPublic ?? true}
                                id="open-quote-1"
                                name="selectVendor"
                                value={VendorSelection.inviteOnly}
                                onChange={(event) => {
                                  setRequest({
                                    ...request,
                                    isPublic: true,
                                  });
                                }}
                              />
                              <label
                                className="fSize14 custom-control-label"
                                htmlFor="open-quote-1"
                              >
                                {VendorSelection.openQuote}
                                <label className="mt-2">
                                  Maximize quote exposure by publishing to all
                                  local vendors that meet your requirements. You
                                  can also invite up to 3 specific vendors to
                                  submit quotes.
                                </label>
                              </label>
                            </div>
                          </li>

                          <li>
                            <div className="custom-control custom-control-sm custom-radio">
                              <input
                                type="radio"
                                className="custom-control-input"
                                checked={!request?.isPublic ?? false}
                                id="invite-only-1"
                                name="selectVendor"
                                value={VendorSelection.inviteOnly}
                                onChange={(event) => {
                                  setRequest({
                                    ...request,
                                    isPublic: false,
                                  });
                                }}
                              />
                              <label
                                className="custom-control-label fSize14"
                                htmlFor="invite-only-1"
                              >
                                {VendorSelection.inviteOnly}
                                <label className="mt-2">
                                  Maximize quote exposure by publishing to all
                                  local vendors that meet your requirements. You
                                  can also invite up to 3 specific vendors to
                                  submit quotes.
                                </label>
                              </label>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </Col>
                    <Col md="12" className="">
                      {request?.vendors && request?.vendors?.length > 0 && (
                        <div className="mb-1">
                          {request?.vendors?.map((item, index) => {
                            return (
                              <Badge
                                className={`badge-dim py-1 flex-shrink ps-2 my-1 me-2`}
                                color="dark"
                                key={index}
                              >
                                <Button
                                  className="p-0 m-0 fw-normal"
                                  type="button"
                                  onClick={(e) => {
                                    const name = item.name
                                      .replace(/\s+/g, "-")
                                      .toLowerCase();
                                    let url = `${process.env.PUBLIC_URL}/vendor/${item.id}-${name}`;
                                    openLinkInNewTab(url, e);
                                  }}
                                >
                                  {item.name ?? ""}
                                </Button>
                                <div className="vr mx-1"></div>
                                <Button
                                  className="p-0 m-0"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    let list = request.vendors?.filter(
                                      (data) => data.id !== item.id
                                    );
                                    setRequest((prev) => ({
                                      ...prev,
                                      vendors: list,
                                    }));
                                  }}
                                >
                                  <Icon name="cross" />
                                </Button>
                              </Badge>
                            );
                          })}
                        </div>
                      )}

                      <div className="mt-3">
                        <Button
                          color="primary"
                          type="button"
                          onClick={() => selectVendors()}
                          className="fSize14"
                          id="#select-vendor"
                        >
                          Select vendor
                        </Button>
                      </div>
                    </Col>
                    <Col xl="12" style={{ marginTop: 50 }}>
                      {requestId && request?.status !== StatusType.draft ? (
                        <Button color="primary" type="submit">
                          {loading ? (
                            <Spinner size="sm" color="light" />
                          ) : (
                            "Save"
                          )}
                        </Button>
                      ) : (
                        <UncontrolledDropdown>
                          <div className="btn-group">
                            <Button color="primary" type="submit">
                              {loading ? (
                                <Spinner size="sm" color="light" />
                              ) : (
                                "Submit quote request"
                              )}
                            </Button>
                            <DropdownToggle
                              className="dropdown-toggle-split"
                              color="primary"
                            >
                              <Icon name="chevron-down"></Icon>
                            </DropdownToggle>
                          </div>
                          <DropdownMenu>
                            <ul className="link-list-opt">
                              <li>
                                <DropdownItem
                                  tag="a"
                                  href="#links"
                                  onClick={(ev) => {
                                    ev.preventDefault();
                                    saveToDraft();
                                  }}
                                >
                                  <span>Save as draft</span>
                                </DropdownItem>
                              </li>
                            </ul>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      )}
                      <Button
                        color="danger"
                        type="button"
                        onClick={() => {
                          history.goBack();
                        }}
                        className={"btn-dim ms-2"}
                      >
                        Cancel
                      </Button>
                    </Col>
                  </Row>
                </form>
              </PreviewCard>
            </BlockHeadContent>
          </BlockHead>
        </Block>
      </Content>
      <PropertyAddModel
        isOpen={isShowAddProperty}
        isEdit={false}
        data={undefined}
        onCancel={() => {
          setIsShowAddProperty(false);
        }}
        onSave={() => {
          setIsShowAddProperty(false);
          loadProperty();
        }}
      />
    </React.Fragment>
  );
};

export default AddRequest;
// Maximize quote exposure by publishing to all local vendors that meet your requirements.
// You can also invite up to 3 specific vendors to submit quotes.
// Select specific vendors you would like to get quotes from.
// Only these vendors will be able to see your request.
