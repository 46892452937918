import React from 'react'; // Replace 'your-ui-library' with the actual UI library you are using
import { useHistory } from "react-router";
import Button from './Button';
import { Icon } from '../Component';

const BackButton = ({onPress}) => {
  const history = useHistory();

  const handleGoBack = () => {
    onPress ? onPress() : history.goBack();
  };

  return (
    <>
      <Button
        color="light"
        outline
        className="bg-white d-none d-sm-inline-flex"
        onClick={handleGoBack}
      >
        <Icon name="arrow-left" />
        <span>Back</span>
      </Button>
      <a
        href="#back"
        onClick={(ev) => {
          ev.preventDefault();
          handleGoBack();
        }}
        className="btn btn-icon btn-outline-light bg-white d-inline-flex d-sm-none"
      >
        <Icon name="arrow-left" />
      </a>
    </>
  );
};

export default BackButton;
