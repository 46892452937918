import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Alert, Modal, ModalBody, Spinner } from "reactstrap";
import { Button, Col, Icon, Row } from "../../../../components/Component";
import { updateUserTenant } from "../../../../pages/auth/AuthData";
import { Storage } from "../../../../services/storage/storage";
import {
  COUNTRY_CODE,
  covertToNumberOnly,
  formatPhoneNumber,
} from "../../../../utils";

export const TenantUpdateProfileModel = ({
  modal,
  user,
  onClosed,
  onSaved,
}) => {
  const [formData, setFormData] = useState({});

  const [phoneNo, setPhoneNo] = useState();
  const [loading, setLoading] = useState(false);
  const [errorVal, setError] = useState();

  useEffect(() => {
    if (modal) {
    } else {
      setError();
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modal]);

  useEffect(() => {
    setFormData({
      firstName: user?.firstName,
      lastName: user?.lastName,
      phone: user?.phone,
    });
    setPhoneNo(user?.phone);
  }, [user]);
  const onInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  function submitForm() {
    setLoading(true);
    var params = {
      firstName: formData.firstName,
      lastName: formData.lastName,
      phone: covertToNumberOnly(formData.phone),
    };

    updateUserTenant(params, (data, error) => {
      setLoading(false);
      let userInfo = Storage.getLoggedInUser();
      userInfo.user.firstName = formData?.firstName;
      userInfo.user.lastName = formData?.lastName;
      userInfo.user.phone = formData?.phone;
      Storage.setLoggedInUser(userInfo);
      if (error === null) {
        onSaved(true);
      } else {
        setError(error.message);
      }
    });
  }

  return (
    <Modal
      isOpen={modal}
      className="modal-dialog-centered"
      size="sm"
      toggle={() => onClosed()}
      autoComplete="off"
    >
      <ModalBody>
        <a
          href="#dropdownitem"
          onClick={(ev) => {
            ev.preventDefault();
            onClosed();
          }}
          className="close"
        >
          <Icon name="cross-sm"></Icon>
        </a>
        <div className="">
          <h5 className="title mb-3">Update profile</h5>
          {errorVal && (
            <div className="my-2">
              <Alert color="danger" className="alert-icon">
                {" "}
                <Icon name="alert-circle" /> {errorVal}{" "}
              </Alert>
            </div>
          )}

          <div className="tab-content">
            <div>
              <Row className="gy-3">
                <Col>
                  <div className="form-group">
                    <label className="form-label" htmlFor="full-name">
                      First name
                    </label>
                    <input
                      type="text"
                      id="first-name"
                      className="form-control"
                      name="firstName"
                      onChange={(e) => onInputChange(e)}
                      defaultValue={formData?.firstName ?? ""}
                      placeholder="Enter first name"
                    />
                  </div>
                </Col>

                <Col>
                  <div className="form-group">
                    <label className="form-label" htmlFor="display-name">
                      Last name
                    </label>
                    <input
                      type="text"
                      id="last-name"
                      className="form-control"
                      name="lastName"
                      onChange={(e) => onInputChange(e)}
                      defaultValue={formData?.lastName ?? ""}
                      placeholder="Enter last name"
                    />
                  </div>
                </Col>
                <Col>
                  <div className="form-group">
                    <label className="form-label" htmlFor="phone">
                      Phone
                    </label>
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <span className="input-group-text">{COUNTRY_CODE}</span>
                      </div>
                      <input
                        type="text"
                        id="phone"
                        name="phone"
                        onChange={(e) => {
                          setPhoneNo(e.target.value);
                          onInputChange(e);
                        }}
                        className="form-control"
                        maxLength={14}
                        minLength={14}
                        value={formatPhoneNumber("", phoneNo)}
                      />
                    </div>
                  </div>
                </Col>
                <Col size="12 mt-4">
                  <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                    <li>
                      <Button
                        color="primary"
                        size="lg"
                        onClick={() => submitForm()}
                      >
                        {loading ? <Spinner size="sm" color="light" /> : "Save"}
                      </Button>
                    </li>
                    <li>
                      <a
                        href="#dropdownitem"
                        onClick={(ev) => {
                          ev.preventDefault();
                          onClosed();
                        }}
                        className="link link-light"
                      >
                        Cancel
                      </a>
                    </li>
                  </ul>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};
