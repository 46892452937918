import { apiService } from "../../../../services/api";
import { APIServicePath } from "../../../../services/api/api-path";

export function saveCard(token, callback) {
  apiService
    .saveCard({ token: token })
    .then((list) => {
      callback(list, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function getCard(callback) {
  apiService
    .getCards()
    .then((card) => {
      callback(card, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function getPayoutConnectLink(params, callback) {
  apiService
    .postCall(APIServicePath.payoutToken, params)
    .then((data) => {
      callback(data, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function getPaymentDetail(callback) {
  apiService
    .getCall(APIServicePath.bankList)
    .then((data) => {
      callback(data, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function getStripeClientSecret(callback) {
  apiService
    .postCall(APIServicePath.bankClientSecret)
    .then((data) => {
      callback(data, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function getStripeClientSecretForBank(id, callback) {
  apiService
    .postCall(APIServicePath.bankClientSecretForBank, { bankId: id })
    .then((data) => {
      callback(data, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function getTenantPaymentDetail(callback) {
  apiService
    .postCall(APIServicePath.tenantBank)
    .then((data) => {
      callback(data, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function removeBankTenant(id, callback) {
  apiService
    .postCall(APIServicePath.tenantBankRemove, { bankId: id })
    .then((data) => {
      callback(data, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function savePMInvoiceSettings(params, callback) {
  apiService
    .postCall(APIServicePath.updatePMProfile, params)
    .then((data) => {
      callback(data, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}
