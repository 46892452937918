import { apiService } from "../../../services/api";

export const notes = [
  {
    id: 1,
    text: "Aproin at metus et dolor tincidunt feugiat eu id quam. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Aenean sollicitudin non nunc vel pharetra.",
    date: "November 18, 2019",
    time: "5:34 pm",
    company: "Softnio",
  },
  {
    id: 2,
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi eleifend libero semper metus aliquam tempus. Sed efficitur elit et ligula lobortis",
    date: "December 27, 2019",
    time: "7:00 pm",
    company: "Softnio",
  },
];

export const filterStatus = [
  { value: "Active", label: "Active" },
  { value: "Pending", label: "Pending" },
  { value: "Suspend", label: "Suspend" },
];


export function getVendorList(data, callback) {
  apiService.getVendorList(data)
  .then((auth) => {
      callback(auth, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function getVendorDetail(data, callback) {
  apiService.getVendorDetail(data)
  .then((auth) => {
      callback(auth, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function getSocialFlagsAsText(vendor) {
  if (vendor === undefined || vendor === null) {
    return ""
  }
  
  var list = [];
  if (vendor.isSocialDiversityInclusionPolicy === 1 || vendor.isSocialDiversityInclusionPolicy === true) {
    list.push("Diversity & inclusion policy");
  }
  if (vendor.isSocialEnvironSustainPolicy === 1 || vendor.isSocialEnvironSustainPolicy === true) {
    list.push("Environmental or sustainability policy");
  }
  if (vendor.isSocialStaffCovidVaccinated === 1 || vendor.isSocialStaffCovidVaccinated === true) {
    list.push("Staff are COVID-19 vaccinated");
  }
  return list.length === 0 ? "N/A" : list.join(", ");
}

export function getServiceOfferFlagsAsText(vendor) {
  if (vendor === undefined || vendor === null) {
    return ""
  }

  var list = [];
  if (vendor.isServiceIndividualUnits === 1 || vendor.isServiceIndividualUnits === true) {
    list.push("Services individual units");
  }
  if (vendor.isServiceSuppliesGoods === 1 || vendor.isServiceSuppliesGoods === true) {
    list.push("Supplies goods/products");
  }
  if (vendor.isServiceUnionizedBusiness === 1 || vendor.isServiceUnionizedBusiness === true) {
    list.push("Unionized business");
  }
  return list.length === 0 ? "N/A" : list.join(", ");
}

export function getAssociationFlagsAsText(vendor) {
  
  if (vendor === undefined || vendor === null) {
    return ""
  }

  var list = [];
  if (vendor.isAssociationAcmo === 1 || vendor.isAssociationAcmo === true) {
    list.push("ACMO");
  }
  if (vendor.isAssociationBoma === 1 || vendor.isAssociationBoma === true) {
    list.push("BOMA");
  }
  return list.length === 0 ? "N/A" : list.join(", ");
}