import React, { useState } from "react";
import {
  Block,
  BlockContent,
  BlockHead,
  BlockTitle,
  Button,
  Icon,
  PreviewCard,
} from "../../components/Component";
import Logo from "../../images/logo.svg";
import LogoDark from "../../images/logo-dark.svg";
import PageContainer from "../../layout/page-container/PageContainer";
import Head from "../../layout/head/Head";
import AuthFooter from "./AuthFooter";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Alert, Form, Spinner } from "reactstrap";
import { NewPasswordType } from "../../utils";
import { checkPassword } from "./AuthData";

export const VerifyPasswordType = {
    changePassword: 1,
    changeEmail: 2,
}

const VerifyPassword = () => {
  const { errors, register, handleSubmit } = useForm();
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const [newPassState, setNewPassState] = useState(false);
  const location = useLocation();
  const [errorVal, setError] = useState("");

  const onFormSubmit = (formData) => {
    var data = formData;
    setLoading(true);
    checkPassword(data, (data, error) => {
      if (error === null) {
        setError(null)
        setTimeout(() => {
          setLoading(false);
          history.push({
            pathname: location.state.type === VerifyPasswordType.changePassword ? `${process.env.PUBLIC_URL}/new-password` : `${process.env.PUBLIC_URL}/new-email`,
            state: {
              type: NewPasswordType.changePassword,
              token: data.token,
            },
          });
        }, 0);
      } else {
        setLoading(false);
        setError(error.message)
      }
    });
  };

  return (
    <React.Fragment>
      <Head title="Verify your current password" />
      <PageContainer>
        <Block className="nk-block-middle nk-auth-body  wide-xs">
          <div className="brand-logo pb-4 text-left mx-4">
            <Link to={process.env.PUBLIC_URL + "/"} className="logo-link">
              <img
                className="logo-light logo-img logo-img-lg"
                src={Logo}
                alt="logo"
              />
              <img
                className="logo-dark logo-img logo-img-lg"
                src={LogoDark}
                alt="logo-dark"
              />
            </Link>
          </div>
          <PreviewCard className="card-bordered" bodyClass="card-inner-lg">
            <BlockHead>
              <BlockContent>
                <BlockTitle tag="h5">Enter your current password</BlockTitle>
              </BlockContent>
            </BlockHead>
            {errorVal && (
              <div className="mb-3">
                <Alert color="danger" className="alert-icon">
                  {" "}
                  <Icon name="alert-circle" /> {errorVal}{" "}
                </Alert>
              </div>
            )}
            <Form
              className="form-validation"
              onSubmit={handleSubmit(onFormSubmit)}
            >
              <div className="form-group">
                <div className="form-label-group">
                  <label className="form-label" htmlFor="password">
                    Current password
                  </label>
                </div>
                <div className="form-control-wrap">
                  <a
                    href="#password"
                    onClick={(ev) => {
                      ev.preventDefault();
                      setNewPassState(!newPassState);
                    }}
                    className={`form-icon lg form-icon-right passcode-switch ${
                      newPassState ? "is-hidden" : "is-shown"
                    }`}
                  >
                    <Icon name="eye" className="passcode-icon icon-hide"></Icon>
                    <Icon
                      name="eye-off"
                      className="passcode-icon icon-show"
                    ></Icon>
                  </a>
                  <input
                    type={newPassState ? "text" : "password"}
                    id="password"
                    name="password"
                    ref={register({
                      required: true,
                    })}
                    className={`form-control-lg form-control ${
                      newPassState ? "is-hidden" : "is-shown"
                    }`}
                  />
                  {errors.password &&
                    errors.password.type === "required" && (
                      <span className="invalid">This field is required</span>
                    )}
                </div>
              </div>
              <div className="form-group">
                <Button
                  type="submit"
                  color="primary"
                  size="lg"
                  className="btn-block"
                >
                  {loading ? <Spinner size="sm" color="light" /> : "Continue"}
                </Button>
              </div>
            </Form>
          </PreviewCard>
        </Block>
        <div className="gap-100px" />
        <AuthFooter />
      </PageContainer>
    </React.Fragment>
  );
};
export default VerifyPassword;
