import React, { useEffect, useState } from "react";
import { Col } from "../../Component";
import { toCapitalize } from "../../../utils";

export const CheckInputOptions = ({
  checkIcon,
  options,
  size = 6,
  onChange,
  indexes,
  name,
  label,
  links,
  labelClassName,
  filterLabel,
}) => {

  const [selectedIndexes, setSelectedIndexes] = useState();

  useEffect(() => {
    setSelectedIndexes(indexes ?? [])
  }, [indexes]);

  const handleChange = (index) => {
    if (onChange) {
        let list = [...selectedIndexes];
        if(list.some(val => val === index)) {
            list = list.filter(item => item !== index)
        } else {
            list.push(index)
        }
      onChange(list);
    }
  };

  return (
    <Col>
      {label && (
        <label
          className={`form-label mb-1 ${
            filterLabel ? "overline-title overline-title-alt" : null
          }  ${labelClassName ? labelClassName : ""}`}
        >
          {label}
        </label>
      )}
      <ul className="custom-control-group g-3 align-center">
        {options.map((item, index) => {
          return (
            <li key={index}>
              <div
                className={
                  checkIcon
                    ? " "
                    : "custom-control custom-control-sm custom-checkbox"
                }
              >
                <input
                  className={"custom-control-input"}
                  type="checkbox"
                  id={name + "-" + item + "-" + index}
                  name={name}
                  value={"" + index}
                  onChange={() => handleChange(index)}
                  checked={selectedIndexes?.some(val => val === index) ?? false}
                />
                <label
                  className={
                      "fSize14 custom-control-label"
                  }
                  htmlFor={name + "-" + item + "-" + index}
                >
                  <span>{toCapitalize(item)}</span>
                </label>
                {links && links[index] && (
                  <span className="ms-1">{links[index]}</span>
                )}
              </div>
            </li>
          );
        })}
      </ul>
    </Col>
  );
};
