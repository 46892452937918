import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Alert } from "reactstrap";
import { ActionButton, CancelButton, Icon } from "../Component";
import Content from "../../layout/content/Content";

export const AltitudeModal = ({
  isOpen,
  toggle,
  title,
  loading,
  size,
  disabled,
  isShowFooter,
  onSave,
  saveButtonTitle = "Save",
  error,
  ...props
}) => {
  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      className="modal-dialog-centered altitude-modal" //altitude-modal
      size={size}
    >
      <ModalBody>
        <a
          href="#cancel"
          onClick={(ev) => {
            ev.preventDefault();
            toggle();
          }}
          className="close"
        >
          <Icon name="cross-sm"></Icon>
        </a>
        <div className="p-2">
          <h5 className="title mb-4">{title}</h5>
          {error && (
            <div className="my-2">
              <Alert color="danger" className="alert-icon">
                {" "}
                <Icon name="alert-circle" /> {error}{" "}
              </Alert>
            </div>
          )}
          <div className="mt-4">
            {props.children}
            {isShowFooter && (
              <div className="justify-content-start mt-4">
                <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                  <li>
                    <ActionButton
                      title={saveButtonTitle}
                      loading={loading}
                      disabled={disabled}
                      onClick={onSave}
                    />
                  </li>
                  <li >
                    <CancelButton onClick={toggle} />
                  </li>
                </ul>
              </div>
            )}
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};
