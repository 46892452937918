import { UserAccountType } from "../../../utils/Utils";
import { apiService } from "../../../services/api";
import moment from "moment";
import {
  CalendarEventType,
  removeTimeFromServerDateString,
  statusColor,
} from "../../../utils";
import { maintenanceRequestList } from "../maintenance-request/MaintenanceRequestData";

export function createQuoteRequest(data, callback) {
  apiService
    .quoteRequest(data)
    .then((data) => {
      callback(data, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function updateQuoteRequest(data, callback) {
  apiService
    .updateQuoteRequest(data)
    .then((data) => {
      callback(data, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function getRequestDetails(data, callback) {
  apiService
    .fetchRequestDetail(data)
    .then((data) => {
      callback(data, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function getPMRequestList(data, callback) {
  apiService
    .getRequestList(data)
    .then((data) => {
      callback(data, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function getCalenderEvents(data, accountType, callback) {
  if (accountType === UserAccountType.manager) {
    getPMRequestList(data, (pmData, error) => {
      let quotationsRequest = [];
      if (error === null) {
        quotationsRequest = toCalenderEvents(
          pmData.list,
          CalendarEventType.quotationRequest
        );
      }
      error && console.log("error 1: ", error);
      maintenanceRequestList(data, (maintenanceData, error) => {
        let maintenanceRequest = [];
        if (error === null) {
          maintenanceRequest = toCalenderEvents(
            maintenanceData.list,
            CalendarEventType.maintenanceRequest
          );
        }
        error && console.log("error 2: ", error);
        callback([...quotationsRequest, ...maintenanceRequest], null);
      });
    });
  } else if (accountType === UserAccountType.tenant) {
    maintenanceRequestList(data, (request, error) => {
      if (error === null) {
        callback(
          toCalenderEvents(request.list, CalendarEventType.maintenanceRequest),
          null
        );
      } else {
        callback(null, error);
      }
    });
  } else {
    getVendorRequestList(data, (data, error) => {
      if (error === null) {
        callback(
          toCalenderEvents(data.list, CalendarEventType.quotationRequest),
          null
        );
      } else {
        callback(null, error);
      }
    });
  }
}

function toCalenderEvents(list, type) {
  var events = [];
  for (let index = 0; index < list.length; index++) {
    const item = list[index];
    let start = moment(
      removeTimeFromServerDateString(item.startDate || item.scheduleFor)
    ).format("YYYY-MM-DD");
    let end = item.endDate
      ? moment(removeTimeFromServerDateString(item.endDate || item.scheduleFor))
          .add(1, "days")
          .format("YYYY-MM-DD")
      : start;
    events.push({
      id: item.id,
      title:
        type === CalendarEventType.quotationRequest
          ? item.title
          : item.category.label + " - " + item.description,
      start: start,
      end: end,
      //className: "fc-event-primary",
      className: "fc-event-" + statusColor(item.status),
      description: item.workDesc || item.description,
      object: item,
      type: type,
    });
  }
  return events;
}

export function getVendorRequestList(data, callback) {
  apiService
    .getVendorRequestList(data)
    .then((data) => {
      callback(data, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function createRemoveQuotation(id, callback) {
  let req = {
    id: id,
  };
  apiService
    .removeQuotation(req)
    .then((data) => {
      callback(data, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function removeRequestByVendor(id, callback) {
  let req = {
    id: id,
  };
  apiService
    .removeRequestbyVendor(req)
    .then((data) => {
      callback(data, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function getInvitedVendorList(req, callback) {
  apiService
    .getInvitedVendor(req)
    .then((data) => {
      callback(data, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function getBidingList(req, callback) {
  apiService
    .getBidList(req)
    .then((data) => {
      callback(data, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function sendBid(req, callback) {
  apiService
    .sendBid(req)
    .then((data) => {
      callback(data, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function updateSentBid(req, callback) {
  apiService
    .updateSentBid(req)
    .then((data) => {
      callback(data, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function bidAction(req, callback) {
  apiService
    .actionOnBid(req)
    .then((data) => {
      callback(data, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function fileUploader(image, type, callback) {
  apiService
    .fileUploader(image, type)
    .then((data) => {
      callback(data, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function getTax(callback) {
  apiService
    .fetchConfig()
    .then((data) => {
      let tax =
        data?.appConfig?.length > 0 ? data?.appConfig[0].value : undefined;
      callback(tax, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function getTimeline(id, callback) {
  apiService
    .getTimeline({ id: id })
    .then((data) => {
      callback(data, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function uploadRequestExtraFileId(id, fileId, callback) {
  apiService
    .updateRequestExtraFileId({ id: id, fileIds: [fileId] })
    .then((data) => {
      callback(data, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function markAsCompleted(id, callback) {
  apiService
    .markRequestAsCompletedByVendor({ id: id })
    .then((data) => {
      callback(data, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function moderateRequest(id, isApproved, callback) {
  apiService
    .moderateRequestByPM({
      id: id,
      status: isApproved ? "APPROVED" : "REJECTED",
    })
    .then((data) => {
      callback(data, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}
