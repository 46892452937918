import React, { useEffect, useState } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import bootstrapPlugin from "@fullcalendar/bootstrap5";
import DatePicker from "react-datepicker";
import {
  Popover,
  PopoverHeader,
  PopoverBody,
  ModalHeader,
  Modal,
  ModalBody,
  Button,
} from "reactstrap";
import { useForm } from "react-hook-form";
import { Col, Row, RSelect } from "../../Component";
import { setDateForPicker } from "../../../utils/Utils";
import { eventOptions, returnDate } from "./CalenderData";

const CalenderApp = ({ events, onEvent, onDateChange }) => {
  return (
    <React.Fragment>
      <FullCalendar
        plugins={[dayGridPlugin, timeGridPlugin, listPlugin, bootstrapPlugin]}
        events={events}
        eventClick={(info) => onEvent(info)}
        datesSet={(dates) => onDateChange(dates.start, dates.end)}
        initialView="dayGridMonth"
        headerToolbar={{
          left: "title prev,next",
          center: null,
          right: "dayGridMonth,timeGridWeek,timeGridDay,listWeek",
          // right: "today dayGridMonth,timeGridWeek,timeGridDay,listWeek",
        }}
        themeSystem="bootstrap5"
        height={800}
        contentHeight={780}
        // eventContent={(e) => <EventView event={e} />}
        aspectRatio={3}
        editable={true}
        droppable={true}
      />
    </React.Fragment>
  );
};

export default CalenderApp;
