import React from "react";
import { Badge } from "reactstrap";
import { URGENCY, toCapitalize } from "../../utils";

const UrgencyBadge = ({ className, color, text, ...props }) => {
  const colorSelect = (Text) => {
    switch (Text) {
      case URGENCY.high:
        return "danger";
      case URGENCY.low:
        return "secondary";
      default:
        break;
    }
  };
  let selectedColor = colorSelect(text);

  return (
    <Badge className="badge-dot badge-dot-xs" color={selectedColor}>
      {toCapitalize(text)}
    </Badge>
  );
};

export default UrgencyBadge;
