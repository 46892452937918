import React from "react";
import { useState } from "react";
import { Col, Form } from "reactstrap";
import {
  AltitudeModal,
  AmenityDropdown,
  FormInput,
  FormInputType,
  RSelect,
  Row,
} from "../../../components/Component";
import { useEffect } from "react";
import {
  bathTypes,
  covertToNumberOnly,
  numberFormatter,
  roomTypes,
} from "../../../utils";
import { savePropertyUnit } from "./PropertyData";

const AddUnit = ({ isOpen, propertyDetails, item, onSave, onCancel }) => {
  const [formData, setFormData] = useState();
  const [errorVal, setError] = useState();
  const [loading, setLoading] = useState(false);
  const [isDataError, setIsDataError] = useState(false);

  useEffect(() => {
    if (item) {
      let rooms = null;
      let roomsList = roomTypes.filter((data) => item.rooms === data.value);
      if (roomsList.length === 1) {
        rooms = roomsList[0];
      }

      let baths = null;
      let bathsList = bathTypes.filter((data) => item.bath === data.value);
      if (bathsList.length === 1) {
        baths = bathsList[0];
      }

      let amenities = [];
      for (let index = 0; index < item?.amenities?.length ?? 0; index++) {
        const element = item?.amenities[index];
        amenities.push({
          id: element.id,
          label: element.name,
          value: element.name,
        });
      }

      setFormData({
        unitNumber: item?.unitName,
        marketRent: numberFormatter("", "" + (item?.marketRent ?? "")),
        size: item?.size ? "" + item?.size : "",
        rooms: rooms,
        baths: baths,
        description: item?.description,
        amenities: amenities,
      });
    }
  }, [item]);

  const onInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const resetForm = () => {
    setFormData({});
    setLoading(false);
    setError();
    setIsDataError();
  };

  const onFormCancel = () => {
    resetForm();
    onCancel();
  };

  //----------------------API-------------------------
  const saveUnit = () => {
    setLoading(true);
    const params = {
      id: item?.id ?? null,
      propertyId: propertyDetails?.id,
      unitName: formData?.unitNumber,
      streetAddress: propertyDetails?.address?.fullAddress,
      marketRent: parseFloat(covertToNumberOnly(formData?.marketRent ?? 0)),
      size: formData?.size ? parseInt(formData?.size) : undefined,
      rooms: formData?.rooms?.value,
      baths: formData?.baths?.value,
      description: formData?.description,
      amenitiesIds: formData?.amenities?.map((item) => item.id),
    };

    savePropertyUnit(params, (data, error) => {
      if (error === null) {
        onSave();
        onFormCancel();
      } else {
        setError(error?.message);
      }
      setLoading(false);
    });
  };

  const onFormSubmit = () => {
    var isError = false;
    if (
      (formData?.unitNumber?.length ?? 0) === 0 ||
      // (formData?.size?.length ?? 0) === 0 ||
      (formData?.marketRent?.length ?? 0) === 0
    ) {
      isError = true;
    }
    setIsDataError(isError);
    if (!isError) {
      saveUnit();
    }
  };

  return (
    <React.Fragment>
      <AltitudeModal
        size={"lg"}
        isShowFooter={true}
        isOpen={isOpen}
        loading={loading}
        disabled={loading}
        saveButtonTitle={item ? "Save" : "Add unit"}
        toggle={() => onFormCancel()}
        title={item ? "Edit unit" : "Add unit"}
        onSave={() => onFormSubmit()}
        error={errorVal}
      >
        <Form className="gy-4" autoComplete="off">
          {/* <h6>Unit information</h6> */}
          <Row className="mb-3">
            <Col md="4" className="mt-2 mt-md-0 ">
              <div className="form-group">
                <label className="form-label">Unit number</label>
                <input
                  type="text"
                  name="unitNumber"
                  value={formData?.unitNumber ?? ""}
                  onChange={(e) => onInputChange(e)}
                  className="form-control"
                />
                {isDataError && (formData?.unitNumber?.length ?? 0) === 0 && (
                  <span className="invalid">This field is required</span>
                )}
              </div>
            </Col>
            {/* <Col md="4" className="mt-2 mt-md-0 ">
              <div className="form-group">
                <label className="form-label">Market rent </label>
                <div className="input-group ">
                  <span className="input-group-text">$</span>
                  <input
                    type="text"
                    name="marketRent"
                    value={numberFormatter("", formData?.marketRent ?? "")}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        [e.target.name]: e.target.value?.replaceAll(
                          /[^\d.]/g,
                          ""
                        ),
                      });
                    }}
                    className="form-control"
                  />
                </div>
                {isDataError && (formData?.marketRent?.length ?? 0) === 0 && (
                  <span className="invalid">This field is required</span>
                )}
              </div>
            </Col> */}
            <Col md="4" className="mt-1">
              <div className="form-group">
                <div className="form-control-wrap">
                  <FormInput
                    labelGroupClass="mt-n1"
                    label={"Market rent"}
                    type={FormInputType.amount}
                    value={formData?.marketRent}
                    onTextChange={(text) =>
                      setFormData({
                        ...formData,
                        marketRent: text,
                      })
                    }
                  />
                  {isDataError &&
                    (formData?.marketRent === undefined ||
                      formData?.marketRent?.length === 0) && (
                      <p className="invalid mt-n20">This field is required</p>
                    )}
                </div>
              </div>
            </Col>
            <Col md="4" className="mt-2 mt-md-0 ">
              <div className="form-group">
                <label className="form-label">Size</label>
                <div className="input-group">
                  <input
                    type="text"
                    name="size"
                    value={numberFormatter("", formData?.size ?? "")}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        [e.target.name]: e.target.value?.replaceAll(
                          /[^\d.]/g,
                          ""
                        ),
                      });
                    }}
                    className="form-control"
                    maxLength={30}
                  />
                  <div className="input-group-append">
                    <span className="input-group-text">
                      sf<sup> 2</sup>
                    </span>
                  </div>
                </div>
                {/* {isDataError && (formData?.size?.length ?? 0) === 0 && (
                  <span className="invalid">This field is required</span>
                )} */}
              </div>
            </Col>
            <Col md="12" className="mt-4">
              <div className="form-group">
                <label className="form-label">Street address</label>
                <input
                  type="text"
                  name="streetAddress"
                  value={propertyDetails?.address?.fullAddress ?? ""}
                  onChange={(e) => onInputChange(e)}
                  className="form-control"
                  readOnly
                />
              </div>
            </Col>
          </Row>
          <h6>Listing information </h6>
          <Row className="mb-3">
            <Col md="6" className="mt-2 mt-md-0 ">
              <div className="form-group">
                <label className="form-label">Rooms</label>
                <div className="form-control-wrap">
                  <RSelect
                    options={roomTypes}
                    value={formData?.rooms}
                    placeholder=""
                    onChange={(e) => setFormData({ ...formData, rooms: e })}
                  />
                </div>
              </div>
            </Col>
            <Col md="6" className="mt-2 mt-md-0 ">
              <div className="form-group">
                <label className="form-label">Baths</label>
                <div className="form-control-wrap">
                  <RSelect
                    options={bathTypes}
                    value={formData?.baths}
                    placeholder=""
                    onChange={(e) => setFormData({ ...formData, baths: e })}
                  />
                </div>
              </div>
            </Col>
            <Col md="12" className="mt-4">
              <div className="form-group">
                <label className="form-label">Amenities</label>
                <div className="form-control-wrap">
                  <AmenityDropdown
                    value={formData?.amenities}
                    isMulti={true}
                    onSelect={(e) => {
                      setFormData({ ...formData, amenities: e });
                    }}
                  />
                </div>
              </div>
            </Col>
            <Col md="12" className="mt-4">
              <div className="form-group">
                <label className="form-label">Description</label>
                <textarea
                  type="text"
                  name="description"
                  value={formData?.description ?? ""}
                  onChange={(e) => onInputChange(e)}
                  className="form-control"
                />
              </div>
            </Col>
          </Row>
        </Form>
      </AltitudeModal>
    </React.Fragment>
  );
};

export default AddUnit;
