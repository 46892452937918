export { AppText } from "./AppText";
export {
  formatPhoneNumber,
  numberFormatter,
  numberFormatterWithDecimal,
  isNumberOnly,
  toCapitalize,
  propertyTypeToObject,
  propertyTypesToString,
  listAsNewLineString,
  removeTimeFromServerDateString,
  getDateTimeFromString,
  propertyTypesToStringWithComma,
  newlineText,
  statusColor,
  openLinkInNewTab,
  quoteRequestRecurringTypeText,
  showSuccessToast,
  showErrorToast,
  showInfoToast,
  isValidPassword,
  dateTimeForChat,
  openWebsite,
  isValidPhone,
  isValidEmail,
  covertToNumberOnly,
  getPayoutAccountData,
  convertToDropdownItem,
  convertToDropdownList,
  getNumberAsText,
} from "./Functions";
export {
  OTPVerificationType,
  businessSizes,
  services,
  AuthPaths,
  quoteRequestRecurringType,
  isToday,
} from "./Utils";

export const DATE_TIME_FORMAT = "MM/DD/YYYY, hh:mm a";

export const TIME_FORMAT = "h:mm a";

export const DATE_FORMAT = "MM/DD/YYYY";

export const COUNTRY_CODE = "+1";
export const CURRENCY_CODE = "$";
export const PHONE_REGX = /^\([0-9]{3}\) [0-9]{3} [0-9]{4}$/i;
export const PASSWORD_REGX =
  /^(?=.*\d)(?=.*[!@#$%&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
export const EMAIL_REGX = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
export const PHONE_REGX_ONLYDIGIT =
  /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/i;
export const POSTALCODE_REGX = /^([A-Z]{1,2}\d{1,2}[A-Z]?)\s?(\d[A-Z]{2})$/i;
export const PASSWORD_TOOL_TIP = (
  <p className="text-start">
    Password must contain:
    <br />• One capital letter.
    <br />• One lowercase letter.
    <br />• One digit.
    <br />• One special character (e.g. !@#$%&*)
    <br />• at least 8 characters.
  </p>
);
export const SERVICE_FEES =
  "The service fee covers bank, payment agent, and processing network fees to ensure the safety and security of your transaction. Please understand your property management company does not receive any portion of these fees.";
export const PROJECT_START_FLEXIBLE_TOOL_TIP = (
  <p className="text-start">Project start is flexible</p>
);
export const TERMS_AND_CONDITIONS =
  "https://doc.clickup.com/8580875/d/h/85vrb-3648/7d6d78811b9df41";
export const PRIVACY_POLICY =
  "https://doc.clickup.com/8580875/d/h/85vrb-3670/a498f175797cd05";
export const HELP =
  "https://doc.clickup.com/8580875/p/h/85vrb-3650/7df2ee1a1930e0c";

export const propertiesTypes = [
  { value: "OFFICE", label: "Office", theme: "purple" },
  { value: "RETAIL", label: "Retail", theme: "primary" },
  { value: "INDUSTRIAL", label: "Industrial", theme: "purple" },
  { value: "APPARTMENT", label: "Apartment", theme: "pink" },
  { value: "CONDO", label: "Condo", theme: "orange" },
  { value: "TOWNHOUSE", label: "Townhouse", theme: "blue" },
  { value: "MIXED_USE", label: "Mixed-use", theme: "red" },
];
export const roomTypes = [
  { value: "Studio", label: "Studio" },
  { value: "1 Bed", label: "1 Bed" },
  { value: "2 Beds", label: "2 Beds" },
  { value: "3 Beds", label: "3 Beds" },
  { value: "4 Beds", label: "4 Beds" },
  { value: "5 Beds", label: "5 Beds" },
  { value: "6 Beds", label: "6 Beds" },
  { value: "7 Beds", label: "7 Beds" },
  { value: "8 Beds", label: "8 Beds" },
  { value: "9+ Beds", label: "9+ Beds" },
];
export const bathTypes = [
  { value: "1 Bath", label: "1 Bath" },
  { value: "1.5 Baths", label: "1.5 Baths" },
  { value: "2 Baths", label: "2 Baths" },
  { value: "2.5 Baths", label: "2.5 Baths" },
  { value: "3 Baths", label: "3 Baths" },
  { value: "3.5 Baths", label: "3.5 Baths" },
  { value: "4 Baths", label: "4 Baths" },
  { value: "4.5 Baths", label: "4.5 Baths" },
  { value: "5 Bath", label: "5 Bath" },
  { value: "5+ Bath", label: "5+ Bath" },
];
export const amenitiesTypes = [
  { value: "1 Bath", label: "1 Bath" },
  { value: "1.5 Bath", label: "1.5 Bath" },
  { value: "2 Bath", label: "2 Bath" },
  { value: "5 Bath", label: "5 Bath" },
  { value: "5+ Bath", label: "5+ Bath" },
];

export const NewPasswordType = {
  forgotPassword: "forgot password",
  changePassword: "change password",
};

export const statusTypes = [
  { value: "ALL", label: "All" },
  { value: "DRAFT", label: "Draft" },
  { value: "PUBLISHED", label: "Published" },
  { value: "QUOTED", label: "Quoted" },
  { value: "CONFIRMED", label: "Confirmed" },
  { value: "COMPLETE", label: "Complete" },
  { value: "CLOSED", label: "Closed" },
  { value: "REOPENED", label: "Reopened" },
  { value: "DELETED", label: "Deleted" },
];

export const StatusType = {
  draft: "DRAFT",
  published: "PUBLISHED",
  quoted: "QUOTED",
  confirmed: "CONFIRMED",
  complete: "COMPLETE",
  closed: "CLOSED",
  reopened: "REOPENED",
  deleted: "DELETED",
  rejected: "REJECTED",
};

export const vendorStatusType = [
  { value: "ALL", label: "All vendors" },
  { value: "PREFERRED", label: "Preferred" },
];

export const SuggestionType = {
  service: "Service",
  association: "Association",
};

export const MaintenanceRequestStatusTypes = [
  //{ value: "ALL", label: "All" },
  { value: "OPEN", label: "Open" },
  { value: "IN_PROGRESS", label: "In progress" },
  { value: "CLOSED", label: "Closed" },
];

export const MaintenanceRequestStatusType = {
  open: "OPEN",
  inprogress: "IN_PROGRESS",
  closed: "CLOSED",
};

export const RequestActivtyType = {
  status: "STATUS_UPDATE",
  comment: "NEW_COMMENT",
  file: "FILE",
  created: "CREATED",
  removedComment: "REMOVED_COMMENT",
  removedFile: "REMOVED_FILE",
};

export const URGENCY = {
  low: "LOW",
  high: "HIGH",
};

export const RadioOptionTypes = {
  status: "status",
  yesOrNo: "Yes or No",
  urgency: "urgency",
};

export const CalendarEventType = {
  quotationRequest: "Quotation request",
  maintenanceRequest: "Maintenance request",
};
