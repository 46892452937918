import moment from "moment";
import StatusBadge from "../../../components/Badge/StatusBadge";
import { BlueText, UrgencyBadge } from "../../../components/Component";
import MoreOption from "../../../components/more/MoreOption";
import TableTitle from "../../../components/text/TableTitle";
import { apiService, toPageInfo } from "../../../services/api";
import { APIServicePath } from "../../../services/api/api-path";

import {
  RequestActivtyType,
  DATE_FORMAT,
  MaintenanceRequestStatusTypes,
  TIME_FORMAT,
  convertToDropdownItem,
} from "../../../utils";
import { Media } from "react-data-table-component";

const caseInsensitiveSort = (rowA, rowB) => {
  const a = rowA?.urgency.toLowerCase();
  const b = rowB?.urgency.toLowerCase();

  if (a > b) {
    return 1;
  }

  if (b > a) {
    return -1;
  }

  return 0;
};

const sortOnCreatedDate = (rowA, rowB) => {
  const a = moment(rowA?.createdAt).format(DATE_FORMAT);
  const b = moment(rowB?.createdAt).format(DATE_FORMAT);

  if (a > b) return 1;
  if (b > a) return -1;
  return 0;
};

const sortOnStatus = (rowA, rowB) => {
  const a = rowA?.status.toLowerCase();
  const b = rowA?.status.toLowerCase();
  if (a > b) return 1;
  if (b > a) return -1;
  return 0;
};

export const MaintenanceTableData = (onEdit, onView, onRemove) => {
  return [
    {
      name: <TableTitle title={"Created on"} className={"ms-2"} />,
      selector: (row) => (
        <span className="ms-2">
          {row?.createdAt ? moment(row?.createdAt).format(DATE_FORMAT) : ""}
        </span>
      ),
      sortable: true,
      sortFunction: sortOnCreatedDate,
      width: "130px",
      hide: Media.SM,
    },
    {
      name: <TableTitle title={"Description"} />,
      compact: false,
      selector: (row) => (
        <BlueText
          title={row?.description ?? ""}
          onClick={(ev) => {
            ev.preventDefault();
            onView(row);
          }}
        />
      ),
      width: "250px",
    },
    {
      name: <TableTitle title={"Status"} />,
      selector: (row) => <StatusBadge text={row?.status ?? ""} />,
      sortable: true,
      sortFunction: sortOnStatus,
    },
    {
      name: <TableTitle title={"Urgency"} />,
      selector: (row) => <UrgencyBadge text={row?.urgency ?? ""} />,
      sortable: true,
      sortFunction: caseInsensitiveSort,
      hide: Media.MD,
    },
    {
      name: <TableTitle title={"Scheduled date"} />,
      selector: (row) =>
        row.scheduleFor ? moment(row.scheduleFor).format(DATE_FORMAT) : "-",
      // sortable: true,
      width: "130px",
      hide: Media.MD,
    },
    {
      name: <TableTitle title={"Scheduled time"} />,
      selector: (row) =>
        row.scheduleFor || row.scheduleEnd
          ? moment(row.scheduleFor).format(TIME_FORMAT) +
            (row.scheduleEnd
              ? " - " + moment(row.scheduleEnd).format(TIME_FORMAT)
              : "")
          : "-",
      sortable: false,
      width: "165px",
      hide: Media.MD,
    },
    {
      name: <TableTitle title={"Category"} />,
      selector: (row) => row?.category?.label ?? "",
      sortable: true,
      grow: 2,
      hide: Media.LG,
      width: "150px",
    },
    {
      name: <TableTitle title={"Property"} />,
      selector: (row) => row?.property?.label ?? "",
      sortable: true,
      hide: Media.LG,
      width: "150px",
    },
    {
      name: <TableTitle title={"Unit"} />,
      selector: (row) => row.unit?.label ?? "",
      sortable: true,
      hide: Media.LG,
      width: "80px",
    },
    {
      name: <TableTitle title={""} align="end" />,
      right: true,
      width: "60px",
      // allowOverflow: true,
      // button: true,
      cell: (row) => (
        <MoreOption
          options={[
            {
              icon: "edit",
              text: "Edit",
              action: () => {
                onEdit(row);
              },
            },
            // {
            //   icon: "trash",
            //   text: "Remove",
            //   action: () => {
            //     onRemove(row);
            //   },
            // },
          ]}
        />
      ),
    },
  ];
};

// ----------------------------------API-----------------------------//
export function fetchCategoryList(params, callback) {
  apiService
    .postCall(APIServicePath.categoryList, params)
    .then((auth) => {
      callback(auth, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}
export function maintenanceRequestSaveApi(params, callback) {
  apiService
    .postCall(APIServicePath.maintenanceRequest, params)
    .then((auth) => {
      callback(auth, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function maintenanceRequestList(params, callback) {
  apiService
    .postCall(APIServicePath.maintenanceRequestList, params)
    .then((result) => {
      let list = toMaintenanceRequestList(result.list);
      let pageInfo = result.pageInfo ? toPageInfo(result.pageInfo) : null;
      callback({ list, pageInfo }, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function getTenantList(unitId, callback) {
  apiService
    .postCall(APIServicePath.unitTenantId, { unitId: unitId })
    .then((object) => {
      let data = toStringOfTenants(object.list);
      callback(data, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function maintenanceRequestDetailApi(params, callback) {
  apiService
    .postCall(APIServicePath.maintenanceRequestDetail, params)
    .then((data) => {
      let detail = toMaintenanceRequest(data.data);
      callback(detail, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function maintenanceStatusUpdateApi(params, callback) {
  apiService
    .postCall(APIServicePath.maintenanceStatusUpdate, params)
    .then((auth) => {
      callback(auth, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function maintenanceRequestCommentApi(params, callback) {
  apiService
    .postCall(APIServicePath.maintenanceRequestComment, params)
    .then((auth) => {
      callback(auth, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}
export function maintenanceRequestCommentListApi(params, callback) {
  apiService
    .postCall(APIServicePath.maintenanceActivityList, params)
    .then((data) => {
      callback(
        {
          list: toMaintenanceRequestActivityList(data?.list),
          pageInfo: data?.pageInfo,
        },
        null
      );
    })
    .catch((error) => {
      callback(null, error);
    });
}

//------------------------------ convertor------------------------//

export const toMaintenanceRequestActivityList = (response) => {
  let list = [];
  for (let index = (response?.length ?? 0) - 1; index >= 0; index--) {
    const element = response[index];
    list.push(toMaintenanceRequestActivity(element));
  }
  return list;
};

export const toMaintenanceRequestActivity = (item) => {
  let updates = null;
  if (item.eventType === RequestActivtyType.status) {
    let status = item.eventValue;
    updates = {
      old: MaintenanceRequestStatusTypes.find(
        (item) => item.value === status.old
      ),
      new: MaintenanceRequestStatusTypes.find(
        (item) => item.value === status.new
      ),
    };
  }
  return {
    id: item.id,
    type: item.eventType,
    statusUpdate: updates,
    comment: item?.comment,
    time: item?.createdAt ? moment(item?.createdAt).format("hh:mm A") : null,
    date: item?.createdAt ? moment(item?.createdAt).format(DATE_FORMAT) : null,
    userInfo: item?.userInfo ? item?.userInfo : null,
    files: item?.files,
    eventValue: item?.eventValue,
  };
};

export const dropDownConvertor = (item) => {
  let list = [];
  item?.map((item) => {
    let object = {
      id: item?.id,
      value: item?.name ?? item?.title,
      label: item?.name ?? item?.title,
    };
    list.push(object);
  });
  return list;
};

const toStringOfTenants = (items) => {
  let email = [];
  let phone = [];
  for (let index = 0; index < items?.length; index++) {
    const item = items[index];
    email.push(item.email);
    phone.push(item.phone);
  }

  return {
    emails: email.join(", "),
    phones: phone.join(", "),
  };
};

const toMaintenanceRequestList = (items) => {
  let list = [];
  for (let index = 0; index < items.length; index++) {
    const element = items[index];
    list.push(toMaintenanceRequest(element));
  }
  return list;
};

const toMaintenanceRequest = (item) => {
  const files = item?.mediaData?.map((item) => {
    return { ...item, isOldFile: true };
  });
  return {
    id: item?.id,
    status: item?.status,
    property: item?.property && convertToDropdownItem(item.property),
    unit: item?.unit && convertToDropdownItem(item.unit),
    category: item?.category && convertToDropdownItem(item.category),
    description: item?.description,
    files: files,
    isEnterPermission: item?.isEnterPermission,
    urgency: item?.urgency,
    entryInstruction: item?.entryInstruction,
    contactEmails: item?.contactEmails,
    contactPhones: item?.contactPhones,
    pmId: item?.pmId,
    createdAt: item?.createdAt,
    scheduleFor: item?.scheduleFor,
    scheduleEnd: item?.scheduleEnd,
    tenantId: item?.tenantId,
    unitTenantId: item?.unitTenantId,
    pmChatId: item?.pmUser?.sbId,
    tenantChatId: item?.tenantUser?.sbId,
  };
};
