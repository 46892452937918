import { apiService } from "../../../services/api";
import { APIServicePath } from "../../../services/api/api-path";

export function fetchTenantList(params, callback) {
  apiService
    .tenantList(params)
    .then((auth) => {
      callback(auth, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function fetchTenantAssociateWithPM(params, callback) {
  apiService
    .tenantAssociateWithPM(params)
    .then((data) => {
      let list = toTenantList(data.list);
      callback({ list: list, pageInfo: data.pageInfo }, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function inviteTenant(params, callback) {
  apiService
    .postCall(APIServicePath.inviteTenant, params)
    .then((auth) => {
      callback(auth, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function removeTenantByPM(params, callback) {
  apiService
    .postCall(APIServicePath.tenantContractComplete, params)
    .then((data) => {
      callback(data, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function fetchTenantPaymentDetails(params, callback) {
  apiService
    .postCall(APIServicePath.rentPaymentDetails, params)
    .then((data) => {
      let remain = toRemainPayment(data);
      callback(remain, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}
export function getPaymentFeeAmount(params, callback) {
  apiService
    .postCall(APIServicePath.paymentFee, params)
    .then((data) => {
      callback(data, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function payRentAmount(params, callback) {
  apiService
    .postCall(APIServicePath.payRent, params)
    .then((data) => {
      callback(data, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function tenantDetails(params, callback) {
  apiService
    .postCall(APIServicePath.tenantDetail, params)
    .then((data) => {
      let tenant = toTenant(data.data);
      callback(tenant, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

// *****************************************************************

function toTenantList(list) {
  let tenants = [];
  for (let index = 0; index < list?.length ?? 0; index++) {
    const element = list[index];
    tenants.push(toTenant(element));
  }
  return tenants;
}

function toTenant(item) {
  let name =
    (item.firstName?.trim() ?? "") + " " + (item.lastName?.trim() ?? "");

  return {
    id: item.id,
    name: name.trim(),
    image: item.image,
    firstName: item.firstName?.trim() ?? "",
    lastName: item.lastName?.trim() ?? "",
    email: item.email ?? item?.invitedData?.email,
    phone: item.phone,
    chatId: item?.sbId,
    property: {
      name: item.property?.name,
    },
    unit: item.unit
      ? {
          id: item.unit?.id,
          name: item.unit?.unitName,
          address: item.unit?.streetAddress,
        }
      : null,
    unitTenant: {
      id: item.unitTenantData?.id,
      rent: item.unitTenantData?.rentAmount,
      dueAmount:
        item.unitTenantData?.totalDueAmount -
        item.unitTenantData?.totalProcessingAmount,
      status: item.unitTenantData?.status,
      endDate: item?.unitTenantData?.endDate,
      startDate: item?.unitTenantData?.startDate,
    },
  };
}

function toRemainPayment(item) {
  let data = {};
  if (item.bankInfo) {
    data.bank = {
      name: item.bankInfo.bankName,
      last4: item.bankInfo.last4,
      status: item.bankInfo.status,
    };
  }

  if (item?.list?.length > 0) {
  }

  data.totalDue = item?.summary?.totalAmount ?? 0;
  // data.serviceFee = item?.summary?.totalServiceFees ?? 0;
  data.underProcessAmount = item?.summary?.totalProcessingFees ?? 0;
  data.amountNeedToPay =
    (item?.summary?.totalAmount ?? 0) >
    (item?.summary?.totalProcessingFees ?? 0)
      ? (item?.summary?.totalAmount ?? 0) -
        (item?.summary?.totalProcessingFees ?? 0)
      : 0;
  return data;
}
