import React from "react";
import Head from "../../../head/Head";

const UserProfileNotificationPage = ({ sm, updateSm }) => {
  return (
    <React.Fragment>
      <Head title="Settings"></Head>
    </React.Fragment>
  );
};
export default UserProfileNotificationPage;
