import React from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { Alert, Col, Form } from "reactstrap";
import { AltitudeModal, Icon } from "../../../components/Component";
import { useEffect } from "react";
import { fetchSavePropertyOwner } from "./PropertyData";

const AddOwner = ({
  isOpen,
  isEdit,
  propertyDetails,
  item,
  currentHoldingPercentage,
  onSave,
  onCancel,
}) => {
  const [formData, setFormData] = useState({});
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [errorVal, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [isDataError, setIsDataError] = useState(false);
  const { errors, register, handleSubmit } = useForm();

  useEffect(() => {
    if (isOpen) {
      setFormData(item);
    }
  }, [item]);

  const onInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const resetForm = () => {
    setFormData({});
    setLoading(false);
    setSelectedAddress(null);
    setIsDataError(false);
    setError(null);
  };

  const onFormCancel = () => {
    resetForm();
    onCancel();
  };

  const onFormSubmit = () => {
    var isError = false;
    let percentage = parseFloat(formData?.percentage ?? 0);
    if (
      (formData?.firstName?.length ?? 0) === 0 ||
      (formData?.lastName?.length ?? 0) === 0 ||
      percentage > 100.0
    ) {
      isError = true;
    }
    setIsDataError(isError);
    if (!isError) {
      if (currentHoldingPercentage + percentage > 100.0) {
        setError(
          "Double-check a portion of other owners and make sure that in total it's no more than 100%"
        );
      } else {
        callSavePropertyOwner();
      }
    }
  };

  // API--------------------------------------->

  const callSavePropertyOwner = () => {
    setLoading(true);
    let params = {
      id: isEdit ? item.id : null,
      propertyId: propertyDetails?.id,
      firstName: formData?.firstName,
      lastName: formData?.lastName,
      percentage: parseInt(formData?.percentage),
    };
    fetchSavePropertyOwner(params, (data, error) => {
      if (error === null) {
        onSave();
        onFormCancel();
      } else {
        setError(error?.message);
      }
      setLoading(false);
    });
  };

  return (
    <React.Fragment>
      <AltitudeModal
        size={"lg"}
        isShowFooter={true}
        isOpen={isOpen}
        loading={loading}
        disabled={loading}
        toggle={() => onFormCancel()}
        title={isEdit ? "Update rental owner" : "Add rental owner"}
        onSave={() => onFormSubmit()}
      >
        {errorVal && (
          <div className="my-2">
            <Alert color="danger" className="alert-icon">
              {" "}
              <Icon name="alert-circle" /> {errorVal}{" "}
            </Alert>
          </div>
        )}
        <div className="mt-2">
          <Form
            className="row gy-2"
            // onSubmit={handleSubmit(onFormSubmit)}
            autoComplete="off"
          >
            <Col md="4">
              <div className="form-group">
                <label className="form-label">First name</label>
                <input
                  type="text"
                  name="firstName"
                  value={formData?.firstName ?? ""}
                  onChange={(e) => onInputChange(e)}
                  className="form-control"
                  maxLength={30}
                />
                {isDataError && (formData?.firstName?.length ?? 0) === 0 && (
                  <span className="invalid">This field is required</span>
                )}
              </div>
            </Col>
            <Col md="4">
              <div className="form-group">
                <label className="form-label">Last name</label>
                <input
                  type="text"
                  name="lastName"
                  value={formData?.lastName ?? ""}
                  onChange={(e) => onInputChange(e)}
                  className="form-control"
                  maxLength={30}
                />

                {isDataError && (formData?.lastName?.length ?? 0) === 0 && (
                  <span className="invalid">This field is required</span>
                )}
              </div>
            </Col>
            <Col md="4">
              <div className="form-group">
                <label className="form-label">Ownership percentage</label>
                <div className="input-group ">
                  <input
                    type="number"
                    name="percentage"
                    value={formData?.percentage ?? ""}
                    onChange={(e) => onInputChange(e)}
                    className="form-control"
                    maxLength={3}
                  />
                  <span className="input-group-text">%</span>
                </div>
                {isDataError && (formData?.percentage?.length ?? 0) === 0 && (
                  <span className="invalid">{errors.percentage?.message}</span>
                )}
                {isDataError &&
                  (formData?.percentage?.length === 0 ||
                  formData?.percentage === undefined ||
                  formData?.percentage > 100 ? (
                    <span className="invalid">
                      Enter valid percentage between 1-100
                    </span>
                  ) : null)}
              </div>
            </Col>
          </Form>
        </div>
      </AltitudeModal>
    </React.Fragment>
  );
};

export default AddOwner;
