import React from "react";
import {
  LocationList,
  propertyList,
  //   unitHistoryList,
} from "../../pages/panel/properties/PropertyData";
import { useState } from "react";
import { RSelect } from "../Component";
import { useEffect } from "react";
import { unitHistoryList } from "./DropdownData";

const LocationDropdown = ({
  tenantId,
  value,
  isMulti = false,
  onSelect,
  isShowAll = false,
  disabled = false,
  ...props
}) => {
  const [list, setList] = useState();

  useEffect(() => {
    !disabled && tenantId && fetchLocations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchLocations = () => {
    unitHistoryList(tenantId, (data, error) => {
      if (error === null) {
        if (isShowAll === false) {
          setList(data);
        } else {
          let all = {
            id: 0,
            label: "All",
            value: "All",
          };
          setList([all, ...data]);
        }
      }
    });
  };
  return (
    <>
      <RSelect
        options={list ?? []}
        isMulti={isMulti}
        isDisabled={disabled}
        placeholder={""}
        value={value ?? (isShowAll ? (list ? list[0] : null) : null)}
        onChange={(e) => {
          if (isShowAll === false) {
            onSelect(e);
          } else {
            e.id === 0 ? onSelect(undefined) : onSelect(e);
          }
        }}
        {...props}
      />
    </>
  );
};

export default LocationDropdown;
