import React from "react";
import { useRef } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Alert, Modal, ModalBody, Spinner } from "reactstrap";
import { Button, Col, Icon, Row } from "../../../../components/Component";
import {
  updatePMCompanyInfo,
  updateUser,
} from "../../../../pages/auth/AuthData";
import { loadScript } from "../../../../services/google/autocomplete";
import { handleScriptLoad } from "../../../../services/google/autocomplete";
import { Storage } from "../../../../services/storage/storage";
import {
  AppText,
  COUNTRY_CODE,
  covertToNumberOnly,
  formatPhoneNumber,
} from "../../../../utils";

export const ManagerUpdateProfileModal = ({
  modal,
  modalTab = "1",
  setModalTab,
  user,
  onClosed,
  onSaved,
}) => {
  const [formData, setFormData] = useState({
    firstName: user?.firstName,
    lastName: user?.lastName,
    companyName: user?.companyInfo?.companyName,
    officeAddress: user?.companyInfo?.address?.fullAddress,
    phone: user?.phone,
    businessSize: user?.companyInfo?.businessSize,
    latitude: user?.companyInfo?.address?.latitude,
    longitude: user?.companyInfo?.address?.longitude,
  });
  const [selectedAddress, setSelectedAddress] = useState(
    formData?.officeAddress
  );
  const autoCompleteRef = useRef(null);
  const [phoneNo, setPhoneNo] = useState(formData?.phone);
  const [loading, setLoading] = useState(false);
  const [errorVal, setError] = useState();

  useEffect(() => {
    if (modal) {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY}&libraries=places`,
        () => handleScriptLoad(onUpdateAddress, autoCompleteRef)
      );
    } else {
      setError();
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modal]);

  const onUpdateAddress = (data) => {
    setFormData({
      ...formData,
      latitude: data?.lat,
      longitude: data?.lng,
      fullAddress: data?.fullAddress,
      city: data?.city,
      state: data?.state,
      country: data?.country,
      zipCode: data?.zipCode,
    });
    setSelectedAddress(data);
  };

  const onInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  function submitForm() {
    setLoading(true);
    if (modalTab === "1") {
      var params = {
        firstName: formData.firstName,
        lastName: formData.lastName,
      };
      updateUser(params, (data, error) => {
        setLoading(false);
        let userInfo = Storage.getLoggedInUser();
        userInfo.user.firstName = formData?.firstName;
        userInfo.user.lastName = formData?.lastName;
        Storage.setLoggedInUser(userInfo);
        if (error === null) {
          onSaved(true);
        } else {
          setError(error.message);
        }
      });
    } else {
      var params = {
        companyName: formData.companyName,
        latitude: formData.latitude,
        longitude: formData.longitude,
        fullAddress: formData.fullAddress,
        city: formData.city,
        state: formData.state,
        country: formData.country,
        zipCode: formData.zipCode,
        phone: covertToNumberOnly(formData.phone),
      };

      if (params.latitude === undefined || params.longitude === undefined) {
        setError(AppText.addressError);
        setLoading(false);
        return;
      }
      updatePMCompanyInfo(params, (data, error) => {
        if (error === null) {
          setLoading(false);
          onSaved(false);
        } else {
          setError(error.message);
        }
      });
    }
  }

  return (
    <Modal
      isOpen={modal}
      className="modal-dialog-centered"
      size="lg"
      toggle={() => onClosed()}
      autoComplete="off"
    >
      <ModalBody>
        <a
          href="#dropdownitem"
          onClick={(ev) => {
            ev.preventDefault();
            onClosed();
          }}
          className="close"
        >
          <Icon name="cross-sm"></Icon>
        </a>
        <div className="p-2">
          <h5 className="title">Update profile</h5>
          {errorVal && (
            <div className="my-2">
              <Alert color="danger" className="alert-icon">
                {" "}
                <Icon name="alert-circle" /> {errorVal}{" "}
              </Alert>
            </div>
          )}
          <ul className="nk-nav nav nav-tabs">
            <li className="nav-item">
              <a
                className={`nav-link ${modalTab === "1" && "active"}`}
                onClick={(ev) => {
                  ev.preventDefault();
                  setModalTab("1");
                }}
                href="#personal"
              >
                User
              </a>
            </li>
            <li className="nav-item">
              <a
                className={`nav-link ${modalTab === "2" && "active"}`}
                onClick={(ev) => {
                  ev.preventDefault();
                  setModalTab("2");
                }}
                href="#address"
              >
                Company
              </a>
            </li>
          </ul>
          <div className="tab-content">
            <div
              className={`tab-pane ${modalTab === "1" ? "active" : ""}`}
              id="personal"
            >
              <Row className="gy-4">
                <Col md="6">
                  <div className="form-group">
                    <label className="form-label" htmlFor="full-name">
                      First name
                    </label>
                    <input
                      type="text"
                      id="first-name"
                      className="form-control"
                      name="firstName"
                      onChange={(e) => onInputChange(e)}
                      defaultValue={formData?.firstName ?? ""}
                      placeholder="Enter first name"
                    />
                  </div>
                </Col>

                <Col md="6">
                  <div className="form-group">
                    <label className="form-label" htmlFor="display-name">
                      Last name
                    </label>
                    <input
                      type="text"
                      id="last-name"
                      className="form-control"
                      name="lastName"
                      onChange={(e) => onInputChange(e)}
                      defaultValue={formData?.lastName ?? ""}
                      placeholder="Enter last name"
                    />
                  </div>
                </Col>

                <Col size="12">
                  <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                    <li>
                      <Button
                        color="primary"
                        size="lg"
                        onClick={() => submitForm()}
                      >
                        {loading ? <Spinner size="sm" color="light" /> : "Save"}
                      </Button>
                    </li>
                    <li>
                      <a
                        href="#dropdownitem"
                        onClick={(ev) => {
                          ev.preventDefault();
                          onClosed();
                        }}
                        className="link link-light"
                      >
                        Cancel
                      </a>
                    </li>
                  </ul>
                </Col>
              </Row>
            </div>
            <div
              className={`tab-pane ${modalTab === "2" ? "active" : ""}`}
              id="company"
            >
              <Row className="gy-4">
                <Col md="6">
                  <div className="form-group">
                    <label className="form-label" htmlFor="companyName">
                      Company name
                    </label>
                    <input
                      type="text"
                      id="companyName"
                      name="companyName"
                      onChange={(e) => onInputChange(e)}
                      defaultValue={formData?.companyName ?? ""}
                      className="form-control"
                    />
                  </div>
                </Col>
                <Col md="6">
                  <div className="form-group">
                    <label className="form-label" htmlFor="office-address">
                      Office address
                    </label>
                    <input
                      type="text"
                      id="office-address"
                      name="fullAddress"
                      onChange={(e) => {
                        onUpdateAddress(null);
                      }}
                      defaultValue={formData?.officeAddress ?? ""}
                      autoComplete="off"
                      className="form-control"
                      ref={(event) => {
                        autoCompleteRef.current = event;
                      }}
                    />
                  </div>
                </Col>
                <Col md="6">
                  <div className="form-group">
                    <label className="form-label" htmlFor="phone">
                      Phone
                    </label>
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <span className="input-group-text">{COUNTRY_CODE}</span>
                      </div>
                      <input
                        type="text"
                        id="phone"
                        name="phone"
                        onChange={(e) => {
                          setPhoneNo(e.target.value);
                          onInputChange(e);
                        }}
                        defaultValue={formatPhoneNumber("", formData?.phone)}
                        className="form-control"
                        maxLength={14}
                        minLength={14}
                        value={formatPhoneNumber("", phoneNo)}
                      />
                    </div>
                  </div>
                </Col>

                <Col size="12">
                  <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                    <li>
                      <Button
                        color="primary"
                        size="lg"
                        onClick={() => submitForm()}
                      >
                        {loading ? <Spinner size="sm" color="light" /> : "Save"}
                      </Button>
                    </li>
                    <li>
                      <a
                        href="#dropdownitem"
                        onClick={(ev) => {
                          ev.preventDefault();
                          onClosed();
                        }}
                        className="link link-light"
                      >
                        Cancel
                      </a>
                    </li>
                  </ul>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};
