import React, { useEffect, useState } from "react";
import { Switch, Route, withRouter, Redirect } from "react-router-dom";
import {
  AuthPaths,
  AuthPathsWithoutToken,
  RedirectAs404,
  UserAccountType,
} from "./utils/Utils";
import PrivateRoute from "./route/PrivateRoute";

import Layout from "./layout/Index";

import Error404Classic from "./pages/error/404-classic";
import Error404Modern from "./pages/error/404-modern";
import Error504Modern from "./pages/error/504-modern";
import Error504Classic from "./pages/error/504-classic";

import Faq from "./pages/others/Faq";
import Terms from "./pages/others/Terms";

import AccountType from "./pages/auth/AccountType";
import Login from "./pages/auth/Login";
import Register from "./pages/auth/Register";
import ForgotPassword from "./pages/auth/ForgotPassword";
import CreatePassword from "./pages/auth/CreatePassword";
import Success from "./pages/auth/Success";
import OTPVerification from "./pages/auth/OTPVerification";
import VendorSetup from "./pages/auth/VendorSetup";
import ConfirmationPage from "./pages/auth/Confirmation";
import VerifyEmail from "./pages/auth/VerifyEmail";
import ResetPassword from "./pages/auth/ResetPassword";
import { AuthProvider } from "./pages/auth/AuthContext";
import { Storage } from "./services/storage/storage";
import EmailRequestProcess from "./pages/auth/EmailRequestProcess";
import LoadingComponent from "./layout/spinner/LoadingSpinner";
import { useDispatch } from "react-redux";
import { loadCurrentUser } from "./services/store/userSlice";
import { useSelector } from "react-redux";

const App = (props) => {
  let user = Storage.getLoggedInUser()?.user;
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const data = useSelector((state) => state.user);

  useEffect(() => {
    if(user) {
      setLoading(true)
      dispatch(loadCurrentUser());
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if(data?.loading === false) {
      setTimeout(() => {
        setLoading(false)
      }, 1000)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <>
      <Switch>
        {user &&
          AuthPaths.some((item) => item === props.location.pathname) &&
          ((user.accountType === UserAccountType.manager &&
            user.isEmailVerified) ||
            (user.accountType === UserAccountType.tenant &&
              user.isEmailVerified) ||
            (user.accountType === UserAccountType.vendor &&
              user.isVendorProfileSetup)) && (
            <Redirect to={`${process.env.PUBLIC_URL}`} />
          )}

        {user === undefined &&
          !AuthPathsWithoutToken.some(
            (item) => item === props.location.pathname
          ) && <Redirect to={`${process.env.PUBLIC_URL}/auth-login`} />}

        {/* Auth Pages */}
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/auth-success`}
          component={Success}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/auth-reset`}
          component={ForgotPassword}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/auth-login`}
          component={Login}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/auth-create-password`}
          component={CreatePassword}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/confirmation`}
          component={ConfirmationPage}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/verify-email`}
          component={VerifyEmail}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/reset-password`}
          component={ResetPassword}
        ></Route>

        <Route
          exact
          path={`${process.env.PUBLIC_URL}/account-type`}
          render={() => (
            <AuthProvider>
              <AccountType />
            </AuthProvider>
          )}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/auth-register`}
          render={() => (
            <AuthProvider>
              <Register />
            </AuthProvider>
          )}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/otp-verification`}
          render={() => (
            <AuthProvider>
              <OTPVerification />
            </AuthProvider>
          )}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/vendor-setup`}
          render={() => (
            <AuthProvider>
              <VendorSetup />
            </AuthProvider>
          )}
        />

        {/* Helper pages */}
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/auths/terms`}
          component={Terms}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/auths/faq`}
          component={Faq}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/email-request-process`}
          component={EmailRequestProcess}
        ></Route>
        {/*Error Pages*/}
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/errors/404-classic`}
          component={Error404Classic}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/errors/504-modern`}
          component={Error504Modern}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/errors/404-modern`}
          component={Error404Modern}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/errors/504-classic`}
          component={Error504Classic}
        ></Route>

        {/*Main Routes*/}
        <PrivateRoute exact path="" component={Layout}></PrivateRoute>
        <Route component={RedirectAs404}></Route>
      </Switch>
      {loading && (
          <LoadingComponent isLoading={loading} isFade={false} />
      )}
    </>
  );
};
export default withRouter(App);
