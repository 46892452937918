import React, { useEffect, useState } from "react";
import { tenantList } from "./DropdownData";
import { RSelect } from "../Component";

export const TenantSearchDropdown = ({
  value,
  isMulti = false,
  isShowAll = false,
  onSelect,
  selectedId,
  handleOnSearch,
  placeholder,
  handleOnSelect,
  inputSearchString,
  isMyTenantOnly = false,
  ...props
}) => {
  const [list, setList] = useState([]);
  const [searchString, setSearchString] = useState();

  useEffect(() => {
    // if (searchString === "" || !searchString) {
    //   setList([]);
    // } else {
    //   tenantSearchList();
    // }
    tenantSearchList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchString]);

  const tenantSearchList = () => {
    tenantList(searchString, isMyTenantOnly, (data, error) => {
      if (error === null) {
        setList(data);
      }
    });
  };

  useEffect(() => {
    if (selectedId && !value) {
      const selectedOption = list?.find((option) => option.id === selectedId);
      if (selectedOption) {
        onSelect(selectedOption);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list, selectedId]);

  useEffect(() => {
    if (value) {
      const selectedOption = list?.find((option) => option.id === value.id);
      if (selectedOption) {
        setSearchString(selectedOption.name);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const handleSearch = (text) => {
    setSearchString(text);
  };

  return (
    <>
      <RSelect
        options={list ?? []}
        isMulti={isMulti}
        placeholder={""}
        value={value ?? (isShowAll ? (list ? list[0] : null) : null)}
        onInputChange={handleSearch}
        onChange={(e) => {
          if (isShowAll === false) {
            onSelect(e);
          } else {
            e.id === 0 ? onSelect(undefined) : onSelect(e);
          }
        }}
        {...props}
      />
    </>
  );
};
