import ReactDatePicker from "react-datepicker";
import { Icon } from "../Component";

export const DatePicker = ({
  value,
  onChange,
  timeIcon, // Change the prop name to onChange
  ...props
}) => {
  return (
    <div className="input-group w-100">
      <div className="input-group-prepend w-100">
        {timeIcon ? (
          <Icon className="input-group-text" name="clock" />
        ) : (
          <Icon className="input-group-text" name="calender-date" />
        )}

        <ReactDatePicker
          selected={value}
          className="form-control rounded-0 rounded-end border-start-0"
          onChange={onChange} // Update the prop name to onChange
          popperPlacement="top-end"
          {...props}
        />
      </div>
    </div>
  );
};

export const DateRangePicker = ({
  start,
  end,
  onChangeStart,
  onChangeEnd,
  ...props
}) => {
  return (
    <div className="w-100">
      <div className="input-daterange date-picker-range input-group date-range-picker">
        {/* <Icon className="input-group-text" name="calender-date" /> */}
        <ReactDatePicker
          selected={start}
          startDate={start}
          endDate={end}
          selectsStart
          className="form-control"
          wrapperClassName="start-m"
          onChange={onChangeStart} // Update the prop name to onChange
          {...props}
        />
        <div className="input-group-addon">TO</div>
        <ReactDatePicker
          selected={end}
          startDate={start}
          endDate={end}
          selectsEnd
          className="form-control"
          onChange={onChangeEnd} // Update the prop name to onChange
          wrapperClassName="end-m"
          {...props}
        />
      </div>
    </div>
  );
};
