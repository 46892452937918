import React from "react";
import { Col, Icon, Row } from "../Component";
import { RadioOptionTypes } from "../../utils";

export const RadioButtonOptions = ({
  checkIcon,
  optionType,
  size = 6,
  onChange,
  activeIndex,
  name,
  label,
  links,
  labelClassName,
  filterLabel,
}) => {
  const handleRadioChange = (index) => {
    if (onChange) {
      onChange(index);
    }
  };

  const getTypeOptions = (optionType) => {
    switch (optionType) {
      case RadioOptionTypes.status:
        return ["One-time", "Recurring"];
      case RadioOptionTypes.yesOrNo:
        return ["No", "Yes"];
      case RadioOptionTypes.urgency:
        return ["Low", "High"];
      default:
        return [];
    }
  };
  const options = getTypeOptions(optionType);

  return (
    <Col>
      {label && (
        <label
          className={`form-label mb-1 ${
            filterLabel ? "overline-title overline-title-alt" : null
          }  ${labelClassName ? labelClassName : ""}`}
        >
          {label}
        </label>
      )}
      <ul className="custom-control-group g-3 align-center">
        {options.map((item, index) => {
          return (
            <li key={index}>
              <div
                className={
                  checkIcon
                    ? " "
                    : "custom-control custom-control-sm custom-radio"
                }
              >
                <input
                  className={
                    checkIcon ? " radio-check-custom" : "custom-control-input"
                  }
                  type="radio"
                  id={name + "-" + item + "-" + index}
                  name={name}
                  value={"" + index}
                  onChange={() => handleRadioChange(index)}
                  checked={index === activeIndex ?? false}
                />
                <label
                  className={
                    checkIcon
                      ? " radio-check-custom-label"
                      : "fSize14 custom-control-label"
                  }
                  htmlFor={name + "-" + item + "-" + index}
                >
                  <span>{item}</span>
                </label>
                {links && links[index] && (
                  <span className="ms-1">{links[index]}</span>
                )}
              </div>
            </li>
          );
        })}
      </ul>
    </Col>
  );
};
