import React from "react";
import { Badge } from "reactstrap";
import { toCapitalize } from "../../utils";

export const PaymentStatusType = {
  pending: "PENDING",
  charged: "CHARGED",
  processing: "PROCESSING",
  paid: "PAID",
  failed: "FAILED",
  canceled: "CANCELED",
  deleted: "DELETED",
};

export const PaymentStatusBadge = ({ className, color, status, ...props }) => {
  const colorSelect = (statusText) => {
    switch (statusText) {
      case PaymentStatusType.pending:
        return "orange";
      case PaymentStatusType.charged:
        return "teal";
      case PaymentStatusType.processing:
        return "warning";
      case PaymentStatusType.paid:
        return "success";
      case PaymentStatusType.failed:
        return "danger";
      case PaymentStatusType.canceled:
        return "info";
      case PaymentStatusType.deleted:
        return "gray";
      default:
        break;
    }
  };
  let selectedColor = colorSelect(status);

  return (
    <Badge
      className={`badge-dim fSize14 p-1 bg-${selectedColor}-dim text-${selectedColor} border-0 badge bg-${selectedColor} ${
        className ? className : ""
      }`}
      color={selectedColor}
    >
      <span>
        {toCapitalize(status ?? "")
          .split("_")
          .join(" ")}
      </span>
    </Badge>
  );
};
