import React, { useEffect, useState } from "react";
import Head from "../../../head/Head";
import {
  ActionButton,
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Button,
  ErrorText,
  FormInput,
  FormInputType,
  Icon,
  PayonDropdown,
  RSelect,
} from "../../../../components/Component";
import { Card, Spinner } from "reactstrap";
import { savePMInvoiceSettings } from "./UserData";
import {
  covertToNumberOnly,
  numberFormatter,
  showErrorToast,
  showSuccessToast,
} from "../../../../utils";
import { Storage } from "../../../../services/storage/storage";

const UserInvoiceSettings = ({ sm, updateSm }) => {
  const [selectedDate, setSelectedDate] = useState();
  const [selectedDueDay, setSelectedDueDay] = useState();
  const [lateFee, setLateFee] = useState();
  const [isChanged, setIsChanged] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isError, setError] = useState(false);

  useEffect(() => {
    let info = Storage.getLoggedInUser() ?? {};
    let day = info?.user?.companyInfo?.invoiceDay;
    if (day) {
      setSelectedDate({
        label: "" + day,
        value: day,
      });
    }

    let dueDay = info?.user?.companyInfo?.invoiceDueDay ?? 0;
    setSelectedDueDay({
      label: "" + (dueDay === 0 ? "None" : dueDay),
      value: dueDay,
    });

    let lateFees = info?.user?.companyInfo?.lateFee ?? 0;
    setLateFee(lateFees === 0 ? "0.00" : numberFormatter("", "" + lateFees));
  }, []);

  function saveDate() {
    if (!selectedDate || !selectedDueDay || !lateFee) {
      setError(true);
      return;
    }
    setError(false);
    setLoading(true);
    let params = {
      rentalInvoiceGenerateDay: selectedDate.value,
      dueDays: selectedDueDay.value,
      lateFees: lateFee ? parseFloat(covertToNumberOnly(lateFee)) : null,
    };
    savePMInvoiceSettings(params, (data, error) => {
      if (error === null) {
        let info = Storage.getLoggedInUser();
        let user = { ...info.user };
        let company = { ...user.companyInfo };
        company.invoiceDay = params.rentalInvoiceGenerateDay;
        company.invoiceDueDay = params?.dueDays;
        company.lateFee = params.lateFees;
        user.companyInfo = company;
        info.user = user;
        Storage.setLoggedInUser(info);
        showSuccessToast("Your changes to general invoice settings applied!");
        setIsChanged(false);
      } else {
        showErrorToast(error.message);
      }
      setLoading(false);
    });
  }

  return (
    <React.Fragment>
      <Head title="Settings"></Head>

      <BlockHead size="lg">
        <BlockBetween>
          <BlockHeadContent>
            <BlockTitle tag="h4">General invoice settings</BlockTitle>
            <BlockDes>
              <p>
                These settings affect all recurring charges that you assign to
                tenants.
              </p>
            </BlockDes>
          </BlockHeadContent>
          <BlockHeadContent className="align-self-start d-lg-none">
            <Button
              className={`toggle btn btn-icon btn-trigger mt-n1 ${
                sm ? "active" : ""
              }`}
              onClick={() => updateSm(!sm)}
            >
              <Icon name="menu-alt-r"></Icon>
            </Button>
          </BlockHeadContent>
          <BlockHeadContent className="text-end">
            <ActionButton
              title={"Save"}
              className="me-2"
              onClick={() => saveDate()}
              disabled={!isChanged || loading}
              loading={loading}
            />
          </BlockHeadContent>
        </BlockBetween>
      </BlockHead>

      <Block>
        <Card className="card-bordered shadow-none">
          <div className="card-inner-group">
            <div className="card-inner">
              <div className="between-center flex-wrap g-3">
                <div className="nk-block-text">
                  <h6>Invoice day</h6>
                  <p>
                    Day of each month when all recurring charges applied to
                    tenant balance.
                  </p>
                </div>
                <div className="nk-block-actions flex-shrink-sm-0">
                  <ul className="align-center flex-wrap flex-sm-nowrap gx-3 gy-2">
                    <li className="order-md-last">
                      <div className="form-control-wrap">
                        <PickDay
                          selectedDate={selectedDate}
                          setSelectedDate={(e) => {
                            setSelectedDate(e);
                            setSelectedDueDay({label: "None", value: 0});
                            setIsChanged(true);
                          }}
                          className={"w-150px"}
                        />
                        {isError && !selectedDate && <ErrorText />}
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="between-center flex-wrap g-3 my-3">
                <div className="nk-block-text">
                  <h6>Payment due day</h6>
                  <p>
                    This is the last day when tenant can pay balance without
                    late fee applied.
                    <br />
                    If balance is not $0 on the next day, late fee charge will
                    be applied.
                  </p>
                </div>
                <div className="nk-block-actions flex-shrink-sm-0">
                  <ul className="align-center flex-wrap flex-sm-nowrap gx-3 gy-2">
                    <li className="order-md-last">
                      <div className="form-control-wrap">
                        <PayonDropdown
                          isShowNone={true}
                          className={"w-150px"}
                          value={selectedDueDay}
                          start={(selectedDate?.value ?? 0) + 1}
                          end={(selectedDate?.value ?? 0) - 1 > 1 ? selectedDate.value - 1 : 28}
                          onSelect={(date) => {
                            setSelectedDueDay(date);
                            if(date.value === 0) {
                              setLateFee("0.00")
                            }
                            setIsChanged(true);
                          }}
                        />
                        {isError && !selectedDueDay && <ErrorText />}
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="between-center flex-wrap g-3">
                <div className="nk-block-text">
                  <h6>Late fee amount</h6>
                  <p>
                    If tenant has balance more than $0.00 on the next day after
                    due date,
                    <br />
                    this amount will be charged.
                  </p>
                </div>
                <div className="nk-block-actions flex-shrink-sm-0">
                  <ul className="align-center flex-wrap flex-sm-nowrap gx-3 gy-2">
                    <li className="order-md-last">
                      <FormInput
                        label={"Holiday"}
                        isShowLabel={false}
                        inputClass={"lateFeeWidth"}
                        type={FormInputType.amount}
                        disabled={selectedDueDay?.value === 0}
                        value={lateFee}
                        onTextChange={(text) => {
                          setLateFee(text);
                          setIsChanged(true);
                        }}
                        errorText={
                          isError && !lateFee ? "This field is required" : ""
                        }
                      />
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </Card>
      </Block>
    </React.Fragment>
  );
};

export default UserInvoiceSettings;

export const PickDay = ({ selectedDate, setSelectedDate, className }) => {
  const [options, setOptions] = useState([]);

  useEffect(() => {
    setPickerOptions();
  }, []);

  function setPickerOptions() {
    let list = [];
    for (let index = 0; index < 28; index++) {
      list.push({
        label: "" + (index + 1),
        value: index + 1,
      });
    }
    setOptions(list);
  }

  return (
    <RSelect
      options={options}
      value={selectedDate ? selectedDate : null}
      className={`fSize14 ${className}`}
      onChange={(e) => setSelectedDate(e)}
    />
  );
};
