import React, { useEffect, useState } from "react";
import { DropdownMenu } from "reactstrap";
import { Col, RSelect, Row } from "../../../components/Component";
import DatePicker from "react-datepicker";

export const RequestListFilter = ({
  isVisible,
  serviceList,
  propertyList,
  filteredServices,
  filteredProperties,
  startDate,
  endDate,
  onFilterApply,
  onResetFilter,
}) => {
  const [selectedServices, setSelectedServices] = useState([]);
  const [selectedProperties, setSelectedProperties] = useState([]);
  const [selectedStartDate, setSelectedStartDate] = useState();
  const [selectedEndDate, setSelectedEndDate] = useState();

  useEffect(() => {
    if(isVisible) {
        setSelectedServices(filteredServices);
        setSelectedProperties(filteredProperties);
        setSelectedStartDate(startDate);
        setSelectedEndDate(endDate);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible]);

  return (
    <DropdownMenu
      end
      className="filter-wg dropdown-menu-xl"
      style={{ overflow: "visible" }}
    >
      <div className="dropdown-head">
        <span className="sub-title dropdown-title">Filter</span>
      </div>
      <div className="dropdown-body dropdown-body-rg">
        <Row className="gx-6 gy-3">
          <Col size="12">
            <div className="form-group">
              <label className="overline-title overline-title-alt">
                Service
              </label>
              <RSelect
                options={serviceList}
                isMulti
                placeholder="Select services"
                value={selectedServices}
                onChange={(e) => setSelectedServices(e)}
              />
            </div>
          </Col>
          <Col size="12">
            <div className="form-group">
              <label className="overline-title overline-title-alt">
                Property
              </label>
              <RSelect
                options={propertyList}
                isMulti
                placeholder="Select Property"
                value={selectedProperties}
                onChange={(e) => setSelectedProperties(e)}
              />
            </div>
          </Col>
          <Col size="6">
            <div className="form-group">
              <label className="overline-title overline-title-alt">
                Start
              </label>
              <DatePicker
                selected={selectedStartDate}
                className="form-control"
                popperPlacement="top-end"
                onChange={(date) => {
                  if (selectedEndDate) {
                    var Difference_In_Time =
                      selectedEndDate.getTime() - date.getTime();
                    var Difference_In_Days =
                      Difference_In_Time / (1000 * 3600 * 24);

                    if (Difference_In_Days < 0) {
                      setSelectedEndDate();
                    }
                    setSelectedStartDate(date);
                  } else {
                    setSelectedStartDate(date);
                  }
                }}
              />
            </div>
          </Col>
          <Col size="6">
            <div className="form-group">
              <label className="overline-title overline-title-alt">
                End
              </label>
              <DatePicker
                selected={selectedEndDate}
                className="form-control"
                popperPlacement="top-end"
                onChange={(date) => {
                  if (selectedStartDate) {
                    var Difference_In_Time =
                      date.getTime() - selectedStartDate.getTime();
                    var Difference_In_Days =
                      Difference_In_Time / (1000 * 3600 * 24);
                    if (Difference_In_Days > 0) {
                      setSelectedEndDate(date);
                    }
                  } else {
                    setSelectedEndDate(date);
                  }
                }}
              />
            </div>
          </Col>
          <Col size="12">
            <div className="form-group">
              <button
                type="button"
                className="btn btn-primary"
                onClick={(e) => {
                  e.preventDefault();
                  if (selectedServices.length === 0 && selectedProperties.length === 0 && selectedStartDate === undefined && selectedEndDate === undefined) {
                    onResetFilter();
                  } else {
                    let data = {
                      services: selectedServices, 
                      properties: selectedProperties, 
                      startDate: selectedStartDate,
                      endDate: selectedEndDate
                    };
                    onFilterApply(data);
                  }
                }}
              >
                Filter
              </button>
            </div>
          </Col>
        </Row>
      </div>
      <div className="dropdown-foot between">
        <a
          href="#reset"
          className="clickable"
          onClick={(ev) => {
            ev.preventDefault();
            onResetFilter();
          }}
        >
          Reset Filter
        </a>
      </div>
    </DropdownMenu>
  );
};
